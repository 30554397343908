import { Stack, Typography } from '@mui/material';
import { ActionPlanningActionPlanActionPlanFragment, sub_building_class_enum } from '@predium/client-graphql';
import { translateSubBuildingClassEnum_dynamic } from '@predium/i18n/client';
import { getAreaPerEbfClassOfUse, getBuildingIsMixed, getEbfClassesOfUse, getNetArea } from '@predium/utils';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/icons';
import Iconify from '../../../../components/Iconify';

type Props = {
  actionPlan: ActionPlanningActionPlanActionPlanFragment;
};

const BuildingProfileStatusQuo = ({ actionPlan }: Props) => {
  const { t } = useTranslation();

  const { building } = actionPlan;

  const totalArea = getNetArea(building.areas);
  const portfolioName = actionPlan.scenario.portfolio.name ?? '';

  // AREA TYPE OF USE
  const typesOfUse = [...getEbfClassesOfUse(building.areas)];
  const isMixedUse = getBuildingIsMixed(building.areas);
  const areasByClassOfUse = getAreaPerEbfClassOfUse(building.areas);
  const commercialShare = ((areasByClassOfUse.COMMERCIAL / totalArea) * 100).toFixed(1);
  const residentialShare = ((areasByClassOfUse.RESIDENTIAL / totalArea) * 100).toFixed(1);

  return (
    <Stack direction="row" gap={2}>
      <MetaInfoElement icon={ICONS.BRIEFCASE} value={portfolioName} />
      <MetaInfoElement icon={ICONS.AREA} value={totalArea + ' m²'} />
      {isMixedUse ? (
        <>
          <MetaInfoElement
            icon={ICONS.HOUSE}
            value={
              residentialShare + ' % ' + translateSubBuildingClassEnum_dynamic(sub_building_class_enum.RESIDENTIAL, t)
            }
          />
          <MetaInfoElement
            icon={ICONS.BRIEFCASE}
            value={
              commercialShare + ' % ' + translateSubBuildingClassEnum_dynamic(sub_building_class_enum.COMMERCIAL, t)
            }
          />
        </>
      ) : (
        <MetaInfoElement
          icon={typesOfUse[0] === sub_building_class_enum.COMMERCIAL ? ICONS.BRIEFCASE : ICONS.HOUSE}
          value={translateSubBuildingClassEnum_dynamic(typesOfUse[0], t)}
        />
      )}
      <MetaInfoElement icon={ICONS.CRANE} value={building.year_constructed} />
    </Stack>
  );
};

const MetaInfoElement = ({ icon, value }: { icon: string; value: string | number }) => {
  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      <Iconify icon={icon} width={16} height={16} color="text.secondary" />
      <Typography variant="caption" color="text.secondary">
        {value}
      </Typography>
    </Stack>
  );
};

export default BuildingProfileStatusQuo;
