import { Trans, useTranslation } from 'react-i18next';
import PreToggleButton from '../../../components/presentations/PreToggleButton';

export type UnitSwitcherType = 'per_square_meter' | 'absolute';

type Props = {
  onDisplayBaseChange: (newDisplayBase: UnitSwitcherType) => void;
  isConsumptionAnalysis?: boolean;
};

export default function EsgAnalysisUnitSwitcher({ onDisplayBaseChange, isConsumptionAnalysis = false }: Props) {
  const { t } = useTranslation();

  // <sub> tag does not work, thus falling back to this: https://gist.github.com/unruthless/413930
  const subscriptComponent = {
    sub: (
      <span
        style={{
          fontSize: '75%',
          lineHeight: 0,
          position: 'relative',
          verticalAlign: 'baseline',
          bottom: '-0.25em',
          marginLeft: '0.25em',
        }}
      />
    ),
  };

  const leftValue = isConsumptionAnalysis ? (
    <Trans i18nKey="EsgAnalysisBenchmark_DisplayBasePerSquareMeter_Consumption" components={subscriptComponent} />
  ) : (
    <Trans i18nKey="EsgAnalysisBenchmark_DisplayBasePerSquareMeter_Demand" components={subscriptComponent} />
  );

  return (
    <PreToggleButton
      leftValue={{ key: 'per_square_meter', value: leftValue }}
      rightValue={{ key: 'absolute', value: t('EsgAnalysisBenchmark_DisplayBaseAbsolut') }}
      initialValueType={'per_square_meter'}
      onToggle={onDisplayBaseChange}
      size="small"
      sx={{
        width: 224,
        height: 'fit-content',
        '& .MuiToggleButton-root': {
          width: 112,
          height: 30,
        },
      }}
    />
  );
}
