/* eslint-disable @typescript-eslint/ban-ts-comment */
import { energy_consumer_type_enum } from '@predium/enums';
import {
  translateEnergyConsumerTechnologyTypeEnum_dynamic,
  translateEnergyConsumerTypeEnum_dynamic,
} from '@predium/i18n/client';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TechnologyIcons } from '../../../../../../../../assets/images';
import { SystemRoute } from '../../../../../BuildingTechnology';
import { generateSystemRouteFieldNames } from '../../../../../Common/building.util';
import useBuilding from '../../../../../Context/useBuilding';
import { TechnologyUnitSubTitle } from '../../TechnologyConsumerRoute';
import TechnologyUnitTable, { TableData } from '../../TechnologyUnitTable';
import EmptyTechnologyUnitCard from '../EmptyTechnologyUnit';
import TechnologyUnitCard from '../TechnologyUnit';
import ConsumerAddModal from './ConsumerAddModal';
import ConsumerEditModal from './ConsumerEditModal';
import { getExpenditureFactorTooltip } from './utils';

type Props = {
  energySystemRoute: SystemRoute;
  routeIndex: number;
  showArrow: boolean;
  systemIndex: number;
};

const ConsumerCard: React.FC<Props> = ({ energySystemRoute, routeIndex, showArrow, systemIndex }) => {
  const { t } = useTranslation();
  const REQUIRED_MESSAGE = t('General_Required');

  const {
    energyConsumerType,
    consumerTechnologyType,
    consumerId,
    energySystemType,
    expenditureFactor,
    energyConsumerConstructionYear,
  } = energySystemRoute;

  const [editConsumerModalOpen, setEditConsumerModalOpen] = useState<boolean>(false);
  const [addConsumerModalOpen, setAddConsumerModalOpen] = useState<boolean>(false);

  const { getFieldState } = useFormContext();
  const { building, hasEditAccess } = useBuilding();

  const { consumerTechnologyFieldname, expenditureFactorFieldname, consumerConstructionYearFieldname } =
    //@ts-ignore
    generateSystemRouteFieldNames(energySystemType, systemIndex, routeIndex);

  const isConsumerTechnologyDirty = getFieldState(consumerTechnologyFieldname).isDirty;
  const isEexpenditureFactorDirty = getFieldState(expenditureFactorFieldname).isDirty;
  const isConsumerConstructionYearDirty = getFieldState(consumerConstructionYearFieldname).isDirty;
  const consumerTechnologyError = getFieldState(consumerTechnologyFieldname).error;

  const consumerTableData: TableData[] = [
    {
      label: t('General_YearOfInstallation'),
      value: energyConsumerConstructionYear ?? '-',
      isDirty: isConsumerConstructionYearDirty,
      tooltipProps: {
        labels: {
          title: t('General_YearOfInstallation'),
          body: t('Education_YearOfInstallationBody'),
        },
      },
    },
  ];

  if (energyConsumerType !== energy_consumer_type_enum.COOLING) {
    const label = (() => {
      if (energyConsumerType === energy_consumer_type_enum.VENTILATION) {
        return t('General_HeatRecovery');
      }

      if (energyConsumerType === energy_consumer_type_enum.LIGHTING) {
        return t('General_LightYield');
      }

      return t('General_ExpenditureFactor');
    })();

    const tooltipBody = (() => {
      if (energyConsumerType === energy_consumer_type_enum.VENTILATION) {
        return t('Education_HeatRecoveryBody');
      }

      if (energyConsumerType === energy_consumer_type_enum.LIGHTING) {
        return t('Education_LightYieldBody');
      }

      return getExpenditureFactorTooltip(t, building.address.country_id);
    })();

    consumerTableData.push({
      label,
      value: expenditureFactor ?? '-',
      isDirty: isEexpenditureFactorDirty,
      tooltipProps: {
        labels: {
          title: label,
          body: tooltipBody,
        },
        hide: !getExpenditureFactorTooltip(t, building.address.country_id),
      },
    });
  }

  const title = t('General_Consumer');
  const icon = TechnologyIcons.generator;

  return (
    <>
      {consumerId && energyConsumerType ? (
        <TechnologyUnitCard
          icon={icon}
          title={title}
          subtitle={
            <TechnologyUnitSubTitle
              subtitle={
                translateEnergyConsumerTypeEnum_dynamic(energyConsumerType, t) +
                (consumerTechnologyType
                  ? ', ' + translateEnergyConsumerTechnologyTypeEnum_dynamic(consumerTechnologyType, t, 1)
                  : '')
              }
              isDirty={isConsumerTechnologyDirty}
            />
          }
          showArrow={showArrow}
          body={<TechnologyUnitTable data={consumerTableData} />}
          onSemanticButtonClick={() => {
            hasEditAccess && setEditConsumerModalOpen(true);
          }}
        />
      ) : (
        <EmptyTechnologyUnitCard
          icon={icon}
          title={title}
          showArrow={showArrow}
          onButtonClick={() => {
            hasEditAccess && setAddConsumerModalOpen(true);
          }}
          noDataText={t('DataCollection_Technology_ConsumerNotAvailable')}
          requiredMessage={consumerTechnologyError && REQUIRED_MESSAGE}
        />
      )}

      <ConsumerAddModal
        open={addConsumerModalOpen}
        onClose={() => setAddConsumerModalOpen(false)}
        energySystemRoute={energySystemRoute}
        index={routeIndex}
        systemIndex={systemIndex}
      />

      <ConsumerEditModal
        open={editConsumerModalOpen}
        onClose={() => setEditConsumerModalOpen(false)}
        energySystemRoute={energySystemRoute}
        systemIndex={systemIndex}
      />
    </>
  );
};

export default ConsumerCard;
