import { alpha, Button, Typography, useTheme } from '@mui/material';
import { area_type_enum } from '@predium/enums';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../../components/Iconify';
import { useCalculator } from '../Calculator/CalculatorProvider';
import { TooltipOpenSource, useValidation } from '../Validation/ValidationProvider';
import CalculationTooltip from './CalculationTooltip';

type Props = {
  areaType: area_type_enum;
  /**
   * A more subtile way of displaying the error without text.
   */
  inline: boolean;
};

const SquareMeterRowAlert = ({ areaType, inline }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { onOpenEquation, getAreaAlerts, getIsAreaAlertOpen } = useValidation();
  const { onAreaAlertClose } = useCalculator();

  const alerts = getAreaAlerts(areaType);
  if (alerts.length === 0) {
    return null;
  }

  const handleSelect = () => {
    onAreaAlertClose();

    onOpenEquation({
      equation: alerts[0].equation,
      areaType,
      source: inline ? TooltipOpenSource.SUMMARY : TooltipOpenSource.ROW,
    });
  };

  const open = getIsAreaAlertOpen(
    areaType,
    inline ? [TooltipOpenSource.HEADER, TooltipOpenSource.SUMMARY] : [TooltipOpenSource.ROW],
  );

  const firstAlert = alerts[0];
  const variant = firstAlert.variant;

  const variantStyles = {
    error: {
      icon: 'mdi:alert-circle',
      color: 'error.main',
      inlineColor: 'error.main',
      bgColor: alpha(theme.palette.error.main, 0.16),
      label: t('DataCollectionAreas_EquationErrorLabel'),
    },
    warning: {
      icon: 'mdi:alert',
      color: 'warning.main',
      inlineColor: 'warning.dark',
      bgColor: alpha(theme.palette.warning.main, 0.16),
      label: t('DataCollectionAreas_EquationWarningLabel'),
    },
  }[variant];

  const handleClose = () => {
    if (open) {
      onOpenEquation(null);
    }
  };

  return (
    <CalculationTooltip
      variant={variant}
      labels={alerts.map((alert) => alert.errorLabels)}
      open={open}
      onClose={handleClose}
    >
      <Button
        title={firstAlert.errorLabels.title}
        onClick={handleSelect}
        variant="text"
        sx={{
          minWidth: 'unset',
          px: 0.5,
          ...(!inline && {
            bgcolor: variantStyles.bgColor,
            borderRadius: 0.75,
            px: 1,
            py: 0.5,
            gap: 0.5,
            '&:hover': {
              bgcolor: variantStyles.bgColor,
            },
          }),
        }}
      >
        <Iconify color={variantStyles.color} icon={variantStyles.icon} />
        {!inline && (
          <Typography fontSize={12} fontWeight="bold" color={variantStyles.inlineColor}>
            {variantStyles.label}
          </Typography>
        )}
      </Button>
    </CalculationTooltip>
  );
};

export default SquareMeterRowAlert;
