import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { ICONS } from '../../../assets/icons';
import Iconify from '../../../components/Iconify';

interface ChangeBuildingButtonProps {
  title: string;
  onClick: () => void;
  isSelected: boolean;
  tooltipText: string;
}

const NavigationSelectorButton: React.FC<ChangeBuildingButtonProps> = ({ title, onClick, isSelected, tooltipText }) => {
  const theme = useTheme();

  return (
    <Tooltip title={tooltipText} arrow placement="top">
      <Button
        onClick={onClick}
        sx={{
          color: 'text.secondary',
          background: isSelected ? theme.palette.action.selected : '',
          width: 'fit-content',
        }}
      >
        <Typography variant="h4">{title}</Typography>
        <Iconify icon={ICONS.CHEVRON_DOWN} width={24} height={24} />
      </Button>
    </Tooltip>
  );
};

export default NavigationSelectorButton;
