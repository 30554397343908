import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { fPercent } from '../../../../../libs/utils/src/lib/utils';
import { ICONS } from '../../assets/icons';
import Iconify from '../Iconify';
import Label, { LabelColor } from '../Label';
import { classifyChange } from './WidgetSummary';

type Unit = '%' | 'a';

type Props = {
  change: number;
  unit?: Unit;
  inverse?: boolean;
  digits?: number;
};

export default function WidgetSummaryLabel({ change, unit = '%', inverse = false, digits = 1 }: Props) {
  const { t } = useTranslation();

  let percent = Number(((change ?? 0) * 100).toFixed(digits));
  const changeDirection = classifyChange(percent);
  if (changeDirection === 'neutral') {
    const u = unit === '%' ? '%' : t('General_Year', { count: Math.abs(change) });
    return <Label sx={{ minWidth: '75px' }}>0 {u}</Label>;
  }

  let color: LabelColor;
  let icon: string;
  if (inverse) {
    color = changeDirection === 'positive' ? 'success' : 'error';
    icon = changeDirection === 'positive' ? ICONS.ARROW_DOWN : ICONS.ARROW_UP;
  } else {
    color = changeDirection === 'positive' ? 'error' : 'success';
    icon = changeDirection === 'positive' ? ICONS.ARROW_UP : ICONS.ARROW_DOWN;
  }

  let child;
  switch (unit) {
    case '%':
      if (percent === Infinity) {
        percent = 100;
      } else if (percent === -Infinity) {
        percent = -100;
      } else if (Number.isNaN(percent)) {
        percent = 0;
      }

      child = (
        <Typography variant="caption" fontWeight={700}>
          {fPercent(percent, digits, 'never')}
        </Typography>
      );
      break;
    case 'a':
      const u = t('General_Year', { count: Math.abs(change) });
      child = `${change} ${u}`;
      break;
    default: {
      const exhaustive: never = unit;
      throw new Error(`Unhandled type: ${exhaustive}`);
    }
  }

  return (
    <Label sx={{ minWidth: '75px' }} color={color} startIcon={<Iconify width={16} height={16} icon={icon} />}>
      {child}
    </Label>
  );
}
