import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import {
  EsgAnalysisBuildingBasicDetailsFragment,
  EsgAnalysisGetBenchmarkBuildingsFragment,
} from '@predium/client-graphql';
import { useTranslation } from 'react-i18next';
import { GET_BUILDING_CRREM_CONSUMPTION_ANALYSIS } from '../../../graphql/EsgAnalysis.queries';
import { CrremAnalysis } from '../DemandAnalysis/CrremAnalysis';
import ConsumptionAllocation from './ConsumptionAllocation';
import ConsumptionKPIs from './ConsumptionKPIs';
import ConsumptionOverTime from './ConsumptionOverTime';

type Props = {
  building: EsgAnalysisBuildingBasicDetailsFragment;
  // Optional, because switching the benchmark re-loads the gql query and leaves it loading for a short time
  benchmark?: EsgAnalysisGetBenchmarkBuildingsFragment;
  showTotal: boolean;
  selectedBenchmarkName: string;
  selectedReportingYear: number;
  selectedBuildingId: number;
};

export default function BuildingConsumptionAnalysisTab({
  building,
  benchmark,
  showTotal,
  selectedBenchmarkName,
  selectedReportingYear,
  selectedBuildingId,
}: Props) {
  const { t } = useTranslation();

  const {
    data: crremAnalysisData,
    loading: crremAnalysisLoading,
    previousData: previousConsumptionAnalysisData,
  } = useQuery(GET_BUILDING_CRREM_CONSUMPTION_ANALYSIS, {
    fetchPolicy: 'cache-and-network',
    variables: { buildingId: building.id, year: selectedReportingYear },
  });

  const crremData = crremAnalysisData ?? previousConsumptionAnalysisData;
  const analysisData = crremData?.getBuildingCRREMConsumptionAnalysis;

  const graphStartYear = selectedReportingYear;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sx={{ pt: 3 }}>
        <ConsumptionKPIs
          building={building}
          showTotal={showTotal}
          benchmark={benchmark}
          selectedReportingYear={selectedReportingYear}
        />
      </Grid>
      <Grid item xs={12}>
        <CrremAnalysis
          buildingLegendName={t('General_Object')}
          cumulativeExcessEmissions={analysisData?.cumulativeExcessEmissions}
          graphStartYear={graphStartYear}
          selectedBenchmarkName={selectedBenchmarkName}
          benchmarkCrremAnalysis={benchmark?.crremAnalysis}
          crremAnalysis={analysisData}
          loading={crremAnalysisLoading}
          currentPageView="esg-building"
        />
      </Grid>
      <Grid item xs={12}>
        <ConsumptionOverTime buildingId={selectedBuildingId} showTotal={showTotal} />
      </Grid>
      <Grid item xs={12}>
        <ConsumptionAllocation buildingId={selectedBuildingId} year={selectedReportingYear} isAbsolute={showTotal} />
      </Grid>
    </Grid>
  );
}
