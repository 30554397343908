/**
 * Returns the path to the SVG icon for a given action type.
 *
 * @param {string} actionType - The type of the action.
 * @returns {string} The path to the SVG icon.
 */
export const getActionIcon = (actionType: string | null): string => {
  if (!actionType) {
    return '';
  }
  return `/icons/action_type_icons/${actionType}.svg`;
};
