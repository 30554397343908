import { useApolloClient } from '@apollo/client';
import { Box, Button, Card, Popover, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import Container from '@mui/material/Container';
import {
  DataCollectionSubBuildingConsumptionDraftFragment,
  consumption_source_type_enum,
} from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import IconStylus from '../../../../assets/Icons/consumption/stylus';
import { ICONS } from '../../../../assets/icons';
import Iconify from '../../../../components/Iconify';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import { SemanticButton } from '../../../../components/Tile';
import { WarningModal } from '../../../../components/WarningModal';
import AccessRightsWrapper from '../../../../components/permission-tooltips/AccessRightsWrapper';
import PreDialog from '../../../../components/presentations/PreDialog/PreDialog';
import { GET_BUILDING } from '../../../../graphql/DataCollection.queries';
import DataCollectionSingleConsumptionDraftModal from '../../../../pages/DataCollection/DataCollectionSingleConsumptionDraftModal';
import { useTypeSafeSearchParams } from '../../../../routes';
import { PATHS, SEARCH_PARAMS } from '../../../../routes/paths';
import useBuilding from '../Context/useBuilding';
import SubBuildingConsumptionDraftList from './SubBuildingConsumptionDraftList';
import SubBuildingConsumptionList from './SubBuildingConsumptionList';
import UploadConsumptionInvoiceDraftModal from './UploadConsumptionDraftModal';

export enum ConsumptionTabNamesEnum {
  consumptions = 'consumptions',
  consumption_drafts = 'consumption_drafts',
}

type ConsumptionTabNames = 'consumptions' | 'consumption_drafts';

export default function SubBuildingConsumptionListTabs() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const apollo = useApolloClient();
  const theme = useTheme();
  const navigate = useNavigate();

  const { building } = useBuilding();

  const [uploadNewConsumptionDraftModalOpen, setUploadNewConsumptionDraftModalOpen] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [consumptionSourceTypeToCreate, setConsumptionSourceTypeToCreate] =
    useState<consumption_source_type_enum | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [newConsumptionDraftAfterCreation, setNewConsumptionDraftAfterCreation] =
    useState<DataCollectionSubBuildingConsumptionDraftFragment | null>(null);
  const anchorElementRef = useRef<HTMLDivElement>();
  const createdConsumptionDrafts = useRef<{ id: number }[]>([]);

  const { hasEditAccess } = useBuilding();

  const subBuilding = building.sub_buildings[0];

  const subBuildingTabs = [
    {
      label: t('DataCollection_ConsumptionHistory'),
      value: 'consumptions',
      component: <SubBuildingConsumptionList subBuilding={subBuilding} />,
    },
    ...(hasEditAccess
      ? [
          {
            label: t('DataCollection_ConsumptionsInReview'),
            value: 'consumption_drafts',
            component: <SubBuildingConsumptionDraftList subBuilding={subBuilding} />,
          },
        ]
      : []),
  ];

  const { searchParams, setSearchParams } = useTypeSafeSearchParams(SEARCH_PARAMS.dataCollection.building);
  const [currentTab, setCurrentTab] = useState<ConsumptionTabNames>(
    searchParams.consumptionsTab! ?? subBuildingTabs[0].value,
  );

  useEffect(() => {
    const tab = searchParams.consumptionsTab!;
    if (tab) {
      setCurrentTab(tab);
    }
  }, [searchParams]);

  const handleChange = (_: React.SyntheticEvent, newValue: ConsumptionTabNames) => {
    setCurrentTab(newValue);
    setSearchParams({
      ...searchParams,
      consumptionsTab: newValue as any,
    });
  };

  const refetchSubBuildingData = async () => {
    await apollo.refetchQueries({
      include: [GET_BUILDING],
    });
  };

  const onConsumptionDraftAdded = async (consumptionDraftIds: number[]) => {
    refetchSubBuildingData();

    setUploadNewConsumptionDraftModalOpen(false);
    createdConsumptionDrafts.current = [];

    enqueueSnackbar(t('General_Upload-success'), {
      variant: 'success',
      autoHideDuration: SnackbarTimeouts.Success,
    });

    if (consumptionDraftIds.length === 1) {
      navigate(
        PATHS.dataCollection.buildingConsumptionInvoiceDraft({
          id: subBuilding.building_id,
          draftId: consumptionDraftIds[0],
        }),
      );
    } else {
      setCurrentTab('consumption_drafts');
    }
  };

  return (
    <Container maxWidth={'lg'} sx={{ mb: 4 }}>
      <Stack direction="row" justifyContent="space-between" sx={{ width: '100%', mb: 2 }}>
        <Tabs value={currentTab} variant="standard" onChange={handleChange}>
          {subBuildingTabs.map((tab) => (
            <Tab disableRipple key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>

        <AccessRightsWrapper hasAccess={hasEditAccess}>
          <div ref={anchorElementRef as any}>
            <Button
              variant="contained"
              onClick={() => setOpenAddModal(true)}
              startIcon={<Iconify icon={'ic:baseline-plus'} />}
            >
              {t('DataCollection_AddNewConsumption-button')}
            </Button>
          </div>
        </AccessRightsWrapper>
      </Stack>

      <Popover
        open={openAddModal}
        anchorEl={anchorElementRef.current}
        onClose={() => setOpenAddModal(false)}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <Card sx={{ maxWidth: '500px' }}>
          <Stack>
            <SemanticButton
              sx={{
                padding: 8,
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
                '&:focus': {
                  backgroundColor: theme.palette.action.focus,
                },
              }}
              onClick={() => {
                setUploadNewConsumptionDraftModalOpen(true);
                setOpenAddModal(false);
              }}
            >
              <Box display="flex" alignItems="center" gap={1}>
                <Iconify icon={ICONS.FILEUPLOADOUTLINE} width={20} height={20} color={theme.palette.grey[600]} />
                <Typography fontWeight={700} textAlign="left">
                  {t('DataCollection_DraftsUploadInvoice-title')}
                </Typography>
              </Box>

              <Typography textAlign="left" color="grey.600">
                {t('DataCollection_DraftsUploadInvoice-description')}
              </Typography>
            </SemanticButton>
            <SemanticButton
              sx={{
                padding: 8,
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
                '&:focus': {
                  backgroundColor: theme.palette.action.focus,
                },
              }}
              onClick={() => {
                setConsumptionSourceTypeToCreate(consumption_source_type_enum.MANUAL_ENTRY);
                setIsEditDialogOpen(true);
                setOpenAddModal(false);
              }}
            >
              <Box display="flex" alignItems="center" gap={1}>
                <Iconify icon={ICONS.GAUGE} width={20} height={20} color={theme.palette.grey[600]} />
                <Typography fontWeight={700} textAlign="left">
                  {t('DataCollection_DraftsUploadManualEntry-title')}
                </Typography>
              </Box>

              <Typography textAlign="left" color="grey.600">
                {t('DataCollection_DraftsUploadManualEntry-description')}
              </Typography>
            </SemanticButton>
            <SemanticButton
              sx={{
                padding: 8,
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
                '&:focus': {
                  backgroundColor: theme.palette.action.focus,
                },
              }}
              onClick={() => {
                setConsumptionSourceTypeToCreate(consumption_source_type_enum.ESTIMATION);
                setIsEditDialogOpen(true);
                setOpenAddModal(false);
              }}
            >
              <Box display="flex" alignItems="center" gap={1}>
                <IconStylus />
                <Typography fontWeight={700} textAlign="left">
                  {t('DataCollection_DraftsUploadEstimation-title')}
                </Typography>
              </Box>

              <Typography textAlign="left" color="grey.600">
                {t('DataCollection_DraftsUploadEstimation-description')}
              </Typography>
            </SemanticButton>
          </Stack>
        </Card>
      </Popover>

      {/* Create new single consumption draft */}
      <DataCollectionSingleConsumptionDraftModal
        buildingId={subBuilding.building_id}
        subBuildingId={subBuilding.id}
        isOpen={Boolean(isEditDialogOpen)}
        consumptionSourceType={consumptionSourceTypeToCreate!}
        onClose={() => {
          setIsEditDialogOpen(false);
          setNewConsumptionDraftAfterCreation(null);
          // Set it delayed to avoid flickering
          setTimeout(() => {
            setConsumptionSourceTypeToCreate(null);
          }, 250);
        }}
        consumptionDraftData={newConsumptionDraftAfterCreation}
        setConsumptionDraftData={setNewConsumptionDraftAfterCreation}
      />

      {/* Create new consumption invoice draft */}
      <PreDialog
        type="definedByChildren"
        open={uploadNewConsumptionDraftModalOpen}
        fullWidth
        onClose={() => {
          setUploadNewConsumptionDraftModalOpen(false);
          if (createdConsumptionDrafts.current.length > 0) {
            // only change tab when upload was created and the dialog wasn't closed immediately.
            setCurrentTab('consumption_drafts');
            setWarningModalOpen(true);
            createdConsumptionDrafts.current = [];
          }
          refetchSubBuildingData();
        }}
      >
        <UploadConsumptionInvoiceDraftModal
          buildingId={subBuilding.building_id}
          onConsumptionDraftAdded={onConsumptionDraftAdded}
          createdConsumptionDrafts={createdConsumptionDrafts}
        />
      </PreDialog>

      <WarningModal
        title={t('General_Note')}
        open={warningModalOpen}
        description={t('DataCollection_DraftsUpload_WarningMessage')}
        onAcknowledge={() => {
          setWarningModalOpen(false);
        }}
        onClose={() => {
          setWarningModalOpen(false);
          setCurrentTab('consumption_drafts');
        }}
        buttonText={t('General_Ok')}
      />

      <Box sx={{ mb: 5 }} />

      {subBuildingTabs.map((tab) => {
        const isMatched = tab.value === currentTab;
        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
      })}
    </Container>
  );
}
