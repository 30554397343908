import { Grid } from '@mui/material';
import { country_enum, efficiency_class_enum, ModificationImpactsFragment } from '@predium/client-graphql';
import { getEfficiencyClassByEnergy } from '@predium/client-lookup';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CO2Emissions } from '../CO2Emissions';
import { EnergyWithEfficiencyClass } from './EnergyWithEfficiencyClass';
import { EnergyWithoutEfficiencyClass } from './EnergyWithoutEfficiencyClass';

type Props = {
  modificationImpact: ModificationImpactsFragment;
  isOverview: boolean;
  isSimulated: boolean;
};

const AustriaEnergyAnalysis: React.FC<Props> = ({ modificationImpact, isOverview, isSimulated }) => {
  const { t } = useTranslation();

  // for Austria, we need to lookup the primary energy efficiency class again
  // since it might differ from the heating demand lookup
  const primaryEnergyEfficiencyBefore = modificationImpact.primary_energy?.before
    ? getEfficiencyClassByEnergy(modificationImpact.primary_energy.before, country_enum.AT)
    : efficiency_class_enum.UNKNOWN;
  const primaryEnergyEfficiencyAfter = modificationImpact.primary_energy?.after
    ? getEfficiencyClassByEnergy(modificationImpact.primary_energy.after, country_enum.AT)
    : efficiency_class_enum.UNKNOWN;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <EnergyWithEfficiencyClass
            modificationImpact={{
              ...modificationImpact,
              efficiency_class: {
                before: primaryEnergyEfficiencyBefore,
                after: primaryEnergyEfficiencyAfter,
              },
            }}
            isOverview={isOverview}
            isSimulated={isSimulated}
            energyType="primary_energy"
            title={t('General_PrimaryEnergy')}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <CO2Emissions modificationImpact={modificationImpact} isOverview={isOverview} isSimulated={isSimulated} />
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={12} mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <EnergyWithoutEfficiencyClass
              modificationImpact={modificationImpact}
              isOverview={isOverview}
              isSimulated={isSimulated}
              title={t('General_FinalEnergy')}
            />
          </Grid>
          {!!modificationImpact.heating_demand?.before && (
            <Grid item xs={12} md={6} lg={6}>
              <EnergyWithEfficiencyClass
                modificationImpact={modificationImpact}
                isOverview={isOverview}
                isSimulated={isSimulated}
                energyType="heating_demand"
                title={t('General_HeatingDemand')}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AustriaEnergyAnalysis;
