import { energy_consumer_technology_type_enum, energy_source_type_enum, energy_system_type_enum } from '@predium/enums';

export type TechnologyTemplate = {
  template_name: TechnologyTemplateNames;
  technology_name: string;
  efficiency: number;
  energySourceTypes: energy_source_type_enum[];
  cost_per_m2?: number;
  correctionFactor?: number;
};

export type SolarModuleTemplate = {
  technology_name: string;
  module_peak_power: number;
  cost_per_m2: number;
  preparation_cost: number;
};

/**
 * Solar module technologies which can be selected as templates by the user.
 */
export enum SolarModuleTechnologyTemplateNames {
  SOLAR_POLYCRISTALLINE = 'SOLAR_POLYCRISTALLINE',
  SOLAR_HIGH_EFFICIENCY_MODULES = 'SOLAR_HIGH_EFFICIENCY_MODULES',
  SOLAR_MONOCRISTALLINE = 'SOLAR_MONOCRISTALLINE',
  SOLAR_CIS_CIGS = 'SOLAR_CIS_CIGS',
}

/**
 * Technologies which can be selected as templates by the user.
 */
export enum TechnologyTemplateNames {
  Fernwaerme_ohneKWK = 'Fernwaerme_ohneKWK',
  Fernwaerme_KWK = 'Fernwaerme_KWK',
  FernwaermeWW_ohneKWK = 'FernwaermeWW_ohneKWK',
  FernwaermeWW_KWK = 'FernwaermeWW_KWK',
  WaermepumpeLuft_standard = 'WaermepumpeLuft_standard',
  WaermepumpeGeo_standard = 'WaermepumpeGeo_standard',
  Luft_Wasser_WaermepumpeWW = 'Luft_Wasser_WaermepumpeWW',
  Sole_Wasser_WaermepumpeWW = 'Sole_Wasser_WaermepumpeWW',
  Holzheizkessel = 'Holzheizkessel',
  HolzheizkesselWW = 'HolzheizkesselWW',
  Elektro_Durchlauferhitzer = 'Elektro_Durchlauferhitzer',
  Gas_Brennwertkessel = 'Gas_Brennwertkessel',
  Gas_BrennwertkesselWW = 'Gas_BrennwertkesselWW',
}

const TechnologyTemplates: { [key in TechnologyTemplateNames]: TechnologyTemplate } = {
  Holzheizkessel: {
    template_name: TechnologyTemplateNames.Holzheizkessel,
    technology_name: 'Holzheizkessel',
    efficiency: 1.09,
    energySourceTypes: [energy_source_type_enum.WOODEN_PELLETS, energy_source_type_enum.WOOD],
    cost_per_m2: 2127.23,
    correctionFactor: -0.587,
  },
  HolzheizkesselWW: {
    template_name: TechnologyTemplateNames.HolzheizkesselWW,
    technology_name: 'Holzheizkessel',
    efficiency: 1.12,
    energySourceTypes: [energy_source_type_enum.WOODEN_PELLETS, energy_source_type_enum.WOOD],
  },
  Fernwaerme_ohneKWK: {
    template_name: TechnologyTemplateNames.Fernwaerme_ohneKWK,
    technology_name: 'Fernwärme (aus Heizwerken)',
    efficiency: 1.02,
    energySourceTypes: [
      energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL,
      energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS,
      energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE,
    ],
    cost_per_m2: 557.07,
    correctionFactor: -0.487,
  },
  Fernwaerme_KWK: {
    template_name: TechnologyTemplateNames.Fernwaerme_KWK,
    technology_name: 'Fernwärme (aus Kraft-Wärme-Kopplung)',
    efficiency: 1.02,
    energySourceTypes: [
      energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL,
      energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS,
      energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE,
    ],
    cost_per_m2: 557.07,
    correctionFactor: -0.487,
  },
  FernwaermeWW_ohneKWK: {
    template_name: TechnologyTemplateNames.FernwaermeWW_ohneKWK,
    technology_name: 'Fernwärme (aus Heizwerken)',
    efficiency: 1.14,
    energySourceTypes: [
      energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL,
      energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS,
      energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE,
    ],
    correctionFactor: -0.487,
  },
  FernwaermeWW_KWK: {
    template_name: TechnologyTemplateNames.FernwaermeWW_KWK,
    technology_name: 'Fernwärme (aus Kraft-Wärme-Kopplung)',
    efficiency: 1.14,
    energySourceTypes: [
      energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL,
      energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS,
      energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE,
    ],
    correctionFactor: -0.487,
  },
  WaermepumpeLuft_standard: {
    template_name: TechnologyTemplateNames.WaermepumpeLuft_standard,
    technology_name: 'Luft-Wasser Wärmepumpe',
    efficiency: 0.43,
    energySourceTypes: [energy_source_type_enum.ELECTRICITY_MIX, energy_source_type_enum.ELECTRICITY_GREEN],
    cost_per_m2: 1944.54,
    correctionFactor: -0.58,
  },
  WaermepumpeGeo_standard: {
    template_name: TechnologyTemplateNames.WaermepumpeGeo_standard,
    technology_name: 'Sole-Wasser Wärmepumpe',
    efficiency: 0.3,
    energySourceTypes: [energy_source_type_enum.ELECTRICITY_MIX, energy_source_type_enum.ELECTRICITY_GREEN],
    cost_per_m2: 4510.08,
    correctionFactor: -0.66,
  },
  Luft_Wasser_WaermepumpeWW: {
    template_name: TechnologyTemplateNames.Luft_Wasser_WaermepumpeWW,
    technology_name: 'Luft-Wasser Wärmepumpe',
    efficiency: 0.41,
    energySourceTypes: [energy_source_type_enum.ELECTRICITY_MIX, energy_source_type_enum.ELECTRICITY_GREEN],
  },
  Sole_Wasser_WaermepumpeWW: {
    template_name: TechnologyTemplateNames.Sole_Wasser_WaermepumpeWW,
    technology_name: 'Sole-Wasser Wärmepumpe',
    efficiency: 0.32,
    energySourceTypes: [energy_source_type_enum.ELECTRICITY_MIX, energy_source_type_enum.ELECTRICITY_GREEN],
  },
  Elektro_Durchlauferhitzer: {
    template_name: TechnologyTemplateNames.Elektro_Durchlauferhitzer,
    technology_name: 'Elektro-Durchlauferhitzer',
    efficiency: 1,
    energySourceTypes: [energy_source_type_enum.ELECTRICITY_MIX, energy_source_type_enum.ELECTRICITY_GREEN],
    // TODO PRE-4673: Add costs per unit
  },
  Gas_Brennwertkessel: {
    template_name: TechnologyTemplateNames.Gas_Brennwertkessel,
    technology_name: 'Gas-Brennwertkessel',
    efficiency: 0.97,
    energySourceTypes: [
      energy_source_type_enum.BIO_GAS,
      energy_source_type_enum.NATURAL_GAS,
      energy_source_type_enum.LPG,
    ],
    cost_per_m2: 760.72,
    correctionFactor: -0.518,
  },
  Gas_BrennwertkesselWW: {
    template_name: TechnologyTemplateNames.Gas_BrennwertkesselWW,
    technology_name: 'Gas-Brennwertkessel',
    efficiency: 1.07,
    energySourceTypes: [
      energy_source_type_enum.BIO_GAS,
      energy_source_type_enum.NATURAL_GAS,
      energy_source_type_enum.LPG,
    ],
  },
};

export const getSolarRenovationTemplates = (): Record<
  keyof typeof SolarModuleTechnologyTemplateNames,
  SolarModuleTemplate
> => {
  return {
    [SolarModuleTechnologyTemplateNames.SOLAR_MONOCRISTALLINE]: {
      technology_name: 'Monokristallin',
      module_peak_power: 154,
      cost_per_m2: 1150,
      preparation_cost: 1260,
    },
    [SolarModuleTechnologyTemplateNames.SOLAR_POLYCRISTALLINE]: {
      technology_name: 'Polykristallin',
      module_peak_power: 143,
      cost_per_m2: 1150,
      preparation_cost: 1260,
    },
    [SolarModuleTechnologyTemplateNames.SOLAR_HIGH_EFFICIENCY_MODULES]: {
      technology_name: 'Hocheffizienzmodule',
      module_peak_power: 200,
      cost_per_m2: 1150,
      preparation_cost: 1260,
    },
    [SolarModuleTechnologyTemplateNames.SOLAR_CIS_CIGS]: {
      technology_name: 'CIS / CIGS',
      module_peak_power: 125,
      cost_per_m2: 1150,
      preparation_cost: 1260,
    },
  };
};

/**
 * Use this lookup table in the client only.
 * For lookups with certain values, use the methods below.
 */
export const TechnologyRenovationTemplates: Record<
  Extract<energy_system_type_enum, energy_system_type_enum.HEATING | energy_system_type_enum.HOT_WATER>,
  { [key in energy_consumer_technology_type_enum]?: TechnologyTemplate[] }
> = {
  HEATING: {
    [energy_consumer_technology_type_enum.WOOD_BOILER]: [TechnologyTemplates.Holzheizkessel],
    [energy_consumer_technology_type_enum.ELECTRIC_HEAT_PUMP_AIR]: [TechnologyTemplates.WaermepumpeLuft_standard],
    [energy_consumer_technology_type_enum.ELECTRIC_HEAT_PUMP_GEO]: [TechnologyTemplates.WaermepumpeGeo_standard],
    [energy_consumer_technology_type_enum.DISTRICT_HEATING_WITHOUT_KWK]: [TechnologyTemplates.Fernwaerme_ohneKWK],
    [energy_consumer_technology_type_enum.DISTRICT_HEATING_WITH_KWK]: [TechnologyTemplates.Fernwaerme_KWK],
    [energy_consumer_technology_type_enum.GAS_CONDENSING_BOILER]: [TechnologyTemplates.Gas_Brennwertkessel],
  },

  HOT_WATER: {
    [energy_consumer_technology_type_enum.WOOD_BOILER]: [TechnologyTemplates.HolzheizkesselWW],
    [energy_consumer_technology_type_enum.ELECTRIC_FLOW_HEATER]: [TechnologyTemplates.Elektro_Durchlauferhitzer],
    [energy_consumer_technology_type_enum.GAS_CONDENSING_BOILER]: [TechnologyTemplates.Gas_BrennwertkesselWW],
    [energy_consumer_technology_type_enum.DISTRICT_HEATING_WITHOUT_KWK]: [TechnologyTemplates.FernwaermeWW_ohneKWK],
    [energy_consumer_technology_type_enum.DISTRICT_HEATING_WITH_KWK]: [TechnologyTemplates.FernwaermeWW_KWK],
    [energy_consumer_technology_type_enum.ELECTRIC_HEAT_PUMP_AIR]: [TechnologyTemplates.Luft_Wasser_WaermepumpeWW],
    [energy_consumer_technology_type_enum.ELECTRIC_HEAT_PUMP_GEO]: [TechnologyTemplates.Sole_Wasser_WaermepumpeWW],
  },
};

export const getTechnologyTemplates = (
  energySystemType: energy_system_type_enum,
  energyConsumerTechnologyType: energy_consumer_technology_type_enum,
): TechnologyTemplate[] => {
  const energyConsumerTechnologies =
    TechnologyRenovationTemplates[
      energySystemType as Extract<
        energy_system_type_enum,
        energy_system_type_enum.HEATING | energy_system_type_enum.HOT_WATER
      >
    ];
  if (!energyConsumerTechnologies) {
    console.error(`Energy system type ${energySystemType} not defined in TechnologyRenovationParameters map.`);
    return [];
  }

  const technologies = energyConsumerTechnologies[energyConsumerTechnologyType];
  if (!technologies || technologies.length === 0) {
    console.error(
      `Energy consumer technology type ${energyConsumerTechnologyType} not defined for energy system type ${energySystemType} in TechnologyRenovationParameters map.`,
    );
    return [];
  }

  return technologies;
};

/**
 * Returns the cost for the given parameters or undefined if the cost is not defined.
 *
 * Logs if the cost is not defined, so that it can be tracked down and eventually added to the lookup table.
 */
export const getTechnologyTemplateCostPerM2 = (
  energySystemType: energy_system_type_enum,
  energyConsumerTechnologyType: energy_consumer_technology_type_enum,
): number | undefined => {
  const technologies = getTechnologyTemplates(energySystemType, energyConsumerTechnologyType);

  if (!(technologies?.length > 0)) {
    return;
  }

  // We can just take the first one, because all technologies in this array have the same price
  const costPerM2 = technologies[0].cost_per_m2;
  if (!costPerM2 || !isFinite(costPerM2)) {
    console.error(`Cost per m2 for technology ${technologies[0].technology_name} is not defined.`);
  }

  return costPerM2;
};

/**
 * Returns heat producer cost correction factor for the given parameters or undefined if the cost is not defined.
 *
 * Logs if the cost is not defined, so that it can be tracked down and eventually added to the lookup table.
 */
export const getHeatProducerCostCorrectionFactor = (
  energySystemType: energy_system_type_enum,
  energyConsumerTechnologyType: energy_consumer_technology_type_enum,
): number | undefined => {
  const technologies = getTechnologyTemplates(energySystemType, energyConsumerTechnologyType);

  if (technologies.length === 0) {
    return;
  }

  // We can just take the first one, because all technologies in this array have the same price
  const correctionFactor = technologies[0].correctionFactor;
  if (!correctionFactor || !isFinite(correctionFactor)) {
    console.error(
      `Heat producer cost correction factor for technology ${technologies[0].technology_name} is not defined.`,
    );
  }

  return correctionFactor;
};

export const getSolarPreparationCost = (solarTechnologyName: string): number | undefined => {
  const solarTechnology = Object.values(getSolarRenovationTemplates()).find(
    (template) => template.technology_name === solarTechnologyName,
  );

  if (!solarTechnology) {
    console.error(`Solar technology name ${solarTechnologyName} not defined in SolarRenovationTemplates map.`);
    return;
  }

  const preparationCost = solarTechnology.preparation_cost;

  if (!isFinite(preparationCost)) {
    console.error(`Preparation cost for solar technology ${solarTechnologyName} is not defined.`);
  }

  return preparationCost;
};
