import { useQuery } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { tax_bracket_enum } from '@predium/enums';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/Iconify';
import { DelayedLoading } from '../../../components/Loading';
import MenuPopover from '../../../components/MenuPopover';
import Scrollbar from '../../../components/Scrollbar';
import { GET_BUILDING_FILTER_VALUES } from '../../../graphql/EsgAnalysis.queries';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import EsgAnalysisFilterValueLabel from './EsgAnalysisFilterValueLabel';
import { EsgAnalysisFiltersKeys, FindEsgAnalysisFilterValue, esgAnalysisFilters } from './EsgAnalysisFilters';

type FilterOptionsAsCheckboxesProps<Key extends EsgAnalysisFiltersKeys> = {
  title: React.ReactNode;
  type: Key;
  options: FindEsgAnalysisFilterValue<Key>[];
  selectedOptions: FindEsgAnalysisFilterValue<Key>[];
  getLabel?: (value: FindEsgAnalysisFilterValue<Key>) => React.ReactNode;
  onSelect?: (value: FindEsgAnalysisFilterValue<Key>) => void;
};

function FilterOptionsAsCheckboxes<Key extends EsgAnalysisFiltersKeys>({
  title,
  type,
  options,
  selectedOptions,
  getLabel,
  onSelect,
}: FilterOptionsAsCheckboxesProps<Key>) {
  const { setFilter } = useEsgAnalysisFilters();

  if (options.length < 2) {
    return null;
  }

  const handleSelect = (value: FindEsgAnalysisFilterValue<Key>) => {
    onSelect?.(value);
    setFilter(type, value);
  };

  const showAsChips = type === 'efficiencyClasses' || type === 'taxBrackets';

  return (
    <Stack spacing={3} sx={{ p: 3, pb: 0 }}>
      <Stack spacing={1}>
        <Typography variant="subtitle1">{title}</Typography>
        {showAsChips ? (
          <Stack direction="row" flexWrap="wrap" gap={1} width={'100%'}>
            {options.map((item, index) => (
              <Button
                key={index}
                variant={selectedOptions.includes(item) ? 'outlined' : 'soft'}
                onClick={() => handleSelect(item)}
                size="small"
                sx={{
                  backgroundColor: selectedOptions.includes(item) ? 'background.neutral' : 'background.paper',
                  border: selectedOptions.includes(item) ? '1px solid' : '1px solid transparent',
                  borderColor: selectedOptions.includes(item) ? 'primary.main' : 'divider',
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  },
                }}
              >
                {getLabel?.(item) ?? <EsgAnalysisFilterValueLabel type={type} value={item} />}
              </Button>
            ))}
          </Stack>
        ) : (
          <FormGroup
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
              gap: 1,
              width: '100%',
            }}
          >
            {options.map((item, index) => (
              <FormControlLabel
                key={index}
                control={<Checkbox checked={selectedOptions.includes(item)} onChange={() => handleSelect(item)} />}
                value={item}
                label={getLabel?.(item) ?? <EsgAnalysisFilterValueLabel type={type} value={item} />}
              />
            ))}
          </FormGroup>
        )}
      </Stack>
    </Stack>
  );
}

type Props = {
  isOpen: boolean;
  onOpen: VoidFunction;
  onResetAll: VoidFunction;
  onClose: VoidFunction;
};

export default function EsgAnalysisFiltersSidebar({ isOpen, onResetAll, onOpen, onClose }: Props) {
  const { t } = useTranslation();
  const { filters } = useEsgAnalysisFilters();
  const theme = useTheme();
  const currentYear = new Date().getFullYear();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { loading: filtersListLoading, data: filtersList } = useQuery(GET_BUILDING_FILTER_VALUES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      currentYear,
    },
    skip: !isOpen,
  });
  /**
   * The options are coming from the database, so we need to map them to the correct keys.
   * We query the database to only show the filters that are available for the current data set.
   */

  const TaxBracketOrder = [
    tax_bracket_enum._10_90,
    tax_bracket_enum._20_80,
    tax_bracket_enum._30_70,
    tax_bracket_enum._40_60,
    tax_bracket_enum._50_50,
    tax_bracket_enum._60_40,
    tax_bracket_enum._70_30,
    tax_bracket_enum._80_20,
    tax_bracket_enum._90_10,
    tax_bracket_enum._100_0,
  ];

  //@ts-ignore
  const options: typeof filters = useMemo(
    () => ({
      subBuildingClasses: filtersList ? map(filtersList.sub_building_class, 'class_of_use_id') : [],
      energySourceTypes: filtersList ? map(filtersList.energy_system_consumer_route, 'energy_source_type_id') : [],
      efficiencyClasses: filtersList ? map(filtersList.energy_path_efficiency_class, 'efficiency_class_id') : [],
      taxBrackets: filtersList
        ? map(filtersList.energy_path_tax_bracket, 'tax_bracket_id').sort(
            (a, b) => TaxBracketOrder.indexOf(a as tax_bracket_enum) - TaxBracketOrder.indexOf(b as tax_bracket_enum),
          )
        : [],
      euTaxonomyCompliances: filtersList
        ? map(filtersList.energy_path_eu_taxonomy_compliance, 'eu_taxonomy_compliance_id')
        : [],
      buildingStates: filtersList ? map(filtersList.building_state_id, 'building_state_id') : [],
      monumentProtections: filtersList ? map(filtersList.monument_protection, 'monument_protection') : [],
      heritageDistrict: filtersList ? map(filtersList.heritage_district, 'heritage_district') : [],
      milieuProtection: filtersList ? map(filtersList.milieu_protection, 'milieu_protection') : [],
      leasehold: filtersList ? map(filtersList.leasehold, 'leasehold') : [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersList],
  );

  const getOptions = (key: EsgAnalysisFiltersKeys) => {
    const values = options[key];

    if (values) {
      return values;
    }

    throw new Error(`No options found for key: ${key}`);
  };

  const visibleFilters = esgAnalysisFilters.filter(({ showInSidebar }) => showInSidebar);

  const filterCount = sumBy(
    visibleFilters.map(({ key }) => filters[key]),
    'length',
  );

  const noFiltersAvailable = visibleFilters.length === 0;

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onOpen();
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  return (
    <>
      <Stack direction={'row'} mr={1} gap={1}>
        <Badge badgeContent={filterCount} color="error">
          <Button
            variant="outlined"
            startIcon={<Iconify icon={'ic:round-filter-list'} />}
            onClick={handleOpen}
            sx={{
              borderColor: isOpen ? theme.palette.common.black : theme.palette.divider,
              '&:hover': {
                borderColor: isOpen ? theme.palette.common.black : theme.palette.divider,
              },
            }}
          >
            {t('General_MoreFilters')}
          </Button>
        </Badge>
        {filterCount > 0 && (
          <Button variant="text" onClick={onResetAll}>
            {t('General_ClearAll')}
          </Button>
        )}
      </Stack>

      <MenuPopover
        open={anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ width: 400, p: 0 }}
        arrow="top-left"
        disabledArrow
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 3, pt: 2 }}>
          <Typography variant="h6">{t('General_Filter', { count: 2 })}</Typography>
          <IconButton onClick={handleClose}>
            <Iconify icon={'eva:close-fill'} width={20} height={20} />
          </IconButton>
        </Stack>

        {filtersListLoading ? (
          <Stack height={'20vh'}>
            <DelayedLoading delay={0} />
          </Stack>
        ) : (
          <Scrollbar sx={{ maxHeight: 500 }}>
            {noFiltersAvailable && (
              <Typography color="text.secondary" sx={{ p: 3, textAlign: 'center' }}>
                No filters available
              </Typography>
            )}
            {visibleFilters.map(({ key, title }) => (
              <FilterOptionsAsCheckboxes
                key={key}
                title={title}
                type={key}
                options={getOptions(key)}
                //@ts-ignore
                selectedOptions={filters[key]}
              />
            ))}
          </Scrollbar>
        )}

        {!noFiltersAvailable && (
          <Stack alignItems="flex-end" sx={{ py: 2, px: 3 }}>
            <Button
              type="submit"
              variant="text"
              onClick={onResetAll}
              disabled={filterCount === 0}
              sx={{ width: 'min-content', whiteSpace: 'nowrap' }}
            >
              {t('General_ClearAll')}
            </Button>
          </Stack>
        )}
      </MenuPopover>
    </>
  );
}
