/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Container } from '@mui/material';
import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UnavailablePageForEconomicUnits } from '../../components/Unavailable';
import useOrgPreferences from '../../hooks/useOrgPreferences';
import { BenchmarkType, ENTIRE_COMPANY } from '../../sections/EsgAnalysis/Components/EsgAnalysisBenchmark';
import EconomicUnitAnalysisHeader from '../../sections/EsgAnalysis/EconomicUnit/EconomicUnitAnalysisHeader';
import { FloorAreaBySubBuildingClass } from '../../sections/EsgAnalysis/EsgAnalysisUtil';
import PortfolioAnalysisBody from '../../sections/EsgAnalysis/Portfolio/PortfolioAnalysisBody';
import { EsgAnalysisTab } from '../../sections/EsgAnalysis/Portfolio/PortfolioAnalysisHeader';

export default function EsgAnalysisEconomicUnits() {
  const { t } = useTranslation();

  const [floorAreaByBuildingClass, setFloorAreaByBuildingClass] = useState<FloorAreaBySubBuildingClass>([]);
  const [currentTab, setCurrentTab] = useState<EsgAnalysisTab>('demand');
  const [showTotal, setShowTotal] = useState<boolean>(false);
  const [currentView, setCurrentView] = useState<'dashboard' | 'list'>('dashboard');
  const [selectedBenchmark, setSelectedBenchmark] = useState<BenchmarkType>({
    id: ENTIRE_COMPANY,
    name: t('EsgAnalysisBenchmark_EntireCompany'),
  });
  const [totalBuildings, setTotalBuildings] = useState(0);

  return (
    <Container sx={{ mb: 1, pb: 30, minHeight: '100vh' }} maxWidth="lg">
      <EconomicUnitAnalysisHeader
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        setShowTotal={setShowTotal}
        setCurrentView={setCurrentView}
        currentView={currentView}
        setSelectedBenchmark={setSelectedBenchmark}
        floorAreaByBuildingClass={floorAreaByBuildingClass}
        totalBuildings={totalBuildings}
      />

      {
        //We have same analysis for economic units as for portfolios
      }
      <PortfolioAnalysisBody
        currentPageView={'esg-economics-units'}
        setFloorAreaByBuildingClass={setFloorAreaByBuildingClass}
        currentTab={currentTab}
        showTotal={showTotal}
        currentView={currentView}
        selectedBenchmark={selectedBenchmark}
        setTotalBuildings={setTotalBuildings}
      />
    </Container>
  );
}

export function EsgAnalysisEconomicUnitsNotActivated({ children }: PropsWithChildren<{}>) {
  const { economicUnitsToggled, loading } = useOrgPreferences();

  if (loading) {
    return null;
  }

  if (economicUnitsToggled) {
    return <>{children}</>;
  }

  return <UnavailablePageForEconomicUnits />;
}
