/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { DialogContent, DialogTitle, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import { ActionPlanningScenariosScenarioFragment } from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { DelayedLoading } from '../../../components/Loading';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';
import { UPDATE_SCENARIO } from '../../../graphql/ActionPlanning.mutations';
import { GET_SCENARIO, GET_SCENARIOS } from '../../../graphql/ActionPlanning.queries';

//form functions
type FormValuesProps = {
  name: string;
  description: string;
  portfolioId: number;
};

type Props = {
  onClose: VoidFunction;
  scenario: ActionPlanningScenariosScenarioFragment;
  scenariosNames: string[];
};

export default function EditScenarioModal({ onClose, scenario, scenariosNames }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  //QUERIES
  const [mutateFunction, { loading }] = useMutation(UPDATE_SCENARIO, {
    refetchQueries: [GET_SCENARIOS, GET_SCENARIO],
    onError: () => {
      enqueueSnackbar(t('ActionPlanning_EditScenarioModal-ErrorMessage'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    onCompleted: () => {
      enqueueSnackbar(t('ActionPlanning_EditScenarioModal-SuccessMessage'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      onClose();
    },
  });

  //setup yup form
  const EventSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('CreateScenarioModal_NameIsRequired'))
      .min(3, t('CreateScenarioModal_MinThreeCharactersRequired'))
      .notOneOf(
        scenariosNames.map((name) => name),
        t('ActionPlanning_Scenario_ScenarioAlreadyExists'),
      )
      .max(255, t('CreateScenarioModal_Maximum255Characters')),

    description: Yup.string().max(500).optional(),
  });

  const defaultValues = useMemo<FormValuesProps>(
    () => ({
      name: scenario.name,
      description: scenario.description ?? '',
      portfolioId: scenario.portfolio.id,
    }),
    [scenario.description, scenario.name, scenario.portfolio.id],
  );

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = methods;

  if (loading) {
    return <DelayedLoading />;
  }

  const onSubmit = async ({ description, name }: FormValuesProps) => {
    mutateFunction({
      variables: {
        id: scenario.id,
        name: name,
        description: description,
      },
    });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>{t('General_EditScenario')}</DialogTitle>

      <DialogContent>
        <Stack spacing={3}>
          <RHFTextField name="name" label={t('General_Label')} sx={{ mt: 1 }} />

          <RHFTextField name="description" label={t('General_Description')} multiline rows={4} />

          <RHFSelect
            name="portfolioId"
            label={t('General_Portfolio')}
            placeholder={t('CreateScenarioModal_SelectPortfolioPlaceholder')}
            disabled
          >
            <MenuItem value={scenario.portfolio.id}>{scenario.portfolio.name}</MenuItem>
          </RHFSelect>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          {t('General_Cancel')}
        </Button>

        <LoadingButton type="submit" variant="contained" loading={isSubmitting} disabled={!isDirty || isSubmitting}>
          {t('General_Save')}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
