import { role_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateRoleEnum = (enumValue: role_enum) => {
  switch (enumValue) {
    case role_enum.API_READ:
      return <Trans i18nKey="Enum_RoleEnum-API_READ" />;
    case role_enum.API_WRITE:
      return <Trans i18nKey="Enum_RoleEnum-API_WRITE" />;
    case role_enum.ORG_ADMIN:
      return <Trans i18nKey="Enum_RoleEnum-ORG_ADMIN" />;
    case role_enum.RETOOL_ADMIN:
      return <Trans i18nKey="Enum_RoleEnum-RETOOL_ADMIN" />;
    case role_enum.RETOOL_USER:
      return <Trans i18nKey="Enum_RoleEnum-RETOOL_USER" />;
    case role_enum.UNAUTHORIZED:
      return <Trans i18nKey="Enum_RoleEnum-UNAUTHORIZED" />;
    case role_enum.USER:
      return <Trans i18nKey="Enum_RoleEnum-USER" />;
  }
};

export const translateRoleEnum_dynamic = (enumValue: role_enum, t: TFunction<'translation', undefined>) => {
  switch (enumValue) {
    case role_enum.API_READ:
      return t('Enum_RoleEnum-API_READ');
    case role_enum.API_WRITE:
      return t('Enum_RoleEnum-API_WRITE');
    case role_enum.ORG_ADMIN:
      return t('Enum_RoleEnum-ORG_ADMIN');
    case role_enum.RETOOL_ADMIN:
      return t('Enum_RoleEnum-RETOOL_ADMIN');
    case role_enum.RETOOL_USER:
      return t('Enum_RoleEnum-RETOOL_USER');
    case role_enum.UNAUTHORIZED:
      return t('Enum_RoleEnum-UNAUTHORIZED');
    case role_enum.USER:
      return t('Enum_RoleEnum-USER');
  }
};
