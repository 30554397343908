import { useMutation } from '@apollo/client';
import { alpha, Button, MenuItem, Stack, useTheme } from '@mui/material';
import {
  ActionPlanningActionPlanActionPlanFragment,
  BuildingModelFragment,
  efficiency_class_enum,
  EnergySystemRouteRenovationParameterOutput,
  EnvelopeRenovationParameterOutput,
  HydraulicBalancingRenovationParameterInput,
  HydraulicBalancingRenovationParameterOutput,
  SimulateActionOutputResponseFragment,
  SolarRenovationParameterOutput,
  SystemRenovationParameterOutput,
} from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../../assets/icons';
import ActionMoreMenu from '../../../../../components/common/ActionMoreMenu';
import Iconify from '../../../../../components/Iconify';
import { SnackbarTimeouts } from '../../../../../components/NotistackProvider';
import { IMPLEMENT_ACTION } from '../../../../../graphql/ActionPlanning.mutations';
import {
  GET_ACTION_PLAN_METRICS,
  GET_ACTIONPLAN,
  GET_ACTIONS_FOR_ACTION_PLAN,
  GET_RECOMMENDED_ACTIONS_FOR_BUILDING_MODEL,
  GET_SUBSIDIES,
} from '../../../../../graphql/ActionPlanning.queries';
import EditAction from '../../Actions/EditAction';
import { ActionType, RecommendationOutput } from '../RecommendedActions';

const formatEnvelopeParameters = (
  parameters:
    | EnvelopeRenovationParameterOutput
    | EnergySystemRouteRenovationParameterOutput
    | SolarRenovationParameterOutput
    | SystemRenovationParameterOutput
    | HydraulicBalancingRenovationParameterOutput,
  action_type: ActionType | undefined,
) => {
  if (!parameters || !action_type) {
    return null;
  }
  //@ts-ignore
  const { __typename, ...restParameters } = parameters;

  switch (action_type) {
    case 'envelope':
      if ('envelope_type' in restParameters) {
        return {
          envelope_renovation_parameter: restParameters,
        };
      }
      break;
    case 'system':
      if ('system_type' in restParameters) {
        return {
          system_renovation_parameter: restParameters,
        };
      } else if ('module_angle' in restParameters) {
        return {
          solar_renovation_parameter: restParameters,
        };
      }

      break;
    case 'non-invasive':
      if ('energy_source_type' in restParameters) {
        return {
          energy_system_route_renovation_parameter: restParameters,
        };
      } else if ('last_hydraulic_balancing' in restParameters) {
        return {
          hydraulic_balancing_renovation_parameter: restParameters as HydraulicBalancingRenovationParameterInput,
        };
      }
      break;
    default:
      return null;
  }
};

type Props = {
  action: RecommendationOutput;
  hideAction: (id: number) => void;
  baseBuildingModel: BuildingModelFragment;
  actionPlan: ActionPlanningActionPlanActionPlanFragment;
  defaultImplementationDate: Date;
};

const RecommendedActionMenu = ({
  action,
  hideAction,
  baseBuildingModel,
  actionPlan,
  defaultImplementationDate,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [editActionModalOpen, setEditActionModalOpen] = useState(false);

  const { parameters, action_type, actionImpact } = action;
  const building = actionPlan.building;

  const [implementAction, { loading: implementActionLoading }] = useMutation(IMPLEMENT_ACTION, {
    onError: () =>
      enqueueSnackbar(t('CreateNewAction_ImplementAction-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
    onCompleted: () => {
      enqueueSnackbar(t('CreateNewAction_ImplementAction-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
    refetchQueries: [
      GET_ACTIONPLAN,
      GET_ACTIONS_FOR_ACTION_PLAN,
      GET_ACTION_PLAN_METRICS,
      GET_SUBSIDIES,
      GET_RECOMMENDED_ACTIONS_FOR_BUILDING_MODEL,
    ],
  });

  const onImplementActionClick = async () => {
    const implementationToDate = defaultImplementationDate;

    const newParameters = formatEnvelopeParameters(parameters, action_type);

    if (!newParameters) {
      return;
    }

    await implementAction({
      variables: {
        action_plan_id: actionPlan.id,
        ...newParameters,

        implementation_to: implementationToDate,
        implementation_from: implementationToDate, // TODO: handle this in the backend. frontend doesn't have implementation_from date
        renovation_ids: [],
        subsidies: [],
      },
    });
  };

  const subBuildingSubsidies = building.sub_buildings
    .flatMap((subBuilding) => subBuilding.subsidies)
    .map((item) => item.subsidy);

  const {
    efficiency_class,
    energy_cost_total,
    co2_cost_total,
    co2_emissions,
    final_energy,
    primary_energy,
    stranding_date,
    total_estimated_cost,
    heating_demand,
  } = actionImpact;

  const defaultSimulatedData: SimulateActionOutputResponseFragment = {
    energy_final: final_energy.after ?? 0,
    energy_primary: primary_energy.after ?? 0,
    co2_emissions: co2_emissions.after ?? 0,
    stranding_date: stranding_date.after ?? new Date(),
    efficiency_class: efficiency_class.after ?? efficiency_class_enum.NOT_APPLICABLE,
    renovation_costs: total_estimated_cost ?? 0, //TODO: double check this
    energy_final_cost: energy_cost_total.after ?? 0,
    co2_tax_cost: co2_cost_total.after ?? 0,
    heating_demand: heating_demand.after ?? 0,
  };

  return (
    <>
      <Stack direction={'row'} justifyContent={'end'} alignItems={'center'}>
        <Button
          startIcon={<Iconify icon={ICONS.ADD} width={16} height={16} />}
          variant="outlined"
          sx={{ mr: 1, borderColor: alpha(theme.palette.grey[500], 0.24) }}
          onClick={onImplementActionClick}
          disabled={implementActionLoading}
        >
          {t('ActionPlanning_ActionRecommendations-AddToActionPlan')}
        </Button>
        <ActionMoreMenu
          variant="icon"
          sx={{
            pointerEvents: implementActionLoading ? 'none' : 'auto',
          }}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  setEditActionModalOpen(true);
                }}
              >
                <Iconify icon={ICONS.EDIT_SQUARE} width={16} height={16} />
                {t('General_Edit')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  hideAction(action.id as number);
                }}
              >
                <Iconify icon={ICONS.HIDE} width={16} height={16} />
                {t('General_Hide')}
              </MenuItem>
            </>
          }
        />
      </Stack>
      {editActionModalOpen && (
        <EditAction
          isOpen={editActionModalOpen}
          onClose={() => {
            setEditActionModalOpen(false);
          }}
          baseBuildingModel={baseBuildingModel}
          metricData={actionImpact}
          subBuildingSubsidies={subBuildingSubsidies}
          implementationTo={defaultImplementationDate}
          country={building.address.country_id}
          defaultSimulatedData={defaultSimulatedData}
          action={action}
        />
      )}
    </>
  );
};

export default RecommendedActionMenu;
