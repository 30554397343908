import { Checkbox, TableCell } from '@mui/material';
import without from 'lodash/without';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDataTableAPI } from '../DataTableAPIContext';
import { BaseColumn, IBaseColumn } from './BaseColumn';

export const selectColumn = {
  ...BaseColumn,
  id: 'checkbox',
  type: 'select',
  renderHead() {
    return (
      <TableCell padding="checkbox" sx={{ minWidth: this.minWidth }}>
        <SelectAllItemsOnThisPage />
      </TableCell>
    );
  },
  renderCell({ id }) {
    return (
      <TableCell padding="checkbox" onClick={(e) => e.stopPropagation()}>
        <SelectItemCheckBox id={id} />
      </TableCell>
    );
  },
} satisfies IBaseColumn<{ id: number }>;

const SelectItemCheckBox: FC<{ id: number }> = ({ id }) => {
  const { toggleItem, getIsItemSelected } = useDataTableAPI();

  return <Checkbox checked={getIsItemSelected(id)} onChange={() => toggleItem(id)} />;
};

const SelectAllItemsOnThisPage = () => {
  const { toggleSelectAll } = useDataTableAPI();

  return <Checkbox checked={false} onChange={toggleSelectAll} />;
};

export const useSelectColumn = ({ count }: { count: number }) => {
  const [selectMode, setSelectMode] = useState<'ALL' | 'MANUAL'>('MANUAL');

  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [excludedIds, setExcludedIds] = useState<number[]>([]);

  const areAllSelected = selectMode === 'ALL';

  const getIsItemSelected = useCallback(
    (id: number) => {
      if (areAllSelected) {
        return !excludedIds.includes(id);
      }

      return selectedIds.includes(id);
    },
    [areAllSelected, excludedIds, selectedIds],
  );

  const getSelectedIds = useCallback(() => {
    if (areAllSelected) {
      throw new Error('Cannot get selected ids when all items are selected');
    }

    return selectedIds;
  }, [areAllSelected, selectedIds]);

  const getExcludedIds = useCallback(() => {
    if (!areAllSelected) {
      throw new Error('Cannot get excluded ids when not all items are selected');
    }

    return excludedIds;
  }, [areAllSelected, excludedIds]);

  const toggleItem = useCallback(
    (id: number) => {
      if (selectMode === 'ALL') {
        setExcludedIds((oldIds) => {
          if (oldIds.includes(id)) {
            return without(oldIds, id);
          }

          return [...oldIds, id];
        });

        return;
      }

      setSelectedIds((oldIds) => {
        if (oldIds.includes(id)) {
          return without(oldIds, id);
        }

        return [...oldIds, id];
      });

      setSelectMode('MANUAL');
    },
    [selectMode],
  );

  const onSelectAll = useCallback(() => {
    setSelectMode('ALL');
    setSelectedIds([]);
    setExcludedIds([]);
  }, []);

  const clearSelectedItems = useCallback(() => {
    setSelectMode('MANUAL');
    setSelectedIds([]);
    setExcludedIds([]);
  }, []);

  const toggleSelectAll = useCallback(() => {
    if (selectMode === 'ALL') {
      clearSelectedItems();
    } else {
      onSelectAll();
    }
  }, [clearSelectedItems, onSelectAll, selectMode]);

  const selectedCount = useMemo(() => {
    if (areAllSelected) {
      return count - excludedIds.length;
    }

    return selectedIds.length;
  }, [areAllSelected, count, excludedIds, selectedIds]);

  const isAnyItemSelected = selectedIds.length > 0 || areAllSelected;

  return {
    toggleSelectAll,
    toggleItem,
    getSelectedIds,
    getExcludedIds,
    clearSelectedItems,
    areAllSelected,
    getIsItemSelected,
    isAnyItemSelected,
    selectedCount,
  };
};
