/* eslint-disable no-case-declarations */
import {
  consumption_frequency_type_enum,
  consumption_sub_type_enum,
  consumption_type_enum,
  consumption_unit_enum,
  energy_source_type_enum,
} from '@predium/enums';

export const isEnergySourceType = (value: any): value is energy_source_type_enum => {
  return Object.values(energy_source_type_enum).includes(value);
};
export const allWasteSubTypes = [
  consumption_sub_type_enum.WASTE_CONSTRUCTION_AND_EXCAVATION,
  consumption_sub_type_enum.WASTE_ELECTRONIC_EQUIPMENT,
  consumption_sub_type_enum.WASTE_GLASS,
  consumption_sub_type_enum.WASTE_MUNICIPAL,
  consumption_sub_type_enum.WASTE_ORGANIC,
  consumption_sub_type_enum.WASTE_OTHER_NOT_RECYCLEABLE,
  consumption_sub_type_enum.WASTE_OTHER_RECYCLEABLE,
  consumption_sub_type_enum.WASTE_PACKAGING,
  consumption_sub_type_enum.WASTE_PAPER,
  consumption_sub_type_enum.WASTE_RESIDUAL,
  consumption_sub_type_enum.WASTE_WOOD,
];
export const allElectricityEnergySources = [
  energy_source_type_enum.ELECTRICITY_GREEN,
  energy_source_type_enum.ELECTRICITY_MIX,
  energy_source_type_enum.SOLAR,
  energy_source_type_enum.UNSPECIFIED,
];
export const allHeatingEnergySources = [
  energy_source_type_enum.BIO_GAS,
  energy_source_type_enum.COAL,
  energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL,
  energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS,
  energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE,
  energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL,
  energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS,
  energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE,
  energy_source_type_enum.FUEL_OIL,
  energy_source_type_enum.LIGNITE,
  energy_source_type_enum.LPG,
  energy_source_type_enum.NATURAL_GAS,
  energy_source_type_enum.WOOD,
  energy_source_type_enum.WOODEN_PELLETS,
  energy_source_type_enum.UNSPECIFIED,
];
export const allWaterSubtypes = [
  consumption_sub_type_enum.WATER_DRINKING,
  consumption_sub_type_enum.WATER_RAINWATER,
  consumption_sub_type_enum.WATER_SEWAGE,
];

export const getStandardUnitForConsumptionType = (consumptionType: consumption_type_enum): consumption_unit_enum => {
  switch (consumptionType) {
    case consumption_type_enum.ELECTRICITY:
      return consumption_unit_enum.KWH;
    case consumption_type_enum.HEATING:
      return consumption_unit_enum.KWH;
    case consumption_type_enum.WATER:
      return consumption_unit_enum.M3;
    case consumption_type_enum.WASTE:
      return consumption_unit_enum.kg;
    default:
      const exhaustiveCheck: never = consumptionType;
      throw new Error(`Unhandled consumption type: ${exhaustiveCheck}`);
  }
};

export const getSubtypesForConsumptionType = (
  consumptionType: consumption_type_enum,
  limitEnergySourceTypeBy?: energy_source_type_enum[],
) => {
  switch (consumptionType) {
    case consumption_type_enum.ELECTRICITY:
      return limitEnergySourceTypeBy
        ? allElectricityEnergySources.filter((energySourceType) => limitEnergySourceTypeBy.includes(energySourceType))
        : allElectricityEnergySources;
    case consumption_type_enum.HEATING:
      return limitEnergySourceTypeBy
        ? allHeatingEnergySources.filter((energySourceType) => limitEnergySourceTypeBy.includes(energySourceType))
        : allHeatingEnergySources;

    case consumption_type_enum.WATER:
      return allWaterSubtypes;

    case consumption_type_enum.WASTE:
      return allWasteSubTypes;

    default:
      const exhaustiveCheck: never = consumptionType;
      throw new Error(`Unhandled consumption type: ${exhaustiveCheck}`);
  }
};

/**
 * Gives the possible units to convert values into standard unit according to:
 * libs/lookup/backend-lookup/src/lib/consumptionCalculation/convertUserInputToStandardUnits.ts
 *
 * @returns all available conversion units as well as the standard unit kWH, m3 or day
 */
export function getConsumptionDisplayUnitValueForType(
  consumptionType: consumption_type_enum,
  energySourceOrSubType: energy_source_type_enum,
) {
  switch (consumptionType) {
    case consumption_type_enum.ELECTRICITY:
      return [consumption_unit_enum.KWH, consumption_unit_enum.MWh];

    case consumption_type_enum.HEATING:
      switch (energySourceOrSubType) {
        case energy_source_type_enum.LPG:
        case energy_source_type_enum.COAL:
        case energy_source_type_enum.LIGNITE:
        case energy_source_type_enum.WOOD:
        case energy_source_type_enum.WOODEN_PELLETS:
          return [
            consumption_unit_enum.kg,
            consumption_unit_enum.T,
            consumption_unit_enum.KWH,
            consumption_unit_enum.MWh,
          ];

        case energy_source_type_enum.FUEL_OIL:
        case energy_source_type_enum.NATURAL_GAS:
        case energy_source_type_enum.BIO_GAS:
          return [
            consumption_unit_enum.l,
            consumption_unit_enum.M3,
            consumption_unit_enum.KWH,
            consumption_unit_enum.MWh,
          ];

        default:
          return [consumption_unit_enum.KWH, consumption_unit_enum.MWh];
      }

    case consumption_type_enum.WATER:
      return [consumption_unit_enum.M3, consumption_unit_enum.l];

    case consumption_type_enum.WASTE:
      return [consumption_unit_enum.kg, consumption_unit_enum.T];

    default:
      const exhaustiveCheck: never = consumptionType;
      throw new Error(`Unhandled consumption type: ${exhaustiveCheck}`);
  }
}

enum ENERGY_BURN_CONSTANTS {
  FUEL_OIL = 10.42,
  NATURAL_GAS = 10.78,
  BIO_GAS = 7.5,
  LPG = 14.06,
  COAL = 8.6,
  LIGNITE = 6,
  WOOD = 4.4,
  WOODEN_PELLETS = 5.4,
}

/**
 * Converts the user input to the respective standard unit of the consumption type which is mostly kWh, m3 or days.
 * Also does validation that only expected units are set because you never know.
 *
 * @param consumptionType the type of consumption
 * @param userInputDisplayUnit the unit of the user input
 * @param value user input value
 * @param energySourceOrSubType the energy source or sub type of the consumption. Important for energy sources like heating.
 *
 * @returns the input value converted to the standard unit of the consumption type
 */
export function convertUserInputToStandardUnits(
  consumptionType: consumption_type_enum,
  userInputDisplayUnit: consumption_unit_enum | consumption_frequency_type_enum,
  value: number,
  energySourceOrSubType: energy_source_type_enum | consumption_sub_type_enum,
): number {
  switch (consumptionType) {
    case consumption_type_enum.ELECTRICITY:
      if (userInputDisplayUnit === consumption_unit_enum.MWh) {
        return value * 1000;
      } else if (userInputDisplayUnit === consumption_unit_enum.KWH) {
        return value;
      } else {
        throw new Error('Invalid unit for electricity consumption');
      }

    case consumption_type_enum.HEATING:
      if (userInputDisplayUnit === consumption_unit_enum.MWh) {
        return value * 1000;
      } else if (userInputDisplayUnit === consumption_unit_enum.KWH) {
        return value;
      }

      switch (energySourceOrSubType) {
        // in l
        case energy_source_type_enum.FUEL_OIL:
          return userInputDisplayUnit === consumption_unit_enum.l
            ? value * ENERGY_BURN_CONSTANTS.FUEL_OIL
            : value * 1000 * ENERGY_BURN_CONSTANTS.FUEL_OIL;
        // in m3
        case energy_source_type_enum.NATURAL_GAS:
          return userInputDisplayUnit === consumption_unit_enum.l
            ? (value * ENERGY_BURN_CONSTANTS.NATURAL_GAS) / 1000
            : value * ENERGY_BURN_CONSTANTS.NATURAL_GAS;
        case energy_source_type_enum.BIO_GAS:
          return userInputDisplayUnit === consumption_unit_enum.l
            ? (value * ENERGY_BURN_CONSTANTS.BIO_GAS) / 1000
            : value * ENERGY_BURN_CONSTANTS.BIO_GAS;
        // in kg
        case energy_source_type_enum.LPG:
          return userInputDisplayUnit === consumption_unit_enum.kg
            ? value * ENERGY_BURN_CONSTANTS.LPG
            : value * 1000 * ENERGY_BURN_CONSTANTS.LPG;
        case energy_source_type_enum.COAL:
          return userInputDisplayUnit === consumption_unit_enum.kg
            ? value * ENERGY_BURN_CONSTANTS.COAL
            : value * 1000 * ENERGY_BURN_CONSTANTS.COAL;
        case energy_source_type_enum.LIGNITE:
          return userInputDisplayUnit === consumption_unit_enum.kg
            ? value * ENERGY_BURN_CONSTANTS.LIGNITE
            : value * 1000 * ENERGY_BURN_CONSTANTS.LIGNITE;
        case energy_source_type_enum.WOOD:
          return userInputDisplayUnit === consumption_unit_enum.kg
            ? value * ENERGY_BURN_CONSTANTS.WOOD
            : value * 1000 * ENERGY_BURN_CONSTANTS.WOOD;
        case energy_source_type_enum.WOODEN_PELLETS:
          return userInputDisplayUnit === consumption_unit_enum.kg
            ? value * ENERGY_BURN_CONSTANTS.WOODEN_PELLETS
            : value * 1000 * ENERGY_BURN_CONSTANTS.WOODEN_PELLETS;
        default:
          throw new Error('Invalid unit for heating consumption');
      }

    case consumption_type_enum.WATER:
      if (userInputDisplayUnit === consumption_unit_enum.l) {
        return value / 1000;
      } else if (userInputDisplayUnit === consumption_unit_enum.M3) {
        return value;
      } else {
        throw new Error('Invalid unit for water consumption');
      }

    case consumption_type_enum.WASTE:
      // waste details frequency
      if (userInputDisplayUnit === consumption_frequency_type_enum.MONTHLY) {
        return value / 30.437;
      } else if (userInputDisplayUnit === consumption_frequency_type_enum.WEEKLY) {
        return value / 7;
        // waste details volume
      } else if (userInputDisplayUnit === consumption_unit_enum.l) {
        return value / 1000;
      } else if (userInputDisplayUnit === consumption_unit_enum.T) {
        return value * 1000;
      } else if (
        userInputDisplayUnit !== consumption_frequency_type_enum.DAILY &&
        userInputDisplayUnit !== consumption_unit_enum.kg &&
        userInputDisplayUnit !== consumption_unit_enum.M3
      ) {
        throw new Error('Invalid unit for waste consumption');
      } else {
        return value;
      }

    default:
      const exhaustiveCheck: never = consumptionType;
      throw new Error(`Unhandled consumption type: ${exhaustiveCheck}`);
  }
}

/**
 * Converts the standard unit back to the unit that the user has input.
 * Mirroring the behavior of `convertUserInputToStandardUnits`
 *
 * @param consumptionType the type of consumption
 * @param userInputDisplayUnit the unit of the user input
 * @param value the standard value (kWh, m3, day)
 * @param energySourceOrSubType the energy source or sub type of the consumption. Important for energy sources like heating.
 *
 * @returns the input standard value converted to the standard unit of the consumption type
 */
export function convertStandardUnitsToUserInput(
  consumptionType: consumption_type_enum,
  userInputDisplayUnit: consumption_unit_enum | consumption_frequency_type_enum,
  value: number,
  energySourceOrSubType: energy_source_type_enum | consumption_sub_type_enum,
): number {
  switch (consumptionType) {
    case consumption_type_enum.ELECTRICITY:
      if (userInputDisplayUnit === consumption_unit_enum.MWh) {
        return value / 1000;
      } else if (userInputDisplayUnit === consumption_unit_enum.KWH) {
        return value;
      } else {
        throw new Error('Invalid unit for electricity consumption');
      }
      break;

    case consumption_type_enum.HEATING:
      if (userInputDisplayUnit === consumption_unit_enum.MWh) {
        return value / 1000;
      } else if (userInputDisplayUnit === consumption_unit_enum.KWH) {
        return value;
      }

      switch (energySourceOrSubType) {
        // in l
        case energy_source_type_enum.FUEL_OIL:
          return userInputDisplayUnit === consumption_unit_enum.l
            ? value / ENERGY_BURN_CONSTANTS.FUEL_OIL
            : value / 1000 / ENERGY_BURN_CONSTANTS.FUEL_OIL;
        // in m3
        case energy_source_type_enum.NATURAL_GAS:
          return userInputDisplayUnit === consumption_unit_enum.l
            ? (value * 1000) / ENERGY_BURN_CONSTANTS.NATURAL_GAS
            : value / ENERGY_BURN_CONSTANTS.NATURAL_GAS;
        case energy_source_type_enum.BIO_GAS:
          return userInputDisplayUnit === consumption_unit_enum.l
            ? (value * 1000) / ENERGY_BURN_CONSTANTS.BIO_GAS
            : value / ENERGY_BURN_CONSTANTS.BIO_GAS;
        // in kg
        case energy_source_type_enum.LPG:
          return userInputDisplayUnit === consumption_unit_enum.kg
            ? value / ENERGY_BURN_CONSTANTS.LPG
            : value / 1000 / ENERGY_BURN_CONSTANTS.LPG;
        case energy_source_type_enum.COAL:
          return userInputDisplayUnit === consumption_unit_enum.kg
            ? value / ENERGY_BURN_CONSTANTS.COAL
            : value / 1000 / ENERGY_BURN_CONSTANTS.COAL;
        case energy_source_type_enum.LIGNITE:
          return userInputDisplayUnit === consumption_unit_enum.kg
            ? value / ENERGY_BURN_CONSTANTS.LIGNITE
            : value / 1000 / ENERGY_BURN_CONSTANTS.LIGNITE;
        case energy_source_type_enum.WOOD:
          return userInputDisplayUnit === consumption_unit_enum.kg
            ? value / ENERGY_BURN_CONSTANTS.WOOD
            : value / 1000 / ENERGY_BURN_CONSTANTS.WOOD;
        case energy_source_type_enum.WOODEN_PELLETS:
          return userInputDisplayUnit === consumption_unit_enum.kg
            ? value / ENERGY_BURN_CONSTANTS.WOODEN_PELLETS
            : value / 1000 / ENERGY_BURN_CONSTANTS.WOODEN_PELLETS;
        default:
          throw new Error('Invalid unit for heating consumption');
      }

    case consumption_type_enum.WATER:
      if (userInputDisplayUnit === consumption_unit_enum.l) {
        return value * 1000;
      } else if (userInputDisplayUnit === consumption_unit_enum.M3) {
        return value;
      } else {
        throw new Error('Invalid unit for water consumption');
      }

    case consumption_type_enum.WASTE:
      // waste details frequency
      if (userInputDisplayUnit === consumption_frequency_type_enum.MONTHLY) {
        return value * 30.437;
      } else if (userInputDisplayUnit === consumption_frequency_type_enum.WEEKLY) {
        return value * 7;
        // waste details volume
      } else if (userInputDisplayUnit === consumption_unit_enum.l) {
        return value * 1000;
      } else if (userInputDisplayUnit === consumption_unit_enum.T) {
        return value / 1000;
      } else if (
        userInputDisplayUnit !== consumption_frequency_type_enum.DAILY &&
        userInputDisplayUnit !== consumption_unit_enum.kg &&
        userInputDisplayUnit !== consumption_unit_enum.M3
      ) {
        throw new Error('Invalid unit for waste consumption');
      } else {
        return value;
      }

    default:
      const exhaustiveCheck: never = consumptionType;
      throw new Error(`Unhandled consumption type: ${exhaustiveCheck}`);
  }
}
