import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { climate_risk_type_enum, EsgAnalysisBuildingBasicDetailsFragment } from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClimateRiskGraph } from '../../../components/data-visialization/esg-analysis/ClimateRiskChart';
import { createStandardErrorSnackbar } from '../../../components/NotistackProvider';
import { GET_ANALYZED_BUILDING } from '../../../graphql/EsgAnalysis.queries';
import useSessionData from '../../../hooks/useSessionData';
import BuildingConsumptionAnalysisTab from '../ConsumptionAnalysis/BuildingConsumptionAnalysisTab';
import { FloorAreaBySubBuildingClass } from '../EsgAnalysisUtil';
import { ClimateRiskData, EsgAnalysisTab } from '../Portfolio/PortfolioAnalysisHeader';
import EsgAnalysisBuildingSectionDemandTab from './BuildingAnalysisDemandTab';

type Props = {
  currentTab: EsgAnalysisTab;
  selectedBuilding: EsgAnalysisBuildingBasicDetailsFragment;
  setFloorAreaByBuildingClass: (data: FloorAreaBySubBuildingClass) => void;
  selectedReportingYear: number;
  showTotal: boolean;
  benchmarkId: number | undefined;
  selectedBenchmarkName: string;
};

export default function EsgBuildingBody({
  showTotal,
  currentTab,
  selectedBuilding,
  setFloorAreaByBuildingClass,
  selectedReportingYear,
  benchmarkId,
  selectedBenchmarkName,
}: Props) {
  const { serverSideFeatureFlags } = useSessionData();
  const { t } = useTranslation();
  const displayStandardMutationQueryErrorSnackbar = createStandardErrorSnackbar(t);
  const { enqueueSnackbar } = useSnackbar();

  const [climateRisks, setClimateRiskData] = useState<ClimateRiskData>([]);

  const currentYear = new Date().getFullYear();
  const isConsumptionAnalysisEnabled = serverSideFeatureFlags.CONSUMPTION_ANALYSIS;

  const {
    data: analyzeBuildingData,
    loading,
    previousData,
  } = useQuery(GET_ANALYZED_BUILDING, {
    fetchPolicy: 'cache-and-network',
    variables: { buildingId: selectedBuilding.id, year: currentYear },
    onCompleted: (data) => {
      setFloorAreaByBuildingClass(data.getAnalyzedBuilding.floorAreaBySubBuildingClass);
      setClimateRiskData(data.getAnalyzedBuilding.climateRisks);
    },
    onError: () => displayStandardMutationQueryErrorSnackbar(enqueueSnackbar),
  });

  const climateRisksData = climateRisks.map((risk) => ({
    ...risk,
    type: climate_risk_type_enum[risk.type],
  }));

  if (currentTab === 'demand') {
    return (
      <Box sx={{ pt: 5 }}>
        <EsgAnalysisBuildingSectionDemandTab
          building={selectedBuilding}
          benchmarkId={benchmarkId}
          showTotal={showTotal}
          analyzeBuildingData={analyzeBuildingData?.getAnalyzedBuilding ?? previousData?.getAnalyzedBuilding}
          loading={loading}
          selectedBenchmarkName={selectedBenchmarkName ?? ''}
        />
      </Box>
    );
  }

  if (currentTab === 'climate_risks') {
    return (
      <Box sx={{ pt: 5 }}>
        <ClimateRiskGraph climateRisks={climateRisksData} />
      </Box>
    );
  }

  if (isConsumptionAnalysisEnabled && currentTab === 'consumption') {
    return (
      <Box sx={{ pt: 5 }}>
        <BuildingConsumptionAnalysisTab
          building={selectedBuilding}
          showTotal={showTotal}
          selectedBenchmarkName={selectedBenchmarkName ?? ''}
          selectedReportingYear={selectedReportingYear}
          selectedBuildingId={selectedBuilding.id}
        />
      </Box>
    );
  }

  return null;
}
