import mapboxgl from 'mapbox-gl';

class EditModeNavigationControl extends mapboxgl.NavigationControl {
  private onClickFunction: () => void;
  private editDisabled: boolean;
  private buttonTitle: string;

  constructor(
    options: mapboxgl.NavigationControlOptions,
    onClick: () => void,
    editDisabled: boolean,
    buttonTitle: string,
  ) {
    super(options);
    this.onClickFunction = onClick;
    this.editDisabled = editDisabled;
    this.buttonTitle = buttonTitle;
  }

  onClick() {
    if (!this.editDisabled) {
      this.onClickFunction();
    }
  }

  onAdd(map: mapboxgl.Map) {
    const container = super.onAdd(map);

    // Add custom pencil button
    //@ts-ignore
    const pencilButton = document.createElement('button');
    pencilButton.type = 'button';
    pencilButton.title = this.buttonTitle;
    pencilButton.onclick = () => {
      this.onClick();
    };
    pencilButton.innerHTML = `
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.75 0.25C0.9175 0.25 0.25 0.9175 0.25 1.75V12.25C0.25 12.6478 0.408035 13.0294 0.68934 13.3107C0.970644 13.592 1.35218 13.75 1.75 13.75H12.25C12.6478 13.75 13.0294 13.592 13.3107 13.3107C13.592 13.0294 13.75 12.6478 13.75 12.25V7H12.25V12.25H1.75V1.75H7V0.25H1.75ZM11.335 1C11.2075 1 11.0725 1.0525 10.975 1.15L10.06 2.0575L11.935 3.9325L12.85 3.025C13.045 2.83 13.045 2.5 12.85 2.3125L11.6875 1.15C11.59 1.0525 11.4625 1 11.335 1ZM9.5275 2.59L4 8.125V10H5.875L11.4025 4.465L9.5275 2.59Z" fill="black"/>
      </svg>`;

    if (this.editDisabled) {
      pencilButton.style.opacity = '0.5';
      pencilButton.style.cursor = 'not-allowed';
    }

    container.appendChild(pencilButton);

    return container;
  }
}

export default EditModeNavigationControl;
