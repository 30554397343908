/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Stack } from '@mui/material';
import { area_type_enum, data_source_type_enum } from '@predium/enums';
import { ComponentProps, ReactNode } from 'react';
import CustomDataSourceInput from '../../../../../components/custom-data-source-input';
import useBuilding from '../../Context/useBuilding';
import { useCalculator } from '../Calculator/CalculatorProvider';
import { useValidation } from '../Validation/ValidationProvider';
import CalculationTooltip from './CalculationTooltip';
import Calculator from './Calculator';
import SquareMeterRowAlert from './SquareMeterRowAlert';
import SquareMeterRowLabel from './SquareMeterRowLabel';

type BaseProps = {
  actions?: ReactNode;
  source?: data_source_type_enum;
  areaType: area_type_enum;
  suffix?: string;
  options?: {
    hoverEffectSupport?: boolean;
    hideEquationError?: boolean;
    hideActionPlaceholder?: boolean;
    displayErrorInline?: boolean;
    showCalculation?: boolean;
  };
} & ComponentProps<typeof SquareMeterRowLabel>;

export type EditableRowProps = {
  name: string;
} & BaseProps;

export type StaticRowProps = {
  value: number;
} & BaseProps;

type Props = EditableRowProps | StaticRowProps;

const SquareMeterRow = ({ icon, actions, labels, source, titleProps, areaType, options, ...props }: Props) => {
  const { hasEditAccess } = useBuilding();
  const {
    hoverEffectSupport,
    hideEquationError,
    hideActionPlaceholder,
    displayErrorInline = true,
    showCalculation = false,
  } = options ?? {};

  const { triggerValidation, getAreaAlertVariant, onOpenEquation } = useValidation();
  const { getActiveStyles, onAreaClick, getInfoTooltipProps, onAreaAlertClose, getIsPopoverOpen } = useCalculator();

  const alertVariant = getAreaAlertVariant(areaType);

  const activeStyles = displayErrorInline ? getActiveStyles(areaType) : {};

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={[
        {
          height: 40,
          px: 1,
          transition: 'background-color 0.2s',
          borderRadius: 0.5,
          ...(displayErrorInline &&
            alertVariant && {
              bgcolor: alertVariant + '.lighter',
            }),
          ...(hoverEffectSupport && {
            height: 48,
            px: 2,
            py: 0.5,
            '&:hover': {
              bgcolor: 'grey.100',
              '.input-value-root': {
                bgcolor: 'white',
              },
            },
          }),
        },
        ...(Array.isArray(activeStyles) ? activeStyles : [activeStyles]),
      ]}
    >
      <SquareMeterRowLabel icon={icon} labels={labels} titleProps={titleProps} />
      <Stack direction="row" alignItems="center" spacing={1}>
        {!hideEquationError && <SquareMeterRowAlert areaType={areaType} inline={displayErrorInline} />}
        <CalculationTooltip {...getInfoTooltipProps(areaType)}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            {showCalculation && <Calculator areaType={areaType} />}
            <CustomDataSourceInput
              suffix="m²"
              {...props}
              source={source}
              actions={hasEditAccess && actions}
              onClickAway={triggerValidation}
              numberFieldProps={{
                min: 0.01,
                max: 99_999_999,
              }}
              onSourceClick={(event) => {
                if (source === data_source_type_enum.MANUAL) {
                  return;
                }

                // to prevent the input from going into edit mode
                event.stopPropagation();

                onAreaClick(areaType);
                onOpenEquation(null);
              }}
              onSourceClickAway={() => {
                if (getIsPopoverOpen(areaType)) {
                  onAreaAlertClose();
                }
              }}
            />
          </Stack>
        </CalculationTooltip>

        {!hideActionPlaceholder && !(hasEditAccess && actions) && <Box width="20px" />}
      </Stack>
    </Stack>
  );
};

export default SquareMeterRow;
