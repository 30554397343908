import { NotWindowDoorEnvelopeType } from '@predium/client-lookup';
import { envelope_type_enum } from '@predium/enums';

/**
 * Retrieves the additional thermal resistance based on the envelope type.
 *
 * @param {envelope_type_enum} envelopeType - The type of the envelope.
 * @returns {number} - The additional thermal resistance.
 */
export const getAdditionalThermalResistance = (envelopeType: NotWindowDoorEnvelopeType): number => {
  switch (envelopeType) {
    case envelope_type_enum.FLAT_ROOF:
      return 0.14;
    case envelope_type_enum.BASEMENT_CEILING:
      return 0.34;
    case envelope_type_enum.TOP_FLOOR_CEILING:
      return 0.2;
    case envelope_type_enum.WALL:
      return 0.17;
    case envelope_type_enum.FLOOR:
      return 0.17;
    case envelope_type_enum.PITCHED_ROOF:
      return 0.14;
    default: {
      const exhaustiveCheck: never = envelopeType;
      throw new Error(`Unhandled envelope type case: ${exhaustiveCheck}`);
    }
  }
};

/**
 * Recalculates the U-value (thermal transmittance) of an envelope.
 *
 * @param {envelope_type_enum} envelopeType - The type of the envelope.
 * @param {number} baseConstructionThickness - The thickness of the base construction (in meters).
 * @param {number} baseConstructionLambda - The thermal conductivity of the base construction material.
 * @param {number} insulationThickness - The thickness of insulation material (in meters).
 * @param {number} insulationLambda - The thermal conductivity of insulation material.
 * @returns {number} - The recalculated U-value
 */
export const calculateUValue = (
  envelopeType: NotWindowDoorEnvelopeType,
  baseConstructionThickness: number,
  baseConstructionLambda: number,
  insulationThickness?: number | null,
  insulationLambda?: number | null,
): number => {
  const additionalThermalResistance = getAdditionalThermalResistance(envelopeType);
  const insulationPresent = insulationLambda && insulationThickness;

  const uValue =
    1 /
    (baseConstructionThickness / baseConstructionLambda +
      (insulationPresent ? insulationThickness / insulationLambda : 0) +
      additionalThermalResistance);

  return Math.round(uValue * 100) / 100;
};
