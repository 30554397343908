import { Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

type TabItem = {
  label: React.ReactNode;
  value: string;
};

type Props = {
  value: string;
  onChange: (event: React.SyntheticEvent, newValue: string) => void;
  tabs: TabItem[];
};

const PreTabsAsToggle: React.FC<Props> = ({ value, onChange, tabs }) => {
  const theme = useTheme();

  return (
    <Tabs
      value={value}
      onChange={onChange}
      TabIndicatorProps={{
        style: {
          display: 'none',
        },
      }}
      sx={{
        '& .MuiTab-root': {
          color: 'text.secondary',
          borderRadius: 1,
          padding: 1,
          pl: 2,
          gap: 0.5,
          typography: 'subtitle1',
          ':not(:last-of-type)': {
            mr: 0.5,
          },
          '&.Mui-selected': {
            color: 'text.primary',
            backgroundColor: theme.palette.grey[500_8],
          },
          '&:hover': {
            backgroundColor: 'action.hover',
          },
          textTransform: 'none',
        },
      }}
    >
      {tabs.map((tab) => (
        <Tab key={tab.value} label={tab.label} value={tab.value} />
      ))}
    </Tabs>
  );
};

export default PreTabsAsToggle;
