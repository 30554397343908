import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OverflowText from '../../../components/OverflowText';
import WidgetSummaryLabel from '../../../components/data-visialization/WidgetSummaryLabel';

type Props = {
  change: number | undefined | null;
  selectedBenchmarkName: string;
  unit?: '%' | 'a';
  /**
   * Hide the change label if the change is 0
   */
  hideNoChange?: boolean;
  inverse?: boolean;
};

export default function BenchmarkLabel({
  change,
  selectedBenchmarkName,
  unit = '%',
  hideNoChange = false,
  inverse = false,
}: Props) {
  const { t } = useTranslation();
  const changeEndAdornment = `${t('General_Vs')} ${selectedBenchmarkName}`;
  const hideChangeLabel = (change === 0 || !change || !isFinite(change) || isNaN(change)) && hideNoChange;

  return (
    <>
      {!hideChangeLabel && (
        <Stack direction="row" spacing={0.5} sx={{ whiteSpace: 'nowrap' }}>
          <Box>
            <WidgetSummaryLabel change={change ?? 0} unit={unit} inverse={inverse} digits={0} />
          </Box>
          {changeEndAdornment && (
            <OverflowText variant="caption" maxWidth="80%" color="text.secondary" text={changeEndAdornment} />
          )}
        </Stack>
      )}
    </>
  );
}
