import { useMutation } from '@apollo/client';
import { Box, MenuItem, Stack, Table, TableBody, TableCell, TableContainer, Tooltip, Typography } from '@mui/material';
import {
  DataCollectionBuildingSubBuildingFragment,
  DataCollectionSubBuildingConsumptionFragment,
  consumption_type_enum,
} from '@predium/client-graphql';
import {
  translateConsumptionSourceTypeEnum,
  translateConsumptionTypeEnum_iconTooltip_dynamic,
} from '@predium/i18n/client';
import groupBy from 'lodash/groupBy';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import uniq from 'lodash/uniq';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ICONS } from '../../../../assets/icons';
import ColoredLabel from '../../../../components/ColoredLabel';
import DeleteConfirmationModal from '../../../../components/DeleteConfirmationModal';
import Iconify from '../../../../components/Iconify';
import InlineUser from '../../../../components/InlineUser';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import OverflowText from '../../../../components/OverflowText';
import AccessRightsWrapper from '../../../../components/permission-tooltips/AccessRightsWrapper';
import TableHeadCustom, { HeadCell } from '../../../../components/table/TableHeadCustom';
import TableMoreMenu from '../../../../components/table/TableMoreMenu';
import TablePaginationStandard from '../../../../components/table/TablePaginationStandard';
import TableRowWithHighlight, { hasRecentlyChanged } from '../../../../components/table/TableRowWithHighlight';
import { DELETE_CONSUMPTION, DELETE_CONSUMPTION_INVOICE } from '../../../../graphql/DataCollection.mutations';
import { GET_CONSUMPTION_SUMMARIES } from '../../../../graphql/DataCollection.queries';
import useTable, { applySortFilter } from '../../../../hooks/useTable';
import DataCollectionSingleConsumptionModal from '../../../../pages/DataCollection/DataCollectionSingleConsumptionModal';
import { useLanguage } from '../../../../provider/LanguageProvider';
import { PATHS } from '../../../../routes';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '../../../../utils/formatTime';
import useBuilding from '../Context/useBuilding';
import { getIconForConsumptionType, getIconThemeColor } from './SubBuildingConsumptionDraftList';
import ConsumptionListToolbar from './SubBuildingConsumptionListToolbar';

export default function SubBuildingConsumptionList({
  subBuilding,
}: {
  subBuilding: DataCollectionBuildingSubBuildingFragment;
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { language } = useLanguage();
  const { hasEditAccess } = useBuilding();

  const [invoiceIdToDelete, setInvoiceIdToDelete] = useState<number | null>(null);
  const [consumptionIdToDelete, setConsumptionIdToDelete] = useState<number | null>(null);
  const [fromToYearFilter, setFromToYearFilter] = useState<number | 'all'>('all');
  const [consumptionTypeFilter, setConsumptionTypeFilter] = useState<consumption_type_enum | 'all'>('all');
  const [singleConsumptionToEdit, setSingleConsumptionToEdit] =
    useState<DataCollectionSubBuildingConsumptionFragment | null>(null);
  const [isSingleConsumptionDialogOpen, setIsSingleConsumptionDialogOpen] = useState(false);

  const TABLE_HEAD: HeadCell[] = [
    { id: 'from', label: t('General_ConsumptionPeriodFrom'), minWidth: 100 },
    { id: 'to', label: t('General_ConsumptionPeriodTo'), minWidth: 100 },
    {
      id: 'consumption_type_id',
      label: t('General_Category'),
      sortingDisabled: true,
      minWidth: 200,
    },
    { id: 'sourceType', label: t('General_EntryType'), minWidth: 150 },
    { id: 'provider', label: t('General_Provider'), minWidth: 300 },
    { id: 'createdByUser', label: t('General_CreatedBy'), minWidth: 200 },
    { id: 'actions_menu', label: '', sortingDisabled: true },
  ];

  const { page, setPage, rowsPerPage, setRowsPerPage, order, orderBy, onSort } = useTable({
    defaultOrder: 'desc',
    defaultOrderBy: 'createdAt',
    defaultRowsPerPage: 25,
  });

  const consumptions: DataCollectionSubBuildingConsumptionFragment[] = subBuilding.consumptions ?? [];
  const availableYearsWithConsumption = uniq(
    consumptions.flatMap(({ from, to }) => [new Date(from).getFullYear(), new Date(to).getFullYear()]),
  );

  const consumptionInvoices = Object.entries(
    groupBy(
      consumptions.filter(({ invoice_id }) => invoice_id),
      'invoice_id',
    ),
  ).map(([invoiceId, consumptionsForInvoice]) => ({
    id: consumptionsForInvoice[0].id!,
    invoiceId,
    area_id: consumptionsForInvoice[0].area_id,
    from: minBy(consumptionsForInvoice, ({ from }) => (from ? new Date(from).getTime() : undefined))!.from,
    to: maxBy(consumptionsForInvoice, ({ to }) => (to ? new Date(to).getTime() : undefined))!.to,
    consumptionTypes: uniq(consumptionsForInvoice.map(({ consumption_type_id }) => consumption_type_id)).sort(),
    provider: consumptionsForInvoice[0].provider!,
    createdByUser: consumptionsForInvoice[0].created_by_user,
    createdAt: consumptionsForInvoice[0].created_at!,
    source_type_id: consumptionsForInvoice[0].source_type_id!,
    // Just for the fragment type instead of casting to any, never accessed
    consumption_type_id: consumptionsForInvoice[0].consumption_type_id,
  }));

  const consumptionsWithoutInvoice = consumptions
    .filter(({ invoice_id }) => !invoice_id)
    .map((consumption) => ({
      id: consumption.id,
      invoiceId: null as any,
      area_id: consumption.area_id,
      from: consumption.from,
      to: consumption.to,
      consumptionTypes: [consumption.consumption_type_id],
      provider: consumption.provider!,
      createdByUser: consumption.created_by_user,
      createdAt: consumption.created_at!,
      source_type_id: consumption.source_type_id!,
      consumption_type_id: consumption.consumption_type_id,
      energy_source_type_id: consumption.energy_source_type_id,
      sub_type_id: consumption.sub_type_id,
      cost: consumption.cost,
      value: consumption.value,
      consumption_waste_detail: consumption.consumption_waste_detail,
      display_unit_value: consumption.display_unit_value,
    }));

  const allConsumptionsForTable = [...consumptionsWithoutInvoice, ...consumptionInvoices];

  const [deleteProcessedBillMutation] = useMutation(DELETE_CONSUMPTION_INVOICE, {
    variables: {
      id: invoiceIdToDelete!,
    },
    onCompleted: () => {
      enqueueSnackbar(t('DataCollection_InvoiceDelete-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
    onError: () => {
      enqueueSnackbar(t('DataCollection_InvoiceDelete-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    update: (cache, { data }) => {
      if (data?.delete_consumption_invoice_by_pk) {
        const normalizedId = cache.identify({
          id: data.delete_consumption_invoice_by_pk.id,
          __typename: data.delete_consumption_invoice_by_pk.__typename,
        });
        cache.evict({ id: normalizedId });

        // Hasura doesn't return the consumptions proper, why??
        const consumptionsForInvoice = consumptions.filter(
          ({ invoice_id }) => invoice_id === data.delete_consumption_invoice_by_pk!.id,
        );
        if (consumptionsForInvoice.length > 0) {
          // Delete all the consumptions too
          consumptionsForInvoice.forEach((consumption) => {
            const normalizedConsumptionId = cache.identify({
              id: consumption.id,
              __typename: 'consumption',
            });
            cache.evict({ id: normalizedConsumptionId });
          });
        }

        cache.gc();
      }
    },
    refetchQueries: [GET_CONSUMPTION_SUMMARIES],
  });

  const [deleteConsumptionMutation] = useMutation(DELETE_CONSUMPTION, {
    variables: {
      id: consumptionIdToDelete!,
    },
    onCompleted: () => {
      enqueueSnackbar(t('DataCollection_ConsumptionDelete-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
    onError: () => {
      enqueueSnackbar(t('DataCollection_ConsumptionDelete-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    update: (cache, { data }) => {
      if (data?.delete_consumption_by_pk) {
        const normalizedId = cache.identify({
          id: data.delete_consumption_by_pk.id,
          __typename: data.delete_consumption_by_pk.__typename,
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
    refetchQueries: [GET_CONSUMPTION_SUMMARIES],
  });

  const filteredRows = applySortFilter({
    data: allConsumptionsForTable,
    dataFilters: [
      fromToYearFilter !== 'all'
        ? (consumptionDrafts: (typeof allConsumptionsForTable)[number]) =>
            (consumptionDrafts.from ? new Date(consumptionDrafts.from).getFullYear() === fromToYearFilter : false) ||
            (consumptionDrafts.to ? new Date(consumptionDrafts.to).getFullYear() === fromToYearFilter : false)
        : undefined,
      consumptionTypeFilter !== 'all'
        ? (consumptions: (typeof allConsumptionsForTable)[number]) =>
            consumptions.consumptionTypes.includes(consumptionTypeFilter)
        : undefined,
    ].filter(Boolean) as ((consumptions: (typeof allConsumptionsForTable)[number]) => boolean)[],
    orderOptions: {
      order,
      orderBy: function (item) {
        switch (orderBy) {
          // default sort but not available in the table itself.
          case 'createdAt':
            return new Date(item.createdAt).getTime();

          case 'createdByUser': {
            if (!item.createdByUser) {
              return '';
            }

            return `${item.createdByUser.first_name} ${item.createdByUser.last_name}`;
          }

          default:
            return (item as any)[orderBy] ?? '';
        }
      },
    },
  });
  const tableRows = filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const openConsumptionInvoice = (consumptionId: number) => {
    navigate(
      PATHS.dataCollection.buildingConsumptionInvoice({
        id: subBuilding.building_id,
        consumptionInvoiceId: consumptionId,
      }),
    );
  };

  const handleClick = (consumption: (typeof tableRows)[number]) => () => {
    if (consumption.invoiceId) {
      openConsumptionInvoice(consumption.invoiceId);
    } else {
      setSingleConsumptionToEdit(consumption);
      setIsSingleConsumptionDialogOpen(true);
    }
  };

  return (
    <Box>
      <ConsumptionListToolbar
        consumptionTypeFilter={consumptionTypeFilter}
        fromToYearFilter={fromToYearFilter}
        setConsumptionTypeFilter={setConsumptionTypeFilter}
        setFromToYearFilter={setFromToYearFilter}
        availableYearsWithConsumption={availableYearsWithConsumption}
      />

      <TableContainer>
        <Table>
          <TableHeadCustom order={order} orderBy={orderBy} headLabel={TABLE_HEAD} onSort={onSort} />

          <TableBody>
            {tableRows.map((consumption) => {
              const { id, from, to, consumptionTypes, createdAt, createdByUser, provider, source_type_id, invoiceId } =
                consumption;

              return (
                <TableRowWithHighlight
                  key={id}
                  hover
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                  onClick={handleClick(consumption)}
                  background={hasRecentlyChanged(createdAt) ? 'success' : 'default'}
                >
                  <TableCell>{formatDateToLocale(from, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language)}</TableCell>
                  <TableCell>{formatDateToLocale(to, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language)}</TableCell>
                  <TableCell>
                    <Stack direction="row" gap={1}>
                      {consumptionTypes.map((type) => (
                        <Tooltip key={type} title={translateConsumptionTypeEnum_iconTooltip_dynamic(type!, t)}>
                          <span>
                            <ColoredLabel variant={getIconThemeColor(type!)}>
                              {getIconForConsumptionType(type!)}
                            </ColoredLabel>
                          </span>
                        </Tooltip>
                      ))}
                    </Stack>
                  </TableCell>
                  <TableCell>{translateConsumptionSourceTypeEnum(source_type_id)}</TableCell>
                  <TableCell component="th" scope="row">
                    <OverflowText text={provider} maxWidth={'250px'}></OverflowText>
                  </TableCell>
                  <TableCell>
                    <InlineUser firstName={createdByUser?.first_name} lastName={createdByUser?.last_name} />
                  </TableCell>

                  <TableCell
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    align="right"
                  >
                    <TableMoreMenu
                      actions={
                        <>
                          <MenuItem onClick={handleClick(consumption)}>
                            <Iconify icon={'mdi:pencil-outline'} />
                            {hasEditAccess ? t('General_Edit') : t('General_View')}
                          </MenuItem>

                          <AccessRightsWrapper hasAccess={hasEditAccess}>
                            <MenuItem
                              onClick={() => {
                                if (invoiceId !== null) {
                                  setInvoiceIdToDelete(invoiceId);
                                } else {
                                  setConsumptionIdToDelete(id);
                                }
                              }}
                              sx={{ color: 'error.main' }}
                            >
                              <Iconify icon={ICONS.TRASH} />
                              {t('General_Delete')}
                            </MenuItem>
                          </AccessRightsWrapper>
                        </>
                      }
                    ></TableMoreMenu>
                  </TableCell>
                </TableRowWithHighlight>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {filteredRows.length === 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', p: 3 }}>
          <Typography>{t('DataCollection_NoConsumptionsAvailable')}</Typography>
        </Box>
      )}

      <TablePaginationStandard
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />

      <DataCollectionSingleConsumptionModal
        buildingId={subBuilding.building_id}
        subBuildingId={subBuilding.id}
        isOpen={isSingleConsumptionDialogOpen}
        onClose={() => {
          setIsSingleConsumptionDialogOpen(false);
          setTimeout(() => {
            setSingleConsumptionToEdit(null);
          }, 250);
        }}
        consumptionData={singleConsumptionToEdit!}
      />

      <DeleteConfirmationModal
        open={Boolean(invoiceIdToDelete)}
        title={t('General_DeleteModalTitle-Invoice', { count: 1 })}
        description={<Trans i18nKey={'General_DeleteModalDescription-Invoice'} />}
        onClose={() => {
          setInvoiceIdToDelete(null);
        }}
        onDelete={async () => {
          await deleteProcessedBillMutation();
          setInvoiceIdToDelete(null);
        }}
      />
      <DeleteConfirmationModal
        open={Boolean(consumptionIdToDelete)}
        title={t('General_DeleteModalTitle-Consumption')}
        description={<Trans i18nKey={'General_DeleteModalDescription-Consumption'} />}
        onClose={() => {
          setConsumptionIdToDelete(null);
        }}
        onDelete={async () => {
          await deleteConsumptionMutation();
          setConsumptionIdToDelete(null);
        }}
      />
    </Box>
  );
}
