import { country_enum, emission_factor_type_enum, energy_source_type_enum } from '@predium/enums';
import { Co2TaxEmissionFactorProvider } from './emission-factor-providers/co2-tax.emission-factor-provider';
import { CRREMEmissionFactorProvider } from './emission-factor-providers/crrem.emission-factor-provider';
import { GegEmissionFactorProvider } from './emission-factor-providers/geg.emission-factor-provider';

function getCo2TaxEmissionFactor({
  energySourceType,
  year,
  country,
}: {
  energySourceType: energy_source_type_enum;
  year: number;
  country: country_enum;
}) {
  if (year < 2020) {
    year = 2020;
  }
  if (year > 2050) {
    year = 2050;
  }

  return Co2TaxEmissionFactorProvider.getEmissionFactor({ energySourceType, year, country });
}

export function getEmissionsFactor({
  energySourceType,
  emissionFactorType,
  country,
  year,
}: {
  energySourceType: energy_source_type_enum;
  emissionFactorType: emission_factor_type_enum;
  country: country_enum;
  year: number;
}): number {
  switch (emissionFactorType) {
    case emission_factor_type_enum.CO2TAX:
      return getCo2TaxEmissionFactor({ energySourceType, year, country });
    case emission_factor_type_enum.CRREM:
      return CRREMEmissionFactorProvider.getEmissionFactor({ energySourceType, country });
    case emission_factor_type_enum.GEG: {
      return GegEmissionFactorProvider.getEmissionFactor({ energySourceType, country });
    }
    default: {
      const exhaustiveCheck: never = emissionFactorType;
      throw new Error(`Unhandled emission factor type ${exhaustiveCheck}`);
    }
  }
}
