import { useQuery } from '@apollo/client';
import { MenuItem, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_PORTFOLIOS } from '../../../graphql/EsgAnalysis.queries';

// ----------------------------------------------------------------------

export const ENTIRE_COMPANY = -1;

export type BenchmarkType = { id: number | undefined; name: string };

type Props = {
  setSelectedBenchmark: (benchmark: BenchmarkType) => void;
};

export default function EsgAnalysisBenchmark({ setSelectedBenchmark }: Props) {
  const { t } = useTranslation();

  const defaultBenchmark = {
    id: ENTIRE_COMPANY,
    name: t('EsgAnalysisBenchmark_EntireCompany'),
  };

  const [benchmark, setBenchmark] = useState<{ id: number; name: string }>(defaultBenchmark);

  const { data: portfoliosData } = useQuery(GET_PORTFOLIOS);

  const portfolios = portfoliosData?.portfolio ?? [];

  const benchmarks = [
    defaultBenchmark,
    ...portfolios.map((portfolio) => ({
      id: portfolio.id,
      name: `Portfolio: ${portfolio.name}`,
    })),
  ];

  return (
    <Stack direction="row" alignItems="center" spacing={1.5}>
      <TextField
        select
        label={t('General_CompareWith')}
        value={benchmark.id}
        size="small"
        sx={{
          minWidth: 120,
        }}
      >
        {benchmarks.map((benchmark) => (
          <MenuItem
            key={benchmark.id}
            value={benchmark.id}
            onClick={() => {
              setBenchmark(benchmark);
              setSelectedBenchmark(benchmark);
            }}
          >
            {benchmark.name}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );
}
