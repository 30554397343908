import {
  area,
  bearing,
  bearingToAzimuth,
  center,
  difference,
  featureCollection,
  getCoord,
  nearestPointOnLine,
  point,
  rewind,
  segmentEach,
  union,
} from '@turf/turf';
import { Feature, GeoJsonProperties, LineString, MultiPolygon, Polygon, Position } from 'geojson';

/**
 * Discretize an orientation angle into a discrete value based on a specified step.
 * @param {number} orientation - The input orientation angle (in degrees).
 * @param {number} step - The step size for discretization (in degrees).
 * @returns {number} The discretized orientation angle.
 */
export function discretizeOrientation(orientation: number, step: number): number {
  const discreteOrientation = (Math.round(orientation / step) * step) % 360;
  return discreteOrientation;
}

/**
 * Calculates the distance between two lines. Only the portion of the "other"
 * line that intersects with the "main" line is considered for the distance calculation.
 *
 * The algorithm works as follows:
 * - First we find the section of "other" that intersects with "main". By
 * projecting the start and end points of the "main" line onto the "other"
 * line, we find the parts we're interested in.
 * - From these two points, we project them back onto the "main" line and see
 * how long each projection distance is. The maximum of these two distances is
 * the distance between the two lines.
 * */
export function distanceBetweenLines(main: Feature<LineString>, other: Feature<LineString>) {
  const [startMain, endMain] = main.geometry.coordinates;

  const projectionStart = nearestPointOnLine(other, point(startMain));
  const projectionEnd = nearestPointOnLine(other, point(endMain));

  const distanceStart = nearestPointOnLine(main, projectionStart, { units: 'meters' });
  const distanceEnd = nearestPointOnLine(main, projectionEnd, { units: 'meters' });

  if (distanceStart.properties.dist === undefined || distanceEnd.properties.dist === undefined) {
    throw new Error('Distance calculation failed');
  }

  return Math.max(distanceStart.properties.dist, distanceEnd.properties.dist);
}

/**
 * Ensures that the polygon follows the right-hand rule. This means that the
 * polygon is oriented in such a way that the exterior ring is counter-clockwise
 * and the interior rings are clockwise.
 *
 * Runs in-place.
 */
export function ensureRightHandRule(polygon: Feature<Polygon>): void {
  rewind(polygon, { mutate: true });
}
/**
 * Given a polygon, this function returns the direction faced by each side of
 * the polygon.
 * */
export function orientPolygon(polygon: Feature<Polygon>): number[] {
  /**
   * According to the specification, the points are given in a
   * counter-clockwise order. This means that if you start walking from the
   * start of the array, the inside of the building will be on your left. Since
   * we want to find the orientation in which the wall is facing, we turn to the
   * right. For example, if we're walking on the north wall, we will be facing
   * west.
   *
   * To get the orientation for a given segment we thus need to first get the
   * bearing (-180 to 180, +clockwise, 0 North), add 90 degrees to turn to the
   * right, and then convert it to a value between 0 and 360.
   * */

  const getOrientation = (start: Position, end: Position): number => {
    const b = bearing(point(start), point(end)); // Between -180 and 180, +clockwise, 0 North
    const azimuth = bearingToAzimuth(b); // Between 0 and 360, +clockwise, 0 North
    return (azimuth + 90) % 360;
  };

  const orientations: number[] = [];
  segmentEach(polygon, (segment) => {
    if (!segment) {
      return;
    }
    const [start, end] = segment.geometry.coordinates;
    let orientation = getOrientation(start, end);
    const lastOrientation = orientations.at(-1);
    if (lastOrientation) {
      const diff = Math.abs(orientation - lastOrientation);
      if (diff < 10) {
        orientation = lastOrientation;
      }
    }
    orientations.push(orientation);
  });

  return orientations;
}

/**
 * Returns true if the first polygon completely contains the second polygon.
 * We consider a non-null difference with an area less than 0.05 of the second polygon
 * essentially the same as containing the polygon.
 * */
export function polygonContains(polygon1: Feature<Polygon>, polygon2: Feature<Polygon>): boolean {
  const differencePolygon = difference(featureCollection([polygon2, polygon1]));
  if (differencePolygon === null) return true; // null means that the building is completely contained in the selectedBuilding
  const area2 = area(polygon2);
  const areaDifference = area(differencePolygon);
  return areaDifference < 0.05 * area2;
}

export function centerPolygons(polygons: Feature<Polygon>[]): { longitude: number; latitude: number } | undefined {
  if (polygons.length === 0) {
    return undefined;
  }
  const centerPoint = getCoord(center(featureCollection(polygons)));
  return { longitude: centerPoint[0], latitude: centerPoint[1] };
}

export function expandSelection<P extends GeoJsonProperties = GeoJsonProperties>(
  allBuildings: Feature<Polygon, P>[],
  selectedIDs: string[],
) {
  const initialSelection = selectedIDs
    .map((id) => allBuildings.find((b) => b.id?.toString() === id))
    .filter((b) => b !== undefined);
  const selection = [...initialSelection];
  const availableBuildings = allBuildings.filter((building) => initialSelection.indexOf(building) === -1);

  const extraContaingSelection = availableBuildings.filter((building) =>
    initialSelection.some((selectedBuilding) => polygonContains(building, selectedBuilding)),
  );
  extraContaingSelection.forEach((building) => selection.push(building));

  const nextAvailableBuildings = availableBuildings.filter((building) => selection.indexOf(building) === -1);
  const extraContainedInSelection = nextAvailableBuildings.filter((building) =>
    selection.some((selectedBuilding) => polygonContains(selectedBuilding, building)),
  );
  extraContainedInSelection.forEach((building) => selection.push(building));
  return selection;
}

export function mergePolygons<P extends GeoJsonProperties = GeoJsonProperties>(
  polygons: Feature<Polygon | MultiPolygon, P>[],
): Feature<Polygon | MultiPolygon, P> | null {
  if (!polygons || polygons.length === 0) {
    console.error('No polygons to merge');
    return null;
  }
  if (polygons?.length === 1) {
    return polygons[0];
  }
  return union(featureCollection(polygons));
}

export function mergeSameIdPolygons<P extends GeoJsonProperties = GeoJsonProperties>(
  polygons: Feature<Polygon, P>[],
): Feature<Polygon, P>[] {
  const seen = new Set<string | number>();
  const mergedPolygons: Feature<Polygon, P>[] = [];
  for (const polygon of polygons) {
    if (polygon.id === undefined) {
      mergedPolygons.push(polygon);
      continue;
    }

    if (seen.has(polygon.id)) {
      continue;
    }

    const sameIdPolygons = polygons.filter((p) => p.id === polygon.id);

    const merged = mergePolygons(sameIdPolygons);

    if (merged === null) {
      continue;
    }

    if (merged.geometry.type === 'MultiPolygon') {
      console.error(`Merged polygon is a MultiPolygon, ${polygon.id}`);
      mergedPolygons.push(...sameIdPolygons);
      continue;
    }

    seen.add(polygon.id);

    mergedPolygons.push({ ...polygon, geometry: merged.geometry });
  }
  return mergedPolygons;
}
