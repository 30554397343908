import { Button, Typography } from '@mui/material';
import * as sentry from '@sentry/react';
import { PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { IMAGES } from '../../assets/images';
import { PATHS } from '../../routes';

/**
 * The most general, outmost error boundary.
 * Serves as a fallback instead of a blank page and for user retention.
 */
function GeneralErrorBoundary({ children }: PropsWithChildren<{}>) {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <ErrorBoundary
      onError={(error, info) => {
        sentry.captureException(error, {
          extra: { info, location: location.pathname, pageName: 'GENERAL' },
        });
      }}
      key="GENERAL"
      fallback={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '2rem',
            height: '100vh',
          }}
        >
          <Typography variant="h3">{t('General_AnErrorHasOccurred')}</Typography>
          <Typography>{t('GeneralErrorBoundary_Description')}</Typography>
          <img src={IMAGES.ErrorFallbackGeneral} alt="A robot with two eyes and wrenches as arms." />

          <div>
            <Button
              variant="contained"
              component={Link}
              to={PATHS.root()}
              reloadDocument
              style={{ marginRight: '2rem' }}
            >
              {t('Page404_GoToHome')}
            </Button>
            <Button href="mailto:support@predium.de">{t('General_ContactSupport')}</Button>
          </div>
        </div>
      }
    >
      {children}
    </ErrorBoundary>
  );
}

export default GeneralErrorBoundary;
