import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TARGET_PATH_LAST_YEAR, pathValuesWithYearFromYear } from '@predium/client-lookup';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  LegendKeys,
  PathGraph,
  PathGraphLegend,
  PathGraphSeries,
} from '../../../../components/data-visialization/PathGraph';
import { createGraphExportFileName } from '../../../../utils/createGraphExportFileName';
import { determineStartYear, filterSeriesByStartYear } from '../../../Scenarios/ActionPlan/ActionPlan.utils';
import AddConsumptionButton from '../../ConsumptionAnalysis/ConsumptionComponents/AddConsumptionButton';
import { esgPathGraphOptions } from '../../DemandAnalysis/CrremAnalysis';

export type StrandingRange = {
  startYear: number;
  endYear: number;
};

type Props = {
  co2Path: number[];
  co2TargetPath: number[];
  buildingLegendName: string;
  strandingYears: StrandingRange[];
  graphStartYear: number;
};

export function Co2PathGraph({ co2Path, co2TargetPath, buildingLegendName, strandingYears, graphStartYear }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { id } = useParams();
  const buildingId = Number(id);

  const startYear: number = determineStartYear(graphStartYear);

  const co2Series = pathValuesWithYearFromYear(co2Path, startYear);
  const targetSeries = pathValuesWithYearFromYear(co2TargetPath, startYear);
  const newActionPlanSeries = filterSeriesByStartYear(co2Series, startYear);

  const isDataAvailable = co2Series.length > 0;

  const generateMultilineSeries = useCallback(() => {
    const years = [
      startYear,
      strandingYears[0]?.startYear ?? 2050,
      strandingYears[0]?.endYear ?? 2050,
      TARGET_PATH_LAST_YEAR,
    ];

    return years
      .map((year, index): PathGraphSeries => {
        return {
          name: buildingLegendName,
          data: newActionPlanSeries.map(([y, value]) => (y >= year && y <= years[index + 1] ? [y, value] : [y, null])),
          color: strandingYears.find(
            (strandingYear) => year >= strandingYear.startYear && year + 1 <= strandingYear.endYear,
          )
            ? theme.palette.error.main
            : theme.palette.common.black,
        };
      })
      .filter(Boolean);
  }, [startYear, strandingYears, newActionPlanSeries, buildingLegendName, theme.palette]);

  const series = useMemo(() => {
    if (!isDataAvailable) return [];

    const multilineSeries = generateMultilineSeries();

    multilineSeries.push({
      data: targetSeries,
      color: theme.palette.energyEfficiencyClassColors.AA.light,
      name: t('General_1_5CTargetPath'),
    });

    return multilineSeries;
  }, [isDataAvailable, generateMultilineSeries, targetSeries, theme.palette, t]);

  const annotations = useMemo(() => {
    if (!isDataAvailable) return { points: [], xaxis: [] };

    const pointsAnnotations: PointAnnotations[] = [];
    strandingYears.forEach(({ startYear }) => {
      const yValue = series[0]?.data.find(([year]) => year === startYear)?.[1];

      pointsAnnotations.push({
        x: startYear,
        y: yValue,
        marker: {
          size: 8,
          strokeWidth: 0,
          fillColor: theme.palette.error.main,
        },
      });
    });

    return {
      points: pointsAnnotations ?? [],
      xaxis: strandingYears
        ? strandingYears.map((strandingYear: { startYear: number; endYear: number }) => ({
            x: strandingYear.startYear,
            x2: strandingYear.endYear,
            fillColor: theme.palette.error.lighter,
          }))
        : [],
    };
  }, [isDataAvailable, strandingYears, series, theme.palette]);

  const legends = useMemo(() => {
    if (!isDataAvailable) return [];

    const legends: { key: LegendKeys; color: string; text: string }[] = [
      { key: 'strandingWithoutActions', color: theme.palette.error.main, text: t('General_StrandingBeforeActions') },
      { key: 'strandingPeriod', color: theme.palette.error.lighter, text: t('General_StrandingPeriod') },
      { key: 'object', color: theme.palette.common.black, text: t('General_Object') },
      {
        key: '1_5CTargetPath',
        color: theme.palette.energyEfficiencyClassColors.AA.light,
        text: t('General_1_5CTargetPath'),
      },
    ];
    return legends;
  }, [isDataAvailable, theme.palette, t]);

  const co2GraphOptions = {
    ...esgPathGraphOptions,
    stroke: {
      curve: 'straight',
      dashArray: [0, 0, 0, 0, 4],
      width: [4, 4, 4, 4, 2],
    },
  } as ApexCharts.ApexOptions;

  const dataToExport = {
    actualPath: newActionPlanSeries,
    targetPath: targetSeries,
  };

  const unit = 'kg CO₂e/m²a';

  return (
    <Box
      sx={{
        '.apexcharts-marker': {
          display: 'none', //using prop from apexchart doesn't work for some reason so we hide it here
        },
      }}
    >
      <PathGraph
        fileName={createGraphExportFileName(t('General_CO2Path'))}
        series={series}
        annotations={annotations}
        options={{
          legend: {
            show: false,
          },
          ...co2GraphOptions,
        }}
        unit={unit}
        dataToExport={dataToExport}
        noDataMessage={t('General_NoDataAvailable')}
        addDataButton={<AddConsumptionButton buildingId={buildingId} />}
      />
      {isDataAvailable && <PathGraphLegend legends={legends} />}
    </Box>
  );
}
