export enum ICONS {
  APPROXIMATION = 'mdi:chart-timeline-variant-shimmer',
  ALERT_CIRCLE = 'mdi:alert-circle',
  CANCEL = 'mdi:cancel',
  INFO = 'mdi:information-outline',
  RESTORE = 'mdi:restore',
  TRASH = 'mdi:trash-can-outline',
  EDIT = 'mdi:square-edit-outline',
  DANGER = 'mdi:alert-circle',
  BRIEFCASE = 'mdi:briefcase-outline',
  CRANE = 'mdi:crane',
  HOUSE = 'mdi:home-outline',
  PLUS = 'mdi:plus',
  FILEUPLOADOUTLINE = 'mdi:file-upload-outline',
  GAUGE = 'mdi:gauge',
  VOLUME = 'mdi:delete-variant',
  CLOSE = 'mdi:close',
  PDF_EXPORT = 'mdi:tray-arrow-down',
  U_VALUE = 'mdi:alpha-u-box',
  ORIENTATION = 'mdi:compass-outline',
  AREA = 'mdi:surface-area',
  INSULATION_THICKNESS = 'mdi:arrow-expand-horizontal',
  CHEVRON_DOWN = 'mdi:chevron-down',
  CHEVRON_LEFT = 'mdi:chevron-left',
  CHEVRON_UP = 'mdi:chevron-up',
  MAGNIFY = 'mdi:magnify',
  PORTFOLIO = 'mdi:briefcase-outline',
  AREA2 = 'mdi:texture-box',
  BUILDING = 'mdi:home-city-outline',
  CONSTRUCTION_YEAR = 'mdi:crane',
  RESIDENTIAL_BUILDING = 'mdi:home-outline',
  COMMERCIAL_BUILDING = 'mdi:office-building',
  DATABASE_OUTLINE = 'mdi:database-outline',
  PAGE_SEARCH = 'iconoir:page-search',
  CONVERSION_PATH = 'material-symbols:conversion-path',
  CHART_TIMELINE = 'mdi:chart-timeline',
  DASHBOARD_VIEW = 'mdi:chart-bar',
  LIST_VIEW = 'mdi:format-list-bulleted-square',
  WATER = 'mdi:drop-outline',
  ELECTRICITY = 'mdi:electricity-outline',
  HEAT = 'material-symbols:heat',
  ARROW_UP = 'mdi:arrow-up',
  ARROW_DOWN = 'mdi:arrow-down',
  ENERGY = 'mdi:energy-circle',
  ADD = 'mdi:plus',
  EDIT_SQUARE = 'mdi:square-edit-outline',
  HIDE = 'mdi:eye-off',
  RECOMMENDED_ACTIONS = 'mdi:creation-outline',
  COUNTRY = 'mdi:flag',
}

export enum ESG_TAB_ICONS {
  DEMAND = 'mdi:calculator',
  CONSUMPTION = 'mdi:gauge',
  CLIMATE_RISKS = 'mdi:tsunami',
}
