/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Box, Card, Divider, Grid, MenuItem, Stack, Switch, TextField, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { emission_factor_type_enum } from '@predium/enums';
import { translateEmissionFactorTypeEnum } from '@predium/i18n/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import { GET_PORTFOLIOS } from '../../graphql/Portfolio.queries';
import useOrgPreferences from '../../hooks/useOrgPreferences';
import useSubscriptionWithFallback from '../../hooks/useSubscriptionFallback';
import { EmissionFactorChangeDialog } from '../../sections/Org/Settings/EmissionFactorChangeDialog';
import { SUPPORTED_EMISSION_FACTOR_TYPES } from '../../sections/Org/Settings/settings.util';

export default function OrgSettings() {
  const { t } = useTranslation();

  const { economicUnitsToggled, toggleEconomicUnits, orgEmissionFactorType } = useOrgPreferences();

  const [newEmissionFactorType, setNewEmissionFactorType] = useState<emission_factor_type_enum>();
  const [showEmissionFactorChangeDialog, setShowEmissionFactorChangeDialog] = useState<boolean>(false);

  const { data: portfolios } = useQuery(GET_PORTFOLIOS);

  return (
    <Page title={t('Settings_ManageOrganization')}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs heading={t('Settings_ManageOrganization')} links={[]} />
        <Card sx={{ p: 3, alignContent: 'center' }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <SettingInfo
                  title={t('OrgSettings_EnableEconomicUnits')}
                  description={t('OrgSettings_EnableEconomicUnitsDescription')}
                />

                <Switch
                  checked={economicUnitsToggled}
                  onChange={() => {
                    toggleEconomicUnits();
                  }}
                />
              </Stack>

              <Divider sx={{ my: 2 }} />

              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <SettingInfo
                  title={t('OrgSettings_DatabaseEmissionFactors')}
                  description={t('OrgSettings_DatabaseEmissionFactorsDescription')}
                />
                <div style={{ width: 250 }}>
                  <TextField
                    fullWidth
                    select
                    value={orgEmissionFactorType}
                    onChange={(event) => {
                      setNewEmissionFactorType(
                        emission_factor_type_enum[event.target.value as emission_factor_type_enum],
                      );
                      setShowEmissionFactorChangeDialog(true);
                    }}
                  >
                    {SUPPORTED_EMISSION_FACTOR_TYPES.map((type) => (
                      <MenuItem key={type} value={type}>
                        {translateEmissionFactorTypeEnum(type)}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </Card>
        <EmissionFactorChangeDialog
          open={showEmissionFactorChangeDialog}
          onClose={() => setShowEmissionFactorChangeDialog(false)}
          currentEmissionFactor={orgEmissionFactorType}
          //@ts-ignore
          newEmissionFactor={newEmissionFactorType}
          portfoliosCount={portfolios ? portfolios.portfolio.length : null}
          useSubscription={useSubscriptionWithFallback}
        />
      </Container>
    </Page>
  );
}

type SettingInfoType = {
  title: string;
  description: string;
};

const SettingInfo = ({ title, description }: SettingInfoType) => {
  return (
    <Box>
      <Typography variant="body1">{title}</Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    </Box>
  );
};
