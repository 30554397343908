import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import {
  ActionPlanningActionPlanActionPlanFragment,
  BuildingModelFragment,
  renovation_type_enum,
} from '@predium/client-graphql';
import { translateRenovationTypeEnum_dynamic } from '@predium/i18n/client';
import { calculateImpact, fCurrencyTrimmed } from '@predium/utils';
import { TFunction } from 'i18next';
import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import EfficiencyClassLabel from '../../../../../components/EfficiencyClassLabel';
import Label from '../../../../../components/Label';
import { getActionIcon } from '../../../../../utils/icons';
import {
  getRecommendedEnvelopeActionName,
  getRecommendedRouteActionName,
  getRecommendedSystemActionName,
} from '../../ActionPlan.utils';
import { ComparisonTableCell } from '../../Components/ComparisonTableCell';
import { RecommendationOutput } from '../RecommendedActions';
import RecommendedActionMenu from './RecommendedActionMenu';

export const getRecommendedActionTitle = (action: RecommendationOutput, t: TFunction<'translation', undefined>) => {
  const systemType = action.parameters && 'system_type' in action.parameters ? action.parameters.system_type : null;
  const envelopeType =
    action.parameters && 'envelope_type' in action.parameters ? action.parameters.envelope_type : null;

  let title;
  let icon;
  switch (action.renovation_type) {
    case renovation_type_enum.ENVELOPE:
      title = getRecommendedEnvelopeActionName(envelopeType);
      icon = getActionIcon(envelopeType);
      break;

    case renovation_type_enum.ENERGY_CONSUMER:
      const materialName =
        action.parameters && 'technology_name' in action.parameters ? action.parameters.technology_name : null;

      const technologyType =
        action.parameters && 'consumer_technology_type' in action.parameters
          ? action.parameters.consumer_technology_type
          : null;
      title = getRecommendedSystemActionName(materialName, technologyType);
      icon = getActionIcon(systemType);

      break;

    case renovation_type_enum.ENERGY_SYSTEM_ROUTE:
      const energySourceType =
        action.parameters && 'energy_source_type' in action.parameters ? action.parameters.energy_source_type : null;
      title = getRecommendedRouteActionName(energySourceType);
      icon = getActionIcon(energySourceType);
      break;

    case renovation_type_enum.SOLAR_PLANT:
    case renovation_type_enum.HYDRAULIC_BALANCING:
      title = translateRenovationTypeEnum_dynamic(action.renovation_type, t);
      icon = getActionIcon(action.renovation_type);
      break;

    default:
      title = '';
      break;
  }

  return { title, icon };
};

type Props = {
  action: RecommendationOutput;
  hideAction: (id: number) => void;
  baseBuildingModel: BuildingModelFragment;
  actionPlan: ActionPlanningActionPlanActionPlanFragment;
  defaultImplementationDate: Date;
  totalArea: number;
};

const RecommendedAction = ({
  action,
  hideAction,
  baseBuildingModel,
  actionPlan,
  defaultImplementationDate,
  totalArea,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const actionImpact = action.actionImpact;
  const strandingDate = actionImpact.stranding_date;
  const strandingDateAfter = strandingDate.after ? new Date(strandingDate.after).getFullYear() : '-';

  const strandingDateChange = strandingDate?.impact ?? 0;

  const strandingDateText =
    strandingDateChange !== 0
      ? `${strandingDateChange < 0 ? '- ' : '+ '}${Math.abs(strandingDateChange)} ${t('General_Year', {
          count: Math.abs(strandingDateChange),
        })}`
      : `0 ${t('General_Year', { count: 0 })}`;

  const { efficiency_class: efficiencyClass, total_estimated_cost, co2_emissions, final_energy } = actionImpact;

  const { title, icon } = getRecommendedActionTitle(action, t);

  return (
    <Grid
      container
      sx={{
        borderRadius: '16px',
        background: theme.palette.common.white,
        ':not(:last-child)': {
          mb: 3,
        },
      }}
      p={3}
      justifyContent="space-between"
    >
      <Grid container mb={3} justifyContent="space-between">
        <Grid item xs={12} md={6} xl={9}>
          <Stack direction={'row'} alignItems={'center'}>
            <Box component={'img'} src={icon} width={24} mr={2} />
            <Typography variant="subtitle1">{title}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <RecommendedActionMenu
            action={action}
            hideAction={hideAction}
            baseBuildingModel={baseBuildingModel}
            actionPlan={actionPlan}
            defaultImplementationDate={defaultImplementationDate}
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs md="auto" xl={2}>
          <Typography variant="caption" sx={{ mb: 0.5, color: 'text.secondary' }}>
            {t('General_FinalEnergy')} (kWh/m²a)
          </Typography>
          <ComparisonTableCell
            before={Number((final_energy.before ?? 0).toFixed(1))}
            after={Number((final_energy.after ?? 0).toFixed(1))}
            comparisonPercent={calculateImpact(
              Number((final_energy.after ?? 0).toFixed(1)),
              Number((final_energy.before ?? 0).toFixed(1)),
            )}
            isTableCell={false}
            minWidth="unset"
          />
        </Grid>

        <Grid item xs md="auto" xl={2}>
          <Typography variant="caption" sx={{ mb: 0.5, color: 'text.secondary' }}>
            {t('General_CO2Intensity')}(kg CO₂e/m²a)
          </Typography>
          <ComparisonTableCell
            before={Number((co2_emissions.before ?? 0).toFixed(1))}
            after={Number((co2_emissions.after ?? 0).toFixed(1))}
            comparisonPercent={calculateImpact(
              Number((co2_emissions.after ?? 0).toFixed(1)),
              Number((co2_emissions.before ?? 0).toFixed(1)),
            )}
            isTableCell={false}
            minWidth="unset"
          />
        </Grid>

        <Grid item xs md="auto" xl={2}>
          <Typography variant="caption" sx={{ mb: 0.5, color: 'text.secondary' }}>
            {t('General_StrandingDate')}
          </Typography>

          <Stack direction={'row'}>
            <Typography variant="body2">{strandingDateAfter}</Typography>
            <Label
              color={strandingDateChange > 0 ? 'success' : strandingDateChange === 0 ? 'default' : 'error'}
              sx={{ minWidth: '40px', ml: 1 }}
            >
              {strandingDateText}
            </Label>
          </Stack>
        </Grid>

        <Grid item xs md="auto" xl={2}>
          <Typography variant="caption" sx={{ mb: 0.5, color: 'text.secondary' }}>
            {t('General_EfficiencyClass')}
          </Typography>
          <ComparisonTableCell
            before={efficiencyClass.before ? <EfficiencyClassLabel efficiencyClass={efficiencyClass.before} /> : null}
            after={efficiencyClass.after ? <EfficiencyClassLabel efficiencyClass={efficiencyClass.after} /> : null}
            isTableCell={false}
            hideComparisonLabel
          />
        </Grid>

        <Grid item xs md="auto" xl={2}>
          <Typography variant="caption" sx={{ mb: 0.5, color: 'text.secondary' }}>
            {t('General_TotalInvestment')}
          </Typography>
          <Stack direction={'row'}>
            <Typography variant="body2">
              {!isNil(total_estimated_cost) ? fCurrencyTrimmed(total_estimated_cost, { unit: ' €' }) : '-'}
            </Typography>
            {!isNil(co2_emissions.impact) && !isNil(total_estimated_cost) ? (
              <Typography variant="body2" color={'text.secondary'} sx={{ ml: 1 }}>
                {`(≙ ${
                  total_estimated_cost > 0
                    ? Number(((Math.abs(co2_emissions.impact) * totalArea) / total_estimated_cost) * 1000).toFixed(1)
                    : '-'
                } g CO₂e/€ a)`}
              </Typography>
            ) : (
              '-'
            )}
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RecommendedAction;
