import { Stack, Typography } from '@mui/material';
import { ComponentProps, ReactNode } from 'react';
import DetailedTooltip from '../../../../../components/DetailedTooltip/DetailedTooltip';

type Props = {
  labels?: {
    title: ReactNode;
    titleSuffix?: ReactNode;
    subtitle?: ReactNode;
    tooltipSubtitle?: ReactNode;
    tooltipBody?: ReactNode;
  };
  titleProps?: ComponentProps<typeof Typography>;
  icon?: ReactNode;
};

const SquareMeterRowLabel = ({
  icon,
  labels: { title, titleSuffix, subtitle, tooltipBody, tooltipSubtitle } = { title: '' },
  titleProps,
}: Props) => {
  return (
    <Stack direction="row" justifyContent="space-between" height={40}>
      <Stack direction="row" spacing={2} alignItems={icon ? 'flex-start' : 'center'}>
        {icon}
        <Stack>
          <Stack direction="row" spacing={0.75} alignItems="center">
            <Typography
              variant="subtitle1"
              {...titleProps}
              sx={{
                '&.MuiTypography-subtitle1': {
                  fontSize: 14,
                  fontWeight: 700,
                },
                ...titleProps?.sx,
              }}
            >
              {title}
            </Typography>
            {titleSuffix && (
              <Typography variant="subtitle2" fontWeight={400}>
                {titleSuffix}
              </Typography>
            )}
            {tooltipBody && (
              <DetailedTooltip
                labels={{
                  title,
                  subtitle: tooltipSubtitle,
                  body: tooltipBody,
                }}
              />
            )}
          </Stack>
          {subtitle && (
            <Typography variant="caption" color="grey.500">
              {subtitle}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SquareMeterRowLabel;
