export const POSTHOG_EVENTS = {
  //GENERAL
  ACCESS_RIGHTS_UPDATED: 'access_rights_updated',
  EMISSION_FACTOR_TYPE_UPDATED: 'emission_factor_type_updated',
  PORTFOLIO_CREATED: 'portfolio_created',
  PORTFOLIO_SELECTED: 'portfolio_selected',
  PORTFOLIO_UPDATED: 'portfolio_updated',
  USER_CREATED: 'user_created',

  //ESG
  BUILDING_ESG_ANALYSIS_TAB_CHANGED: 'building_esg_analysis_tab_changed',
  ESG_BUILDING_CRREM_ANALYSIS_TAB_CHANGE: 'esg_building_crrem_analysis_tab_change',
  ESG_PORTFOLIO_CRREM_ANALYSIS_TAB_CHANGE: 'esg_portfolio_crrem_analysis_tab_change',
  ESG_ECONOMIC_UNIT_CRREM_ANALYSIS_TAB_CHANGE: 'esg_economic_units_crrem_analysis_tab_change',
  ESG_CRREM_EXCESS_EMISSION_COST_DISCOUNT_RATE_CHANGED: 'esg_crrem_excess_emission_cost_discount_rate_changed',

  //ECONOMIC UNIT
  ESG_ECONOMIC_UNIT_SELECTED: 'esg_economic_unit_selected',

  // DATA COLLECTION
  CREATE_SCENARIO: 'data_collection_create_scenario',
  EMISSION_CERTIFICATE_CREATED: 'data_collection_emission_certificate_created',
  BUILDING_AREA_UPDATED: 'data_collection_building_area_updated',
  BUILDING_MODEL_MUTATED: 'data_collection_building_model_mutated',
  BUILDING_CREATED: 'data_collection_building_created',
  BUILDING_DELETED: 'data_collection_building_deleted',
  BUILDING_LOCATION_UPDATED: 'data_collection_building_location_updated',
  BUILDING_SINGLE_CONSUMPTION_DRAFT_UPDATED: 'data_collection_building_single_consumption_draft_updated',
  BUILDING_SINGLE_CONSUMPTION_CREATED: 'data_collection_building_single_consumption_created',
  BUILDING_INVOICE_CONSUMPTION_CREATED: 'data_collection_building_consumption_invoice_created',
  BUILDING_SELECTED: 'data_collection_building_selected',
  DISPLAY_BASE_CHANGED: 'data_collection_display_base_changed',
  ECONOMIC_UNIT_SELECTED: 'data_collection_economic_unit_selected',

  // ACTION PLANNING
  ACTION_CREATED: 'action_plan_action_created',
  ACTION_VIEWED: 'action_plan_action_viewed',
  ACTION_CUSTOM_COST_UPDATED: 'action_plan_action_custom_cost_updated',

  // REPORTING
  REPORT_CREATED: 'reporting_report_created',
  REPORT_COMPLETED: 'reporting_report_completed',
};

// List of urls we want to track (user viewed this page)
export const POSTHOG_PAGES_TO_TRACK = ['org/users'];

//type of the
export type PosthogEvent = keyof typeof POSTHOG_EVENTS;
