import {
  country_enum,
  efficiency_class_enum,
  energy_source_type_enum,
  energy_system_type_enum,
  sub_building_class_enum,
} from '@predium/enums';
import sumBy from 'lodash/sumBy';

const EfficiencyClasses: Record<country_enum, Record<efficiency_class_enum, number>> = {
  DE: {
    AAA: 0,
    AA: 30,
    A: 50,
    B: 75,
    C: 100,
    D: 130,
    E: 160,
    F: 200,
    G: 250,
    H: Infinity,
    NOT_APPLICABLE: 0,
    UNKNOWN: 0,
  },
  AT: {
    AAA: 60,
    AA: 70,
    A: 80,
    B: 160,
    C: 220,
    D: 280,
    E: 340,
    F: 400,
    G: Infinity,
    H: Infinity,
    NOT_APPLICABLE: 0,
    UNKNOWN: 0,
  },
  PL: {
    AAA: 0,
    AA: 0,
    A: 63,
    B: 157,
    C: 250,
    D: 344,
    E: 438,
    F: 531,
    G: Infinity,
    H: Infinity,
    NOT_APPLICABLE: 0,
    UNKNOWN: 0,
  },
};

const HeatingDemandAustriaLookup: Record<efficiency_class_enum, number> = {
  AAA: 10,
  AA: 15,
  A: 25,
  B: 50,
  C: 100,
  D: 150,
  E: 200,
  F: 250,
  G: Infinity,
  H: Infinity,
  NOT_APPLICABLE: 0,
  UNKNOWN: 0,
};

//this is to make sure they are evaluated in the right order, even when the order in the enum should change
// Use Record<> to make sure that we get a compile error when adding new values to the enum
const EfficiencyClassOrderRecord: Record<efficiency_class_enum, null> = {
  [efficiency_class_enum.AAA]: null,
  [efficiency_class_enum.AA]: null,
  [efficiency_class_enum.A]: null,
  [efficiency_class_enum.B]: null,
  [efficiency_class_enum.C]: null,
  [efficiency_class_enum.D]: null,
  [efficiency_class_enum.E]: null,
  [efficiency_class_enum.F]: null,
  [efficiency_class_enum.G]: null,
  [efficiency_class_enum.H]: null,
  [efficiency_class_enum.NOT_APPLICABLE]: null,
  [efficiency_class_enum.UNKNOWN]: null,
};

export const EfficiencyClassOrder: efficiency_class_enum[] = Object.keys(
  EfficiencyClassOrderRecord,
) as efficiency_class_enum[];

export function getEfficiencyClass({
  country,
  subBuildingClass,
  energySystems,
  heatingDemand,
}: {
  country: country_enum;
  subBuildingClass: sub_building_class_enum | string;
  energySystems: {
    energy_system_type: energy_system_type_enum;
    energy_system_consumer_routes: {
      energy_final: number;
      energy_source_type: energy_source_type_enum;
      energy_primary: number;
    }[];
  }[];
  heatingDemand: number | null;
}): efficiency_class_enum {
  if (subBuildingClass === sub_building_class_enum.COMMERCIAL && country != country_enum.AT) {
    return efficiency_class_enum.NOT_APPLICABLE;
  }

  //calculate the energy that is used to look up the efficiency class based on country
  let lookupEnergy = 0;
  switch (country) {
    case country_enum.DE:
      //use final energy heating and hot water for the lookup
      lookupEnergy = sumBy(
        energySystems.filter(
          (energySystem) =>
            energySystem.energy_system_type === energy_system_type_enum.HEATING ||
            energySystem.energy_system_type === energy_system_type_enum.HOT_WATER,
        ),
        (energySystem) => sumBy(energySystem.energy_system_consumer_routes, (route) => route.energy_final),
      );
      break;
    case country_enum.AT:
      if (heatingDemand) {
        for (const efficiencyClass of EfficiencyClassOrder) {
          if (heatingDemand < HeatingDemandAustriaLookup[efficiencyClass]) {
            return efficiencyClass;
          }
        }
      }
      //use primary energy for the lookup
      lookupEnergy = sumBy(energySystems, (system) =>
        sumBy(system.energy_system_consumer_routes, (route) => route.energy_primary),
      );
      break;
    case country_enum.PL:
      //use primary energy for the lookup
      lookupEnergy = sumBy(energySystems, (system) =>
        sumBy(system.energy_system_consumer_routes, (route) => route.energy_primary),
      );
      break;

    default:
      throw new Error(`Country ${country} not supported`);
  }

  //use the calculated lookup energy to... look up the efficiency class
  return getEfficiencyClassByEnergy(lookupEnergy, country);
}

export function getEfficiencyClassByEnergy(lookupEnergy: number, country: country_enum) {
  for (const efficiencyClass of EfficiencyClassOrder) {
    if (lookupEnergy < EfficiencyClasses[country][efficiencyClass]) {
      return efficiencyClass;
    }
  }
  return efficiency_class_enum.UNKNOWN;
}

export function averageEfficiencyClass(
  areaByEfficiencyClass: {
    area: number;
    efficiencyClass: efficiency_class_enum;
  }[],
) {
  const orderedEfficiencyClasses = EfficiencyClassOrder.map((efficiencyClass) => {
    const matching = areaByEfficiencyClass.filter((c) => c.efficiencyClass === efficiencyClass);
    if (matching.length === 0) {
      return {
        area: 0,
        efficiencyClass,
      };
    }
    return {
      area: sumBy(matching, ({ area }) => area),
      efficiencyClass,
    };
  });

  //remove unknown efficiency class and commercial buildings with efficiency class NOT_APPLICABLE
  const cleanClasses = orderedEfficiencyClasses.filter(
    (efficiencyClassBracket) =>
      efficiencyClassBracket.efficiencyClass !== efficiency_class_enum.UNKNOWN &&
      efficiencyClassBracket.efficiencyClass !== efficiency_class_enum.NOT_APPLICABLE,
  );

  const totalArea = sumBy(cleanClasses, ({ area }) => area);

  if (totalArea === 0) return efficiency_class_enum.NOT_APPLICABLE; //for commercial only portfolios

  const averageWeightedEfficiencyClassIndex = cleanClasses.reduce((acc, efficiencyClassBracket, index) => {
    const efficiencyClassIndex = index + 1; //+1 because otherwise class 0 has no effect
    return acc + (efficiencyClassBracket.area / totalArea) * efficiencyClassIndex;
  }, -1); //start with -1 because otherwise class 0 has no effect

  return EfficiencyClassOrder[Math.round(averageWeightedEfficiencyClassIndex)]; //round to get the closest class and get enum value for it
}
