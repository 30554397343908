import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ICONS } from '../../../../assets/icons';
import Iconify from '../../../../components/Iconify';
import { BuildingTabEnum } from '../../../../pages/DataCollection/DataCollectionBuilding';
import { PATHS } from '../../../../routes';

type Props = {
  buildingId: number;
};

const AddConsumptionButton = ({ buildingId }: Props) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="outlined"
      size="small"
      sx={{ pt: 0.5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      endIcon={
        <Iconify
          icon={ICONS.ARROW_UP}
          width={16}
          height={16}
          sx={{
            transform: 'rotate(45deg) translateX(-2px)',
          }}
        />
      }
      component={Link}
      to={PATHS.dataCollection.building({ id: buildingId }, { tab: BuildingTabEnum.consumption })}
    >
      {t('General_AddData')}
    </Button>
  );
};

export default AddConsumptionButton;
