import { ApolloQueryResult, useQuery, useSubscription } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { createContext, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import {
  async_job_status_type_enum,
  DataCollectionBuildingGetBuildingQuery,
} from '../../../../../../../libs/generated/client-graphql/src';
import LoadingScreen from '../../../../components/LoadingScreen';
import { createStandardErrorSnackbar } from '../../../../components/NotistackProvider';
import { PermissionType } from '../../../../contexts/PermissionContext';
import { ASYNC_JOB_SUBSCRIPTION_ENTITY_ORG } from '../../../../graphql/common.queries';
import { GET_BUILDING } from '../../../../graphql/DataCollection.queries';
import usePermissions from '../../../../hooks/usePermissions';
import useSessionData from '../../../../hooks/useSessionData';
import { PATHS } from '../../../../routes';

type BuildingContextProps = {
  building: NonNullable<DataCollectionBuildingGetBuildingQuery['building_by_pk']>;
  hasEditAccess: boolean;
  reloadCurrentBuilding: () => Promise<ApolloQueryResult<DataCollectionBuildingGetBuildingQuery>>;
};

const BuildingContext = createContext<BuildingContextProps | null>(null);

type Props = PropsWithChildren<{
  useSubscription: typeof useSubscription;
}>;

function BuildingContextProvider({ children, useSubscription }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { id: buildingIdString } = useParams();
  const buildingId = parseInt(buildingIdString!);
  const { org } = useSessionData();

  const { data, refetch: reloadCurrentBuilding } = useQuery(GET_BUILDING, {
    variables: { buildingId, year: new Date().getFullYear() },
    onError: () => createStandardErrorSnackbar(t)(enqueueSnackbar),
  });

  const building = data?.building_by_pk;

  useSubscription(ASYNC_JOB_SUBSCRIPTION_ENTITY_ORG, {
    skip:
      !building ||
      !org?.id ||
      !building.sub_buildings[0].id ||
      building.sub_buildings.some((subBuilding) => subBuilding.subsidies_updated_at),
    variables: {
      title: 'UPDATE_SUBSIDIES',
      orgId: org?.id,
      entityId: building?.sub_buildings[0].id,
    },
    onData: async ({ data: subscriptionData }) => {
      if (!subscriptionData.data || !subscriptionData.data.async_job.length) {
        return;
      }

      const latestJob = subscriptionData.data.async_job[0];
      const status = latestJob.status_id;
      /**
       * If the subsidies are not fetched yet, we can refetch after one minute.
       */
      if (
        status === async_job_status_type_enum.COMPLETED &&
        !building!.sub_buildings.some((subBuilding) => subBuilding.subsidies_updated_at)
      ) {
        await reloadCurrentBuilding();
      }
    },
  });

  const { checkBuildingPermission } = usePermissions();

  if (!data) return <LoadingScreen />;

  if (!data.building_by_pk) {
    return <Navigate to={PATHS.notFound()} />;
  }

  const hasEditAccess = checkBuildingPermission(data.building_by_pk.id, PermissionType.WRITE);

  return (
    <BuildingContext.Provider
      value={{
        building: data.building_by_pk!,
        hasEditAccess,
        reloadCurrentBuilding,
      }}
    >
      {children}
    </BuildingContext.Provider>
  );
}

export { BuildingContext, BuildingContextProvider };
