import { type_of_use_enum } from '@predium/enums';

export const commonAreaRatios: Record<type_of_use_enum, number> = {
  [type_of_use_enum.MFH]: 0.25,
  [type_of_use_enum.SFH]: 0.055,
  [type_of_use_enum.DISTRIBUTION_WAREHOUSE_COLD]: 0.03,
  [type_of_use_enum.DISTRIBUTION_WAREHOUSE_WARM]: 0.03,
  [type_of_use_enum.OFFICE]: 0.25,
  [type_of_use_enum.RETAIL]: 0.03,
  [type_of_use_enum.HEALTH_FACILITY]: 0.25,
  [type_of_use_enum.HOTEL]: 0.2,
  [type_of_use_enum.RETAIL_WAREHOUSE]: 0.03,
  [type_of_use_enum.SHOPPING_CENTER]: 0.25,
};

export const commonAreaRange: Record<type_of_use_enum, [number, number]> = {
  [type_of_use_enum.MFH]: [0.2, 0.3],
  [type_of_use_enum.SFH]: [0.01, 0.1],
  [type_of_use_enum.DISTRIBUTION_WAREHOUSE_COLD]: [0.01, 0.05],
  [type_of_use_enum.DISTRIBUTION_WAREHOUSE_WARM]: [0.01, 0.05],
  [type_of_use_enum.OFFICE]: [0.01, 0.3],
  [type_of_use_enum.RETAIL]: [0.01, 0.05],
  [type_of_use_enum.HEALTH_FACILITY]: [0.01, 0.35],
  [type_of_use_enum.HOTEL]: [0.01, 0.25],
  [type_of_use_enum.RETAIL_WAREHOUSE]: [0.01, 0.05],
  [type_of_use_enum.SHOPPING_CENTER]: [0.01, 0.3],
};

/**
 * min/max range due to GRESB for buildings of mixed use.
 */
export const mixedUseGRESBAreaRange = [0.05, 0.3];
