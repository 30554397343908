import { Box, Stack, Typography, useTheme } from '@mui/material';
import { efficiency_class_enum } from '@predium/client-graphql';
import { translateEfficiencyClassEnum } from '@predium/i18n/client';

type Props = {
  /**
   * The new efficiency class
   */
  efficiencyClass: efficiency_class_enum;
};

const NewEfficiencyClassLabel: React.FC<Props> = ({ efficiencyClass }) => {
  const theme = useTheme();

  const color = theme.palette.energyEfficiencyClassColors[efficiencyClass].light;
  const text = translateEfficiencyClassEnum(efficiencyClass);

  return (
    <Stack alignItems={'center'} direction={'row'} pl={1}>
      <Box
        sx={{
          borderTop: '14px solid transparent',
          borderBottom: '14px solid transparent',
          borderRight: `12px solid ${color}`,
        }}
      />
      <Stack
        sx={{
          width: 30,
          height: 28,
          backgroundColor: color,
          borderRadius: '0px 4px 4px 0px',
        }}
      >
        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
          <Typography
            sx={{
              color: theme.palette.common.black,
              typography: 'body1',
            }}
          >
            {text}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default NewEfficiencyClassLabel;
