/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { createStandardErrorSnackbar } from '../../../../components/NotistackProvider';
import { EXPORT_ALL_BUILDINGS } from '../../../../graphql/DataCollection.mutations';
import { BulkOperationsFilters } from './AllBulkMove';
import BulkExport from './Components/BulkExport';

type Props = {
  bulkFilters: BulkOperationsFilters;
  count: number;
  onActionCompleted?: VoidFunction;
};

export default function AllBulkExport({ bulkFilters, count, onActionCompleted }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const displayStandardMutationQueryErrorSnackbar = createStandardErrorSnackbar(t);

  const [buildingExcelExportMutation, { data }] = useMutation(EXPORT_ALL_BUILDINGS, {
    onError: () => displayStandardMutationQueryErrorSnackbar(enqueueSnackbar),
  });

  const triggerExport = useCallback(
    () =>
      buildingExcelExportMutation({
        variables: {
          ...bulkFilters,
        },
      }),
    [bulkFilters, buildingExcelExportMutation],
  );

  return (
    <BulkExport
      count={count}
      triggerExport={triggerExport}
      exportBody={data?.exportAllSubBuildingsAsExcel?.body}
      onActionCompleted={onActionCompleted}
    />
  );
}
