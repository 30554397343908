import { SxProps, Theme } from '@mui/material';
import { area_type_enum, country_enum } from '@predium/enums';
import { getBuildingIsOnlyCommercial } from '@predium/utils';
import { ComponentProps, createContext, PropsWithChildren, useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useBuilding from '../../Context/useBuilding';
import CalculationTooltip from '../Components/CalculationTooltip';
import { AreaFormValues } from '../types';
import { Equation, getNGFFormula, getNGFFormulaForCommercial } from './formulas';

type CalculatorContextType = {
  getIsPopoverOpen: (areaType: area_type_enum) => boolean;
  getActiveStyles: (areaType: area_type_enum) => SxProps<Theme>;
  onAreaClick: (areaType: area_type_enum) => void;
  getInfoTooltipProps: (areaType: area_type_enum) => ComponentProps<typeof CalculationTooltip>;
  onAreaAlertClose: () => void;
};

const CalculatorContext = createContext<CalculatorContextType | undefined>(undefined);

export const CalculatorProvider = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const {
    building: {
      address: { country_id },
    },
  } = useBuilding();
  const { getValues } = useFormContext<AreaFormValues>();
  const [openAreaType, setOpenAreaType] = useState<area_type_enum | null>(null);

  const getCalculations = () => {
    const areas = getValues('areas').filter((area) => !area.delete);
    const isSingleCommercialDE = getBuildingIsOnlyCommercial(areas) && country_id === country_enum.DE;

    return [isSingleCommercialDE ? getNGFFormulaForCommercial(t) : getNGFFormula(t)] satisfies Equation[];
  };

  const getCalculation = (areaType: area_type_enum) => {
    return getCalculations().find((calculation) => calculation.area === areaType);
  };

  const onAreaClick = (areaType: area_type_enum) => {
    setOpenAreaType(getCalculations().find((calculation) => calculation.area === areaType)?.area ?? null);
  };

  const onAreaAlertClose = () => {
    setOpenAreaType(null);
  };

  const getIsPopoverOpen = (areaType: area_type_enum) => {
    return openAreaType === areaType;
  };

  const getActiveStyles = (areaType: area_type_enum): SxProps<Theme> => {
    if (!openAreaType) {
      return {};
    }

    const calc = getCalculation(openAreaType);
    if (!calc) {
      return {};
    }

    if (calc.area === areaType) {
      return {
        borderRadius: 1,
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='black' stroke-width='1' stroke-dasharray='5' stroke-dashoffset='100' stroke-linecap='square'/%3e%3c/svg%3e")`,
      };
    }

    if (calc.affectedAreas.includes(areaType)) {
      const index = calc.affectedAreas.findIndex((area) => area === areaType);

      return (theme) => ({
        bgcolor: theme.palette.areaColors.areas[index].bgColor,
        '& .MuiTypography-root, svg': {
          color: 'text.primary',
        },
      });
    }

    return {};
  };

  const getLabelsForTooltip = (areaType: area_type_enum): Equation['labels'][] => {
    if (!openAreaType) {
      return [];
    }

    if (openAreaType === areaType) {
      return [getCalculation(openAreaType)?.labels].filter((label) => label !== undefined);
    }

    return [];
  };

  const getInfoTooltipProps = (areaType: area_type_enum): ComponentProps<typeof CalculationTooltip> => {
    return {
      labels: getLabelsForTooltip(areaType),
      variant: getCalculation(areaType)?.type,
      open: getIsPopoverOpen(areaType),
      onClose: onAreaAlertClose,
    };
  };

  return (
    <CalculatorContext.Provider
      value={{
        getIsPopoverOpen,
        getActiveStyles,
        onAreaClick,
        getInfoTooltipProps,
        onAreaAlertClose,
      }}
    >
      {children}
    </CalculatorContext.Provider>
  );
};

export const useCalculator = () => {
  const context = useContext(CalculatorContext);

  if (context === undefined) {
    throw new Error('useCalculator must be used within a CalculatorProvider');
  }

  return context;
};
