import { area_type_enum } from '@predium/enums';
import { getAreaSum, getBuildingIsOnlyCommercial } from '@predium/utils';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import {
  createContext,
  forwardRef,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useBuilding from '../../Context/useBuilding';
import { AreaFormValues } from '../types';
import { calculateNGF } from '../utils';
import {
  getAFValidation,
  getEBFBGFValidation,
  getEBFNGFValidation,
  getMFandAFToBGFValidation,
  getNGFBGFValidation,
  Validation,
} from './validations';

export enum TooltipOpenSource {
  HEADER = 'HEADER',
  SUMMARY = 'SUMMARY',
  ROW = 'ROW',
}

type OnOpenEquationArg = {
  equation: string;
  areaType: area_type_enum;
  source: TooltipOpenSource;
} | null;

type TContext = {
  triggerValidation: () => boolean;

  getAreaAlerts: (areaType: area_type_enum) => Validation[];
  getIsAreaAlertOpen: (areaType: area_type_enum, sources: TooltipOpenSource[]) => boolean;
  getAreaAlertVariant: (areaType: area_type_enum) => 'error' | 'warning' | null;

  onOpenEquation: (equation: OnOpenEquationArg) => void;
};

const ValidationContext = createContext<TContext | null>(null);

type Props = {
  handlePropagateFormState: (errors: Validation[], onClick: VoidFunction) => void;
};

export type ValidationProviderRef = {
  triggerValidation: () => boolean;
};

/**
 * We have multiple equations that we need to validate, we need to check if the values are correct
 * and if not, we need to show the user the error and the affected areas.
 *
 * We have these equations:
 * 1. Allgemeinfläche innerhalb des vom GRESB definierten Bereichs
 * 2. NGF <= BGF
 * 3. EBF <= BGF
 * 4. EBF <= NGF
 *
 */
export const ValidationProvider = forwardRef<ValidationProviderRef, PropsWithChildren<Props>>(
  ({ children, handlePropagateFormState }, ref) => {
    const {
      building: {
        address: { country_id },
      },
    } = useBuilding();
    const { t } = useTranslation();

    const [activeErrors, setActiveErrors] = useState<Validation[]>([]);
    const [activeWarnings, setActiveWarnings] = useState<Validation[]>([]);

    const [openedEquation, setOpenedEquation] = useState<OnOpenEquationArg>(null);

    const { getValues } = useFormContext<AreaFormValues>();

    const getAreasByType = useCallback(() => {
      const areas = getValues('areas').filter((area) => !area.delete);

      return {
        ...mapValues(groupBy(areas, 'area_type_id'), (area) => getAreaSum(area)),
        NGF: calculateNGF(areas, country_id),
      } as Record<area_type_enum, number>;
    }, [country_id, getValues]);

    const getValidators = useCallback(() => {
      const areasByType = getAreasByType();
      const areas = getValues('areas').filter((area) => !area.delete);

      const isSingleCommercial = getBuildingIsOnlyCommercial(areas);

      return [
        getAFValidation(areas, areasByType, t),
        getNGFBGFValidation(areasByType, t),
        getEBFBGFValidation(areasByType, t),
        getEBFNGFValidation(areasByType, t),
        ...(isSingleCommercial ? [getMFandAFToBGFValidation(areasByType, t)] : []),
      ] satisfies Validation[];
    }, [getAreasByType, getValues, t]);

    const triggerValidation = useCallback(() => {
      const validators = getValidators();

      const newErrors = validators.filter((validator) => validator.variant === 'error' && !validator.isFullFilled);
      const newWarnings = validators.filter((validator) => validator.variant === 'warning' && !validator.isFullFilled);

      setActiveErrors(newErrors);
      setActiveWarnings(newWarnings);

      return newErrors.length === 0;
    }, [getValidators]);

    useEffect(
      () =>
        handlePropagateFormState(activeErrors, () => {
          // Hack: We need to wait for the next tick to open the equation tooltip
          // Why: because of using clickaway for tooltips, so this remotely opens the tooltip that is somewhere else,
          // and clicking this item, triggers that clickaway of that component
          // (even if you put a check in clickaway onclick callback, still it doesn't work as expect)
          // So, we need to wait for the next tick to open the tooltip
          setTimeout(() => {
            const activeError = activeErrors[0];

            setOpenedEquation({
              equation: activeError.equation,
              areaType: activeError.affectedAreas[0],
              source: TooltipOpenSource.HEADER,
            });
          }, 10);
        }),
      [activeErrors, handlePropagateFormState],
    );

    useImperativeHandle(ref, () => ({
      triggerValidation,
    }));

    const getAreaAlerts = useCallback(
      (areaType: area_type_enum) => {
        if (activeErrors.length > 0) {
          const activeErrorMessages = activeErrors.filter((error) => error.affectedAreas.includes(areaType));

          if (activeErrorMessages.length > 0) {
            return activeErrorMessages;
          }

          return [];
        }

        const warnings = activeWarnings.filter((warning) => warning.affectedAreas.includes(areaType));
        if (warnings.length > 0) {
          return warnings;
        }

        return [];
      },
      [activeErrors, activeWarnings],
    );

    const getAreaAlertVariant = useCallback(
      (areaType: area_type_enum) => {
        if (!openedEquation) {
          return null;
        }

        const openedEquationAlert = getValidators().find((alert) => alert.equation === openedEquation.equation);
        if (!openedEquationAlert) {
          throw new Error(`Opened equation is invalid: ${openedEquation.equation}`);
        }

        const alert = [...activeErrors, ...activeWarnings]
          .filter((alert) => alert.affectedAreas.includes(areaType))
          .find((alert) => alert.affectedAreas.includes(openedEquation.areaType));

        return alert?.variant ?? null;
      },
      [activeErrors, activeWarnings, getValidators, openedEquation],
    );

    const getIsAreaAlertOpen = useCallback(
      (areaType: area_type_enum, sources: TooltipOpenSource[]) =>
        openedEquation?.areaType === areaType && sources.includes(openedEquation.source),
      [openedEquation],
    );

    return (
      <ValidationContext.Provider
        value={{
          triggerValidation,
          onOpenEquation: setOpenedEquation,
          getAreaAlerts,
          getIsAreaAlertOpen,
          getAreaAlertVariant,
        }}
      >
        {children}
      </ValidationContext.Provider>
    );
  },
);

export const useValidation = () => {
  const context = useContext(ValidationContext);

  if (!context) {
    throw new Error('useValidation must be used within a ValidationProvider');
  }

  return context;
};
