import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  consumption_frequency_type_enum,
  DataCollectionGetConsumptionInvoiceDraftQuery,
  DataCollectionSaveConsumptionInvoiceDraftMutation,
} from '@predium/client-graphql';
import { convertStandardUnitsToUserInput } from '@predium/client-lookup';
import { consumption_unit_enum, draft_status_enum, invoice_payer_type_enum } from '@predium/enums';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { FieldErrorsImpl, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import { FormProvider } from '../../../../components/hook-form';
import { UPDATE_CONSUMPTION_INVOICE_DRAFT } from '../../../../graphql/DataCollection.mutations';
import { GET_BUILDING } from '../../../../graphql/DataCollection.queries';
import { SingleConsumptionDraftFormData } from './SubBuildingSingleConsumptionDraftForm';
import { constructConsumptionValidationSchema } from './SubBuildingSingleConsumptionForm';

/**
 * Extends the SingleConsumptionFormData with the invoice data.
 */
export type ConsumptionInvoiceDraftFormData = SingleConsumptionDraftFormData & {
  invoiceDate?: string;
  invoicePayer?: string;
};

type Props = {
  setIsDirty: (isDirty: boolean) => void;
  consumptionInvoiceDraftData: NonNullable<
    DataCollectionGetConsumptionInvoiceDraftQuery['consumption_invoice_draft_by_pk']
  >;
  onCreateInvoice: VoidFunction;
  renderChildren: (
    submitForm: () => Promise<DataCollectionSaveConsumptionInvoiceDraftMutation>,
    errors: Partial<FieldErrorsImpl<ConsumptionInvoiceDraftFormData>>,
  ) => JSX.Element;
};

function SubBuildingConsumptionDraftForm({
  consumptionInvoiceDraftData,
  onCreateInvoice,
  renderChildren,
  setIsDirty,
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [saveConsumptionInvoiceDraft] = useMutation(UPDATE_CONSUMPTION_INVOICE_DRAFT, {
    onError: () => {
      enqueueSnackbar(t('DataCollectionSubBuildingConsumptionDraft_SaveChanges-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    onCompleted: () => {
      enqueueSnackbar(t('General_ChangesSaved'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
    refetchQueries: [GET_BUILDING],
  });

  const DataSchema = constructConsumptionValidationSchema({
    t,
  });
  // For the invoice itself
  const INVOICE_DATE_MESSAGE = t('DataCollection_ValidationInvoiceDateRequired');
  const INVOICE_PAYER_MESSAGE = t('DataCollection_ValidationInvoicePayerRequired');
  const InvoiceSchema = Yup.object().shape({
    invoiceDate: Yup.date().typeError(INVOICE_DATE_MESSAGE).required(INVOICE_DATE_MESSAGE),
    invoicePayer: Yup.string().typeError(INVOICE_PAYER_MESSAGE).required(INVOICE_PAYER_MESSAGE),
  });

  const { date, invoice_payer, consumption_drafts, building } = consumptionInvoiceDraftData;

  const sharedConsumptionDraftData = consumption_drafts[0] ?? {
    area_id: undefined,
    from: undefined,
    to: undefined,
    provider: undefined,
  };
  const { area_id, from, to, provider } = sharedConsumptionDraftData;

  const methods = useForm<ConsumptionInvoiceDraftFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    resolver: yupResolver(DataSchema.concat(InvoiceSchema)),
    shouldFocusError: false,
    defaultValues: {
      buildingId: building.id,
      invoiceDate: date ?? '',
      invoicePayer: invoice_payer ?? '',

      consumptionDrafts: consumption_drafts.map(
        ({
          id,
          value,
          display_unit_value,
          cost,
          consumption_type_id,
          energy_source_type_id,
          sub_type_id,
          consumption_waste_details_draft,
        }) => ({
          id,
          consumptionTypeId: consumption_type_id ?? undefined,
          energySourceTypeId: energy_source_type_id ?? undefined,
          subTypeId: sub_type_id ?? undefined,
          value:
            value && display_unit_value
              ? parseFloat(
                  convertStandardUnitsToUserInput(
                    consumption_type_id!,
                    display_unit_value,
                    value,
                    (energy_source_type_id ?? sub_type_id)!,
                  ).toFixed(4),
                )
              : value ?? undefined,
          displayUnitValue: display_unit_value ?? ('' as consumption_unit_enum),
          cost: cost ?? undefined,

          // TRASH CONSUMPTION ONLY, OPTIONAL
          waste: consumption_waste_details_draft
            ? {
                id: consumption_waste_details_draft?.id ?? undefined,
                volume:
                  consumption_waste_details_draft?.volume && consumption_waste_details_draft.display_unit_volume
                    ? parseFloat(
                        convertStandardUnitsToUserInput(
                          consumption_type_id!,
                          consumption_waste_details_draft.display_unit_volume,
                          consumption_waste_details_draft.volume,
                          (energy_source_type_id ?? sub_type_id)!,
                        ).toFixed(4),
                      )
                    : consumption_waste_details_draft?.volume ?? undefined,
                displayUnitVolume:
                  consumption_waste_details_draft?.display_unit_volume ?? ('' as consumption_unit_enum),
                frequency:
                  consumption_waste_details_draft?.frequency && consumption_waste_details_draft.display_unit_frequency
                    ? parseFloat(
                        convertStandardUnitsToUserInput(
                          consumption_type_id!,
                          consumption_waste_details_draft.display_unit_frequency,
                          consumption_waste_details_draft.frequency,
                          (energy_source_type_id ?? sub_type_id)!,
                        ).toFixed(4),
                      )
                    : consumption_waste_details_draft?.frequency ?? undefined,
                displayUnitFrequency:
                  consumption_waste_details_draft?.display_unit_frequency ?? ('' as consumption_frequency_type_enum),
                amount: consumption_waste_details_draft?.amount ?? undefined,
                price: consumption_waste_details_draft?.price ?? undefined,
              }
            : undefined,
        }),
      ),

      provider: provider ?? '',
      from: from ?? '',
      to: to ?? '',
      areaId: area_id ?? ('' as any),
    },
  });

  const {
    handleSubmit,
    getValues,
    formState: { isValid, errors, dirtyFields, isDirty },
    reset,
  } = methods;

  const saveForm = async (data: ConsumptionInvoiceDraftFormData) => {
    const {
      buildingId,
      invoiceDate,
      invoicePayer,
      consumptionDrafts,
      from: sharedFrom,
      to: sharedTo,
      provider: sharedProvider,
    } = data;
    let { areaId: sharedAreaId } = data;
    // Damn MUI force me to set "" for a number select
    // @ts-ignore
    sharedAreaId = sharedAreaId !== '' ? sharedAreaId : undefined;

    const newDraftStatus = isValid ? draft_status_enum.COMPLETE : draft_status_enum.INCOMPLETE;

    // In case any shared field has changed and is not nullish
    const sharedFieldMutated =
      (dirtyFields.from && sharedFrom) ||
      (dirtyFields.to && sharedTo) ||
      (dirtyFields.areaId && sharedAreaId) ||
      (dirtyFields.provider && sharedProvider);

    const sharedProperties = {
      from: sharedFrom !== '' ? sharedFrom : undefined,
      to: sharedTo !== '' ? sharedTo : undefined,
      areaId: sharedAreaId,
      provider: sharedProvider !== '' ? sharedProvider : undefined,
    };

    const { data: mutationResult } = await saveConsumptionInvoiceDraft({
      variables: {
        buildingId,
        consumptionInvoiceDraftId: consumptionInvoiceDraftData.id,
        invoiceDate: invoiceDate !== '' ? (invoiceDate as DateTime) : undefined,
        invoicePayer: invoicePayer !== '' ? (invoicePayer as invoice_payer_type_enum) : undefined,
        draftStatus: newDraftStatus,

        consumptionDrafts:
          consumptionDrafts.length === 0 && sharedFieldMutated
            ? // create a placeholder draft to save shared fields if there are no consumption drafts yet. Workaround for ideal UX
              [{ ...sharedProperties }]
            : consumptionDrafts.map(({ waste, ...consumptionDraft }) => ({
                ...consumptionDraft,
                ...sharedProperties,
                displayUnitValue: consumptionDraft.displayUnitValue || undefined,
                subTypeId: consumptionDraft.subTypeId ?? null,
                energySourceTypeId: consumptionDraft.energySourceTypeId ?? null,
                ...(waste
                  ? {
                      wasteDetails: {
                        id: waste.id,
                        volume: waste.volume,
                        displayUnitVolume: waste.displayUnitVolume || undefined,
                        frequency: waste.frequency,
                        displayUnitFrequency: waste.displayUnitFrequency || undefined,
                        amount: waste.amount,
                        price: waste.price,
                      },
                    }
                  : {}),
              })),
      },
      onCompleted: () => {
        // Reset dirty fields for NavigationModal
        reset(undefined, { keepValues: true, keepErrors: true, keepIsSubmitted: true });
      },
    });

    return mutationResult;
  };

  // Called when just the draft should be saved
  const submitForm = async () => {
    const values = getValues();

    return await saveForm(values);
  };

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(async (data) => {
        const shouldCreateInvoice = Boolean(await saveForm(data));

        if (shouldCreateInvoice) {
          onCreateInvoice();
        }
      })}
    >
      {/* @ts-ignore */}
      {renderChildren(submitForm, errors)}
    </FormProvider>
  );
}

export default SubBuildingConsumptionDraftForm;
