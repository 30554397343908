import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  MAPBOX_CURRENT_BUILDING_LAYER_ID,
  MAPBOX_HOVERED_BUILDING_LAYER_ID,
  MAPBOX_OTHER_BUILDINGS_LAYER_ID,
  MAPBOX_SELECTED_BUILDING_LAYER_ID,
} from '@predium/client-lookup';
import { expandSelection, mergePolygons, mergeSameIdPolygons } from '@predium/geo-utils';
import { bbox, center, featureCollection, point } from '@turf/turf';
import { Feature, Point, Polygon } from 'geojson';
import uniq from 'lodash/uniq';
import { MapEvent, default as mapboxgl } from 'mapbox-gl';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map, { Marker } from 'react-map-gl';
import { ICONS } from '../../assets/icons';
import useBuilding from '../../sections/DataCollection/Building/Context/useBuilding';
import Iconify from '../Iconify';
import EditModeNavigationControl from './EditModeNavigationControl';
import CustomNavigationControl from './MapNavigationControl';

const BUILDING_MAX_DISTANCE = 200;

declare global {
  interface Window {
    debugMapboxMode: boolean;
  }
}

window.debugMapboxMode = false;
mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

type BuildingMapProps = {
  id: string;
  onEdit?: () => void;
  onCancel?: () => void;
  onSave?: (args: { ids: string[]; longitude?: number; latitude?: number }) => void;
  editMode?: boolean;
  isLoading?: boolean;
  editDisabled?: boolean;
};

export default function BuildingMap({
  id,
  onEdit,
  onCancel,
  onSave,
  editMode = false,
  isLoading = false,
  editDisabled = false,
}: BuildingMapProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { building, hasEditAccess } = useBuilding();
  const { address } = building;
  const mapRef = useRef<mapboxgl.Map | null>(null);

  const [mapLoaded, setMapLoaded] = useState(false);
  const [showPendingChangesDialog, setShowPendingChangesDialog] = useState(false);
  const [currentBuildings, setCurrentBuildings] = useState<Feature<Polygon>[]>([]);
  const [selectionPoint, setSelectionPoint] = useState<[number, number] | null>(null);
  const [selectedBuildingsIds, setSelectedBuildingsIds] = useState<number[]>(
    editMode ? address.mapbox_building_ids.map((idsAsString: string) => parseInt(idsAsString)) : [],
  );
  const [viewState, setViewState] = useState({
    longitude: address.longitude ?? 0,
    latitude: address.latitude ?? 0,
    zoom: address.mapbox_zoom ?? 16,
    pitch: address.mapbox_pitch ?? 55,
    bearing: address.mapbox_bearing ?? 0,
  });
  const [showAddressPin, setShowAddressPin] = useState(false);

  const currentBuildingIds = useMemo(() => {
    return address.mapbox_building_ids.map((idsAsString: string) => parseInt(idsAsString));
  }, [address.mapbox_building_ids]);

  const showLoadingState = isLoading && !address.mapbox_building_ids.length && !editMode;
  const showErrorState = !isLoading && !address.mapbox_building_ids.length && !editMode;

  const hasCoordinates = !!address.longitude && !!address.latitude;

  const noSelectionMade =
    selectedBuildingsIds.every((id) => currentBuildingIds.includes(id)) &&
    (selectedBuildingsIds.length !== 0 || address.mapbox_building_ids.length === 0) &&
    currentBuildingIds.every((id) => selectedBuildingsIds.includes(id));

  const buildingByIdFilter = (ids: number[]) => ['in', ['id'], ['literal', ids]] as any;

  const recenterMap = useCallback(() => {
    const mergedPolygons = mergePolygons(
      mapRef.current!.querySourceFeatures('composite', {
        sourceLayer: 'building',
        filter: buildingByIdFilter(currentBuildingIds),
      }) as Feature<Polygon>[],
    );

    if (mergedPolygons === null) {
      console.error('Could not merge polygons');
      return;
    }

    const bounds = bbox(mergedPolygons);
    const centerPoint = center(mergedPolygons).geometry.coordinates as [number, number];

    mapRef.current!.fitBounds(
      [
        [bounds[0], bounds[1]],
        [bounds[2], bounds[3]],
      ],
      {
        padding: { top: 10, bottom: 25, left: 15, right: 15 },
        center: centerPoint,
        // use user defined zoom but a little less because the pdf map is so small
        maxZoom: Math.max(
          16,
          address.mapbox_zoom ? address.mapbox_zoom - 0.5 : 17.5 - (currentBuildingIds.length - 1) * 0.25,
        ),
        speed: 0.5,
        bearing: address.mapbox_bearing ?? 0,
        pitch: address.mapbox_pitch ?? 55,
        curve: 1,
        essential: true,
      },
    );
  }, [address, currentBuildingIds]);

  const highlightBuilding = useCallback(
    (
      layerId:
        | typeof MAPBOX_HOVERED_BUILDING_LAYER_ID
        | typeof MAPBOX_SELECTED_BUILDING_LAYER_ID
        | typeof MAPBOX_CURRENT_BUILDING_LAYER_ID
        | typeof MAPBOX_OTHER_BUILDINGS_LAYER_ID,
      ids: number[],
    ) => {
      const otherBuildingIds = uniq([...ids, ...selectedBuildingsIds]);

      //set the filter
      mapRef.current?.setFilter(layerId, buildingByIdFilter(ids));

      //remove from the other buildings layer
      mapRef.current?.setFilter(MAPBOX_OTHER_BUILDINGS_LAYER_ID, ['!', buildingByIdFilter(otherBuildingIds)]);
    },
    [selectedBuildingsIds],
  );

  const resetSelection = () => {
    setSelectedBuildingsIds(currentBuildingIds);
  };

  const handleLoad = useCallback(
    (event: MapEvent) => {
      const map = event.target;
      mapRef.current = map;

      //config presets
      map.setConfigProperty('basemap', 'lightPreset', 'day');
      map.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
      map.setConfigProperty('basemap', 'showRoadLabels', true);
      map.setConfigProperty('basemap', 'show3dObjects', false);

      //standard navigation control + recenter button
      const customNav = new CustomNavigationControl(
        { showCompass: true, showZoom: true, visualizePitch: true },
        t('MapNavigationControlRecenter_Title'),
        () => {
          if (!showLoadingState) {
            recenterMap();
          }
        },
      );

      //edit mode navigation control
      const editNav = new EditModeNavigationControl(
        {
          showCompass: false,
          showZoom: false,
          visualizePitch: false,
        },
        () => {
          if (!showLoadingState && !editDisabled) {
            onEdit?.();
          }
        },
        editDisabled,
        t('DataCollection_MapBoxEditMode_Title'),
      );

      if (!showLoadingState && !showErrorState) {
        map.addControl(customNav, 'bottom-right');
        if (!editMode && hasEditAccess) {
          map.addControl(editNav, 'top-right');
        }
      }

      highlightBuilding(
        editMode ? MAPBOX_SELECTED_BUILDING_LAYER_ID : MAPBOX_CURRENT_BUILDING_LAYER_ID,
        currentBuildingIds,
      );
      recenterMap();
      setMapLoaded(true);
    },
    [
      t,
      editMode,
      highlightBuilding,
      currentBuildingIds,
      recenterMap,
      onEdit,
      showLoadingState,
      showErrorState,
      editDisabled,
      hasEditAccess,
    ],
  );

  const handleSave = () => {
    if (onSave === undefined) return;

    if (showPendingChangesDialog) {
      setShowPendingChangesDialog(false);
    }

    if (mapRef.current === null) {
      onSave({ ids: selectedBuildingsIds.map((id) => id.toString()) });
      return;
    }

    const features = mapRef.current.querySourceFeatures('composite', {
      sourceLayer: 'building',
      filter: buildingByIdFilter(selectedBuildingsIds),
    }) as Feature<Polygon>[];
    const centerPoint = center(featureCollection(features)).geometry.coordinates;
    const longitude = centerPoint[0];
    const latitude = centerPoint[1];
    onSave({ ids: selectedBuildingsIds.map((id) => id.toString()), longitude, latitude });
  };

  const handleCloseMapbox = () => {
    if (noSelectionMade) {
      onCancel?.();
    } else {
      setShowPendingChangesDialog(true);
    }
  };

  useEffect(() => {
    if (!editMode) return;
    highlightBuilding(MAPBOX_SELECTED_BUILDING_LAYER_ID, selectedBuildingsIds);
  }, [highlightBuilding, selectedBuildingsIds, editMode]);

  useEffect(() => {
    if (mapRef.current) {
      setCurrentBuildings(
        mapRef.current.querySourceFeatures('composite', {
          sourceLayer: 'building',
          filter: buildingByIdFilter(currentBuildingIds),
        }) as Feature<Polygon>[],
      );
    }
  }, [currentBuildingIds]);

  useEffect(() => {
    if (!mapRef.current || !mapLoaded) return;

    const getEventFeatures = (e: mapboxgl.MapMouseEvent & { features?: mapboxgl.GeoJSONFeature[] }) => {
      if (e.features && e.features.length > 0) {
        const rendered: Feature<Polygon>[] = mapRef.current!.querySourceFeatures('composite', {
          sourceLayer: 'building',
          filter: ['<=', ['distance', point([e.lngLat.lng, e.lngLat.lat])], BUILDING_MAX_DISTANCE],
        }) as Feature<Polygon>[];
        const merged = mergeSameIdPolygons(rendered);

        return expandSelection(
          merged as Feature<Polygon>[],
          e.features.map((feature) => String(feature.id)),
        );
      }
      return [];
    };

    const mouseMoveHandler = (e: mapboxgl.MapMouseEvent & { features?: mapboxgl.GeoJSONFeature[] }) => {
      if (e.features && e.features.length > 0 && editMode) {
        const hoveredBuildingIds = getEventFeatures(e).map((feature) => parseInt(feature.id as string));
        highlightBuilding(MAPBOX_HOVERED_BUILDING_LAYER_ID, hoveredBuildingIds);
        mapRef.current!.getCanvas().style.cursor = 'pointer';
      }
    };

    const mouseLeaveHandler = () => {
      if (editMode) {
        highlightBuilding(MAPBOX_HOVERED_BUILDING_LAYER_ID, []);
        mapRef.current!.getCanvas().style.cursor = '';
      }
    };

    const selectBuildingHandler = (e: mapboxgl.MapMouseEvent & { features?: mapboxgl.GeoJSONFeature[] }) => {
      if (e.features && e.features.length > 0 && editMode) {
        const eventFeatures = getEventFeatures(e);
        const newSelection = uniq([
          ...selectedBuildingsIds,
          ...eventFeatures.map((feature) => parseInt(feature.id as string)),
        ]);
        setSelectionPoint([e.lngLat.lng, e.lngLat.lat]);

        setSelectedBuildingsIds(newSelection);
        highlightBuilding(MAPBOX_SELECTED_BUILDING_LAYER_ID, newSelection);
      }
    };

    const unselectBuildingHandler = (e: mapboxgl.MapMouseEvent & { features?: mapboxgl.GeoJSONFeature[] }) => {
      if (e.features && e.features.length > 0 && editMode) {
        const selectedBuildingIds = getEventFeatures(e).map((feature) => parseInt(feature.id as string));
        const newValue = selectedBuildingsIds.filter((id) => !selectedBuildingIds.includes(id));
        setSelectedBuildingsIds(newValue);
        highlightBuilding(MAPBOX_HOVERED_BUILDING_LAYER_ID, newValue);
      }
    };

    const layers = [
      MAPBOX_OTHER_BUILDINGS_LAYER_ID,
      MAPBOX_CURRENT_BUILDING_LAYER_ID,
      MAPBOX_SELECTED_BUILDING_LAYER_ID,
      MAPBOX_HOVERED_BUILDING_LAYER_ID,
    ];

    layers.forEach((layer) => {
      mapRef.current!.on('mousemove', layer, mouseMoveHandler);
    });

    mapRef.current.on('mouseleave', MAPBOX_HOVERED_BUILDING_LAYER_ID, mouseLeaveHandler);
    mapRef.current.on('mouseleave', MAPBOX_SELECTED_BUILDING_LAYER_ID, mouseLeaveHandler);
    mapRef.current.on('click', MAPBOX_HOVERED_BUILDING_LAYER_ID, selectBuildingHandler);
    mapRef.current.on('click', MAPBOX_SELECTED_BUILDING_LAYER_ID, unselectBuildingHandler);

    return () => {
      if (mapRef.current) {
        layers.forEach((layer) => {
          mapRef.current!.off('mousemove', layer, mouseMoveHandler);
        });
        mapRef.current.off('mouseleave', MAPBOX_HOVERED_BUILDING_LAYER_ID, mouseLeaveHandler);
        mapRef.current.off('mouseleave', MAPBOX_SELECTED_BUILDING_LAYER_ID, mouseLeaveHandler);
        mapRef.current.off('click', MAPBOX_HOVERED_BUILDING_LAYER_ID, selectBuildingHandler);
        mapRef.current.off('click', MAPBOX_SELECTED_BUILDING_LAYER_ID, unselectBuildingHandler);
      }
    };
  }, [editMode, highlightBuilding, mapLoaded, selectedBuildingsIds]);

  const handleZoomEnd = () => {
    const currentZoom = mapRef.current!.getZoom();
    setShowAddressPin(currentZoom < 15);
  };

  return (
    <Box
      height="100%"
      width="100%"
      position="relative"
      sx={{
        '& .MuiDialogActions-root': {
          px: '0px !important',
        },
      }}
    >
      {editMode && (
        <DialogTitle sx={{ p: 0, pb: 2 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center" gap={2}>
              <IconButton onClick={handleCloseMapbox}>
                <Iconify icon={ICONS.CHEVRON_LEFT} width={24} height={24} color="action.active" />
              </IconButton>
              <Typography variant="h6">{t('DataCollection_MapBoxEditMode_Title')}</Typography>
            </Stack>
            <IconButton onClick={handleCloseMapbox}>
              <Iconify icon={ICONS.CLOSE} width={24} height={24} color="action.active" />
            </IconButton>
          </Stack>
        </DialogTitle>
      )}

      {editMode && (
        <Card
          sx={{
            position: 'absolute',
            top: 'calc(16px + 40px + 24px)',
            right: '24px',
            zIndex: 1000,
            width: '300px',
            boxShadow: theme.shadows[12],
          }}
        >
          <CardHeader title={t('General_BuildingLocation')} />
          <CardContent
            sx={{
              paddingY: 2,
            }}
          >
            <Typography variant="body2" color="text.secondary">
              {t('DataCollection_MapboxEditMode_Description')}
            </Typography>
            <Typography variant="subtitle2" color="text.primary" sx={{ mt: 2 }}>
              {address.street}
            </Typography>
            <Typography variant="body2" color="text.primary">
              {address.latitude?.toFixed(4)}°N, {address.longitude?.toFixed(4)}°E
            </Typography>
            <Stack direction="row" justifyContent="flex-end" mt={2}>
              <Button
                disabled={noSelectionMade || showLoadingState}
                variant="text"
                onClick={resetSelection}
                startIcon={<Iconify icon={ICONS.RESTORE} />}
              >
                {t('General_ResetToDefault')}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      )}

      <Map
        {...viewState}
        onMove={(evt) => setViewState(evt.viewState)}
        id={id}
        mapStyle="mapbox://styles/predium/cm0l1v65u008u01o39pm9f7bw"
        mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
        style={{
          width: '100%',
          height: editMode ? 'calc(100% - 36px - 24px - 24px - 24px - 24px)' : '100%',
          borderRadius: editMode ? '16px' : undefined,
          opacity: showLoadingState || showErrorState ? 0.5 : 1,
          filter: showLoadingState || !hasCoordinates ? 'grayscale(1)' : 'none',
        }}
        onLoad={handleLoad}
        doubleClickZoom={false}
        failIfMajorPerformanceCaveat
        dragPan={editMode && !showLoadingState}
        scrollZoom={editMode}
        onZoom={handleZoomEnd}
        maxBounds={
          currentBuildings.length > 0
            ? [
                [currentBuildings[0].geometry.coordinates[0][0][0], currentBuildings[0].geometry.coordinates[0][0][1]],
                [currentBuildings[0].geometry.coordinates[0][0][0], currentBuildings[0].geometry.coordinates[0][0][1]],
              ]
            : [
                [
                  address?.longitude ? address.longitude - 0.01 : -0.01,
                  address?.latitude ? address.latitude - 0.01 : -0.01,
                ],
                [
                  address?.longitude ? address.longitude + 0.01 : 0.01,
                  address?.latitude ? address.latitude + 0.01 : 0.01,
                ],
              ]
        }
      >
        {showAddressPin && (
          <Marker color="#5035FA" longitude={address.longitude ?? 0} latitude={address.latitude ?? 0} anchor="center" />
        )}
        {/* ... existing child components or layers */}
      </Map>
      {editMode && (
        <DialogActions>
          {selectedBuildingsIds.length === 0 && (
            <Stack direction="row" gap={1} alignItems="center">
              <Iconify icon={ICONS.DANGER} color="text.primary" />
              <Typography variant="body2" color="text.primary">
                {t('DataCollection_MapboxEditMode_Warning')}
              </Typography>
            </Stack>
          )}
          <Stack direction="row" gap={1.5}>
            <Button variant="outlined" onClick={handleCloseMapbox} disabled={showLoadingState}>
              {t('General_Cancel')}
            </Button>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={noSelectionMade || selectedBuildingsIds.length === 0 || showLoadingState}
            >
              {t('General_Save')}
            </Button>
            {window.debugMapboxMode && (
              <Button
                variant="contained"
                onClick={() => {
                  if (mapRef.current === null || selectionPoint === null) {
                    return;
                  }
                  const clickPoint = point(selectionPoint);

                  const rendered: Feature<Polygon>[] = mapRef.current!.querySourceFeatures('composite', {
                    sourceLayer: 'building',
                    filter: ['<=', ['distance', clickPoint], BUILDING_MAX_DISTANCE],
                  }) as Feature<Polygon>[];
                  const merged = mergeSameIdPolygons(rendered).map((f) => ({
                    id: f.id,
                    type: f.type,
                    properties: f.properties ?? {},
                    geometry: f.geometry,
                  }));

                  // Pull the ids to the features
                  merged.forEach((feature) => {
                    feature.properties = feature.properties ?? {};
                    feature.properties.id = feature.id;
                  });

                  merged
                    .filter((feature) => selectedBuildingsIds.includes(parseInt(feature.id as string)))
                    .forEach((feature) => {
                      feature.properties = feature.properties ?? {};
                      feature.properties['fill'] = 'blue';
                      feature.properties['stroke'] = 'blue';
                    });

                  const collection = featureCollection<Polygon | Point>([...merged, clickPoint]);
                  navigator.clipboard.writeText(JSON.stringify(collection));
                  enqueueSnackbar('Last click GeoJSON Copied to clipboard', { variant: 'success' });
                }}
              >
                Debug
              </Button>
            )}
          </Stack>
        </DialogActions>
      )}

      {editMode && (
        <Card
          sx={{
            position: 'absolute',
            bottom: 'calc(24px + 24px + 32px + 24px)',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
          }}
        >
          <CardContent sx={{ padding: 2, '&:last-child': { paddingBottom: 2 } }}>
            <Stack direction="row" gap={1}>
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  borderRadius: '50%',
                  backgroundColor: '#454F5B',
                  alignSelf: 'center',
                }}
              />
              <Typography variant="body2">{t('General_SelectedLocation', { count: 2 })}</Typography>
            </Stack>
          </CardContent>
        </Card>
      )}

      {showPendingChangesDialog && (
        <Dialog open={showPendingChangesDialog} onClose={() => setShowPendingChangesDialog(false)}>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {t('DataCollection_MapboxEditMode_PendingChangesTitle')}
            <IconButton onClick={() => setShowPendingChangesDialog(false)}>
              <Iconify icon={ICONS.CLOSE} width={20} height={20} />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ minWidth: '480px' }}>
            <Typography variant="body2">{t('DataCollection_MapboxEditMode_PendingChangesDescription')}</Typography>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" gap={1} justifyContent="space-between" width="100%">
              <Button variant="text" color="error" onClick={onCancel}>
                {t('General_DontSave')}
              </Button>
              <Stack direction="row" gap={1}>
                <Button variant="outlined" onClick={() => setShowPendingChangesDialog(false)}>
                  {t('General_KeepEditing')}
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  disabled={noSelectionMade || selectedBuildingsIds.length === 0}
                >
                  {t('General_Save')}
                </Button>
              </Stack>
            </Stack>
          </DialogActions>
        </Dialog>
      )}

      {showLoadingState && <LoadingOverlay />}

      {showErrorState && <ErrorOverlay onEdit={onEdit} hasCoordinates={hasCoordinates} />}
    </Box>
  );
}

function LoadingOverlay() {
  return (
    <Box
      sx={{
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
      }}
    >
      <CircularProgress />
    </Box>
  );
}

function ErrorOverlay({ onEdit, hasCoordinates }: { onEdit?: () => void; hasCoordinates: boolean }) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: 'absolute',
        borderRadius: '16px',
        display: 'flex',
        gap: 1.5,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: 1000,
      }}
    >
      <Iconify icon={ICONS.ALERT_CIRCLE} width={40} height={40} color="error.main" />
      <Typography variant="body2" color="error.darker">
        {hasCoordinates
          ? t('DataCollection_MapboxEditMode_Error-hasCoordinates')
          : t('DataCollection_MapboxEditMode_Error-noCoordinates')}
      </Typography>
      {!!onEdit && hasCoordinates && (
        <Button variant="outlined" onClick={onEdit} sx={{ borderColor: 'grey.300' }}>
          {t('DataCollection_MapboxEditMode_Error_EditManually')}
        </Button>
      )}
    </Box>
  );
}
