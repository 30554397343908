import { InputAdornment, MenuItem, Stack, TextField } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/Iconify';
import { ALL_PORTFOLIOS } from '../../EsgAnalysis/NavigationSelectors/PortfolioSelector';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
  pl: 3,
}));

type EconomicUnitToolbarProps = {
  children: React.ReactNode;
  filterName: string;
  onFilterName: (value: string) => void;
  handlePortfolioChange: (value: string) => void;
  selectedPortfolio: string;
  portfolioOptions: string[];
};

export default function EconomicUnitToolbar({
  filterName,
  children,
  onFilterName,
  handlePortfolioChange,
  selectedPortfolio,
  portfolioOptions,
}: EconomicUnitToolbarProps) {
  const { t } = useTranslation();

  return (
    <RootStyle>
      <Stack direction="row" sx={{ width: '100%' }}>
        <TextField
          select
          label={t('General_Portfolio')}
          value={selectedPortfolio}
          onChange={(event) => handlePortfolioChange(event.target.value)}
          sx={{
            minWidth: 250,
            mr: 3,
          }}
        >
          {portfolioOptions.map((portfolioName) => (
            <MenuItem
              key={portfolioName}
              value={portfolioName}
              sx={{ typography: 'body2', mx: 1, my: 0.5, borderRadius: 0.75 }}
            >
              {portfolioName === ALL_PORTFOLIOS ? t('General_AllPortfolios') : portfolioName}
            </MenuItem>
          ))}
        </TextField>
        {onFilterName && (
          <TextField
            value={filterName}
            onChange={(event) => onFilterName(event.target.value)}
            placeholder={t('General_SearchEconomicUnitPlaceholder')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              ),
            }}
            sx={{ minWidth: 250 }}
          />
        )}
        {children}
      </Stack>
    </RootStyle>
  );
}
