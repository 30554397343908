/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Card, CardProps, useTheme } from '@mui/material';
import { ClimateRiskOutput } from '@predium/client-graphql';
import { climate_risk_type_enum } from '@predium/enums';
import { translateClimateRiskTypeEnum_dynamic } from '@predium/i18n/client';
import { fPercent } from '@predium/utils';
import { TFunction } from 'i18next';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import BaseOptionChart from '../../../theme/apexcharts';

interface Props extends CardProps {
  climateRisks: ClimateRiskOutput[];
}

export function ClimateRiskGraph({ climateRisks, sx }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const climateRiskTypeOrder: climate_risk_type_enum[] = [
    climate_risk_type_enum.WINTER_STORM,
    climate_risk_type_enum.HEAVY_RAIN,
    climate_risk_type_enum.HAIL,
    climate_risk_type_enum.SNOW_LOAD,
    climate_risk_type_enum.LIGHTNING_STRIKE,
    climate_risk_type_enum.EARTHQUAKE,
    climate_risk_type_enum.FOREST_FIRE,
    climate_risk_type_enum.HEAT,
  ];

  const categoryTypes = Object.keys(climate_risk_type_enum).sort((a, b) => {
    // Cast the keys to the enum type
    const keyA = a as climate_risk_type_enum;
    const keyB = b as climate_risk_type_enum;

    return climateRiskTypeOrder.indexOf(climate_risk_type_enum[keyA]) >
      climateRiskTypeOrder.indexOf(climate_risk_type_enum[keyB])
      ? 1
      : -1;
  }) as climate_risk_type_enum[];

  const { series, dataLabels } = bucketClimateRisks(t, categoryTypes, climateRisks);

  const COLORS = [
    climateRiskTypeOrder.map((value) => theme.palette.climateRiskColors[value].main),
    climateRiskTypeOrder.map((value) => theme.palette.climateRiskColors[value].lighter),
  ];

  const futureColorFunc = ({ dataPointIndex }: { dataPointIndex: number }) => COLORS[1][dataPointIndex];

  const isDataAvailable = climateRisks.length > 0;

  const chartState: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
    },
    colors: [
      ({ dataPointIndex }: { dataPointIndex: number }) => COLORS[0][dataPointIndex],
      futureColorFunc,
      futureColorFunc,
      futureColorFunc,
    ],
    dataLabels: {
      enabled: true,
      formatter: (_, opts) => dataLabels[opts.dataPointIndex][opts.seriesIndex],
      offsetY: 5,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        barHeight: '100%',
        borderRadius: 8,
        borderRadiusApplication: 'end',
        columnWidth: '68%',
        dataLabels: {
          orientation: 'vertical',
          position: 'top',
        },
      },
    },
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    stroke: {
      colors: ['#fff'],
      show: true,
      width: 2,
    },
    subtitle: {
      text: t('ClimateRiskChart_Subtitle'),
    },
    title: {
      text: t('General_EnvironmentalRisk'),
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (value) => fPercent(value),
        title: {
          formatter: () => '',
        },
      },
    },
    xaxis: {
      labels: {
        show: isDataAvailable,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: categoryTypes.map((type) => translateClimateRiskTypeEnum_dynamic(type, t)),
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: isDataAvailable,
        formatter: (value: number) => Math.trunc(value).toString(),
      },
      max: 100,
      min: 0,
    },
    grid: {
      show: isDataAvailable,
    },
    noData: {
      align: 'center',
      text: t('ClimateRiskChart_NoDataText'),
      verticalAlign: 'middle',
    },
  };

  const SerieParsed = climateRisks.length ? series : [];

  return (
    <Card sx={{ p: 3, pb: 1, ...sx }}>
      <ReactApexChart
        //@ts-ignore
        type={chartState.chart.type}
        options={merge(BaseOptionChart(), chartState)}
        series={SerieParsed}
        height={350}
      />
    </Card>
  );
}

const bucketClimateRisks = (
  t: TFunction<'translation', undefined>,
  categoryTypes: climate_risk_type_enum[],
  climateRisks: ClimateRiskOutput[],
): { series: { data: number[] }[]; dataLabels: string[][] } => {
  const allClimateRiskBuckets = categoryTypes.map((categoryType) => {
    const climateRiskBuckets = climateRisks.filter((risk) => risk.type === categoryType);

    const buckets: { endYear: string; value: number }[] = climateRiskBuckets.map((bucket) => ({
      //@ts-ignore
      endYear: getEndYear(t, bucket.timespan),
      value: bucket.value,
    }));
    if (buckets.length < 4) {
      return buckets.concat(new Array(4 - buckets.length).fill({ endYear: '', value: null }));
    }
    return buckets;
  });

  const series: { data: number[] }[] = allClimateRiskBuckets[0].map((_, index) => {
    const row = allClimateRiskBuckets.map((bucket) => bucket[index].value);
    return { data: row };
  });

  const dataLabels = allClimateRiskBuckets.map((climateRisk) => climateRisk.map((bucket) => bucket.endYear));

  return { series, dataLabels };
};

const getEndYear = (t: TFunction<'translation', undefined>, timespan: string): string => {
  if (!timespan || timespan === 'Present') {
    return t('General_Current');
  }
  const years = timespan.split('-');
  if (years.length === 2) {
    return years[1];
  }
  return '';
};
