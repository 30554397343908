import { MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import { data_source_type_enum } from '@predium/client-graphql';
import { translateDataSourceTypeEnum } from '@predium/i18n/client';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../../assets/icons';
import DetailedTooltip, { DetailedTooltipProps } from '../../../../../components/DetailedTooltip/DetailedTooltip';
import Iconify from '../../../../../components/Iconify';
import ActionMoreMenu from '../../../../../components/common/ActionMoreMenu';
import {
  DoorFormValuesProps,
  OtherFormValuesProps,
  PitchedRoofFormValuesProps,
  WindowFormValuesProps,
} from '../../BuildingEnvelope';
import DataSourceTypeChip from '../../Common/DataSourceTypeChip';
import useBuilding from '../../Context/useBuilding';

export function EnvelopeUnitHead({
  title,
  icon,
  index,
  data,
  handleCopy,
  handleRemove,
  tooltipProps,
}: {
  title: string;
  icon: string;
  index: number;
  data: OtherFormValuesProps | WindowFormValuesProps | DoorFormValuesProps | PitchedRoofFormValuesProps;
  handleCopy: (index: number) => void;
  handleRemove: (index: number) => void;
  tooltipProps?: DetailedTooltipProps;
}) {
  const { t } = useTranslation();
  const { hasEditAccess } = useBuilding();

  const { data_source_type_id: dataSourceType, create, delete: deleted } = data;

  return (
    <CardHeader
      sx={{ alignItems: 'center', p: 0, mb: deleted ? 0 : 3 }}
      title={
        <Stack alignItems="center" direction="row" spacing={1}>
          <Typography variant="h6" style={{ textDecoration: deleted ? 'line-through' : 'none' }}>
            {title}
          </Typography>
          {tooltipProps && <DetailedTooltip size={20} {...tooltipProps} />}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {dataSourceType === data_source_type_enum.MANUAL ? (
              <Tooltip title={t('DataCollection_ManualEditTooltip')}>
                <Box>
                  <DataSourceTypeChip
                    value={translateDataSourceTypeEnum(data_source_type_enum.MANUAL)}
                    type={data_source_type_enum.MANUAL}
                  />
                </Box>
              </Tooltip>
            ) : (
              <Tooltip title={t('DataCollection_EnvelopeUnit_HeadTooltipTitle')}>
                <Box>
                  <DataSourceTypeChip value={translateDataSourceTypeEnum(dataSourceType)} type={dataSourceType} />
                </Box>
              </Tooltip>
            )}
          </Box>
        </Stack>
      }
      avatar={<Iconify icon={icon} width={25} height={25} />}
      action={
        <>
          {!deleted && hasEditAccess && (
            <ActionMoreMenu
              variant="icon"
              actions={
                <>
                  <MenuItem
                    onClick={() => {
                      handleCopy(index);
                    }}
                    disabled={deleted}
                  >
                    <Iconify icon={'mdi:content-copy'} />
                    {t('General_Duplicate')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleRemove(index);
                    }}
                    sx={{ color: 'error.main' }}
                    disabled={create}
                  >
                    <Iconify icon={ICONS.TRASH} />
                    {t('General_Delete')}
                  </MenuItem>
                </>
              }
            />
          )}
        </>
      }
    />
  );
}
