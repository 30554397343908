import { CircularProgress, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  address: string;
};

const BuildingIsProcessing = ({ address }: Props) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        backgroundColor: 'grey.200',
        width: '100%',
        px: 3,
        py: 2,
        mb: 2,
        borderRadius: 1,
      }}
    >
      <Typography variant="h5">{address}</Typography>
      <Stack direction="row" alignItems="center">
        <Typography variant="body2" sx={{ color: 'text.disabled' }}>
          {t('DataCollection_Building_ProcessingMessage')}
        </Typography>
        <CircularProgress
          size={25}
          sx={{
            ml: 3,
            color: 'grey.500',
          }}
        />
      </Stack>
    </Stack>
  );
};

export default BuildingIsProcessing;
