import { area_type_enum, sub_building_class_enum, type_of_use_enum } from '@predium/enums';
import { uniq } from 'lodash';
import { accessEnum } from '../enums';
import { fFloatingPoint } from '../utils';
export type area = {
  value: number;
  area_type_id: string;
  type_of_use_id?: string;
  class_of_use_id?: string;
  [key: string]: unknown;
};

/**
 * Return the sum of all EBF areas
 * @param areas array of areas
 * @returns number
 *
 */
export const getNetArea = (areas: Pick<area, 'value' | 'area_type_id'>[]): number => {
  const sum = areas.filter((a) => a.area_type_id === area_type_enum.EBF).reduce((acc, area) => acc + area.value, 0);
  return fFloatingPoint(sum);
};

/**
 * Return the sum of all EBF areas
 * @param areas array of areas
 * @returns number
 *
 */
export const getEnergyReferenceAreaSum = (areas: Pick<area, 'value' | 'area_type_id'>[]): number => {
  const sum = areas.filter((a) => a.area_type_id === area_type_enum.EBF).reduce((acc, area) => acc + area.value, 0);
  return fFloatingPoint(sum);
};

/**
 * Return sum of the values of all areas
 * @param areas array of areas
 * @returns number
 */
export const getAreaSum = (areas: Pick<area, 'value'>[]): number => {
  const sum = areas.reduce((acc, area) => acc + area.value, 0);
  return fFloatingPoint(sum);
};

/**
 * Return the value of the area with the given type
 * @param areas array of areas
 * @param areaType area_type_enum
 * @returns number | null
 */
export const getValueAreaByType = (
  areas: Pick<area, 'value' | 'area_type_id'>[],
  areaType: area_type_enum,
): number | null => {
  const area = areas.find((a) => a.area_type_id === areaType);
  return area ? area.value : null;
};

/**
 * Return areas with the given type
 * @param areas array of areas
 * @param areaType area_type_enum
 * @returns area[]
 */
export const getAreaByType = <T extends area>(areas: T[], areaType: area_type_enum): T[] => {
  return areas.filter((a) => a.area_type_id === areaType);
};

/**
 * Return if a list of areas are mixed
 * @param areas array of areas
 * @returns true if a list of areas has multiple classes of use
 */
export const getBuildingIsMixed = (areas: Required<Pick<area, 'class_of_use_id' | 'area_type_id'>>[]): boolean => {
  const classesOfUse = getEbfClassesOfUse(areas);
  return classesOfUse.size > 1;
};

/**
 * Return types of use for all EBF areas
 * @param areas array of areas
 * @returns type_of_use_enum[]
 */
export const getEbfTypesOfUse = (
  areas: Required<Pick<area, 'type_of_use_id' | 'area_type_id'>>[],
): type_of_use_enum[] => {
  const typesOfUse = areas
    .filter((a) => a.area_type_id === area_type_enum.EBF)
    .map((a) => accessEnum(type_of_use_enum, a.type_of_use_id));
  return uniq(typesOfUse);
};

/**
 * Return the classes of use for an array of areas
 * @param areas areas table
 * @returns
 */
export const getEbfClassesOfUse = (
  areas: Required<Pick<area, 'class_of_use_id' | 'area_type_id'>>[],
): Set<sub_building_class_enum> => {
  const ebfAreas = areas.filter((area) => area.area_type_id === area_type_enum.EBF);
  const classesOfUse = new Set<sub_building_class_enum>();
  ebfAreas.forEach((area) => {
    classesOfUse.add(accessEnum(sub_building_class_enum, area.class_of_use_id));
  });
  return classesOfUse;
};

export const getAreaByTypeOfUse = (
  areas: Pick<area, 'value' | 'area_type_id' | 'type_of_use_id'>[],
): Record<Partial<type_of_use_enum>, number> => {
  const nufAreas = areas.filter((area) => area.area_type_id === area_type_enum.EBF);
  const typeOfUseDistribution = <Record<Partial<type_of_use_enum>, number>>{};
  nufAreas.forEach((area) => {
    if (!area.type_of_use_id) {
      throw new Error('No type of use found');
    }
    const typeOfUse = accessEnum(type_of_use_enum, area.type_of_use_id);
    const existingValue = typeOfUseDistribution[typeOfUse] ?? 0;
    typeOfUseDistribution[typeOfUse] = fFloatingPoint(existingValue + area.value);
  });
  return typeOfUseDistribution;
};

export const isEuTaxonomyAvailable = (
  areas: Required<Pick<area, 'class_of_use_id' | 'area_type_id' | 'type_of_use_id'>>[],
): boolean => {
  const typesOfUse = getEbfTypesOfUse(areas);
  if (typesOfUse.length === 0) {
    throw new Error('No types of use found');
  }
  if (typesOfUse.length === 1) return true;
  if (getBuildingIsMixed(areas)) return false;
  const classesOfUse = getEbfClassesOfUse(areas); // we know this is only one since it's not mixed
  // If the class of use is residential, we can use the EU taxonomy. Otherwise,
  // we can't since we have multiple types of use
  return classesOfUse.has(sub_building_class_enum.RESIDENTIAL);
};

export const getBuildingHasAtLeastOneResidential = (
  areas: Required<Pick<area, 'class_of_use_id' | 'area_type_id'>>[],
): boolean => {
  const classesOfUse = getEbfClassesOfUse(areas);
  return classesOfUse.has(sub_building_class_enum.RESIDENTIAL);
};

export const getBuildingHasAtLeastOneCommercial = (
  areas: Required<Pick<area, 'class_of_use_id' | 'area_type_id'>>[],
): boolean => {
  const classesOfUse = getEbfClassesOfUse(areas);
  return classesOfUse.has(sub_building_class_enum.COMMERCIAL);
};

export const getAreaPerEbfClassOfUse = (
  areas: Required<Pick<area, 'class_of_use_id' | 'value' | 'area_type_id'>>[],
): Record<sub_building_class_enum, number> => {
  const ebfAreas = areas.filter((area) => area.area_type_id === area_type_enum.EBF);
  const classOfUseDistribution: Record<sub_building_class_enum, number> = {
    [sub_building_class_enum.RESIDENTIAL]: 0,
    [sub_building_class_enum.COMMERCIAL]: 0,
  };
  ebfAreas.forEach((area) => {
    if (area.class_of_use_id) {
      const classOfUse = accessEnum(sub_building_class_enum, area.class_of_use_id);
      classOfUseDistribution[classOfUse] = fFloatingPoint(classOfUseDistribution[classOfUse] + area.value);
    }
  });
  return classOfUseDistribution;
};

export const getBuildingIsOnlyResidential = (
  areas: Required<Pick<area, 'class_of_use_id' | 'area_type_id'>>[],
): boolean => {
  const classesOfUse = getEbfClassesOfUse(areas);
  return classesOfUse.size === 1 && classesOfUse.has(sub_building_class_enum.RESIDENTIAL);
};

export const getBuildingIsOnlyCommercial = (
  areas: Required<Pick<area, 'class_of_use_id' | 'area_type_id'>>[],
): boolean => {
  const classesOfUse = getEbfClassesOfUse(areas);
  return classesOfUse.size === 1 && classesOfUse.has(sub_building_class_enum.COMMERCIAL);
};
