import { useLazyQuery, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionSummary,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import {
  ActionPlanningActionPlanActionPlanFragment,
  BuildingModelFragment,
  EnergySystemRouteRecommendedActionFragment,
  EnvelopeRecommendedActionFragment,
  HydraulicBalancingRecommendationActionFragment,
  SolarRecommendationOutputFragment,
  SystemRecommendationActionFragment,
  renovation_type_enum,
} from '@predium/client-graphql';
import { TFunction } from 'i18next';
import last from 'lodash/last';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/icons';
import Iconify from '../../../../components/Iconify';
import { DelayedLoading } from '../../../../components/Loading';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import {
  GET_ACTIONS_FOR_RECOMMENDED_ACTIONS,
  GET_RECOMMENDED_ACTIONS_FOR_BUILDING_MODEL,
} from '../../../../graphql/ActionPlanning.queries';
import { getMaxDate } from '../ActionPlan.utils';
import RecommendedAction from './RecommendedActions/RecommendedAction';

export type ActionType = 'all' | 'envelope' | 'system' | 'non-invasive';

const buttonLabels = (t: TFunction<'translation', undefined>) => {
  return [
    {
      id: 'all',
      name: t('General_All'),
    },
    {
      id: 'envelope',
      name: t('General_Envelope'),
    },
    {
      id: 'system',
      name: t('General_System'),
    },
    {
      id: 'non-invasive',
      name: t('General_NonInvasive'),
    },
  ];
};

export type FilterId =
  | 'maximum_final_energy_reduction'
  | 'maximum_co2_reduction'
  | 'cost_effective_final_energy_reduction'
  | 'cost_effective_co2_reduction';

export type Filter = {
  name: string;
  id: FilterId;
};

export const filters = (t: TFunction<'translation', undefined>): Filter[] => {
  return [
    {
      name: t('ActionPlanning_ActionRecommendations-MaximumCO2Reduction'), // 'Maximum CO₂ Reduction'
      id: 'maximum_co2_reduction',
    },
    {
      name: t('ActionPlanning_ActionRecommendations-MaximumFinalEnergyReduction'), // 'Maximum Final Energy Reduction'
      id: 'maximum_final_energy_reduction',
    },
    {
      name: t('ActionPlanning_ActionRecommendations-CostEffectiveCO2Reduction'), // 'Cost-Effective CO2e-Reduction'
      id: 'cost_effective_co2_reduction',
    },
    {
      name: t('ActionPlanning_ActionRecommendations-CostEffectiveFinalEnergyReduction'), // 'Cost-Effective Final Energy Reduction'
      id: 'cost_effective_final_energy_reduction',
    },
  ];
};

type TempAction = { action_type?: ActionType; id?: number; renovation_type?: renovation_type_enum };

export type RecommendationOutput =
  | (EnergySystemRouteRecommendedActionFragment & TempAction)
  | (EnvelopeRecommendedActionFragment & TempAction)
  | (HydraulicBalancingRecommendationActionFragment & TempAction)
  | (SolarRecommendationOutputFragment & TempAction)
  | (SystemRecommendationActionFragment & TempAction);

type Props = {
  baseBuildingModel: BuildingModelFragment;
  actionPlan: ActionPlanningActionPlanActionPlanFragment;
  totalArea: number;
};

const RecommendedActions = ({ baseBuildingModel, actionPlan, totalArea }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [expanded, setExpanded] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<FilterId>('cost_effective_co2_reduction');
  const [selectedButton, setSelectedButton] = useState<ActionType>('all');
  const [hiddenActions, setHiddenActions] = useState<number[]>([]);
  const [fakeLoading, setFakeLoading] = useState(false);

  const [newDate, setNewDate] = useState(new Date());
  const [minDate, setMinDate] = useState(new Date());
  const [loaderText, setLoaderText] = useState<string>('');

  useQuery(GET_ACTIONS_FOR_RECOMMENDED_ACTIONS, {
    variables: { actionPlanId: actionPlan.id },
    onCompleted: (data) => {
      const lastAction = last(data.action);
      if (lastAction) {
        const lastActionDate = new Date(lastAction.implementation_to);
        setMinDate(lastActionDate);
        const tempDate = new Date(lastActionDate.setFullYear(lastActionDate.getFullYear() + 1));
        setNewDate(tempDate);
      }
    },
  });

  const [getRecommendedActions, { data: actionsData, loading: actionsLoading }] = useLazyQuery(
    GET_RECOMMENDED_ACTIONS_FOR_BUILDING_MODEL,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const data = actionsData?.recommendActions;

  const fetchData = async () => {
    setFakeLoading(true);

    await new Promise((resolve) => setTimeout(resolve, 3000));

    await getRecommendedActions({
      variables: { implementation_date: newDate as Date, action_plan_id: actionPlan.id },
      onCompleted: () => {
        setFakeLoading(false);
        setLoaderText('');
      },
      onError: () => {
        setFakeLoading(false);
        setLoaderText('');
      },
    });
  };

  useEffect(() => {
    setFakeLoading(true);
    setTimeout(() => {
      setFakeLoading(false);
    }, 3000);
  }, [newDate]);

  const filterRecommendations = useCallback(
    (recommendations: RecommendationOutput[], filterId: FilterId): RecommendationOutput[] => {
      const sortByImpact = (impactType: 'co2_emissions' | 'final_energy', costEffective: boolean = false) => {
        return [...recommendations]
          .sort((a, b) => {
            const otherImpactType = impactType === 'co2_emissions' ? 'final_energy' : 'co2_emissions';

            const impactA = Number((a.actionImpact[impactType]?.impact ?? 0).toFixed(1));
            const impactB = Number((b.actionImpact[impactType]?.impact ?? 0).toFixed(1));
            const otherImpactA = Number((a.actionImpact[otherImpactType]?.impact ?? 0).toFixed(1));
            const otherImpactB = Number((b.actionImpact[otherImpactType]?.impact ?? 0).toFixed(1));
            const costA = a.actionImpact.total_estimated_cost ?? 1;
            const costB = b.actionImpact.total_estimated_cost ?? 1;

            // 2nd prio sorting if the impact is the same
            if (impactA === impactB) {
              if (costEffective) {
                return otherImpactA / costA - otherImpactB / costB;
              }
              return otherImpactA - otherImpactB;
            }

            if (costEffective) {
              return impactA / costA - impactB / costB;
            }

            return impactA - impactB;
          })
          .filter((action) => {
            // Filter out actions that have no impact or negative impact on the selected metric
            const before = Number(action.actionImpact[impactType]?.before ?? 0);
            const after = Number(action.actionImpact[impactType]?.after ?? 0);
            return after < before && after !== before;
          });
      };

      switch (filterId) {
        case 'maximum_co2_reduction':
          return sortByImpact('co2_emissions');
        case 'maximum_final_energy_reduction':
          return sortByImpact('final_energy');
        case 'cost_effective_co2_reduction':
          return sortByImpact('co2_emissions', true);
        case 'cost_effective_final_energy_reduction':
          return sortByImpact('final_energy', true);
        default:
          return recommendations;
      }
    },
    [],
  );

  useEffect(() => {
    const textArray = [
      t('ActionPlaning_RecommendedActions-AnalyzingData'),
      t('ActionPlaning_RecommendedActions-SimulatingPossibleActions'),
      t('ActionPlaning_RecommendedActions-PrioritizingActions'),
    ];

    let index = 0;
    const interval = setInterval(() => {
      if (index < textArray.length) {
        setLoaderText(textArray[index]);
        index++;
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fakeLoading]);

  let uniqueIdCounter = 0;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const generateUniqueId = () => {
    uniqueIdCounter += 1;
    return uniqueIdCounter;
  };

  const updateActionType = useCallback(
    (
      actions: RecommendationOutput[],
      type: ActionType,
      renovation_type: renovation_type_enum,
    ): RecommendationOutput[] => {
      return actions.map((action) => ({
        ...action,
        action_type: type,
        id: generateUniqueId(),
        renovation_type,
      }));
    },
    [generateUniqueId],
  );

  //This is Temporary, might pushed to backend
  const allActions = useMemo(() => {
    return data
      ? [
          ...updateActionType(data.energySystemRouteActions, 'non-invasive', renovation_type_enum.ENERGY_SYSTEM_ROUTE),
          ...updateActionType(data.envelopeActions, 'envelope', renovation_type_enum.ENVELOPE),
          ...updateActionType(data.hydraulicBalancingActions, 'non-invasive', renovation_type_enum.HYDRAULIC_BALANCING),
          ...updateActionType(data.solarActions, 'system', renovation_type_enum.SOLAR_PLANT),
          ...updateActionType(data.systemActions, 'system', renovation_type_enum.ENERGY_CONSUMER),
        ]
      : [];
  }, [data, updateActionType]);

  const filteredRecommendations = filterRecommendations(allActions, selectedFilter);

  const selectedRecommendations = filteredRecommendations.filter((action) => {
    return selectedButton === 'all' ? true : action.action_type === selectedButton;
  });

  const filteredActions = selectedRecommendations.filter((action) => !hiddenActions.includes(action.id as number));

  const hideAction = (actionId: number) => {
    setHiddenActions((prev) => [...prev, actionId]);
    enqueueSnackbar(t('ActionPlanning_RecommendedActions-RecommendationHiddenMessage'), {
      variant: 'success',
      autoHideDuration: SnackbarTimeouts.Success,
    });
  };

  return (
    <Stack my={3}>
      <Accordion
        disableGutters={true}
        defaultExpanded={true}
        expanded={expanded}
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          '&.Mui-expanded': {
            background: theme.palette.grey[200],
          },
          background: theme.palette.grey[200],
          boxShadow: 'none',
          '&.MuiAccordion-root': {
            borderRadius: 3,
          },
        }}
      >
        <AccordionSummary
          onClick={() => setExpanded(!expanded)}
          sx={{
            '.MuiAccordionSummary-expandIconWrapper': {
              width: 36,
              height: 36,
            },
            p: 3,
            pb: expanded ? 4 : 3,
            '.MuiAccordionSummary-content': {
              m: 0,
            },
            '&.Mui-focusVisible': {
              backgroundColor: 'transparent',
            },
          }}
          expandIcon={
            allActions.length > 0 && (
              <IconButton onClick={() => setExpanded(false)}>
                <Iconify icon={ICONS.CHEVRON_DOWN} width={20} height={20} />
              </IconButton>
            )
          }
        >
          <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
            <Stack>
              <Stack direction={'row'} alignItems={allActions.length === 0 ? 'flex-start' : 'center'}>
                <Iconify
                  icon={ICONS.RECOMMENDED_ACTIONS}
                  width={32}
                  height={32}
                  sx={{ mr: 1 }}
                  color={theme.palette.info.main}
                />

                <Typography variant="h6">
                  {allActions.length > 0
                    ? t('ActionPlanning_ActionRecommendations-RecommendedActions-open')
                    : t('ActionPlanning_ActionRecommendations-RecommendedActions')}
                </Typography>

                {allActions.length > 0 && (
                  <TextField
                    select
                    variant="standard"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    value={selectedFilter}
                    onChange={(e) => {
                      setSelectedFilter(e.target.value as FilterId);
                    }}
                    sx={{
                      ml: 1,
                      mt: 0.5,
                      pointerEvents: 'auto',
                      '.MuiInputBase-input': {
                        fontSize: 'h6.fontSize',
                        fontWeight: 'h6.fontWeight',
                        color: 'text.secondary',
                        px: 1,
                        ':focus': {
                          borderRadius: '8px',
                        },
                        ':hover': {
                          backgroundColor: 'grey.300',
                          borderRadius: '8px',
                        },
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  >
                    {filters(t).map((filter) => (
                      <MenuItem key={filter.id} value={filter.id}>
                        {filter.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Stack>
              <Stack pl={5}>
                {allActions.length === 0 && (
                  <Typography variant="body2" color="text.secondary">
                    {t('ActionPlanning_ActionRecommendations-GenerateRecommendationsDescription')}
                  </Typography>
                )}
              </Stack>
            </Stack>

            {!expanded && allActions.length === 0 && (
              <LoadingButton
                variant="contained"
                onClick={fetchData}
                sx={{
                  transition: 'background-color 0.3s',
                  zIndex: 0,
                  pointerEvents: 'auto',
                  py: 1,
                }}
                loading={actionsLoading}
                disabled={actionsLoading}
                size="medium"
              >
                {t('ActionPlanning_ActionRecommendations-GenerateRecommendations')}
              </LoadingButton>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 3, pt: 0 }}>
          <Grid container mb={4} alignItems={'center'}>
            <Grid item xs={12} md={8} lg={8} mb={3}>
              <ToggleButtonGroup
                size="small"
                value={selectedButton}
                onChange={(_, value) => {
                  if (value !== selectedButton && value) {
                    //Don't want to anything when clicked on the same button
                    setSelectedButton(value);
                  }
                }}
                exclusive
                sx={{
                  backgroundColor: 'transparent',
                  border: 'none',
                }}
              >
                {buttonLabels(t).map((button, index) => (
                  <ToggleButton
                    key={index}
                    value={button.id}
                    sx={{
                      color: 'text.secondary',
                      mr: 1,
                      minWidth: 40,
                      '&.Mui-selected': {
                        backgroundColor: theme.palette.common.white,
                        color: 'text.primary',
                      },
                    }}
                  >
                    {button.name}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} md={4} lg={4} container justifyContent="flex-end">
              <DesktopDatePicker
                label={t('General_PlannedFor')}
                value={newDate}
                onChange={(date) => {
                  const selectedDate = new Date(date as Date);
                  if (selectedDate.getDate() === minDate.getDate() || selectedDate > minDate) {
                    setNewDate(selectedDate);
                  }
                }}
                renderInput={(params) => <TextField {...params} size={'small'} />}
                minDate={minDate}
                maxDate={getMaxDate()}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} pl={3} minHeight={200}>
            <>
              {fakeLoading || actionsLoading ? (
                <>
                  <DelayedLoading
                    delay={0}
                    sx={{
                      zIndex: 1,
                    }}
                  />
                  <Stack width={'100%'} alignItems={'center'} mt={-8}>
                    <Typography variant="h6" color={'text.secondary'}>
                      {loaderText}
                    </Typography>
                  </Stack>
                </>
              ) : filteredActions && filteredActions.length > 0 ? (
                <>
                  {/* Show only 3 actions */}
                  {filteredActions.slice(0, 3).map((action, index) => (
                    <RecommendedAction
                      action={action}
                      key={index}
                      hideAction={hideAction}
                      baseBuildingModel={baseBuildingModel}
                      actionPlan={actionPlan}
                      defaultImplementationDate={newDate}
                      totalArea={totalArea}
                    />
                  ))}
                </>
              ) : (
                <Stack
                  sx={{
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'text.secondary',
                  }}
                >
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    {t('ActionPlanning_ActionRecommendations-NoRecommendations')}
                  </Typography>
                  <Typography variant="body2">
                    {t('ActionPlanning_ActionRecommendations-NoRecommendationsDescription')}
                  </Typography>
                </Stack>
              )}
            </>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default RecommendedActions;
