import { InputSelectIcon } from './CustomIcons';

export default function Select() {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: InputSelectIcon,
      },
      styleOverrides: {
        icon: {
          right: 14, // Aligned with Autocomplete arrow
        },
      },
    },
  };
}
