import { Stack, StackProps, Typography, TypographyProps } from '@mui/material';
import { fShortenNumber } from '@predium/utils';
import Iconify from '../../../../components/Iconify';
import InfoTooltip from '../../../../components/InfoTooltip';

type Props = {
  icon?: string;
  iconColor?: string;

  title: string;
  subtitle?: string;
  titleTooltip?: string;

  value?: number | null;
  hasValue?: boolean;
  unit: string | string[];
  valueSubtitle?: string;

  slotsProp?: {
    root?: StackProps;
    title?: TypographyProps;
    unitsRoot?: StackProps;
  };
};

const KPI = ({
  title,
  subtitle,
  titleTooltip,
  valueSubtitle,
  unit,
  value,
  hasValue = true,
  icon,
  iconColor,
  slotsProp,
}: Props) => {
  const units = Array.isArray(unit) ? unit : [unit];

  return (
    <Stack direction="row" spacing={1} {...slotsProp?.root}>
      {icon && <Iconify icon={icon} color={iconColor} fontSize={32} />}
      <Stack>
        <Stack direction="row" spacing={0.75} alignItems="center">
          <Typography variant="body2" fontWeight={700}>
            {title}
          </Typography>
          {titleTooltip && <InfoTooltip text={titleTooltip} sx={{ p: 0, svg: { height: 16, width: 16 } }} />}
        </Stack>

        {subtitle && (
          <Typography variant="caption" mt={1}>
            {subtitle}
          </Typography>
        )}
        <Stack direction="row" spacing={0.5} alignItems={'baseline'}>
          <Typography fontWeight={700} variant="h5" {...slotsProp?.title}>
            {hasValue && value ? fShortenNumber(value) : '—'}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              textAlign: 'left',
              fontWeight: 700,
              color: 'grey.600',
              pb: 0.25,
            }}
          >
            {units.map((singleUnit) => singleUnit)}
          </Typography>
        </Stack>
        {valueSubtitle && (
          <Typography variant="caption" color="grey.600">
            {valueSubtitle}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default KPI;
