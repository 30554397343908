import { Box, IconButton, Popover, Stack, SxProps, Typography, styled, useTheme } from '@mui/material';
import { ReactNode, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ICONS } from '../../assets/icons';
import Iconify from '../Iconify';
import { SemanticButton } from '../Tile';

type Props = {
  name: string;
  icon?: string;
  title: string;
  titleTooltip?: ReactNode;
  subtitle?: string;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
  sx?: SxProps;
};

// TODO replace that with the MUI Radio once it works again
// see PRE-2906

export default function RHFRadioButton({ name, icon, title, titleTooltip, subtitle, onChange, disabled, sx }: Props) {
  const theme = useTheme();
  const { control } = useFormContext();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={false}
      render={({ field: { ref, ...field } }) => (
        <Box sx={{ mb: 2 }}>
          <SemanticButton
            type="button"
            disabled={disabled}
            onClick={() => {
              field.onChange(!field.value);
              onChange?.(!field.value);
            }}
            sx={{
              ...sx,
              display: 'block',
              width: '100%',
              border: `1px solid ${theme.palette.grey[500_24]}`,
              '&:focus': {
                borderColor: theme.palette.grey[500],
              },
              '&:hover': { cursor: 'pointer' },
              borderRadius: '8px',
              opacity: disabled ? 0.5 : 1,
            }}
          >
            <Box
              sx={{
                borderRadius: 'inherit',
                py: 2,
                px: 4,
                textAlign: 'left',
                backgroundColor: !!field.value ? theme.palette.grey[500_12] : 'inherit',

                ':hover': {
                  backgroundColor: theme.palette.grey[500_24],
                },
              }}
            >
              <Stack direction="row" alignItems={'center'} alignContent={'center'} spacing={4}>
                <RadioButtonStyled tabIndex={-1} type="radio" checked={field.value} readOnly />
                {!!icon && <Iconify icon={icon} width={48} height={48} sx={{ minWidth: 48, minHeight: 48 }} />}
                <Box>
                  <Stack direction="row" alignItems={'center'} alignContent={'center'} gap={1}>
                    <Typography variant="subtitle2" fontWeight={!!subtitle ? 700 : 500} fontSize={14}>
                      {title}
                    </Typography>
                    {!!titleTooltip && (
                      <>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handlePopoverOpen(e);
                          }}
                          sx={{ p: 0 }}
                        >
                          <Iconify icon={ICONS.INFO} width={16} height={16} color="text.secondary" />
                        </IconButton>
                        <Popover
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handlePopoverClose}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          PaperProps={{
                            sx: {
                              overflow: 'visible',
                              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                              mt: 1.5,
                            },
                          }}
                          sx={{ mt: -3 }}
                        >
                          <Box
                            // custom arrow
                            sx={{
                              position: 'relative',
                              '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                bottom: -10,
                                left: '50%',
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateX(-50%) translateY(-50%) rotate(135deg)',
                                zIndex: 0,
                              },
                            }}
                          >
                            <Typography sx={{ p: 2, position: 'relative', zIndex: 1 }}>{titleTooltip}</Typography>
                          </Box>
                        </Popover>
                      </>
                    )}
                  </Stack>
                  {!!subtitle && <Typography color="text.disabled">{subtitle}</Typography>}
                </Box>
              </Stack>
            </Box>
          </SemanticButton>
        </Box>
      )}
    />
  );
}

const RadioButtonStyled = styled('input')(({ theme }) => ({
  accentColor: theme.palette.primary.main,
  width: '20px',
  height: '20px',
}));
