import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormEditBar from '../../../../components/common/FormEditBar';
import StickyBox from '../../../../components/common/StickyBox';
import { TechnologyEnvelopeState } from '../BuildingTechnologyEnvelope';

type Props = {
  techEnvelopeState: TechnologyEnvelopeState;
  address: string;
  onCancel: VoidFunction;
  onSubmit: VoidFunction;
};

const TechnologyEnvelopeFormBar = ({ techEnvelopeState, address, onCancel, onSubmit }: Props) => {
  const { t } = useTranslation();

  const errorCount = techEnvelopeState.envelopeErrorCount + techEnvelopeState.technologyErrorCount;

  const errorCountText = t('DataCollection_FormErrorCount', {
    count: errorCount,
  });

  return (
    <StickyBox>
      <FormEditBar
        onCancel={onCancel}
        title={address}
        errorCountText={
          errorCount > 0 && (
            <Typography variant="caption" color="error">
              ({errorCountText})
            </Typography>
          )
        }
        primaryButton={
          <LoadingButton variant="contained" onClick={onSubmit} disabled={errorCount > 0}>
            {t('General_Accept')}
          </LoadingButton>
        }
      />
    </StickyBox>
  );
};

export default TechnologyEnvelopeFormBar;
