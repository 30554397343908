/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery, type useSubscription } from '@apollo/client';
import { Container, Stack } from '@mui/material';
import { EnergyCertificateDraftStatusSubscription, draft_status_enum } from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { DelayedLoading } from '../../components/Loading';
import { SnackbarTimeouts } from '../../components/NotistackProvider';
import Page from '../../components/Page';
import { NavigateAction } from '../../components/snackbars/NavigateToRouteAction';
import {
  CREATE_BUILDING_FROM_ENERGY_CERTIFICATE_DRAFT,
  DELETE_ENERGY_CERTIFICATE_DRAFT,
  SET_ENERGY_CERTIFICATE_DRAFT_OCR_FAILED,
} from '../../graphql/DataCollection.mutations';
import {
  ENERGY_CERTIFICATE_DRAFT_STATUS_SUBSCRIPTION,
  GET_ALL_BUILDINGS_WITH_SUB_BUILDINGS,
  GET_ALL_ENERGY_CERTIFICATE_DRAFTS,
  GET_ECONOMIC_UNITS_WITH_BUILDINGS,
  GET_ENERGY_CERTIFICATE_DRAFT,
} from '../../graphql/DataCollection.queries';
import { GET_PORTFOLIOS_WITH_WRITE_PERMISSIONS } from '../../graphql/Portfolio.queries';
import usePosthogTracking from '../../hooks/usePosthogTracking';
import useSessionData from '../../hooks/useSessionData';
import { PATHS } from '../../routes/paths';
import DataCollectionDraft from '../../sections/DataCollection/Building/Drafts/DataCollectionDraftDeprecated';
import { DraftStatusLoading } from '../../sections/DataCollection/Building/Drafts/DraftStatusLoading';
import EnergyCertificateDraftForm from '../../sections/DataCollection/Building/Drafts/EnergyCertificateDraftForm';
import EnergyCertificateDraftTabs from '../../sections/DataCollection/Building/Drafts/EnergyCertificateDraftTabs';

type Props = {
  useSubscription: typeof useSubscription;
};

export default function DataCollectionEnergyCertificateDraft({ useSubscription }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { trackEvent } = usePosthogTracking();
  const { user } = useSessionData();
  const navigate = useNavigate();

  const { id: energyCertificateDraftIdString } = useParams();

  //@ts-ignore
  const energyCertificateDraftId = parseInt(energyCertificateDraftIdString);

  const [subscriptionStatusData, setSubscriptionStatusData] = useState<EnergyCertificateDraftStatusSubscription | null>(
    null,
  );
  const [energyCertificateDraftName, setEnergyCertificateDraftName] = useState<string | null>(null);
  const [createEnergyCertificateDraftModalOpen, setCreateEnergyCertificateDraftModalOpen] = useState<boolean>(false);
  const [acceptOcrResultModalOpen, setAcceptOcrResultModalOpen] = useState<boolean>(false);
  const [steps, setSteps] = useState<number[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const maxSteps = steps.length;

  const showErrorSnackbar = () => {
    enqueueSnackbar(t('DataCollection_EnergyCertificateDraft_DeleteDraft-error'), {
      variant: 'error',
      autoHideDuration: SnackbarTimeouts.Error,
    });
  };

  const [deleteEnergyCertificateDraftMutation] = useMutation(DELETE_ENERGY_CERTIFICATE_DRAFT, {
    variables: {
      energyCertificateDraftId,
    },
    update: (cache, { data }) => {
      //@ts-ignore
      if (data.delete_energy_certificate_draft_by_pk) {
        const normalizedId = cache.identify({
          //@ts-ignore
          id: data.delete_energy_certificate_draft_by_pk.id,

          //@ts-ignore
          __typename: data.delete_energy_certificate_draft_by_pk.__typename,
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
    onError: () => showErrorSnackbar(),
    onCompleted: (data) => {
      if (!data.delete_energy_certificate_draft_by_pk) {
        showErrorSnackbar();
        return;
      }
      enqueueSnackbar(t('DataCollection_EnergyCertificateDraft_DeleteDraft-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
  });

  const [setEnergyCertificateDraftOcrFailedMutation] = useMutation(SET_ENERGY_CERTIFICATE_DRAFT_OCR_FAILED, {
    variables: {
      energyCertificateDraftId,
    },
    onError: () =>
      enqueueSnackbar(t('DataCollection_EnergyCertificateDraft_SaveDraft-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
  });

  const { data: allEnergyCertificateDrafts, loading: allEnergyCertificateDraftsLoading } = useQuery(
    GET_ALL_ENERGY_CERTIFICATE_DRAFTS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.energy_certificate_draft) {
          const ids = data.energy_certificate_draft.map((item) => item.id);
          setSteps(ids);
        }
      },
      onError: () => {
        navigate(PATHS.notFound());
      },
    },
  );

  const {
    data: energyCertificateDraftData,
    loading: energyCertificateDraftDataLoading,
    refetch,
  } = useQuery(GET_ENERGY_CERTIFICATE_DRAFT, {
    variables: { energyCertificateDraftId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data.energy_certificate_draft_by_pk) {
        return navigate(PATHS.notFound());
      }

      //@ts-ignore
      setEnergyCertificateDraftName(data.energy_certificate_draft_by_pk.street);
    },
    onError: () => {
      navigate(PATHS.notFound());
    },
  });

  // if the user has write permissions, they can only assign the draft to a portfolio they have write permissions to
  const { loading: portfoliosWithWritePermissionsLoading, data: portfoliosWithWritePermissionsData } = useQuery(
    GET_PORTFOLIOS_WITH_WRITE_PERMISSIONS,
    {
      //@ts-ignore
      variables: { user_id: user?.id },
      skip: !user,
    },
  );

  const portfolios = portfoliosWithWritePermissionsData?.portfolio ?? [];

  const { loading } = useSubscription(ENERGY_CERTIFICATE_DRAFT_STATUS_SUBSCRIPTION, {
    variables: {
      energyCertificateDraftId,
    },
    onData: async ({ data }) => {
      const { data: subscriptionData } = data;

      if (!subscriptionData || !subscriptionData.energy_certificate_draft_by_pk) {
        return;
      }

      const status = subscriptionData.energy_certificate_draft_by_pk.draft_status_id;

      // trigger GET_ENERGY_CERTIFICATE_DRAFT query when subscription status changes but when its not uploading and OCR is not in progress
      if (status !== draft_status_enum.UPLOADING && status !== draft_status_enum.OCR_IN_PROGRESS) {
        await refetch();
      }
      // Update the draft status only after new form data has been fetched.

      setSubscriptionStatusData(subscriptionData);
    },
  });

  const activeStep =
    allEnergyCertificateDrafts?.energy_certificate_draft.map((item) => item.id).indexOf(energyCertificateDraftId) ?? 0;
  const handleStep = (direction: 'increase' | 'decrease') => () => {
    const prevStep = activeStep + (direction === 'increase' ? 1 : -1);
    navigate(PATHS.dataCollection.energyCertificateDraft({ id: steps[prevStep] }));
  };

  const stepperProps = useMemo(
    () => ({
      backStepTitle: t('DataCollection_PreviousDraft'),
      nextStepTitle: t('DataCollection_NextDraft'),
      maxSteps: maxSteps,
      handleNext: handleStep('increase'),
      handleBack: handleStep('decrease'),
      activeStep,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeStep, maxSteps],
  );

  const [createBuildingFromEnergyCertificateDraftMutation] = useMutation(
    CREATE_BUILDING_FROM_ENERGY_CERTIFICATE_DRAFT,
    {
      variables: {
        energyCertificateDraftId,
      },
      refetchQueries: [
        { query: GET_ALL_ENERGY_CERTIFICATE_DRAFTS },
        { query: GET_ECONOMIC_UNITS_WITH_BUILDINGS },
        { query: GET_ALL_BUILDINGS_WITH_SUB_BUILDINGS },
      ],
      onError: () =>
        enqueueSnackbar(t('DataCollection_EnergyCertificateDraft_CreateBuildingFromDraft-error'), {
          variant: 'error',
          autoHideDuration: SnackbarTimeouts.Error,
        }),
      onCompleted: async (data) => {
        const buildingId = data.createBuildingFromEnergyCertificateDraft.building_id;
        // need to get updated steps inline to avoid race condition
        const stepsAfterUpdate = steps.filter((id) => id !== energyCertificateDraftId);

        if (stepsAfterUpdate.length === 0) {
          navigate(PATHS.dataCollection.building({ id: buildingId }));
        } else {
          if (stepsAfterUpdate.length >= 1) {
            navigate(PATHS.dataCollection.energyCertificateDraft({ id: stepsAfterUpdate[0] }));
          }

          enqueueSnackbar(t('DataCollection_EnergyCertificateDraft_CreateBuildingFromDraft-success'), {
            variant: 'success',
            autoHideDuration: SnackbarTimeouts.Success,
            action:
              activeStep === maxSteps - 1 ? null : (
                <NavigateAction
                  path={PATHS.dataCollection.building({ id: buildingId })}
                  snackBarKey={buildingId}
                  title={t('DataCollection_EnergyCertificateDraft-title')}
                />
              ),
          });
        }

        const draftStatus = subscriptionStatusData?.energy_certificate_draft_by_pk?.draft_status_id;
        trackEvent('BUILDING_CREATED', {
          ...(draftStatus && { draft_status_id: draftStatus }),
        });
      },
    },
  );

  if (
    loading ||
    energyCertificateDraftDataLoading ||
    allEnergyCertificateDraftsLoading ||
    portfoliosWithWritePermissionsLoading ||
    !subscriptionStatusData
  ) {
    return <DelayedLoading />;
  }

  //@ts-ignore
  const cleanEnergyCertificateDraft = energyCertificateDraftData.energy_certificate_draft_by_pk;

  //@ts-ignore
  const draftStatus = subscriptionStatusData.energy_certificate_draft_by_pk.draft_status_id;

  const createSubBuilding = async () => {
    await createBuildingFromEnergyCertificateDraftMutation();
    setCreateEnergyCertificateDraftModalOpen(false);
  };

  const navigateToNextStep = () => {
    if (activeStep === maxSteps - 1) {
      navigate(PATHS.dataCollection.energyCertificateDraft({ id: steps[0] }));
    } else {
      navigate(PATHS.dataCollection.energyCertificateDraft({ id: steps[activeStep + 1] }));
    }
  };

  const goToNextAfterDelete = () => {
    if (maxSteps === 1) {
      navigate(PATHS.dataCollection.buildings());
    } else {
      navigateToNextStep();
    }
  };

  const isEditable = !(
    draftStatus === draft_status_enum.UPLOADING || draftStatus === draft_status_enum.OCR_IN_PROGRESS
  );

  const deleteDraft = async () => {
    await deleteEnergyCertificateDraftMutation();
    goToNextAfterDelete();
  };

  const setOcrFailed = async () => {
    await setEnergyCertificateDraftOcrFailedMutation();
  };

  return (
    <Page title={t('General_Building')}>
      <Container maxWidth="lg" sx={{ mb: 5 }}>
        <Stack sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <HeaderBreadcrumbs
            heading={energyCertificateDraftName ? energyCertificateDraftName : t('General_Draft')}
            links={[
              { name: t('General_DataCollection'), href: PATHS.dataCollection.buildings() },
              { name: t('PageName_DataVerification') },
            ]}
            infoText={t('DataCollection_EnergyCertificateDraft_DraftInfoMessage')}
            sx={{ mb: draftStatus === draft_status_enum.OCR_FAILED ? 2 : 0 }}
          ></HeaderBreadcrumbs>
        </Stack>

        {!isEditable ? (
          <DraftStatusLoading
            deleteInvoiceDraft={deleteDraft}
            stepperProps={stepperProps}
            draftSubscription={{
              draftStatus,

              //@ts-ignore
              updatedAt: new Date(subscriptionStatusData.energy_certificate_draft_by_pk.updated_at),
            }}
            onDeleteAndRetry={async () => {
              await deleteDraft();
            }}
            onManualEntry={async () => {
              await setOcrFailed();
            }}
          />
        ) : (
          <>
            <EnergyCertificateDraftForm
              cleanEnergyCertificateDraft={cleanEnergyCertificateDraft!}
              portfolios={portfolios}
              users={energyCertificateDraftData!.user}
              energyCertificateDraftId={energyCertificateDraftId}
              setEnergyCertificateDraftName={setEnergyCertificateDraftName}
              setCreateEnergyCertificateDraftModalOpen={setCreateEnergyCertificateDraftModalOpen}
              renderChildren={(submitForm, getValues) => (
                <DataCollectionDraft
                  createLabel={t('General_CreateBuilding')}
                  createDialogTitle={t('DataCollection_CreateSubBuilding_DraftConfirmationMessage', {
                    draftType: t('General_Building'),
                  })}
                  createDialogDescription={t('DataCollection_CreateSubBuilding_ConfirmationMessage', {
                    street: getValues('street'),
                    portfolio: portfolios.find((portfolio) => portfolio.id === getValues('portfolio_id'))?.name,
                  })}
                  createDialogButtonTitle={t('DataCollection_CreateSubBuilding_DraftConfirmationMessage', {
                    draftType: t('General_Building'),
                  })}
                  downloadUrl={energyCertificateDraftData!.energy_certificate_draft_by_pk!.file.downloadUrl}
                  draftStatus={draftStatus}
                  submitForm={submitForm}
                  createMutation={createSubBuilding}
                  stepperProps={stepperProps}
                  createDraftModalOpen={createEnergyCertificateDraftModalOpen}
                  setCreateDraftModalOpen={setCreateEnergyCertificateDraftModalOpen}
                  acceptOcrResultModalOpen={acceptOcrResultModalOpen}
                  setAcceptOcrResultModalOpen={setAcceptOcrResultModalOpen}
                  hasEditAccess
                  deleteModalOpen={deleteModalOpen}
                  setDeleteModalOpen={setDeleteModalOpen}
                >
                  <EnergyCertificateDraftTabs
                    //@ts-ignore
                    cleanEnergyCertificateDraft={cleanEnergyCertificateDraft}
                    portfolios={portfolios}
                    //@ts-ignore
                    users={energyCertificateDraftData.user}
                    //@ts-ignore
                    economicUnits={energyCertificateDraftData.economic_unit}
                    setAcceptOcrResultModalOpen={setAcceptOcrResultModalOpen}
                    draftStatus={draftStatus}
                    submitForm={submitForm}
                  />
                </DataCollectionDraft>
              )}
            ></EnergyCertificateDraftForm>

            <DeleteConfirmationModal
              open={deleteModalOpen}
              title={t('General_DeleteModalTitle-BuildingDraft', { count: 1 })}
              description={
                <Trans
                  i18nKey={'General_DeleteModalDescription-BuildingDraft'}
                  values={{
                    name: energyCertificateDraftData!.energy_certificate_draft_by_pk!.street,
                    count: 1,
                  }}
                  components={{ bold: <strong /> }}
                />
              }
              onClose={() => {
                setDeleteModalOpen(false);
              }}
              onDelete={async () => {
                await deleteDraft();
                setDeleteModalOpen(false);
              }}
            />
          </>
        )}
      </Container>
    </Page>
  );
}
