import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../assets/icons';
import Iconify from '../../../components/Iconify';
import PreToggleButton from '../../../components/presentations/PreToggleButton';

export type DisplayViewType = 'dashboard' | 'list';

type Props = {
  onViewChange: (selectedView: DisplayViewType) => void;
  currentView: DisplayViewType;
};

export default function EsgAnalysisViewSwitcher({ onViewChange, currentView }: Props) {
  const { t } = useTranslation();
  return (
    <PreToggleButton
      leftValue={{
        key: 'dashboard',
        value: <Iconify width={20} height={20} icon={ICONS.DASHBOARD_VIEW} />,
        tooltip: t('General_DashboardAnalysis'),
      }}
      rightValue={{
        key: 'list',
        value: <Iconify width={20} height={20} icon={ICONS.LIST_VIEW} />,
        tooltip: t('General_ListAnalysis'),
      }}
      initialValueType={currentView}
      onToggle={onViewChange}
      size="small"
      sx={{
        width: 84,
        height: 'fit-content',
        '& .MuiToggleButton-root': {
          height: 30,
        },
      }}
    />
  );
}
