import { country_enum } from '@predium/enums';
import sum from 'lodash/sum';
import { TARGET_PATH_YEARS_RANGE } from '../targetPaths/TargetPathConstants';
import { electricityEF } from './electricity';

export const YEAR_RANGE = TARGET_PATH_YEARS_RANGE;
export const SUPPORTED_YEARS = [2020, 2021, 2022, 2023];
export const DEFAULT_DISCOUNT_RATE = 0.036;

export function getRenewableDistrictHeatingEmissionFactor(country: country_enum): number {
  switch (country) {
    case country_enum.DE:
      return 0.05613427417198402;
    case country_enum.AT:
      return 0.02835889176495527;
    case country_enum.PL:
      return 0.07631790789848127;
    default: {
      const exhaustiveCheck: never = country;
      throw new Error(`Unhandled country ${country}: ${exhaustiveCheck}`);
    }
  }
}

export function getNonRenewableDistrictHeatingEmissionFactor(country: country_enum): number {
  return (0.20431 * electricityEF[country][2020]) / electricityEF['UK'][2020];
}

export function getDiscountedCost(list: number[], discountRate: number): number {
  return applyDiscountRate(
    list.map((v) => Math.max(v, 0)),
    discountRate,
  );
}

export function getDiscountedValue(list: number[], discountRate: number): number {
  return -applyDiscountRate(
    list.map((v) => Math.min(v, 0)),
    discountRate,
  );
}

export function applyDiscountRate(list: number[], discountRate: number): number {
  if (list.length !== YEAR_RANGE.length) {
    list = Array(YEAR_RANGE.length - list.length)
      .fill(0)
      .concat(list);
  }
  const [first, ...rest] = list;
  return first + sum(rest.map((v, idx) => v / Math.pow(1 + discountRate, idx + 2)));
}
