/* eslint-disable @typescript-eslint/ban-ts-comment */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Grid, MenuItem, Typography, useTheme } from '@mui/material';
import { envelope_type_enum } from '@predium/client-graphql';
import { WindowRenovationTemplates } from '@predium/client-lookup';
import get from 'lodash/get';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormProvider,
  RHFDatePicker,
  RHFNumberField,
  RHFSelect,
  RHFTextField,
} from '../../../../../../components/hook-form';
import InfoTable, { InfoTableData } from '../../../../../../components/presentations/InfoTable';
import { useLanguage } from '../../../../../../provider/LanguageProvider';
import { createEnvelopeInfoTable } from '../../../../Scenario/scenarios.util';
import { getIsCostAvailable, getMaxDate } from '../../../ActionPlan.utils';
import { NumberInputSx, SelectSx, StyledListItemText } from '../../../Components/ActionCustomStyles';
import { PartialRenovationOption } from '../../CreateAction';
import { SetValueOptions, WindowActionFormSchema } from '../../CreateActions/validation-schema';
import { EnvelopeActionEditProps } from '../../EditAction';

export type WindowActionForm = {
  renovation_ids: number[];
  implementation_to: DateTime | '';
  envelope_renovation_parameter: {
    envelope_type: envelope_type_enum.WINDOW;
    material_name: string;
    insulation_lambda: number | undefined;
    cost_per_m2: number | undefined;
    u_value: number | undefined;
  };
  affected_parts: PartialRenovationOption[];
};

export type WindowActionSchemaType = ReturnType<typeof WindowActionFormSchema>;

const WindowAction = forwardRef(
  (
    {
      selectedActionType,
      previousActionBuildingModel,
      resetSimulatedData,
      minimumDate,
      loading,
      parameters,
    }: EnvelopeActionEditProps,
    ref,
  ) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { language } = useLanguage();

    const [isNewMaterial, setIsNewMaterial] = useState<boolean>(false);

    const envelopes = previousActionBuildingModel.envelope_units;
    const buildingId = previousActionBuildingModel.building!.id;

    const infoTableData: InfoTableData[] | null = createEnvelopeInfoTable(selectedActionType, envelopes, t);

    const defaultValues = {
      envelope_renovation_parameter: {
        envelope_type: selectedActionType as envelope_type_enum.WINDOW,
        material_name: parameters.material_name,
        insulation_lambda: parameters.insulation_lambda ?? undefined,
        cost_per_m2: parameters.cost_per_m2 ? parseFloat(parameters.cost_per_m2.toFixed(2)) : undefined,
        u_value: parameters.u_value ?? undefined,
      },
      implementation_to: minimumDate,
      renovation_ids: [],
      affected_parts: [],
    };

    const formMethods = useForm<WindowActionForm>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: defaultValues,
      resolver: yupResolver(WindowActionFormSchema(minimumDate, language, true)),
    });
    const { setValue, watch } = formMethods;

    useImperativeHandle(ref, () => ({
      methods: formMethods,
    }));

    const affectedParts = watch('affected_parts');
    const materialName = watch('envelope_renovation_parameter.material_name');

    const allParameters = WindowRenovationTemplates;
    const materialNames = allParameters.map((parameter) => parameter.material_name);

    const handleMaterialChange = (selectedMaterialName: string) => {
      resetSimulatedData();
      const parameters = allParameters.find((material) => material?.material_name === selectedMaterialName);
      if (parameters) {
        setValue('envelope_renovation_parameter.material_name', get(parameters, 'material_name', ''), SetValueOptions);
        setValue('envelope_renovation_parameter.u_value', get(parameters, 'u_value', 0), SetValueOptions);
        setValue('envelope_renovation_parameter.cost_per_m2', get(parameters, 'cost_per_m2', 0), SetValueOptions);
        setValue(
          'envelope_renovation_parameter.insulation_lambda',
          get(parameters, 'insulation_lambda', 0),
          SetValueOptions,
        );
      }
    };

    const handleAddNewMaterial = () => {
      setValue('envelope_renovation_parameter.material_name', '', {
        shouldDirty: true,
      });
      //TODO somehow set to empty instead of 0
      setValue('envelope_renovation_parameter.cost_per_m2', 0, {
        shouldDirty: true,
      });
      setValue('envelope_renovation_parameter.u_value', 0, {
        shouldDirty: true,
      });
      setValue('envelope_renovation_parameter.insulation_lambda', 0, {
        shouldDirty: true,
      });
      setIsNewMaterial(true);
    };

    const shouldDisable = !isNewMaterial || !materialName;

    useEffect(() => {
      setValue(
        'renovation_ids',
        affectedParts.flatMap((part: PartialRenovationOption) => part.id),
        {
          shouldDirty: true,
          shouldValidate: true,
        },
      );
    }, [affectedParts, setValue]);

    const isCostAvailableForMaterial = getIsCostAvailable(materialName, allParameters);
    const showBorder = isNewMaterial || materialName;

    return (
      <FormProvider methods={formMethods}>
        <Box my={3}>
          {infoTableData && (
            <Box mb={3}>
              <InfoTable buildingId={buildingId} data={infoTableData} title={t('General_StateBeforeRenovation')} />
            </Box>
          )}

          <Box
            sx={{
              p: showBorder ? 2 : 0,
              border: showBorder ? `1px solid ${theme.palette.grey[500_32]}` : 0,
              borderRadius: '8px',
              mb: 3,
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {isNewMaterial ? (
                  <RHFTextField
                    name="envelope_renovation_parameter.material_name"
                    label={t('General_Window_one')}
                    size={'small'}
                    sx={{ ...NumberInputSx, pb: 0, mb: 0 }}
                    disabled={loading}
                    onChange={(e) => {
                      resetSimulatedData();
                      setValue('envelope_renovation_parameter.material_name', e.target.value);
                    }}
                  />
                ) : (
                  <RHFSelect
                    name="envelope_renovation_parameter.material_name"
                    label={t('General_Window_one')}
                    size={'small'}
                    sx={{ ...SelectSx, pb: 0, mb: 0 }}
                    disabled={loading}
                    onValueChange={handleMaterialChange}
                  >
                    {materialNames.map((material) => (
                      <MenuItem key={material} value={material}>
                        <StyledListItemText
                          action={material}
                          secondary={`${getIsCostAvailable(material, allParameters) ? '€' : ''}`}
                        />
                      </MenuItem>
                    ))}
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        handleAddNewMaterial();
                      }}
                    >
                      <Button
                        type="button"
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                        size="small"
                        variant="text"
                      >
                        {t('CreateNewEnvelopeAction_CreateNewWindowParameters')}
                      </Button>
                    </MenuItem>
                  </RHFSelect>
                )}
              </Grid>
            </Grid>

            {(materialName || isNewMaterial) && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <RHFNumberField
                      name="envelope_renovation_parameter.insulation_lambda"
                      label={`${t('General_GValue')} [W/(m·K)]`}
                      size={'small'}
                      sx={{ ...NumberInputSx, mt: 2 }}
                      disabled={shouldDisable || loading}
                      onChange={(e) => {
                        resetSimulatedData();
                        setValue('envelope_renovation_parameter.insulation_lambda', parseFloat(e.target.value));
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <RHFNumberField
                      name="envelope_renovation_parameter.u_value"
                      label={`${t('General_UwValue')}
                   [W/(m²·K)]`}
                      size={'small'}
                      sx={{ ...NumberInputSx, mt: 2 }}
                      disabled={shouldDisable || loading}
                      onChange={(e) => {
                        resetSimulatedData();
                        setValue('envelope_renovation_parameter.u_value', parseFloat(e.target.value));
                      }}
                    />
                  </Grid>
                </Grid>
                {isCostAvailableForMaterial && (
                  <Grid container spacing={2}>
                    <Grid item xs={6} mt={0}>
                      <RHFNumberField
                        name="envelope_renovation_parameter.cost_per_m2"
                        label={t('General_PricePerM2Part')}
                        size={'small'}
                        sx={{ ...NumberInputSx, mt: 2 }}
                        disabled={shouldDisable || loading}
                        onChange={(e) => {
                          resetSimulatedData();
                          setValue('envelope_renovation_parameter.cost_per_m2', parseFloat(e.target.value));
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
                {!isNewMaterial && !isCostAvailableForMaterial && (
                  <Grid item xs={12} mt={2}>
                    <Typography variant="caption" sx={{ display: 'flex', mb: 0 }} gutterBottom>
                      {t('General_NoCostAvailableForAction')}
                    </Typography>
                  </Grid>
                )}
              </>
            )}
          </Box>
          {
            //TODO: move this to parent and pass it as children
          }
          <RHFDatePicker
            label={t('General_PlannedFor')}
            name="implementation_to"
            size={'small'}
            sx={NumberInputSx}
            disabled={loading}
            minDate={minimumDate}
            maxDate={getMaxDate()}
            onChange={(date) => {
              resetSimulatedData();
              setValue('implementation_to', date as DateTime);
            }}
          />
        </Box>
      </FormProvider>
    );
  },
);

export default WindowAction;
