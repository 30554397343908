/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Checkbox, Table, TableBody, TableCell, TableContainer } from '@mui/material';
import {
  DataCollectionEnergyCertificateDraftsListEnergyCertificateDraftFragment,
  DataCollectionEnergyCertificateDraftsListGetAllEnergyCertificateDraftsSubscriptionSubscription,
} from '@predium/client-graphql';
import uniq from 'lodash/uniq';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ICONS } from '../../../assets/icons';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal';
import DraftStatusLabel from '../../../components/DraftStatusLabel';
import Iconify from '../../../components/Iconify';
import InlineUser from '../../../components/InlineUser';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import OverflowText from '../../../components/OverflowText';
import Scrollbar from '../../../components/Scrollbar';
import { TableSelectedActions } from '../../../components/table';
import TablePaginationStandard from '../../../components/table/TablePaginationStandard';
import TableRowWithHighlight, { hasRecentlyChanged } from '../../../components/table/TableRowWithHighlight';
import { DataCollectionBuildingTabState } from '../../../contexts/DataCollectionFiltersContext';
import { DELETE_ENERGY_CERTIFICATE_DRAFTS } from '../../../graphql/DataCollection.mutations';
import { GET_ENERGY_CERTIFICATE_DRAFT_SUBSCRIPTION_FILES } from '../../../graphql/DataCollection.queries';
import useDataCollectionFilters from '../../../hooks/useDataCollectionFilters';
import useTable, { applySortFilter } from '../../../hooks/useTable';
import { useLanguage } from '../../../provider/LanguageProvider';
import { PATHS } from '../../../routes';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '../../../utils/formatTime';
import { ALL_PORTFOLIOS } from '../../EsgAnalysis/NavigationSelectors/PortfolioSelector';
import DataCollectionHead, { HeadLabel } from './DataCollectionHead';
import DataCollectionToolbar from './DataCollectionToolbar';
import { DataCollectionTabEnum } from './TabsEnums';

type Props = {
  shouldOpenCreateModal: boolean;
  setCurrentTab: (subBuildingTabData: DataCollectionBuildingTabState) => void;
  data: DataCollectionEnergyCertificateDraftsListGetAllEnergyCertificateDraftsSubscriptionSubscription;
};

export default function DataCollectionEnergyCertificateDraftsList({
  shouldOpenCreateModal,
  setCurrentTab,
  data,
}: Props) {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, data: dataFiles } = useQuery(GET_ENERGY_CERTIFICATE_DRAFT_SUBSCRIPTION_FILES, {
    variables: {
      ids: data.energy_certificate_draft.map((draft) => draft.id),
    },
  });

  const showErrorSnackbar = () => {
    enqueueSnackbar(t('DataCollection_EnergyCertificateDraft_DeleteDraft-error'), {
      variant: 'error',
      autoHideDuration: SnackbarTimeouts.Error,
    });
  };

  const [deleteEnergyCertificateDraftsMutation] = useMutation(DELETE_ENERGY_CERTIFICATE_DRAFTS, {
    update: (cache, { data: deletedEnergyCertificateDraftData }) => {
      //@ts-ignore

      //@ts-ignore
      if (deletedEnergyCertificateDraftData.delete_energy_certificate_draft.affected_rows > 0) {
        //@ts-ignore

        //@ts-ignore
        deletedEnergyCertificateDraftData.delete_energy_certificate_draft.returning.forEach(({ id, __typename }) => {
          const normalizedId = cache.identify({
            id,
            __typename,
          });
          cache.evict({ id: normalizedId });
        });

        cache.gc();
      }
    },
    onError: () => showErrorSnackbar(),
    onCompleted: (data) => {
      if (!data.delete_energy_certificate_draft?.affected_rows) {
        showErrorSnackbar();
        return;
      }
      enqueueSnackbar(t('DataCollection_EnergyCertificateDraft_DeleteDraft-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
  });

  const [filteredDrafts, setFilteredDrafts] = useState<
    DataCollectionEnergyCertificateDraftsListEnergyCertificateDraftFragment[]
  >([]);
  const {
    filters: {
      dataCollectionDraftsFilters: { selectedPortfolio },
    },
    setSelectedPortfolioDrafts: setSelectedPortfolio,
  } = useDataCollectionFilters();
  const { page, setPage, rowsPerPage, setRowsPerPage, order, orderBy, handleRequestSort } = useTable({
    defaultOrder: 'desc',
    defaultOrderBy: 'updated_at',
    defaultRowsPerPage: 25,
  });
  const [selected, setSelected] = useState<number[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const doFilterDrafts = (portfolioName: string) => {
    let parsedCertificates: DataCollectionEnergyCertificateDraftsListEnergyCertificateDraftFragment[] = [];
    for (const energyCertificateDraft of data.energy_certificate_draft) {
      //@ts-ignore
      const { file } = dataFiles?.energy_certificate_draft.find((element) => {
        return element.id === energyCertificateDraft.id;
      });
      parsedCertificates.push({
        ...energyCertificateDraft,
        file: {
          name: file.name,
          downloadUrl: file.downloadUrl,
        },
      });
    }

    if (portfolioName === ALL_PORTFOLIOS) {
      setFilteredDrafts(parsedCertificates);
    } else {
      setFilteredDrafts(parsedCertificates.filter((draft) => draft.portfolio?.name === portfolioName));
    }
  };

  useEffect(() => {
    if (data?.energy_certificate_draft && !loading) {
      doFilterDrafts(selectedPortfolio);
    }
    // This useEffect is only used to initially set the filteredDrafts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.energy_certificate_draft, loading]);

  const portfolios = data.energy_certificate_draft.map((draft) => draft.portfolio);
  const portfolioOptions = uniq([
    ALL_PORTFOLIOS,
    ...portfolios
      .filter((portfolio) => portfolio?.name)

      //@ts-ignore
      .map((portfolio) => portfolio.name)
      .sort(),
  ]);

  const handlePortfolioChange = (portfolioName: string) => {
    setSelectedPortfolio(portfolioName);
    doFilterDrafts(portfolioName);
  };

  const handleDraftClick = (id: number) => {
    navigate(PATHS.dataCollection.energyCertificateDraft({ id }));
  };

  const sortedRows = applySortFilter({
    data: filteredDrafts,
    orderOptions: {
      order,

      //@ts-ignore
      orderBy: (item) => {
        switch (orderBy) {
          case 'file_name':
            return item.file.name;
          case 'name':
            return `${item.user?.first_name ?? ''} ${item.user?.last_name ?? ''}`;
          default:
            return item[orderBy as keyof typeof item] as string | number;
        }
      },
    },
  });

  const handleCheckboxClick = (id: number) => {
    const selectedIndex = selected.indexOf(id);

    if (selectedIndex === -1) {
      setSelected([...selected, id]);
    } else {
      // removes the index from the array in a more efficient way than filter

      //@ts-ignore
      setSelected([].concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1)));
    }
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      const newSelectedIds = sortedRows.map((draft) => draft.id);
      setSelected(newSelectedIds);
    } else {
      setSelected([]);
    }
  };

  const tableColumns: HeadLabel[] = [
    { id: 'street', label: t('General_Address'), minWidth: 200 },
    { id: 'city', label: t('General_City'), minWidth: 140 },
    { id: 'file_name', label: t('General_Document', { count: 1 }), minWidth: 200 },
    { id: 'draft_status_id', label: t('General_OCR'), minWidth: 200 },
    { id: 'created_at', label: t('General_UploadedAt'), minWidth: 200 },
    { id: 'name', label: t('General_ResponsibleUser'), minWidth: 200 },
  ];

  return (
    <>
      <Card>
        <DataCollectionToolbar
          handlePortfolioChange={handlePortfolioChange}
          //@ts-ignore
          portfolioOptions={portfolioOptions}
          selectedPortfolio={selectedPortfolio}
          shouldOpenCreateModal={shouldOpenCreateModal}
          setCurrentTab={() => setCurrentTab({ value: DataCollectionTabEnum.ENERGY_CERTIFICATE_DRAFTS })}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            {selected.length > 0 && (
              <TableSelectedActions
                selectedText={t('General_BuildingDraft', { count: selected.length })}
                numSelected={selected.length}
                rowCount={sortedRows.length}
                onSelectAllRows={handleSelectAllClick}
                action={
                  <Button
                    color="error"
                    endIcon={<Iconify icon={ICONS.TRASH} />}
                    onClick={() => {
                      setDeleteModalOpen(true);
                    }}
                  >
                    {t('General_Delete')}
                  </Button>
                }
                sx={{ pr: 3 }}
              />
            )}

            <Table>
              <DataCollectionHead
                order={order}
                orderBy={orderBy}
                headLabel={tableColumns}
                rowCount={sortedRows.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((draft) => {
                  const isItemSelected = selected.indexOf(draft.id) !== -1;
                  return (
                    <TableRowWithHighlight
                      hover
                      key={draft.id}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                      background={hasRecentlyChanged(draft.created_at) ? 'success' : 'default'}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} onClick={() => handleCheckboxClick(draft.id)} />
                      </TableCell>
                      <TableCell onClick={() => handleDraftClick(draft.id)}>
                        <OverflowText
                          text={draft.street ?? '-'}
                          maxWidth="200px"
                          TooltipProps={{
                            arrow: true,
                            placement: 'top-start',
                          }}
                        />
                      </TableCell>
                      <TableCell onClick={() => handleDraftClick(draft.id)}>{draft.city ?? '-'}</TableCell>
                      <TableCell onClick={() => handleDraftClick(draft.id)}>{draft.file.name ?? '-'}</TableCell>
                      <TableCell onClick={() => handleDraftClick(draft.id)}>
                        <DraftStatusLabel status={draft.draft_status_id} />
                      </TableCell>
                      <TableCell onClick={() => handleDraftClick(draft.id)}>
                        {formatDateToLocale(draft.created_at, COMMON_DATE_FORMATS.DAY_MONTH_YEAR_TIME, language)}
                      </TableCell>
                      <TableCell onClick={() => handleDraftClick(draft.id)}>
                        {draft.user ? (
                          //@ts-ignore

                          //@ts-ignore
                          <InlineUser firstName={draft.user.first_name} lastName={draft.user.last_name} size={25} />
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRowWithHighlight>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePaginationStandard
          count={sortedRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Card>

      <DeleteConfirmationModal
        open={deleteModalOpen}
        title={t('General_DeleteModalTitle-BuildingDraft', { count: selected.length })}
        description={
          // t('General_DeleteModalDescription-BuildingDraft', { count })
          <Trans
            i18nKey={'General_DeleteModalDescription-BuildingDraft'}
            values={{
              count: selected.length,
            }}
            components={{ bold: <strong /> }}
          />
        }
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        onDelete={async () => {
          setDeleteModalOpen(false);
          await deleteEnergyCertificateDraftsMutation({
            variables: {
              energyCertificateDraftIds: selected,
            },
          });
          setSelected([]);
        }}
      />
    </>
  );
}
