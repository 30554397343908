import { useMutation } from '@apollo/client';
import { Alert, Box, Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../assets/icons';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { FormProvider, RHFRadioButton } from '../../../components/hook-form';
import BuildingMap from '../../../components/map/BuildingMap';
import { UPDATE_BUILDING_LOCATION } from '../../../graphql/DataCollection.mutations';
import { GET_BUILDING } from '../../../graphql/DataCollection.queries';
import usePosthogTracking from '../../../hooks/usePosthogTracking';

const FullScreenDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    width: '100%',
    height: '100%',
    margin: 0,
    maxWidth: 'none',
    maxHeight: 'none',
    borderRadius: '0px !important',
  },
}));

const FullWidthDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
  height: 'calc(100% - 64px)', // Subtract the height of the DialogTitle
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
}));

const MapContainer = styled(Box)(() => ({
  flexGrow: 1,
  width: '100%',
  height: '100%',
  position: 'relative',
}));

interface BuildingMapboxProps {
  buildingId: number;
  isProcessing: boolean;
  hasEnvelope: boolean;
  hasApproximatedEnvelope: boolean;
  hasManualInputEnvelope: boolean;
  hasActions: boolean;
  onMapboxUpdate: () => void;
}

export default function BuildingMapbox({
  buildingId,
  isProcessing,
  hasEnvelope,
  hasApproximatedEnvelope,
  hasManualInputEnvelope,
  hasActions,
  onMapboxUpdate,
}: BuildingMapboxProps) {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();
  const { enqueueSnackbar } = useSnackbar();

  const [isEditingMapbox, setIsEditingMapbox] = useState(false);
  const [showMapboxConflictDialog, setShowMapboxConflictDialog] = useState(false);
  const [newMapboxIds, setNewMapboxIds] = useState<string[] | null>(null);
  const [newCoordinates, setNewCoordinates] = useState<{ longitude: number; latitude: number } | null>(null);

  const [updateBuildingLocation] = useMutation(UPDATE_BUILDING_LOCATION, {
    onCompleted: () => {
      setIsEditingMapbox(false);
      onMapboxUpdate();
      enqueueSnackbar(t('DataCollection_MapBoxEditMode_Success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      trackEvent('BUILDING_LOCATION_UPDATED', {
        building_id: buildingId,
      });
    },
    refetchQueries: [GET_BUILDING],
  });

  const showConflictDialog =
    (hasEnvelope && hasApproximatedEnvelope) ||
    (hasEnvelope && hasManualInputEnvelope) ||
    (hasApproximatedEnvelope && hasManualInputEnvelope) ||
    hasActions;

  const onEditMapboxSave = ({ ids, latitude, longitude }: { ids: string[]; longitude?: number; latitude?: number }) => {
    if (longitude !== undefined && latitude !== undefined) {
      setNewCoordinates({ longitude, latitude });
    }
    setNewMapboxIds(ids);
    if (showConflictDialog) {
      setShowMapboxConflictDialog(true);
    } else {
      onUpdateBuildingSelection({ ids, longitude, latitude, shouldApproximate: true });
    }
  };

  const onUpdateBuildingSelection = ({
    ids,
    longitude,
    latitude,
    shouldApproximate,
  }: {
    ids: string[];
    longitude?: number;
    latitude?: number;
    shouldApproximate: boolean;
  }) => {
    setShowMapboxConflictDialog(false);
    const coordinates = longitude !== undefined && latitude !== undefined ? { longitude, latitude } : undefined;

    updateBuildingLocation({
      variables: {
        buildingId,
        coordinates,
        selectedIds: ids,
        approximate: shouldApproximate,
      },
    });
  };

  const onEditMapbox = () => {
    setIsEditingMapbox(true);
  };

  const handleCloseMapbox = () => {
    setIsEditingMapbox(false);
  };

  return (
    <>
      {showMapboxConflictDialog && newMapboxIds && (
        <Dialog open={showMapboxConflictDialog} onClose={() => setShowMapboxConflictDialog(false)}>
          <MapboxConflictDialogContent
            hasManualInputEnvelope={hasManualInputEnvelope}
            hasActions={hasActions}
            onConfirm={(shouldApproximate: boolean) => {
              onUpdateBuildingSelection({
                ids: newMapboxIds,
                longitude: newCoordinates?.longitude ?? undefined,
                latitude: newCoordinates?.latitude ?? undefined,
                shouldApproximate,
              });
            }}
            onCancel={() => {
              setShowMapboxConflictDialog(false);
            }}
          />
        </Dialog>
      )}
      <FullScreenDialog open={isEditingMapbox} onClose={handleCloseMapbox}>
        <FullWidthDialogContent>
          <MapContainer>
            <BuildingMap
              editMode
              id="building-map-2"
              onCancel={() => setIsEditingMapbox(false)}
              onSave={onEditMapboxSave}
            />
          </MapContainer>
        </FullWidthDialogContent>
      </FullScreenDialog>
      <BuildingMap isLoading={isProcessing} id="building-map-1" onEdit={onEditMapbox} editDisabled={isProcessing} />
    </>
  );
}

const MapboxConflictDialogContent = ({
  hasManualInputEnvelope,
  hasActions,
  onConfirm,
  onCancel,
}: {
  hasManualInputEnvelope: boolean;
  hasActions: boolean;
  onConfirm: (shouldApproximate: boolean) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const methods = useForm<{ keepManualInputsAndActions: boolean; discardManualInputsAndActions: boolean }>({
    defaultValues: {
      discardManualInputsAndActions: true,
      keepManualInputsAndActions: false,
    },
    mode: 'onChange',
  });

  const handleChange = (source: 'keepManualInputsAndActions' | 'discardManualInputsAndActions') => {
    if (source === 'keepManualInputsAndActions') {
      methods.setValue('keepManualInputsAndActions', true);
      methods.setValue('discardManualInputsAndActions', false);
    } else {
      methods.setValue('keepManualInputsAndActions', false);
      methods.setValue('discardManualInputsAndActions', true);
    }
  };

  const alertText = hasActions
    ? hasManualInputEnvelope
      ? t('MapboxConflictDialogContent_Alert_HasManualEnvelopesAndActionPlans')
      : t('MapboxConflictDialogContent_Alert_HasActionPlans')
    : hasManualInputEnvelope
    ? t('MapboxConflictDialogContent_Alert_HasManualEnvelopes')
    : t('MapboxConflictDialogContent_Alert_HasApproximatedEnvelopes');

  const tooltip = (
    <Stack sx={{ maxWidth: '280px' }}>
      <Typography variant="subtitle2">{t('MapboxConflictDialogContent_Tooltip-title')}</Typography>
      <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
        {t('MapboxConflictDialogContent_Tooltip-subtitle')}
      </Typography>
    </Stack>
  );

  const content = hasActions ? (
    hasManualInputEnvelope ? (
      <Stack>
        <Typography sx={{ mb: 2 }}>{t('MapboxConflictDialogContent_Alert_Subtitle')}</Typography>{' '}
        <RHFRadioButton
          name="discardManualInputsAndActions"
          title={t('MapboxConflictDialogContent_Alert_DiscardManualEnvelopesAndActionPlans')}
          titleTooltip={tooltip}
          subtitle={t('MapboxConflictDialogContent_Alert_DiscardManualEnvelopesAndActionPlans_Subtitle')}
          icon={ICONS.APPROXIMATION}
          onChange={() => handleChange('discardManualInputsAndActions')}
        />
        <RHFRadioButton
          name="keepManualInputsAndActions"
          title={t('MapboxConflictDialogContent_Alert_KeepManualEnvelopesAndActionPlans')}
          subtitle={t('MapboxConflictDialogContent_Alert_KeepManualEnvelopesAndActionPlans_Subtitle')}
          icon={ICONS.CANCEL}
          onChange={() => handleChange('keepManualInputsAndActions')}
        />
      </Stack>
    ) : (
      <Stack>
        <Typography sx={{ mb: 2 }}>{t('MapboxConflictDialogContent_Alert_Subtitle')}</Typography>
        <RHFRadioButton
          name="discardManualInputsAndActions"
          title={t('MapboxConflictDialogContent_Alert_DiscardActionPlans')}
          titleTooltip={tooltip}
          subtitle={t('MapboxConflictDialogContent_Alert_DiscardActionPlans_Subtitle')}
          icon={ICONS.APPROXIMATION}
          onChange={() => handleChange('discardManualInputsAndActions')}
        />
        <RHFRadioButton
          name="keepManualInputsAndActions"
          title={t('MapboxConflictDialogContent_Alert_KeepActionPlans')}
          subtitle={t('MapboxConflictDialogContent_Alert_KeepActionPlans_Subtitle')}
          icon={ICONS.CANCEL}
          onChange={() => handleChange('keepManualInputsAndActions')}
        />
      </Stack>
    )
  ) : hasManualInputEnvelope ? (
    <Stack>
      <Typography sx={{ mb: 2 }}>{t('MapboxConflictDialogContent_Alert_Subtitle')}</Typography>
      <RHFRadioButton
        name="discardManualInputsAndActions"
        title={t('MapboxConflictDialogContent_Alert_DiscardManualEnvelopes')}
        titleTooltip={tooltip}
        subtitle={t('MapboxConflictDialogContent_Alert_DiscardManualEnvelopes_Subtitle')}
        icon={ICONS.APPROXIMATION}
        onChange={() => handleChange('discardManualInputsAndActions')}
      />
      <RHFRadioButton
        name="keepManualInputsAndActions"
        title={t('MapboxConflictDialogContent_Alert_KeepManualEnvelopes')}
        subtitle={t('MapboxConflictDialogContent_Alert_KeepManualEnvelopes_Subtitle')}
        icon={ICONS.CANCEL}
        onChange={() => handleChange('keepManualInputsAndActions')}
      />
    </Stack>
  ) : (
    <Typography>{t('MapboxConflictDialogContent_Alert_ConfirmReapproximation')}</Typography>
  );

  return (
    <FormProvider
      methods={methods}
      onSubmit={(event) => {
        event.stopPropagation();
        event.preventDefault();
        onConfirm(methods.getValues('discardManualInputsAndActions'));
      }}
    >
      <DialogContent sx={{ '& .MuiDialogTitle-root': { px: 0 }, minWidth: '512px' }}>
        <DialogTitle sx={{ textAlign: 'left' }}>{t('MapboxConflictDialogContent_Title')}</DialogTitle>
        <Alert sx={{ mb: 2 }} severity="warning">
          {alertText}
        </Alert>
        {content}
        <Stack direction="row" justifyContent="flex-end" gap={2} py={2}>
          <Button onClick={onCancel} variant="outlined">
            {t('General_Cancel')}
          </Button>
          <Button onClick={() => onConfirm(methods.getValues('discardManualInputsAndActions'))} variant="contained">
            {t('General_Confirm')}
          </Button>
        </Stack>
      </DialogContent>
    </FormProvider>
  );
};
