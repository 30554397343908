/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, DialogContent, DialogTitle, TableCell, Tooltip, useTheme } from '@mui/material';
import { efficiency_class_enum } from '@predium/client-graphql';
import { ensureDefined } from '@predium/utils';
import isNil from 'lodash/isNil';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import EfficiencyClassLabel from '../../../components/EfficiencyClassLabel';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { DataTable, DataTableColumns, QueryVariables } from '../../../components/data-table';
import { useDebounce } from '../../../components/data-table/debounce';
import { CREATE_ACTION_PLAN } from '../../../graphql/ActionPlanning.mutations';
import { GET_BUILDINGS, GET_SCENARIO } from '../../../graphql/ActionPlanning.queries';
import useTable from '../../../hooks/useTable';
import { PATHS } from '../../../routes';
import SearchTableToolbar from '../../SearchTableToolbar';

type Props = {
  scenario: {
    id: number;
    portfolio_id: number;
  };
  onClose: VoidFunction;
};

export default function CreateActionPlanModal({ onClose, scenario }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const theme = useTheme();

  const [selectedBuildingId, setSelectedBuildingId] = useState<number | null>(null);

  //QUERIES
  const [mutateFunction, { loading }] = useMutation(CREATE_ACTION_PLAN, {
    onError: () => {
      enqueueSnackbar(t('CreateActionPlanModal_CreateActionPlan-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
      onClose();
    },
    onCompleted: (data) => {
      enqueueSnackbar(t('CreateActionPlanModal_CreateActionPlan-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });

      navigate(
        PATHS.actionPlanning.actionPlan({ scenarioId: scenario.id, id: data.insert_action_plan!.returning[0].id }),
      );

      onClose();
    },
    refetchQueries: [
      { query: GET_BUILDINGS, variables: { portfolioId: scenario.portfolio_id, scenarioId: scenario.id } },
      { query: GET_SCENARIO, variables: { scenarioId: scenario.id } },
    ],
  });

  //Table content
  const { filterName, handleFilterByName } = useTable();

  const handleCreateActionPlan = () => {
    if (isNil(selectedBuildingId)) {
      throw new Error('Selected building id is null');
    }

    mutateFunction({
      variables: {
        building_id: selectedBuildingId,
        scenario_id: scenario.id,
      },
    });
  };

  const debouncedFilterName = useDebounce(filterName, 500);

  const extraVariables: QueryVariables<typeof GET_BUILDINGS> = useMemo(() => {
    return {
      year: new Date().getFullYear(),
      where: {
        _and: [
          { economic_unit: { portfolio_id: { _eq: scenario.portfolio_id } } },
          { _not: { action_plans: { scenario_id: { _eq: scenario.id } } } },
          ...(debouncedFilterName
            ? [
                {
                  _or: [
                    { address: { street: { _ilike: `%${debouncedFilterName}%` } } },
                    { address: { city: { _ilike: `%${debouncedFilterName}%` } } },
                    { address: { postal_code: { _ilike: `%${debouncedFilterName}%` } } },
                  ],
                },
              ]
            : []),
        ],
      },
    };
  }, [debouncedFilterName, scenario.id, scenario.portfolio_id]);

  const columns: DataTableColumns<typeof GET_BUILDINGS> = [
    {
      id: 'address.street',
      label: t('General_Building'),
      minWidth: 170,
    },
    {
      id: 'address.city',
      label: t('General_City'),
      minWidth: 140,
    },
    {
      id: 'address.postal_code',
      label: t('General_PostalCodeAbbreviated'),
      minWidth: 160,
    },
    {
      id: 'active_building_model.energy_paths[0].efficiency_class_id',
      label: t('General_EfficiencyClass'),
      minWidth: 180,
      sortable: false,
      renderCell: (row) => {
        return (
          <TableCell align="center">
            <EfficiencyClassLabel
              efficiencyClass={
                ensureDefined(row.active_building_model).energy_paths?.[0].efficiency_class_id ??
                efficiency_class_enum.NOT_APPLICABLE
              }
            />
          </TableCell>
        );
      },
    },
    {
      id: 'action',
      label: '',
      minWidth: 170,
      sortable: false,
      renderCell: (row) => {
        const actionPlanningAvailable = ensureDefined(row.active_building_model).action_planning_available;

        const isSelected = selectedBuildingId === row.id;

        return (
          <TableCell align="right" sx={{ minWidth: 170 }}>
            {isSelected ? (
              <Button size="small" variant="outlined" color="error" onClick={() => setSelectedBuildingId(null)}>
                {t('General_ResetSelection')}
              </Button>
            ) : (
              <Tooltip title={!actionPlanningAvailable ? t('General_InvalidBuildingData') : ''} placement="top">
                <Box component="span">
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => setSelectedBuildingId(row.id)}
                    disabled={!actionPlanningAvailable}
                  >
                    {t('General_Select')}
                  </Button>
                </Box>
              </Tooltip>
            )}
          </TableCell>
        );
      },
    },
  ];

  return (
    <>
      <DialogTitle>{t('General_NewActionPlan')}</DialogTitle>

      <DialogContent>
        <SearchTableToolbar
          numSelected={0}
          filterName={filterName}
          onFilterName={handleFilterByName}
          sx={{
            height: 'unset',
          }}
          style={{ padding: 0 }}
        />

        <DataTable
          columns={columns}
          query={GET_BUILDINGS}
          searchKeyword={filterName}
          initials={{
            sortBy: 'address.street',
            rowsPerPage: 5,
          }}
          extraVariables={extraVariables}
          slotProps={{
            tableContainer: {
              sx: {
                mt: 2,
                border: `1px solid ${theme.palette.grey[500_32]}`,
                borderRadius: '8px',
                width: 'auto',
              },
            },
            tableBody: {
              sx: { minHeight: '200px' },
            },
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t('General_Cancel')}
        </Button>
        <LoadingButton
          disabled={!selectedBuildingId}
          variant="contained"
          loading={loading}
          onClick={() => handleCreateActionPlan()}
        >
          {t('General_Create')}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
