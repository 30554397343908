/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, ListItemIcon, MenuItem, TextField } from '@mui/material';
import {
  BuildingModelFragment,
  EnvelopeRenovationParameterOutput,
  SimulateActionOutputResponseFragment,
  envelope_type_enum,
} from '@predium/client-graphql';
import { translateEnvelopeTypeEnum_insulation } from '@predium/i18n/client';
import { t } from 'i18next';
import { useState } from 'react';
import { getActionIcon } from '../../../../../utils/icons';
import { DropdownIcon, SelectSx, StyledListItemText } from '../../Components/ActionCustomStyles';
import { CurrentFormRef } from '../CreateAction';
import { EnvelopeActionEditProps } from '../EditAction';
import DoorAction from './EnvelopeActions/DoorAction';
import NonWindowDoorAction from './EnvelopeActions/NonWindowDoorAction';
import WindowAction from './EnvelopeActions/WindowAction';

type Props = {
  previousActionBuildingModel: BuildingModelFragment;
  formRef: CurrentFormRef;
  minimumDate: Date;
  setSimulatedData: (data: SimulateActionOutputResponseFragment) => void;
  resetSimulatedData: () => void;
  loading: boolean;
  parameters: EnvelopeRenovationParameterOutput;
};

export default function EnvelopeAction({
  previousActionBuildingModel,
  formRef,
  minimumDate,
  resetSimulatedData,
  loading,
  parameters,
}: Props) {
  const [envelopeType, setEnvelopeType] = useState<envelope_type_enum | ''>(parameters.envelope_type || '');

  const props: EnvelopeActionEditProps = {
    previousActionBuildingModel: previousActionBuildingModel,
    selectedActionType: envelopeType as envelope_type_enum,
    ref: formRef,
    minimumDate: minimumDate,
    resetSimulatedData: resetSimulatedData,
    loading: loading,
    parameters,
  };

  const envelopes = previousActionBuildingModel.envelope_units;
  const availableEnvelopeTypes = envelopes.map((e) => e.envelope_type_id);

  const handleActionChange = (envelopeType: envelope_type_enum) => {
    setEnvelopeType(envelopeType);
  };

  return (
    <>
      <TextField
        select
        value={envelopeType}
        label={t('General_Category')}
        onChange={(event) => handleActionChange(event.target.value as envelope_type_enum)}
        fullWidth
        sx={{ ...SelectSx, mt: 2, mb: 0 }}
        title={t('General_Category')}
        size="small"
        disabled={true}
        SelectProps={{ IconComponent: DropdownIcon }}
      >
        {Object.entries(envelope_type_enum).map(([key, value]) => (
          <MenuItem key={key} value={value} disabled={!availableEnvelopeTypes.includes(value as envelope_type_enum)}>
            <ListItemIcon style={{ minWidth: 18 }}>
              <Box component="img" src={getActionIcon(value)} />
            </ListItemIcon>
            <StyledListItemText action={translateEnvelopeTypeEnum_insulation(value as envelope_type_enum)} />
          </MenuItem>
        ))}
      </TextField>

      {(() => {
        if (envelopeType) {
          switch (envelopeType) {
            case envelope_type_enum.WINDOW:
              return <WindowAction {...props} />;
            case envelope_type_enum.DOOR:
              return <DoorAction {...props} />;
            case envelope_type_enum.WALL:
            case envelope_type_enum.FLAT_ROOF:
            case envelope_type_enum.PITCHED_ROOF:
            case envelope_type_enum.FLOOR:
            case envelope_type_enum.BASEMENT_CEILING:
            case envelope_type_enum.TOP_FLOOR_CEILING:
              return <NonWindowDoorAction {...props} />;

            default:
              const exhaustiveCheck = envelopeType;
              throw new Error(`Unhandled envelope type ${envelopeType}: ${exhaustiveCheck}`);
          }
        } else {
          return null;
        }
      })()}
    </>
  );
}
