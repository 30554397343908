import { country_enum, energy_source_type_enum } from '@predium/enums';

export const Co2TaxEmissionFactorProvider = {
  getEmissionFactor({
    energySourceType,
    year,
    country,
  }: {
    energySourceType: energy_source_type_enum;
    year: number;
    country: country_enum;
  }): number {
    const CO2TAX_lookup: Record<country_enum, Record<energy_source_type_enum, number>> = {
      [country_enum.DE]: {
        [energy_source_type_enum.FUEL_OIL]: 0.2664,
        [energy_source_type_enum.NATURAL_GAS]: year < 2023 ? 0.2016 : 0.20088,
        [energy_source_type_enum.LPG]: year < 2023 ? 0.23868 : 0.2358,
        [energy_source_type_enum.COAL]: 0.3571,
        [energy_source_type_enum.LIGNITE]: 0.3571,

        [energy_source_type_enum.WOODEN_PELLETS]: 0,
        [energy_source_type_enum.WOOD]: 0,
        [energy_source_type_enum.UNSPECIFIED]: 0,
        [energy_source_type_enum.ELECTRICITY_MIX]: 0,
        [energy_source_type_enum.ELECTRICITY_GREEN]: 0,
        [energy_source_type_enum.SOLAR]: 0,
        [energy_source_type_enum.BIO_GAS]: 0,

        [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 0.254 * Math.pow(0.984, year - 2020),
        [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 0.254 * Math.pow(0.984, year - 2020),
        [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: 0.06,

        [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.254 * Math.pow(0.984, year - 2020),
        [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.254 * Math.pow(0.984, year - 2020),
        [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: 0.04,
      },

      //TODO: MR change numbers
      [country_enum.AT]: {
        [energy_source_type_enum.FUEL_OIL]: 0.2664,
        [energy_source_type_enum.NATURAL_GAS]: year < 2023 ? 0.2016 : 0.20088,
        [energy_source_type_enum.LPG]: year < 2023 ? 0.23868 : 0.2358,
        [energy_source_type_enum.COAL]: 0.3571,
        [energy_source_type_enum.LIGNITE]: 0.3571,

        [energy_source_type_enum.WOODEN_PELLETS]: 0,
        [energy_source_type_enum.WOOD]: 0,
        [energy_source_type_enum.UNSPECIFIED]: 0,
        [energy_source_type_enum.ELECTRICITY_MIX]: 0,
        [energy_source_type_enum.ELECTRICITY_GREEN]: 0,
        [energy_source_type_enum.SOLAR]: 0,
        [energy_source_type_enum.BIO_GAS]: 0,

        [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 0.254 * Math.pow(0.984, year - 2020),
        [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 0.254 * Math.pow(0.984, year - 2020),
        [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: 0.06,

        [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.254 * Math.pow(0.984, year - 2020),
        [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.254 * Math.pow(0.984, year - 2020),
        [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: 0.04,
      },
      [country_enum.PL]: {
        [energy_source_type_enum.FUEL_OIL]: 0.2664,
        [energy_source_type_enum.NATURAL_GAS]: year < 2023 ? 0.2016 : 0.20088,
        [energy_source_type_enum.LPG]: year < 2023 ? 0.23868 : 0.2358,
        [energy_source_type_enum.COAL]: 0.3571,
        [energy_source_type_enum.LIGNITE]: 0.3571,

        [energy_source_type_enum.WOODEN_PELLETS]: 0,
        [energy_source_type_enum.WOOD]: 0,
        [energy_source_type_enum.UNSPECIFIED]: 0,
        [energy_source_type_enum.ELECTRICITY_MIX]: 0,
        [energy_source_type_enum.ELECTRICITY_GREEN]: 0,
        [energy_source_type_enum.SOLAR]: 0,
        [energy_source_type_enum.BIO_GAS]: 0,

        [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 0.254 * Math.pow(0.984, year - 2020),
        [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 0.254 * Math.pow(0.984, year - 2020),
        [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: 0.06,

        [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.254 * Math.pow(0.984, year - 2020),
        [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.254 * Math.pow(0.984, year - 2020),
        [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: 0.04,
      },
    };

    return CO2TAX_lookup[country][energySourceType];
  },
};
