import { PaperProps, TableCell, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

interface Props extends PaperProps {
  searchQuery?: string;
  colSpan?: number;
}

/**
 * Minimal@4 component.
 */
function SearchNotFound({ searchQuery = '', sx, ...other }: Props) {
  const { t } = useTranslation();

  return searchQuery ? (
    <Paper
      sx={{
        textAlign: 'center',
        ...sx,
      }}
      {...other}
    >
      <Typography variant="h6" paragraph>
        {t('SearchNotFound_Title')}
      </Typography>
      <Typography variant="body2" align="center">
        <Trans
          i18nKey="SearchNotFound_NoResults"
          values={{ query: searchQuery }}
          components={{
            bold: <strong />,
          }}
        />
      </Typography>
    </Paper>
  ) : (
    <Typography variant="body2">{t('SearchNotFound_EnterKeywords')}</Typography>
  );
}

export default function SearchNotFoundRow(searchNotFoundProps: Props) {
  return (
    <TableRow>
      <TableCell
        align="center"
        colSpan={searchNotFoundProps.colSpan ?? 6}
        sx={{ textAlign: 'center', verticalAlign: 'middle', height: 400 }}
      >
        <SearchNotFound {...searchNotFoundProps} />
      </TableCell>
    </TableRow>
  );
}
