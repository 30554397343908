/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Container } from '@mui/material';
import { EsgAnalysisBuildingBasicDetailsFragment } from '@predium/client-graphql';
import { SUPPORTED_YEARS } from '@predium/client-lookup';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../../components/Page';
import usePosthogTracking from '../../hooks/usePosthogTracking';
import EsgBuildingHeader from '../../sections/EsgAnalysis/Building/BuildingHeader';
import EsgBuildingBody from '../../sections/EsgAnalysis/Building/EsgBuildingBody';
import { BenchmarkType, ENTIRE_COMPANY } from '../../sections/EsgAnalysis/Components/EsgAnalysisBenchmark';
import { FloorAreaBySubBuildingClass } from '../../sections/EsgAnalysis/EsgAnalysisUtil';
import { EsgAnalysisTab } from '../../sections/EsgAnalysis/Portfolio/PortfolioAnalysisHeader';

export default function EsgAnalysisBuilding() {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();

  const [currentTab, setCurrentTab] = useState<EsgAnalysisTab>('demand');
  const [floorAreaByBuildingClass, setFloorAreaByBuildingClass] = useState<FloorAreaBySubBuildingClass>([]);
  const [showTotal, setShowTotal] = useState(false);
  const [selectedReportingYear, setSelectedReportingYear] = useState(SUPPORTED_YEARS[SUPPORTED_YEARS.length - 1]);
  const [selectedBuilding, setSelectedBuilding] = useState<EsgAnalysisBuildingBasicDetailsFragment>();
  const [selectedBenchmark, setSelectedBenchmark] = useState<BenchmarkType>({
    id: ENTIRE_COMPANY,
    name: t('EsgAnalysisBenchmark_EntireCompany'),
  });

  const handleTabChange = (tab: EsgAnalysisTab) => {
    setCurrentTab(tab);

    switch (true) {
      case tab === 'demand' && selectedBenchmark?.id === undefined:
        setSelectedBenchmark({
          id: ENTIRE_COMPANY,
          name: t('EsgAnalysisBenchmark_EntireCompany'),
        });

        break;
      case tab === 'consumption':
        setSelectedBenchmark({
          id: undefined,
          name: '',
        });
        trackEvent('BUILDING_ESG_ANALYSIS_TAB_CHANGED', {
          display_base: tab,
        });
        break;
    }
  };

  return (
    <Page title={t('General_Building')}>
      {
        //minimum height is needed to page doesn't jump when switching tabs
      }
      <Container sx={{ mb: 1, pb: 30, minHeight: '100vh' }} maxWidth="lg">
        <EsgBuildingHeader
          currentTab={currentTab}
          setCurrentTab={handleTabChange}
          floorAreaByBuildingClass={floorAreaByBuildingClass}
          setShowTotal={setShowTotal}
          setSelectedReportingYear={setSelectedReportingYear}
          selectedReportingYear={selectedReportingYear}
          selectedBuilding={selectedBuilding}
          setSelectedBenchmark={setSelectedBenchmark}
          setSelectedBuilding={setSelectedBuilding}
        />
        {selectedBuilding && (
          <EsgBuildingBody
            selectedBuilding={selectedBuilding}
            currentTab={currentTab}
            setFloorAreaByBuildingClass={setFloorAreaByBuildingClass}
            selectedReportingYear={selectedReportingYear}
            showTotal={showTotal}
            benchmarkId={selectedBenchmark?.id}
            selectedBenchmarkName={selectedBenchmark?.name ?? ''}
          />
        )}
      </Container>
    </Page>
  );
}
