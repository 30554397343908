import { Box, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BenchmarkLabel from '../../Components/BenchmarkLabel';
import { EnergyPathGraph } from '../../Visualization/EsgPathGraphs/EnergyPathGraph';

type Props = {
  energyPath: number[];
  energyTargetPath: number[];
  buildingLegendName: string;
  yearOfExcedance?: number;
  graphStartYear: number;
  benchmark: {
    selectedBenchmarkName: string;
    yearOfExcedanceChange: number | null;
  };
};

export function EnergyCrremAnalysis({
  energyPath,
  energyTargetPath,
  buildingLegendName,
  yearOfExcedance,
  graphStartYear,
  benchmark: { selectedBenchmarkName, yearOfExcedanceChange },
}: Props) {
  const { t } = useTranslation();

  const isDataAvailable = energyPath && energyPath.length > 0;

  return (
    <Grid container>
      <Grid container>
        <Grid item xs={12} md={2} lg={2}>
          <Box>
            <Box>
              <Typography variant="subtitle1" sx={{ mr: 1 }}>
                {t('General_YearOfExcedance')}
              </Typography>
              <Typography variant="h3" sx={{ mr: 1 }}>
                {yearOfExcedance && isDataAvailable ? yearOfExcedance : '-'}
              </Typography>
              {isDataAvailable && selectedBenchmarkName && (
                <BenchmarkLabel
                  change={yearOfExcedanceChange}
                  selectedBenchmarkName={selectedBenchmarkName}
                  unit="a"
                  inverse
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={10} lg={10}>
          <Box pl={3}>
            <Stack pl={2}>
              <Stack direction={'row'} alignItems={'center'}>
                <Typography variant="subtitle1" sx={{ mr: 1 }}>
                  {t('General_EnergyPath')}
                </Typography>
                <Typography variant="caption">{t('EsgAnalysis_CrremAnalysis-EnergyGraphCaption')}</Typography>
              </Stack>

              <Typography variant="caption">kWh/m²a</Typography>
            </Stack>

            <EnergyPathGraph
              energyPath={energyPath}
              energyTargetPath={energyTargetPath}
              buildingLegendName={buildingLegendName}
              graphStartYear={graphStartYear}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
