import { Stack, Typography } from '@mui/material';
import { eu_taxonomy_compliance_enum } from '@predium/enums';
import React from 'react';
import Iconify from '../../../components/Iconify';
import EUTaxonomyTooltip, { euTaxonomyColors } from './EUTaxonomyTooltip';

interface EUTaxonomyBadgeProps {
  compliance: eu_taxonomy_compliance_enum;
  toBeSaved: number;
}

const EUTaxonomyBadge: React.FC<EUTaxonomyBadgeProps> = ({ compliance, toBeSaved }) => {
  return (
    <EUTaxonomyTooltip compliance={compliance} toBeSaved={toBeSaved}>
      <Stack
        ml={1}
        sx={{
          width: 32,
          height: 32,
          borderRadius: '50%',
          backgroundColor: euTaxonomyColors.complianceColor[compliance],
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
          <Typography variant="body1">EU</Typography>
        </Stack>

        <Iconify
          icon={euTaxonomyColors.icon[compliance]}
          width={18}
          height={18}
          color={euTaxonomyColors.iconColor[compliance]}
          sx={{
            borderColor: 'background.paper',
            borderRadius: '50%',
            position: 'absolute',
            bottom: -6,
            right: -6,
          }}
        />
      </Stack>
    </EUTaxonomyTooltip>
  );
};

export default EUTaxonomyBadge;
