import { darken, lighten } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { subsidy_type_enum } from '@predium/client-graphql';
import {
  climate_risk_type_enum,
  consumption_type_enum,
  efficiency_class_enum,
  energy_source_type_enum,
  eu_taxonomy_compliance_enum,
  sub_building_class_enum,
  tax_bracket_enum,
} from '@predium/enums';

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

interface ChartPaletteOptions {
  violet: string[];
  blue: string[];
  green: string[];
  yellow: string[];
  red: string[];
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
    energyEfficiencyClassColors: { [key in efficiency_class_enum]: PaletteColor };
    climateRiskColors: { [key in climate_risk_type_enum]: PaletteColor };
    subBuildingClassColors: { [key in sub_building_class_enum]: PaletteColor };
    taxBracketColors: { [key in tax_bracket_enum]: PaletteColor };
    energySourceTypeColors: { [key in energy_source_type_enum]: PaletteColor };
    euTaxonomyColors: { [key in eu_taxonomy_compliance_enum]: PaletteColor };
    subsidyTypeColors: { [key in subsidy_type_enum]: PaletteColor };
    consumptionColors: { [key in consumption_type_enum]: PaletteColor };
    areaColors: {
      areas: {
        bgColor: string;
        textColor: string;
      }[];
    };
    link: string;
    excessEmissionCostColors: {
      cost: string;
      value: string;
    };
  }
  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
}

declare module '@mui/material' {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#C8FACD',
  light: '#5BE584',
  main: '#000000',
  dark: '#555555',
  darker: '#005249',
};
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
};
const INFO = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
};
const SUCCESS = {
  lighter: '#D3FCD2',
  light: '#77ED8B',
  main: '#22C55E',
  dark: '#118D57',
  darker: '#065E49',
};
const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
};
const ERROR = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
};

type ShadedColor = {
  lighter: string;
  light: string;
  main: string;
  dark: string;
  darker: string;
};

const lighterCoefficient = 0.6;
const lightCoefficient = 0.4;
const darkCoefficient = 0.3;
const darkerCoefficient = 0.5;

const createShadedColor = (color: string): ShadedColor => ({
  lighter: lighten(color, lighterCoefficient),
  light: lighten(color, lightCoefficient),
  main: color,
  dark: darken(color, darkCoefficient),
  darker: darken(color, darkerCoefficient),
});

const energyEfficiencyClassColors: Record<efficiency_class_enum, ShadedColor> = {
  [efficiency_class_enum.AAA]: createShadedColor('#0C7FDD'),
  [efficiency_class_enum.AA]: createShadedColor('#23A6DE'),
  [efficiency_class_enum.A]: createShadedColor('#80C457'),
  [efficiency_class_enum.B]: createShadedColor('#B3D54C'),
  [efficiency_class_enum.C]: createShadedColor('#E1DD37'),
  [efficiency_class_enum.D]: createShadedColor('#F8DB22'),
  [efficiency_class_enum.E]: createShadedColor('#F9BA2F'),
  [efficiency_class_enum.F]: createShadedColor('#F57E35'),
  [efficiency_class_enum.G]: createShadedColor('#F03C2D'),
  [efficiency_class_enum.H]: createShadedColor('#A81834'),
  [efficiency_class_enum.UNKNOWN]: createShadedColor('#C4CDD5'),
  [efficiency_class_enum.NOT_APPLICABLE]: createShadedColor('#C4CDD5'),
};

const climateRiskColors: Record<climate_risk_type_enum, ShadedColor> = {
  [climate_risk_type_enum.EARTHQUAKE]: createShadedColor('#DEA876'),
  [climate_risk_type_enum.FOREST_FIRE]: createShadedColor('#E86A59'),
  [climate_risk_type_enum.HAIL]: createShadedColor('#739BCB'),
  [climate_risk_type_enum.HEAT]: createShadedColor('#D90000'),
  [climate_risk_type_enum.HEAVY_RAIN]: createShadedColor('#659FCA'),
  [climate_risk_type_enum.LIGHTNING_STRIKE]: createShadedColor('#F4F07C'),
  [climate_risk_type_enum.SNOW_LOAD]: createShadedColor('#3B6595'),
  [climate_risk_type_enum.WINTER_STORM]: createShadedColor('#78B0F2'),
};

const subBuildingClassColors: Record<sub_building_class_enum, { main: string; light: string }> = {
  [sub_building_class_enum.COMMERCIAL]: { main: '#22609B', light: '#22609B' },
  [sub_building_class_enum.RESIDENTIAL]: { main: '#A7C0D7', light: '#A7C0D7' },
};

const taxBracketColors: Record<tax_bracket_enum, ShadedColor> = {
  [tax_bracket_enum._10_90]: createShadedColor('#A81834'),
  [tax_bracket_enum._20_80]: createShadedColor('#F03C2D'),
  [tax_bracket_enum._30_70]: createShadedColor('#F57E35'),
  [tax_bracket_enum._40_60]: createShadedColor('#F9BA2F'),
  [tax_bracket_enum._50_50]: createShadedColor('#F8DB22'),
  [tax_bracket_enum._60_40]: createShadedColor('#E1DD37'),
  [tax_bracket_enum._70_30]: createShadedColor('#B3D54C'),
  [tax_bracket_enum._80_20]: createShadedColor('#80C457'),
  [tax_bracket_enum._90_10]: createShadedColor('#5FBA5A'),
  [tax_bracket_enum._100_0]: createShadedColor('#3A7B3A'),
};

const energySourceTypeColors: Record<energy_source_type_enum, ShadedColor> = {
  [energy_source_type_enum.COAL]: createShadedColor('#000'),
  [energy_source_type_enum.ELECTRICITY_GREEN]: createShadedColor('#B3D54C'),
  [energy_source_type_enum.ELECTRICITY_MIX]: createShadedColor('#E1C300'),
  [energy_source_type_enum.FUEL_OIL]: createShadedColor('#525252'),
  [energy_source_type_enum.LIGNITE]: createShadedColor('#692C00'),
  [energy_source_type_enum.LPG]: createShadedColor('#C2DCDC'),
  [energy_source_type_enum.NATURAL_GAS]: createShadedColor('#22609B'),
  [energy_source_type_enum.SOLAR]: createShadedColor('#FFDF39'),
  [energy_source_type_enum.WOOD]: createShadedColor('#A81834'),
  [energy_source_type_enum.WOODEN_PELLETS]: createShadedColor('#F03C2D'),
  [energy_source_type_enum.UNSPECIFIED]: createShadedColor('#C4CDD5'),
  [energy_source_type_enum.BIO_GAS]: createShadedColor('#60F1C8'),
  [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: createShadedColor('#A7C0D7'),
  [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: createShadedColor('#FFBD98'),
  [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: createShadedColor('#FFBD98'),
  [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: createShadedColor('#FFBD98'),
  [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: createShadedColor('#FFBD98'),
  [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: createShadedColor('#FFBD98'),
};

const euTaxonomyColors: Record<eu_taxonomy_compliance_enum, ShadedColor> = {
  [eu_taxonomy_compliance_enum.COMPLIANT]: createShadedColor('#80C457'),
  [eu_taxonomy_compliance_enum.MEETS_DNSH_CRITERIA]: createShadedColor('#E1C300'),
  [eu_taxonomy_compliance_enum.NON_COMPLIANT]: createShadedColor('#F03C2D'),
  [eu_taxonomy_compliance_enum.UNKNOWN]: createShadedColor('#637381'),
};

const subsidyTypeColors: Record<subsidy_type_enum, ShadedColor> = {
  [subsidy_type_enum.GRANT]: createShadedColor('#1890FF'),
  [subsidy_type_enum.LOAN]: createShadedColor('#7B61FF'),
  [subsidy_type_enum.BOND]: createShadedColor('#B76E00'),
};

const consumptionColors = {
  [consumption_type_enum.ELECTRICITY]: createShadedColor('#F8DB22'),
  [consumption_type_enum.HEATING]: createShadedColor('#F57E35'),
  [consumption_type_enum.WATER]: createShadedColor('#23A6DE'),
  [consumption_type_enum.WASTE]: createShadedColor('#7C5D17'),
};

const excessEmissionCostColors = {
  cost: '#F48B84',
  value: '#A2D5A1',
};

const areaColors = {
  areas: [
    { bgColor: '#A7B0F1', textColor: '#283EE7' },
    { bgColor: '#EDC3F9', textColor: '#8E33FF' },
  ],
};

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  link: '#1A73E8',
  energyEfficiencyClassColors,
  climateRiskColors,
  subBuildingClassColors,
  taxBracketColors,
  energySourceTypeColors,
  euTaxonomyColors,
  subsidyTypeColors,
  consumptionColors,
  excessEmissionCostColors,
  areaColors,
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action },
  },
} as const;

export default palette;
