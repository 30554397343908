/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DataCollectionEnergyCertificateDraftEnergyCertificateDraftFragment,
  DataCollectionEnergyCertificateDraftSaveEnergyCertificateDraftMutation,
  DataCollectionEnergyCertificateDraftUserFragment,
  PortfolioManagementPortfolioFragment,
  energy_certificate_consumer_insert_input,
} from '@predium/client-graphql';
import {
  building_state_enum,
  country_enum,
  country_state_enum,
  draft_status_enum,
  energy_certificate_type_enum,
  energy_source_type_enum,
  energy_system_type_enum,
  sub_building_class_enum,
  type_of_use_enum,
} from '@predium/enums';
import { useSnackbar } from 'notistack';
import { UseFormGetValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import { FormProvider } from '../../../../components/hook-form';
import { SAVE_ENERGY_CERTIFICATE_DRAFT } from '../../../../graphql/DataCollection.mutations';
import useOrgPreferences from '../../../../hooks/useOrgPreferences';
import { getPostalCodeValidation } from '../../../../utils/formUtils';

const YEAR_CONSTRUCTED_MIN = 1500;
const YEAR_CONSTRUCTED_MAX = new Date().getFullYear();

type DataCollectionDraftFormBasicData = {
  street: string;
  city: string;
  postal_code: string;
  country_id: country_enum;
  country_state_id: country_state_enum;
  building_state_id: building_state_enum;
  portfolio_id: number;
  monument_protection: boolean;
  leasehold: boolean;
  heritage_district: boolean;
  milieu_protection: boolean;
  customer_external_identifier: string | undefined | null;
  economic_unit_id: undefined | number;
  building_id: number | undefined;
  responsible_user_id: number;
  year_constructed: number;
  area_usable: number;
  sub_building_class_id: sub_building_class_enum;
  type_of_use_id: type_of_use_enum;
  expiry_date: string;
  units: number;
};

export const DATA_COLLECTION_DRAFT_FORM_BASIC_DATA_KEYS: (keyof DataCollectionDraftFormBasicData)[] = [
  'customer_external_identifier',
  'area_usable',
  'city',
  'expiry_date',
  'portfolio_id',
  'postal_code',
  'responsible_user_id',
  'street',
  'country_id',
  'country_state_id',
  'sub_building_class_id',
  'building_state_id',
  'type_of_use_id',
  'units',
  'year_constructed',
  'monument_protection',
  'leasehold',
  'heritage_district',
  'milieu_protection',
];

type DataCollectionDraftFormEnergyData = {
  energy_certificate_type_id: energy_certificate_type_enum;
  energy_certificate_consumers: {
    energy_system_type_id: energy_system_type_enum;
    energy_source_type_id: energy_source_type_enum;
    energy_final: number;
  }[];
};

export const DATA_COLLECTION_DRAFT_FORM_ENERGY_DATA_KEYS: (keyof DataCollectionDraftFormEnergyData)[] = [
  'energy_certificate_type_id',
  'energy_certificate_consumers',
];

export type EnergyCertificateDraftFormValueProps = DataCollectionDraftFormBasicData & DataCollectionDraftFormEnergyData;

type Props = {
  cleanEnergyCertificateDraft: DataCollectionEnergyCertificateDraftEnergyCertificateDraftFragment;
  portfolios: PortfolioManagementPortfolioFragment[];
  users: DataCollectionEnergyCertificateDraftUserFragment[];
  energyCertificateDraftId: number;
  setEnergyCertificateDraftName: (name: string) => void;
  setCreateEnergyCertificateDraftModalOpen: (open: boolean) => void;
  renderChildren: (
    submitForm: () => Promise<DataCollectionEnergyCertificateDraftSaveEnergyCertificateDraftMutation>,
    getValues: UseFormGetValues<EnergyCertificateDraftFormValueProps>,
  ) => JSX.Element;
};

function EnergyCertificateDraftForm({
  cleanEnergyCertificateDraft,
  portfolios,
  users,
  energyCertificateDraftId,
  setEnergyCertificateDraftName,
  setCreateEnergyCertificateDraftModalOpen,
  renderChildren,
}: Props) {
  const { t } = useTranslation();
  const { economicUnitsToggled } = useOrgPreferences();
  const { enqueueSnackbar } = useSnackbar();

  const [saveEnergyCertificateDraft] = useMutation(SAVE_ENERGY_CERTIFICATE_DRAFT, {
    onError: () => {
      enqueueSnackbar(t('DataCollection_DraftForm_BuildingCreateError'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    onCompleted: () => {
      //@ts-ignore
      setEnergyCertificateDraftName(street);
      enqueueSnackbar(t('General_ChangesSaved'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
  });

  const BUILDING_MESSAGE = t('DataCollection_DraftForm_BuildingMessage');
  const CITY_MESSAGE = t('DataCollection_DraftForm_CityMessage');
  const BUILDING_STATE_MESSAGE = t('DataCollection_DraftForm_BuildingStateMessage');
  const PORTFOLIO_ID_MESSAGE = t('DataCollection_PortfolioIsRequired');
  const RESPONSIBLE_USER_ID_MESSAGE = t('DataCollection_DraftForm_ResponsibleUserIdMessage');
  const YEAR_CONSTRUCTED_MESSAGE = t('DataCollection_DraftForm_YearConstructedMessage', {
    min: YEAR_CONSTRUCTED_MIN,
    max: YEAR_CONSTRUCTED_MAX,
  });
  const ENERGY_REFERENCE_AREA_MESSAGE = t('DataCollection_DraftForm_EnergyReferenceAreaMessage');
  const SUB_BUILDING_CLASS_MESSAGE = t('DataCollection_DraftForm_SubBuildingClassMessage');
  const TYPE_OF_USE_MESSAGE = t('DataCollection_DraftForm_TypeOfUseMessage');
  const ENERGY_CERTIFICATE_TYPE_MESSAGE = t('DataCollection_DraftForm_EnergyCertificateTypeMessage');
  const EXPIRY_DATE_MESSAGE = t('DataCollection_DraftForm_ExpiryDateMessage');
  const UNITS_MESSAGE = t('DataCollection_DraftForm_UnitsMessage');
  const ENERGY_SYSTEM_MESSAGE = t('DataCollection_DraftForm_EnergyConsumerMessage');
  const ENERGY_SOURCE_MESSAGE = t('DataCollection_DraftForm_EnergySourceMessage');
  const ENERGY_FINAL_MESSAGE = t('DataCollection_DraftForm_EnergyFinalMessage');
  const ENERGY_CONSUMER_HEATING_MESSAGE = t('DataCollection_DraftForm_EnergyConsumerHeatingMessage');
  const ENERGY_FINAL_MIN_MESSAGE = t('DataCollection_DraftForm_EnergyFinalMinMessage');
  const COUNTRY_MESSAGE = t('DataCollection_DraftForm_CountryMessage');
  const COUNTRY_STATE_MESSAGE = t('DataCollection_DraftForm_CountryStateMessage');

  const BasicDataSchema = Yup.object().shape({
    street: Yup.string().typeError(BUILDING_MESSAGE).required(BUILDING_MESSAGE),
    city: Yup.string().typeError(CITY_MESSAGE).required(CITY_MESSAGE),
    postal_code: Yup.string().when('country_id', getPostalCodeValidation(t)),
    building_state_id: Yup.string()
      .typeError(BUILDING_STATE_MESSAGE)
      .oneOf(Object.keys(building_state_enum), BUILDING_STATE_MESSAGE)
      .required(BUILDING_STATE_MESSAGE),
    portfolio_id: Yup.number()
      .typeError(PORTFOLIO_ID_MESSAGE)
      .integer(PORTFOLIO_ID_MESSAGE)
      .oneOf(
        portfolios.map((portfolio) => portfolio.id),
        PORTFOLIO_ID_MESSAGE,
      )
      .required(PORTFOLIO_ID_MESSAGE),
    responsible_user_id: Yup.number()
      .typeError(RESPONSIBLE_USER_ID_MESSAGE)
      .integer(RESPONSIBLE_USER_ID_MESSAGE)
      .oneOf(
        users.map((user) => user.id),
        RESPONSIBLE_USER_ID_MESSAGE,
      )
      .required(RESPONSIBLE_USER_ID_MESSAGE),
    monument_protection: Yup.boolean(),
    customer_external_identifier: Yup.string(),
    leasehold: Yup.boolean(),
    heritage_district: Yup.boolean(),
    milieu_protection: Yup.boolean(),
    year_constructed: Yup.number()
      .typeError(YEAR_CONSTRUCTED_MESSAGE)
      .integer(YEAR_CONSTRUCTED_MESSAGE)
      .min(YEAR_CONSTRUCTED_MIN, YEAR_CONSTRUCTED_MESSAGE)
      .max(YEAR_CONSTRUCTED_MAX, YEAR_CONSTRUCTED_MESSAGE)
      .required(YEAR_CONSTRUCTED_MESSAGE),
    area_usable: Yup.number()
      .typeError(ENERGY_REFERENCE_AREA_MESSAGE)
      .moreThan(0, ENERGY_REFERENCE_AREA_MESSAGE)
      .required(ENERGY_REFERENCE_AREA_MESSAGE),
    sub_building_class_id: Yup.string()
      .typeError(SUB_BUILDING_CLASS_MESSAGE)
      .oneOf(Object.keys(sub_building_class_enum), SUB_BUILDING_CLASS_MESSAGE)
      .required(SUB_BUILDING_CLASS_MESSAGE),
    type_of_use_id: Yup.string()
      .typeError(TYPE_OF_USE_MESSAGE)
      .oneOf(Object.keys(type_of_use_enum), TYPE_OF_USE_MESSAGE)
      .required(TYPE_OF_USE_MESSAGE),
    expiry_date: Yup.date()
      .typeError(EXPIRY_DATE_MESSAGE)
      .min('01/01/1900', EXPIRY_DATE_MESSAGE)
      .required(EXPIRY_DATE_MESSAGE),
    units: Yup.number()
      .integer(UNITS_MESSAGE)
      .typeError(UNITS_MESSAGE)
      .moreThan(0, UNITS_MESSAGE)
      .required(UNITS_MESSAGE),
    country_id: Yup.string()
      .typeError(COUNTRY_MESSAGE)
      .oneOf(Object.keys(country_enum), COUNTRY_MESSAGE)
      .required(COUNTRY_MESSAGE),
    country_state_id: Yup.string()
      .typeError(COUNTRY_STATE_MESSAGE)
      .oneOf(
        Object.keys(country_state_enum).filter((state) => state !== country_state_enum.UNKNOWN),
        COUNTRY_STATE_MESSAGE,
      ) //TODO PRE-2000: remove unknown from enum
      .required(COUNTRY_STATE_MESSAGE),
  });

  const EnergyDataSchema = Yup.object().shape({
    energy_certificate_type_id: Yup.string()
      .typeError(ENERGY_CERTIFICATE_TYPE_MESSAGE)
      .oneOf(Object.keys(energy_certificate_type_enum), ENERGY_CERTIFICATE_TYPE_MESSAGE)
      .required(ENERGY_CERTIFICATE_TYPE_MESSAGE),
    energy_certificate_consumers: Yup.array(
      Yup.object().shape({
        energy_system_type_id: Yup.string()
          .typeError(ENERGY_SYSTEM_MESSAGE)
          .oneOf(Object.keys(energy_system_type_enum), ENERGY_SYSTEM_MESSAGE)
          .required(ENERGY_SYSTEM_MESSAGE),
        energy_source_type_id: Yup.string()
          .typeError(ENERGY_SOURCE_MESSAGE)
          .oneOf(Object.keys(energy_source_type_enum), ENERGY_SOURCE_MESSAGE)
          .required(ENERGY_SOURCE_MESSAGE),
        energy_final: Yup.number()
          .typeError(ENERGY_FINAL_MESSAGE)
          .moreThan(0, ENERGY_FINAL_MIN_MESSAGE)
          .typeError(ENERGY_FINAL_MESSAGE)
          .required(ENERGY_FINAL_MESSAGE),
      }),
      // At least on consumer of "Heizung" must be defined
    ).test({
      test: function (energyCertificateConsumers) {
        //@ts-ignore
        return energyCertificateConsumers.some(
          (item) => item.energy_system_type_id === energy_system_type_enum.HEATING,
        );
      },
      message: ENERGY_CONSUMER_HEATING_MESSAGE,
    }),
  });

  const DataSchema = BasicDataSchema.concat(EnergyDataSchema);

  const {
    energy_certificate_consumers,
    area_usable,
    city,
    country_state_id,
    country_id,
    economic_unit_id,
    energy_certificate_type_id,
    expiry_date,
    portfolio_id,
    postal_code,
    responsible_user_id,
    street,
    sub_building_class_id,
    building_state_id,
    type_of_use_id,
    units,
    year_constructed,
    monument_protection,
    leasehold,
    heritage_district,
    milieu_protection,
    customer_external_identifier,
  } = cleanEnergyCertificateDraft;

  const methods = useForm<EnergyCertificateDraftFormValueProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(DataSchema),
    shouldFocusError: false,
    defaultValues: {
      street: street ?? '',
      city: city ?? '',
      postal_code: postal_code ?? '',
      country_state_id: country_state_id ?? ('' as unknown as country_state_enum),
      country_id: country_id as country_enum,
      building_state_id: building_state_id ?? undefined,
      portfolio_id: portfolio_id ?? ('' as unknown as number), // mandatory in db
      economic_unit_id: economic_unit_id ?? ('' as unknown as number),
      responsible_user_id: responsible_user_id ?? ('' as unknown as number),
      year_constructed: year_constructed ?? undefined,
      area_usable: area_usable ?? undefined,
      sub_building_class_id: sub_building_class_id ?? undefined,
      type_of_use_id: type_of_use_id ?? ('' as unknown as type_of_use_enum),
      energy_certificate_type_id: energy_certificate_type_id ?? undefined,
      expiry_date: expiry_date ?? undefined,
      units: units ?? undefined,
      monument_protection: monument_protection ?? false,
      leasehold: leasehold ?? false,
      heritage_district: heritage_district ?? false,
      milieu_protection: milieu_protection ?? false,
      customer_external_identifier: customer_external_identifier ?? undefined,
      energy_certificate_consumers: energy_certificate_consumers.map((energyCertificateConsumer) => ({
        energy_system_type_id: energyCertificateConsumer.energy_system_type_id ?? ('' as energy_system_type_enum),
        energy_source_type_id: energyCertificateConsumer.energy_source_type_id ?? ('' as energy_source_type_enum),
        energy_final: energyCertificateConsumer.energy_final ?? undefined,
      })),
    },
  });

  const {
    handleSubmit,
    getValues,
    formState: { isValid, isDirty },
    reset,
  } = methods;

  const saveForm = async (data: EnergyCertificateDraftFormValueProps) => {
    const {
      economic_unit_id,
      area_usable,
      city,
      country_state_id,
      country_id,
      energy_certificate_type_id,
      energy_certificate_consumers,
      expiry_date,
      portfolio_id,
      postal_code,
      responsible_user_id,
      street,
      sub_building_class_id,
      building_state_id,
      type_of_use_id,
      units,
      year_constructed,
      monument_protection,
      leasehold,
      heritage_district,
      milieu_protection,
      customer_external_identifier,
    } = data;

    const newDraftStatus = isValid ? draft_status_enum.COMPLETE : draft_status_enum.INCOMPLETE;

    const ecoUnitId = (economic_unit_id as unknown as string) !== '' && economicUnitsToggled ? economic_unit_id : null;

    const { data: mutationResult } = await saveEnergyCertificateDraft({
      variables: {
        customer_external_identifier,
        energy_certificate_draft_id: energyCertificateDraftId,
        area_usable,
        city,
        energy_certificate_type_id,
        expiry_date,
        responsible_user_id: (responsible_user_id as unknown as string) !== '' ? responsible_user_id : undefined,
        street,
        sub_building_class_id,
        building_state_id,
        type_of_use_id: (type_of_use_id as string) !== '' ? type_of_use_id : undefined,
        units,
        year_constructed,

        //@ts-ignore
        portfolio_id: (portfolio_id as unknown as string) !== '' ? portfolio_id : undefined,
        monument_protection,
        leasehold,
        heritage_district,
        milieu_protection,
        economic_unit_id: ecoUnitId,
        country_id: country_id as country_enum,
        country_state_id: (country_state_id as string) !== '' ? country_state_id : undefined,
        postal_code: postal_code.toString(),
        draft_status_id: newDraftStatus,
        energy_certificate_consumers: energy_certificate_consumers
          .filter((energyCertificateConsumer) => energyCertificateConsumer.energy_system_type_id) // EnergyConsumers can only be inserted if system_type is not null
          .map(
            (energyCertificateConsumer) =>
              ({
                energy_certificate_draft_id: energyCertificateDraftId,
                energy_system_type_id:
                  (energyCertificateConsumer.energy_system_type_id as string) !== ''
                    ? energyCertificateConsumer.energy_system_type_id
                    : undefined,
                energy_source_type_id:
                  (energyCertificateConsumer.energy_source_type_id as string) !== ''
                    ? energyCertificateConsumer.energy_source_type_id
                    : undefined,
                energy_final: energyCertificateConsumer.energy_final,
              } satisfies energy_certificate_consumer_insert_input),
          ),
      },
      onCompleted: () => {
        // reset the "save draft" button by making the form pristine but keep the errors
        reset(data, { keepErrors: true, keepIsSubmitted: true });
      },
    });

    return mutationResult;
  };

  // Called when just the draft should be saved
  const submitForm = async () => {
    const values = getValues();
    return await saveForm(values);
  };

  return (
    <FormProvider
      methods={methods}
      // onSubmit called when the draft is saved and the building should be created
      onSubmit={handleSubmit(async (data) => {
        let shouldCreateBuilding = true;

        if (isDirty) {
          shouldCreateBuilding = Boolean(await saveForm(data));
        }

        if (shouldCreateBuilding) {
          setCreateEnergyCertificateDraftModalOpen(true);
        }
      })}
    >
      {/* @ts-ignore */}
      {renderChildren(submitForm, getValues)}
    </FormProvider>
  );
}

export default EnergyCertificateDraftForm;
