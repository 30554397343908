import { consumption_unit_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateConsumptionUnitEnum = (enumValue: consumption_unit_enum) => {
  switch (enumValue) {
    case consumption_unit_enum.KWH:
      return <Trans i18nKey="Enum_ConsumptionUnitEnum-KWH" />;
    case consumption_unit_enum.M3:
      return <Trans i18nKey="Enum_ConsumptionUnitEnum-M3" />;
    case consumption_unit_enum.MWh:
      return <Trans i18nKey="Enum_ConsumptionUnitEnum-MWh" />;
    case consumption_unit_enum.T:
      return <Trans i18nKey="Enum_ConsumptionUnitEnum-T" />;
    case consumption_unit_enum.WH:
      return <Trans i18nKey="Enum_ConsumptionUnitEnum-WH" />;
    case consumption_unit_enum.kg:
      return <Trans i18nKey="Enum_ConsumptionUnitEnum-kg" />;
    case consumption_unit_enum.l:
      return <Trans i18nKey="Enum_ConsumptionUnitEnum-l" />;
  }
};

export const translateConsumptionUnitEnum_dynamic = (
  enumValue: consumption_unit_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case consumption_unit_enum.KWH:
      return t('Enum_ConsumptionUnitEnum-KWH');
    case consumption_unit_enum.M3:
      return t('Enum_ConsumptionUnitEnum-M3');
    case consumption_unit_enum.MWh:
      return t('Enum_ConsumptionUnitEnum-MWh');
    case consumption_unit_enum.T:
      return t('Enum_ConsumptionUnitEnum-T');
    case consumption_unit_enum.WH:
      return t('Enum_ConsumptionUnitEnum-WH');
    case consumption_unit_enum.kg:
      return t('Enum_ConsumptionUnitEnum-kg');
    case consumption_unit_enum.l:
      return t('Enum_ConsumptionUnitEnum-l');
  }
};
