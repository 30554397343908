/* eslint-disable @typescript-eslint/ban-ts-comment */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, ListItemIcon, MenuItem } from '@mui/material';
import { energy_source_type_enum, EnergySystemRouteRecommendedActionFragment } from '@predium/client-graphql';
import { t } from 'i18next';
import { forwardRef, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProvider, RHFDatePicker, RHFSelect } from '../../../../../../components/hook-form';
import InfoTable, { InfoTableData } from '../../../../../../components/presentations/InfoTable';
import { BuildingTabEnum } from '../../../../../../pages/DataCollection/DataCollectionBuilding';
import { useLanguage } from '../../../../../../provider/LanguageProvider';
import { getActionIcon } from '../../../../../../utils/icons';
import { createEnergyInfoTable } from '../../../../Scenario/scenarios.util';
import { getMaxDate } from '../../../ActionPlan.utils';
import { NumberInputSx, SelectSx, StyledListItemText } from '../../../Components/ActionCustomStyles';
import { EnergyRouteActionFormSchema } from '../../CreateActions/validation-schema';
import { availableOptions, TechnicalActionEditProps } from '../TechnicalAction';

export type EnergyRouteActionForm = {
  energy_system_route_renovation_parameter: {
    energy_source_type: energy_source_type_enum;
  };
  implementation_to: DateTime | '';
  renovation_ids: number[];
};

export type EnergyRouteActionFormSchemaType = ReturnType<typeof EnergyRouteActionFormSchema>;

const energyRouteOptions: SupportedEnergySources[] = [
  energy_source_type_enum.ELECTRICITY_GREEN,
  energy_source_type_enum.BIO_GAS,
  /* We're using DISTRICT_HEATING_PLANTS_RENEWABLE as a placeholder for both renewable district heating types */
  energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE,
];

export type SupportedEnergySources =
  | energy_source_type_enum.ELECTRICITY_GREEN
  | energy_source_type_enum.BIO_GAS
  | energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE
  | energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE;

export const getEnergyRouteTypeEnumTitle = (routeType: SupportedEnergySources) => {
  switch (routeType) {
    case energy_source_type_enum.ELECTRICITY_GREEN:
      return t('ActionPlanning_NewEnergySystemRoute_ElectricityGreen');

    case energy_source_type_enum.BIO_GAS:
      return t('ActionPlanning_NewEnergySystemRoute_BioGas');

    case energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE:
      return t('ActionPlanning_NewEnergySystemRoute_DistrictHeatingPlantsRenewable');

    case energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE:
      return t('ActionPlanning_NewEnergySystemRoute_DistrictHeatingCHPRenewable');

    default:
      const exhaustiveCheck = routeType;
      throw new Error(`Unhandled energy_source_type_enum ${routeType}: ${exhaustiveCheck}`);
  }
};

type RouteOptionExclusions = {
  [key in energy_source_type_enum]?: energy_source_type_enum[];
};

const routeOptionExclusions: RouteOptionExclusions = {
  [energy_source_type_enum.ELECTRICITY_GREEN]: [energy_source_type_enum.ELECTRICITY_MIX],
  [energy_source_type_enum.BIO_GAS]: [energy_source_type_enum.NATURAL_GAS],
  [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: [
    energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS,
    energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL,
    energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS,
    energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL,
  ],
};

const EnergyRouteAction = forwardRef(
  (
    {
      previousActionBuildingModel,
      resetSimulatedData,
      minimumDate,
      loading,
      action,
    }: TechnicalActionEditProps & {
      action: EnergySystemRouteRecommendedActionFragment;
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const { language } = useLanguage();

    const { energySources } = availableOptions(previousActionBuildingModel);
    const buildingId = previousActionBuildingModel.building!.id;
    const parameters = action.parameters;

    const defaultValues: EnergyRouteActionForm = {
      implementation_to: minimumDate,
      energy_system_route_renovation_parameter: {
        energy_source_type: parameters.energy_source_type ?? ('' as energy_source_type_enum),
      },
      renovation_ids: [],
    };

    const formMethods = useForm<EnergyRouteActionForm>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: defaultValues,
      resolver: yupResolver(EnergyRouteActionFormSchema(minimumDate, language)),
    });
    const { watch, setValue } = formMethods;

    useImperativeHandle(ref, () => ({
      methods: formMethods,
    }));

    const energySource = watch('energy_system_route_renovation_parameter.energy_source_type');

    const infoTableData: InfoTableData[] | null = createEnergyInfoTable(energySource, previousActionBuildingModel, t);

    const getRouteOptionAvailability = (routeOptionType: energy_source_type_enum) => {
      const exclusions = routeOptionExclusions[routeOptionType];
      return exclusions ? !exclusions.some((exclusion) => energySources.includes(exclusion)) : true;
    };

    const handleEnergySourceChange = (value: SupportedEnergySources) => {
      resetSimulatedData();

      setValue('energy_system_route_renovation_parameter.energy_source_type', value);
      const renovation_ids = previousActionBuildingModel.energy_systems.flatMap((system) =>
        system.energy_system_consumer_routes.map((escr) => escr.id),
      );
      setValue('renovation_ids', renovation_ids);
    };

    return (
      <FormProvider methods={formMethods}>
        <Box my={3}>
          <RHFSelect
            name="energy_system_route_renovation_parameter.energy_source_type"
            label={t('General_EnergySource')}
            size={'small'}
            sx={{ ...SelectSx, mb: 3 }}
            disabled={loading}
            onValueChange={handleEnergySourceChange}
          >
            {energyRouteOptions.map((routeType: SupportedEnergySources) => (
              <MenuItem key={routeType} value={routeType} disabled={getRouteOptionAvailability(routeType)}>
                <ListItemIcon style={{ minWidth: '16px' }}>
                  <Box component="img" src={getActionIcon(routeType)} />
                </ListItemIcon>
                <StyledListItemText action={getEnergyRouteTypeEnumTitle(routeType as SupportedEnergySources)} />
              </MenuItem>
            ))}
          </RHFSelect>

          {infoTableData && (
            <Box mb={3}>
              <InfoTable
                buildingId={buildingId}
                showTab={BuildingTabEnum.technology}
                data={infoTableData}
                title={t('General_StateBeforeRenovation')}
              />
            </Box>
          )}

          <RHFDatePicker
            label={t('General_PlannedFor')}
            name="implementation_to"
            size={'small'}
            sx={NumberInputSx}
            minDate={minimumDate}
            maxDate={getMaxDate()}
            onChange={(date) => {
              resetSimulatedData();
              setValue('implementation_to', date as DateTime);
            }}
          />
        </Box>
      </FormProvider>
    );
  },
);

export default EnergyRouteAction;
