import { country_enum, energy_source_type_enum } from '@predium/enums';
import { CRREMEmissionFactorProvider } from './crrem.emission-factor-provider';

// For all other cases, we want to use GEG only for district heating and electricity.
type GegSpecificEnergySourceType =
  | energy_source_type_enum.ELECTRICITY_MIX
  | energy_source_type_enum.ELECTRICITY_GREEN
  | energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL
  | energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS
  | energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE
  | energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL
  | energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS
  | energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE;
const isGegSpecificEnergySourceType = (
  energySourceType: energy_source_type_enum,
): energySourceType is GegSpecificEnergySourceType => {
  return [
    energy_source_type_enum.ELECTRICITY_MIX,
    energy_source_type_enum.ELECTRICITY_GREEN,
    energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL,
    energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS,
    energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE,
    energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL,
    energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS,
    energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE,
  ].includes(energySourceType);
};

const GEG_lookup: Record<country_enum, Record<GegSpecificEnergySourceType, number>> = {
  [country_enum.DE]: {
    [energy_source_type_enum.ELECTRICITY_MIX]: 0.56,
    [energy_source_type_enum.ELECTRICITY_GREEN]: 0,

    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 0.4,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 0.3,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: 0.06,

    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.3,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.18,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: 0.04,
  },

  [country_enum.AT]: {
    [energy_source_type_enum.ELECTRICITY_MIX]: 0.156,
    [energy_source_type_enum.ELECTRICITY_GREEN]: 0,

    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 0.193,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 0.193,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: 0.059,

    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.067,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.067,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: 0.067,
  },

  [country_enum.PL]: {
    [energy_source_type_enum.ELECTRICITY_MIX]: 0.1821,
    [energy_source_type_enum.ELECTRICITY_GREEN]: 0,

    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 0.399,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 0.399,
    [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: 0.399,

    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.399,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.399,
    [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: 0.399,
  },
};

export const GegEmissionFactorProvider = {
  getEmissionFactor({
    energySourceType,
    country,
  }: {
    energySourceType: energy_source_type_enum;
    country: country_enum;
  }): number {
    if (isGegSpecificEnergySourceType(energySourceType)) {
      return GEG_lookup[country][energySourceType];
    }

    return CRREMEmissionFactorProvider.getEmissionFactor({ energySourceType, country });
  },
};
