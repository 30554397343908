import { emission_factor_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateEmissionFactorTypeEnum = (enumValue: emission_factor_type_enum) => {
  switch (enumValue) {
    case emission_factor_type_enum.CO2TAX:
      return <Trans i18nKey="Enum_EmissionFactorTypeEnum-CO2TAX" />;
    case emission_factor_type_enum.CRREM:
      return <Trans i18nKey="Enum_EmissionFactorTypeEnum-CRREM" />;
    case emission_factor_type_enum.GEG:
      return <Trans i18nKey="Enum_EmissionFactorTypeEnum-GEG" />;
  }
};

export const translateEmissionFactorTypeEnum_dynamic = (
  enumValue: emission_factor_type_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case emission_factor_type_enum.CO2TAX:
      return t('Enum_EmissionFactorTypeEnum-CO2TAX');
    case emission_factor_type_enum.CRREM:
      return t('Enum_EmissionFactorTypeEnum-CRREM');
    case emission_factor_type_enum.GEG:
      return t('Enum_EmissionFactorTypeEnum-GEG');
  }
};
