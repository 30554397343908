/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { createStandardErrorSnackbar } from '../../../../components/NotistackProvider';
import { BUILDING_EXCEL_EXPORT } from '../../../../graphql/DataCollection.mutations';
import BulkExport from './Components/BulkExport';

type Props = {
  buildingIds: number[];
  onActionCompleted?: () => void;
};

export default function LimitedBulkExport({ buildingIds, onActionCompleted }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const displayStandardMutationQueryErrorSnackbar = createStandardErrorSnackbar(t);

  const [buildingExcelExportMutation, { data }] = useMutation(BUILDING_EXCEL_EXPORT, {
    onError: () => displayStandardMutationQueryErrorSnackbar(enqueueSnackbar),
  });

  const triggerExport = useCallback(
    () =>
      buildingExcelExportMutation({
        variables: {
          buildingIds,
        },
      }),
    [buildingExcelExportMutation, buildingIds],
  );

  return (
    <BulkExport
      count={buildingIds.length}
      triggerExport={triggerExport}
      exportBody={data?.exportSubBuildingsAsExcel?.body}
      onActionCompleted={onActionCompleted}
    />
  );
}
