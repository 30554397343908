/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery } from '@apollo/client';
import { building_bool_exp } from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import DeleteConfirmationModal from '../../../../components/DeleteConfirmationModal';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import PreDialog from '../../../../components/presentations/PreDialog/PreDialog';
import { DELETE_ALL_BUILDINGS } from '../../../../graphql/DataCollection.mutations';
import {
  GET_ALL_BUILDINGS_WITH_SUB_BUILDINGS,
  USER_HAS_WRITE_ACCESS_TO_ALL_BUILDINGS,
} from '../../../../graphql/DataCollection.queries';
import useOrgPreferences from '../../../../hooks/useOrgPreferences';
import useSessionData from '../../../../hooks/useSessionData';
import useTooltipConstants from '../../../../hooks/useTooltipConstants';
import { DeleteEmptyEconomicUnit } from '../../Building/Common/DeleteEmptyEconomicUnit';
import AllBulkExport from './AllBulkExport';
import AllBulkMove, { BulkOperationsFilters } from './AllBulkMove';
import ActionButtons from './Components/ActionButtons';

type Props = {
  count: number;
  bulkFilters: BulkOperationsFilters;
  buildingWhere: building_bool_exp;
  onActionCompleted: VoidFunction;
};

export default function AllBulkActionsMenu({ count, bulkFilters, buildingWhere, onActionCompleted }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { economicUnitsToggled } = useOrgPreferences();
  const { tooltips: deleteTooltip } = useTooltipConstants();
  const [keepEmptyEu, setKeepEmptyEu] = useState(false);
  const [openBuildingExportDialog, setOpenBuildingExportDialog] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [openMoveBuildingModal, setOpenMoveBuildingModal] = useState(false);
  const { isAdmin } = useSessionData();

  const { data } = useQuery(USER_HAS_WRITE_ACCESS_TO_ALL_BUILDINGS, {
    variables: {
      buildingWhere: {
        ...buildingWhere,
        id: { _nin: bulkFilters.excludeIds },
      },
    },
    skip: isAdmin,
  });

  const [deleteBuildings] = useMutation(DELETE_ALL_BUILDINGS, {
    variables: {
      keepEmptyEconomicUnits: keepEmptyEu,
      ...bulkFilters,
    },
    onCompleted(data) {
      if (!data.deleteAllBuildings.success) {
        enqueueSnackbar(t('General_DeleteBuilding-error', { count }), {
          variant: 'error',
          autoHideDuration: SnackbarTimeouts.Error,
        });
      } else {
        enqueueSnackbar(t('General_DeleteBuilding-success'), {
          variant: 'success',
          autoHideDuration: SnackbarTimeouts.Success,
        });
      }
    },
    refetchQueries: [
      {
        query: GET_ALL_BUILDINGS_WITH_SUB_BUILDINGS,
      },
    ],
  });

  const hasEditAccess = isAdmin || data?.building_aggregate.aggregate?.count === count;

  return (
    <>
      <ActionButtons
        hasEditAccess={hasEditAccess}
        onMoveTo={() => setOpenMoveBuildingModal(true)}
        onExportData={() => setOpenBuildingExportDialog(true)}
        onDelete={() => setDeleteModalOpen(true)}
      />

      <PreDialog
        type="definedByChildren"
        open={openBuildingExportDialog}
        onClose={() => setOpenBuildingExportDialog(false)}
      >
        <AllBulkExport bulkFilters={bulkFilters} count={count} onActionCompleted={onActionCompleted} />
      </PreDialog>

      <PreDialog
        type="definedByChildren"
        open={openMoveBuildingModal}
        onClose={() => setOpenMoveBuildingModal(false)}
        maxWidth="md"
        fullWidth
      >
        <AllBulkMove
          count={count}
          filters={bulkFilters}
          onMoveCompleted={() => {
            setOpenMoveBuildingModal(false);
            onActionCompleted();
          }}
          onCancel={() => setOpenMoveBuildingModal(false)}
        />
      </PreDialog>

      <DeleteConfirmationModal
        open={deleteModalOpen}
        title={t('General_DeleteModalTitle-Building', { count })}
        description={
          <Trans
            i18nKey={'General_DeleteModalDescription-Building'}
            values={{
              name: '',
              count,
              alongWithAssociatedData: t('General_DeleteWarningAssociatedDataText', { count }),
            }}
            components={{ bold: <strong /> }}
          />
        }
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        onDelete={() => {
          deleteBuildings();
          setDeleteModalOpen(false);
        }}
        deleteConfirmationText={t('General_DeleteModalConfirmation-Building', { count })}
        tooltips={deleteTooltip}
      >
        <DeleteEmptyEconomicUnit
          isBulkOperation
          economicUnitsToggled={economicUnitsToggled}
          setKeepEmptyEu={setKeepEmptyEu}
        />
      </DeleteConfirmationModal>
    </>
  );
}
