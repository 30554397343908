import { country_enum, energy_source_type_enum } from '@predium/enums';

//values in €/kwh
type CountryEnergySources = Record<energy_source_type_enum, number>;
const lookup: Record<country_enum, Record<number, CountryEnergySources>> = {
  [country_enum.DE]: {
    2023: {
      [energy_source_type_enum.NATURAL_GAS]: 0.0934,
      [energy_source_type_enum.LPG]: 0.0879,
      [energy_source_type_enum.FUEL_OIL]: 0.1236,
      [energy_source_type_enum.WOODEN_PELLETS]: 0.0979,
      [energy_source_type_enum.WOOD]: 0.0979,
      [energy_source_type_enum.ELECTRICITY_MIX]: 0.2675,
      [energy_source_type_enum.ELECTRICITY_GREEN]: 0.282,
      [energy_source_type_enum.SOLAR]: 0,
      [energy_source_type_enum.BIO_GAS]: 0.212,
      [energy_source_type_enum.LIGNITE]: 0.113,
      [energy_source_type_enum.COAL]: 0.111,
      [energy_source_type_enum.UNSPECIFIED]: 0.282,

      [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.0987,
      [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.0987,
      [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: 0.1459,

      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 0.0987,
      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 0.0987,
      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: 0.1459,
    },
    2024: {
      [energy_source_type_enum.NATURAL_GAS]: 0.1187,
      [energy_source_type_enum.LPG]: 0.1395,
      [energy_source_type_enum.FUEL_OIL]: 0.0955,
      [energy_source_type_enum.WOODEN_PELLETS]: 0.0543,
      [energy_source_type_enum.WOOD]: 0.113,
      [energy_source_type_enum.ELECTRICITY_MIX]: 0.2946,
      [energy_source_type_enum.ELECTRICITY_GREEN]: 0.3163,
      [energy_source_type_enum.SOLAR]: 0,
      [energy_source_type_enum.BIO_GAS]: 0.1958,
      [energy_source_type_enum.LIGNITE]: 0.094,
      [energy_source_type_enum.COAL]: 0.1385,
      [energy_source_type_enum.UNSPECIFIED]: 0.2946,

      [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.15,
      [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.15,
      [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: 0.1788,

      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 0.15,
      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 0.15,
      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: 0.1788,
    },
  },
  [country_enum.PL]: {
    2024: {
      [energy_source_type_enum.NATURAL_GAS]: 0.0808,
      [energy_source_type_enum.LPG]: 0.1035,
      [energy_source_type_enum.FUEL_OIL]: 0.1123,
      [energy_source_type_enum.WOODEN_PELLETS]: 0.05065,
      [energy_source_type_enum.WOOD]: 0.0434,
      [energy_source_type_enum.ELECTRICITY_MIX]: 0.1112,
      [energy_source_type_enum.ELECTRICITY_GREEN]: 0.254,
      [energy_source_type_enum.SOLAR]: 0,
      [energy_source_type_enum.BIO_GAS]: 0.1435,
      [energy_source_type_enum.LIGNITE]: 0.113,
      [energy_source_type_enum.COAL]: 0.0208,
      [energy_source_type_enum.UNSPECIFIED]: 0.254,

      [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.13,
      [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.13,
      [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: 0.087,

      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 0.13,
      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 0.13,
      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: 0.087,
    },
  },

  [country_enum.AT]: {
    2024: {
      [energy_source_type_enum.NATURAL_GAS]: 0.1046,
      [energy_source_type_enum.LPG]: 0.1783,
      [energy_source_type_enum.FUEL_OIL]: 0.1094,
      [energy_source_type_enum.WOODEN_PELLETS]: 0.0565,
      [energy_source_type_enum.WOOD]: 0.0676,
      [energy_source_type_enum.ELECTRICITY_MIX]: 0.2484,
      [energy_source_type_enum.ELECTRICITY_GREEN]: 0.179,
      [energy_source_type_enum.SOLAR]: 0,
      [energy_source_type_enum.BIO_GAS]: 0.0606,
      [energy_source_type_enum.LIGNITE]: 0.0853,
      [energy_source_type_enum.COAL]: 0.07875,
      [energy_source_type_enum.UNSPECIFIED]: 0.2484,

      [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.139,
      [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.0755,
      [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: 0.1172,

      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 0.139,
      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 0.0755,
      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: 0.1172,
    },
  },
};

/**
 * Given the energy_consumption per square meter, the energy_source_type, and
 * a year, returns the costs per square meter.
 * */
export function getEnergyCostsAtYear(
  energy_consumption: number,
  energy_source_type: energy_source_type_enum,
  year: number,
  country: country_enum,
  postalCode?: string,
): number {
  const countryCosts = lookup[country];
  const recordedYears = Object.keys(countryCosts).map(Number);

  //PRE-3210 TODO: remove this again once delta energy is happy and we support custom energy prices
  if (country === country_enum.DE && postalCode === '30559' && energy_source_type) {
    if (energy_source_type === energy_source_type_enum.NATURAL_GAS) {
      return 0.09 * energy_consumption * Math.pow(1.02, year - 2024);
    }
    if (energy_source_type === energy_source_type_enum.ELECTRICITY_MIX) {
      return 0.3 * energy_consumption * Math.pow(1.02, year - 2024);
    }
  }
  if (recordedYears.includes(year)) {
    return countryCosts[year][energy_source_type] * energy_consumption;
  } else {
    const maxYear = Math.max(...recordedYears);
    if (year > maxYear) {
      return countryCosts[maxYear][energy_source_type] * energy_consumption * Math.pow(1.02, year - maxYear);
    } else {
      const minYear = Math.min(...recordedYears);
      return countryCosts[minYear][energy_source_type] * energy_consumption * Math.pow(1.02, year - minYear);
    }
  }
}
