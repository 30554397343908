import { TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  count: number;
  page: number;
  rowsPerPage: number;
  setPage: (page: number) => void;
  setRowsPerPage: (rows: number) => void;
  paginationOptions?: number[];
  labelRowsPerPage?: string;
};

/**
 * Default behavior for most of our tables. Recreate the same feel for all tables with this component.
 */
function TablePaginationStandard({
  count,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  labelRowsPerPage,
  paginationOptions = [5, 10, 25],
}: Props) {
  const { t } = useTranslation();

  return (
    <TablePagination
      rowsPerPageOptions={paginationOptions}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={(_, page) => setPage(page)}
      onRowsPerPageChange={(event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
      }}
      labelRowsPerPage={labelRowsPerPage ?? t('General_Table-PerPage')}
      labelDisplayedRows={({ from, to, count }) => t('General_Table-of', { from, to, totalAmount: count })}
      sx={{
        '& .MuiSelect-icon': {
          left: '30px',
        },
      }}
    />
  );
}

export default TablePaginationStandard;
