import { IconButton } from '@mui/material';
import { area_type_enum } from '@predium/enums';
import Iconify from '../../../../../components/Iconify';
import { useCalculator } from '../Calculator/CalculatorProvider';
import { useValidation } from '../Validation/ValidationProvider';

type Props = {
  areaType: area_type_enum;
};

const Calculator = ({ areaType }: Props) => {
  const { onAreaClick } = useCalculator();
  const { onOpenEquation } = useValidation();

  return (
    <IconButton
      size="small"
      onClick={() => {
        onOpenEquation(null);
        onAreaClick(areaType);
      }}
    >
      <Iconify icon="mdi:calculator" />
    </IconButton>
  );
};

export default Calculator;
