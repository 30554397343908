import { Box, Button, Card, Grid, IconButton, Link, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { draft_status_enum } from '@predium/enums';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/icons';
import DeleteConfirmationModal from '../../../../components/DeleteConfirmationModal';
import Iconify from '../../../../components/Iconify';
import { DelayedLoading } from '../../../../components/Loading';
import StepperNavigation from '../../../../components/StepperNavigation';
import { OCR_TIMEOUT_MINUTES, UPLOAD_TIMEOUT_MINUTES } from './DataCollectionDraftDeprecated';

type Props = {
  onDeleteAndRetry: () => void;
  onManualEntry: () => void;
  deleteInvoiceDraft: () => Promise<void>;
  draftSubscription: {
    draftStatus: draft_status_enum;
    updatedAt: Date;
  };
  stepperProps: {
    maxSteps: number;
    handleNext: () => void;
    handleBack: () => void;
    activeStep: number;
    nextStepTitle: string;
    backStepTitle: string;
  };
};

export function DraftStatusLoading({
  draftSubscription: { draftStatus, updatedAt },
  onDeleteAndRetry,
  onManualEntry,
  deleteInvoiceDraft,
  stepperProps: { maxSteps, handleNext, handleBack, activeStep, backStepTitle, nextStepTitle },
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const isUploadTimeout =
    draftStatus === draft_status_enum.UPLOADING &&
    new Date().getTime() - updatedAt.getTime() > 1000 * 60 * UPLOAD_TIMEOUT_MINUTES;

  const isOcrTimeout =
    draftStatus === draft_status_enum.OCR_IN_PROGRESS &&
    new Date().getTime() - updatedAt.getTime() > 1000 * 60 * OCR_TIMEOUT_MINUTES;

  const isTimeout = isUploadTimeout || isOcrTimeout;
  const timeout = draftStatus === draft_status_enum.UPLOADING ? UPLOAD_TIMEOUT_MINUTES : OCR_TIMEOUT_MINUTES;

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%', mt: -5 }}>
        <Box sx={{ width: 400 }}></Box>

        <Box sx={{ width: 200 }}>
          <StepperNavigation
            variant="horizontal"
            maxSteps={maxSteps}
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            backStepTitle={backStepTitle}
            nextStepTitle={nextStepTitle}
          />
        </Box>

        <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ width: 400 }}>
          <Tooltip title={t('General_Delete')}>
            <IconButton
              color="error"
              onClick={() => {
                setDeleteModalOpen(true);
              }}
            >
              <Iconify icon={ICONS.TRASH} width={20} height={20} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      <Grid container justifyContent="center">
        <Card sx={{ p: 4, maxWidth: '700px' }}>
          <Stack direction="column" spacing={2} alignItems="center">
            {draftStatus === draft_status_enum.UPLOADING ? (
              <Iconify icon="clarity:upload-cloud-line" width={120} height={120} color={theme.palette.grey[500]} />
            ) : (
              <Iconify
                icon="fluent:document-table-search-20-regular"
                width={120}
                height={120}
                color={theme.palette.grey[500]}
              />
            )}

            {isTimeout ? (
              <>
                <Typography variant="h4" textAlign="center">
                  {t('General_Error')}
                </Typography>
                {/* Show this if the upload or ocr timeout is reached */}
                <Typography variant="body1" textAlign="center">
                  <Trans
                    i18nKey="General_UploadErrorContactSupport"
                    values={{ operation: isUploadTimeout ? t('General_Upload') : t('General_Read') }}
                    components={{
                      support: <Link href="mailto:support@predium.de" underline="always" />,
                    }}
                  />
                </Typography>
                <Stack direction="row" spacing={2}>
                  {/* delete and retry */}
                  <Button variant={isUploadTimeout ? 'contained' : 'outlined'} onClick={onDeleteAndRetry}>
                    {t('General_TryAgain')}
                  </Button>
                  {/* ocr: manual entry */}
                  {isOcrTimeout && (
                    <Button variant="contained" onClick={onManualEntry}>
                      {t('General_ManualEntry')}
                    </Button>
                  )}
                </Stack>
              </>
            ) : (
              <>
                {/* Show this if upload/ocr is in progress and no timeouts are reached */}
                {draftStatus === draft_status_enum.UPLOADING && (
                  <>
                    <Typography variant="h4" textAlign="center">
                      {t('General_DocumentIsBeingUploaded')}
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      {t('DataCollection_Drafts_UploadInfoText')}
                    </Typography>
                  </>
                )}
                {draftStatus === draft_status_enum.OCR_IN_PROGRESS && (
                  <>
                    <Typography variant="h4" textAlign="center">
                      {t('General_DocumentIsBeingProcessed')}
                    </Typography>

                    <Typography variant="body1" textAlign="center">
                      {t('DataCollection_Drafts_OCRInfoText')}
                      <br />
                      {t('DataCollection_Drafts_OCRResultsInfoText')}
                    </Typography>
                  </>
                )}
                <DelayedLoading delay={0} />
                <Typography variant="body1" fontWeight={700} textAlign="center">
                  {t('DraftStatusLoading_DurationStatusInMinutes', { count: timeout })}
                </Typography>
              </>
            )}
          </Stack>
        </Card>
      </Grid>

      <DeleteConfirmationModal
        open={deleteModalOpen}
        title={t('General_DeleteModalTitle-BuildingDraft', { count: 1 })}
        description={
          <Trans
            i18nKey={'General_DeleteModalDescription-BuildingDraft'}
            values={{
              name: '',
              count: 1,
            }}
            components={{ bold: <strong /> }}
          />
        }
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        onDelete={async () => {
          await deleteInvoiceDraft();
          setDeleteModalOpen(false);
        }}
      />
    </>
  );
}
