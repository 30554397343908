import { IconButton } from '@mui/material';
import { InputSelectIcon } from './CustomIcons';

// ----------------------------------------------------------------------

export default function Autocomplete() {
  return {
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: (
          <IconButton size="small" disableRipple>
            <InputSelectIcon />
          </IconButton>
        ),
        slotProps: {
          popupIndicator: {
            disableRipple: true,
          },
        },
      },

      styleOverrides: {
        popupIndicator: {
          transform: 'none', // Remove default transform, the icon does it
          '&.MuiAutocomplete-popupIndicatorOpen': {
            transform: 'none', // Remove default transform when open, the icon does it
          },
        },
      },
    },
  };
}
