import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import App from './App';
import { DelayedLoading } from './components/Loading';
import { AccessTokenProvider } from './contexts/AccessTokenContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import Auth0ProviderWithHistory from './provider/Auth0ProviderWithHistory';

const isNonDevelopment = import.meta.env.VITE_ENV !== 'development';

if (import.meta.env.VITE_SENTRY_DSN && isNonDevelopment) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENV,
    replaysOnErrorSampleRate: isNonDevelopment ? 1.0 : 0,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
        // We are tracing them using "apollo-link-sentry" instead
        traceFetch: false,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: true,
      }),
      Sentry.rewriteFramesIntegration(),
    ],
  });
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

// This starts the dev mock server. Make sure you have the correct GQL client generated first.
if (import.meta.env.VITE_API_MOCKING === 'true' && import.meta.env.VITE_ENV === 'development') {
  import('./mocks/browser').then(({ worker }) => {
    worker.start();
  });
}

const container = document.getElementById('root');

//@ts-ignore
const root = createRoot(container);

const browserRouter = sentryCreateBrowserRouter([
  {
    path: '*',
    element: (
      <Auth0ProviderWithHistory>
        <AccessTokenProvider>
          <App />
        </AccessTokenProvider>
      </Auth0ProviderWithHistory>
    ),
  },
]);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <CollapseDrawerProvider>
        <RouterProvider router={browserRouter} fallbackElement={<DelayedLoading />}></RouterProvider>
      </CollapseDrawerProvider>
    </HelmetProvider>
  </React.StrictMode>,
);
