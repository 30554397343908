import { Box, Stack, TableCell, Typography } from '@mui/material';
import { ReactNode } from 'react';
import Iconify from '../../../../components/Iconify';
import WidgetSummaryLabel from '../../../../components/data-visialization/WidgetSummaryLabel';

type Props = {
  before: ReactNode;
  after: ReactNode;
  comparisonPercent?: number;
  hideComparisonLabel?: boolean;
  isTableCell?: boolean;
  minWidth?: string;
};

export function ComparisonTableCell({
  before,
  after,
  comparisonPercent,
  hideComparisonLabel,
  isTableCell = true,
  minWidth = '65px',
}: Props) {
  const comparison = (
    <Stack display="flex" flexDirection={'row'} gap={2}>
      <Box display="flex" flexDirection="row" alignItems={'center'} gap={0.5} sx={{ minWidth: minWidth }}>
        <Typography variant="body2">{before}</Typography>
        <Iconify icon={'mdi:arrow-right'} width={16} height={16} />
        <Typography variant="body2">{after}</Typography>
      </Box>
      {!hideComparisonLabel && <WidgetSummaryLabel change={(comparisonPercent ?? 0) / 100} />}
    </Stack>
  );

  return <>{isTableCell ? <TableCell>{comparison}</TableCell> : comparison}</>;
}
