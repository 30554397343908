import { CardContent, Divider, Stack, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { AnalyzeBuildingCrremAnalysisFragment, AnalyzeBuildingsCrremAnalysisFragment } from '@predium/client-graphql';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TabLabelWithOptionalTooltip from '../../../components/common/TabLabelWithOptionalTooltip';
import { DelayedLoading } from '../../../components/Loading';
import { PreLink } from '../../../components/presentations/PreLink';
import PreTabsAsToggle from '../../../components/presentations/PreTabsAsToggle';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import { CurrentPageView } from '../Portfolio/PortfolioAnalysisHeader';
import { Co2CrremAnalysis } from './CrremAnalysis/Co2CrremAnalysis';
import { EnergyCrremAnalysis } from './CrremAnalysis/EnergyCrremAnalysis';
import { ExcessEmissionCost } from './CrremAnalysis/ExcessEmissionCost';

export const esgPathGraphOptions = {
  chart: {
    toolbar: {
      offsetY: -40,
    },
  },
  stroke: {
    curve: 'straight',
    dashArray: [0, 4],
    width: [4, 2],
  },
  legend: {
    show: false,
  },
} as ApexCharts.ApexOptions;

type Props = {
  buildingLegendName: string;
  cumulativeExcessEmissions: number | undefined;
  selectedBenchmarkName: string;
  benchmarkCrremAnalysis:
    | {
        yearOfExcedance: number | null;
        strandingYear: number | null;
      }
    | undefined;
  crremAnalysis: AnalyzeBuildingCrremAnalysisFragment | AnalyzeBuildingsCrremAnalysisFragment | undefined | null;
  loading?: boolean;
  graphStartYear: number;
  currentPageView: CurrentPageView;
};

type PathGraphType = 'co2-graph' | 'energy-graph' | 'excess-emission-cost';

export function CrremAnalysis({
  buildingLegendName,
  selectedBenchmarkName,
  cumulativeExcessEmissions,
  graphStartYear,
  benchmarkCrremAnalysis,
  crremAnalysis,
  loading,
  currentPageView,
}: Props) {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();

  const [pathGraphType, setPathGraphType] = useState<PathGraphType>('co2-graph');

  if (loading && crremAnalysis === undefined) {
    return <DelayedLoading />;
  }

  const link = (
    <PreLink
      link={{
        href: 'https://support.predium.de/artikel/crrem-pfad-und-stranding-zeitpunkt-verstehen',
        name: t('General_LearnMore'),
        external: true,
      }}
    />
  );

  const co2Path = crremAnalysis?.co2Path?.m2 ?? [];
  const co2TargetPath = crremAnalysis?.co2TargetPath ?? [];
  const energyPath = crremAnalysis?.energyPath?.m2 ?? [];
  const energyTargetPath = crremAnalysis?.energyTargetPath ?? [];
  const strandingYear = crremAnalysis?.strandingYear;
  const yearOfExcedance = crremAnalysis?.yearOfExcedance;

  const yearOfExcedanceChange =
    benchmarkCrremAnalysis?.yearOfExcedance && crremAnalysis?.yearOfExcedance
      ? crremAnalysis.yearOfExcedance - benchmarkCrremAnalysis?.yearOfExcedance
      : null;

  const strandingYearChange =
    crremAnalysis?.strandingYear && benchmarkCrremAnalysis?.strandingYear
      ? crremAnalysis.strandingYear - benchmarkCrremAnalysis?.strandingYear
      : null;

  const tabs: {
    label: ReactNode;
    value: PathGraphType;
  }[] = [
    {
      label: (
        <TabLabelWithOptionalTooltip
          label={t('General_CO2Path')}
          tooltipText={t('General_1_5C02PathGraph-tooltip')}
          link={link}
        />
      ),
      value: 'co2-graph',
    },
    {
      label: (
        <TabLabelWithOptionalTooltip
          label={t('General_EnergyPath')}
          tooltipText={t('General_1_5EnergyPathGraph-tooltip')}
          link={link}
        />
      ),
      value: 'energy-graph',
    },
    {
      label: (
        <TabLabelWithOptionalTooltip
          label={t('General_ExcessEmissionCost')}
          tooltipText={t('General_ExcessEmissionCostGraph-tooltip')}
          link={link}
        />
      ),
      value: 'excess-emission-cost',
    },
  ];

  const handleTabChange = (newValue: PathGraphType) => {
    setPathGraphType(newValue);

    if (newValue === 'excess-emission-cost') {
      switch (currentPageView) {
        case 'esg-portfolio':
          trackEvent('ESG_PORTFOLIO_CRREM_ANALYSIS_TAB_CHANGE', {
            tab_name: 'excess-emission-cost-portfolio',
          });
          break;
        case 'esg-building':
          trackEvent('ESG_BUILDING_CRREM_ANALYSIS_TAB_CHANGE', {
            tab_name: 'excess-emission-cost-building',
          });
          break;
        case 'esg-economics-units':
          trackEvent('ESG_ECONOMIC_UNIT_CRREM_ANALYSIS_TAB_CHANGE', {
            tab_name: 'excess-emission-cost-economic-unit',
          });
          break;
        default:
          const exhaustiveCheck = currentPageView;
          throw new Error(`Unhandled page  ${currentPageView}: ${exhaustiveCheck}`);
      }
    }
  };

  return (
    <Card>
      <Stack direction="row" alignItems="center" px={3} py={1}>
        <Stack mr={3} spacing={0} direction="row" alignItems="center">
          <Typography variant="h5">{t('EsgAnalysis_CrremAnalysis-Header')}</Typography>
          <Typography ml={2} variant="caption" color="grey.600">
            {t('EsgAnalysis_CrremAnalysis-SubTitle')}
          </Typography>
        </Stack>
        <PreTabsAsToggle
          value={pathGraphType}
          tabs={tabs}
          onChange={(_, newValue) => handleTabChange(newValue as PathGraphType)}
        />
      </Stack>
      <Divider />
      <CardContent sx={{ p: 3 }}>
        {pathGraphType === 'co2-graph' ? (
          <Co2CrremAnalysis
            co2Path={co2Path}
            co2TargetPath={co2TargetPath}
            buildingLegendName={buildingLegendName}
            strandingYear={strandingYear}
            cumulativeExcessEmissions={cumulativeExcessEmissions}
            graphStartYear={graphStartYear}
            benchmark={{
              selectedBenchmarkName,
              strandingYearChange,
            }}
          />
        ) : pathGraphType === 'energy-graph' ? (
          <EnergyCrremAnalysis
            energyPath={energyPath}
            energyTargetPath={energyTargetPath}
            buildingLegendName={buildingLegendName}
            yearOfExcedance={yearOfExcedance}
            graphStartYear={graphStartYear}
            benchmark={{
              selectedBenchmarkName,
              yearOfExcedanceChange,
            }}
          />
        ) : (
          <ExcessEmissionCost
            graphStartYear={graphStartYear}
            excessEmissionCostPath={crremAnalysis?.excessEmissionCostPath}
          />
        )}
      </CardContent>
    </Card>
  );
}
