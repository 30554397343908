import { consumption_frequency_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateConsumptionFrequencyTypeEnum = (enumValue: consumption_frequency_type_enum) => {
  switch (enumValue) {
    case consumption_frequency_type_enum.DAILY:
      return <Trans i18nKey="Enum_ConsumptionFrequencyTypeEnum-DAILY" />;
    case consumption_frequency_type_enum.HOURLY:
      return <Trans i18nKey="Enum_ConsumptionFrequencyTypeEnum-HOURLY" />;
    case consumption_frequency_type_enum.MONTHLY:
      return <Trans i18nKey="Enum_ConsumptionFrequencyTypeEnum-MONTHLY" />;
    case consumption_frequency_type_enum.QUARTER_HOURLY:
      return <Trans i18nKey="Enum_ConsumptionFrequencyTypeEnum-QUARTER_HOURLY" />;
    case consumption_frequency_type_enum.QUARTER_YEARLY:
      return <Trans i18nKey="Enum_ConsumptionFrequencyTypeEnum-QUARTER_YEARLY" />;
    case consumption_frequency_type_enum.WEEKLY:
      return <Trans i18nKey="Enum_ConsumptionFrequencyTypeEnum-WEEKLY" />;
    case consumption_frequency_type_enum.YEARLY:
      return <Trans i18nKey="Enum_ConsumptionFrequencyTypeEnum-YEARLY" />;
  }
};

export const translateConsumptionFrequencyTypeEnum_dynamic = (
  enumValue: consumption_frequency_type_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case consumption_frequency_type_enum.DAILY:
      return t('Enum_ConsumptionFrequencyTypeEnum-DAILY');
    case consumption_frequency_type_enum.HOURLY:
      return t('Enum_ConsumptionFrequencyTypeEnum-HOURLY');
    case consumption_frequency_type_enum.MONTHLY:
      return t('Enum_ConsumptionFrequencyTypeEnum-MONTHLY');
    case consumption_frequency_type_enum.QUARTER_HOURLY:
      return t('Enum_ConsumptionFrequencyTypeEnum-QUARTER_HOURLY');
    case consumption_frequency_type_enum.QUARTER_YEARLY:
      return t('Enum_ConsumptionFrequencyTypeEnum-QUARTER_YEARLY');
    case consumption_frequency_type_enum.WEEKLY:
      return t('Enum_ConsumptionFrequencyTypeEnum-WEEKLY');
    case consumption_frequency_type_enum.YEARLY:
      return t('Enum_ConsumptionFrequencyTypeEnum-YEARLY');
  }
};
