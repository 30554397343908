/* eslint-disable @typescript-eslint/ban-ts-comment */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { getSupportedStorageTypesForSystemType } from '@predium/client-lookup';
import { data_source_type_enum } from '@predium/enums';
import { translateEnergyStorageTypeEnum } from '@predium/i18n/client';
import { useForm, useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { number, object, string } from 'yup';
import { TechnologyIcons } from '../../../../../../../../assets/images';
import DetailedTooltip from '../../../../../../../../components/DetailedTooltip/DetailedTooltip';
import { FormProvider, RHFAutocomplete, RHFNumberField, RHFSelect } from '../../../../../../../../components/hook-form';
import PreDialog, {
  PreDialogBody,
  PreDialogTitle,
} from '../../../../../../../../components/presentations/PreDialog/PreDialog';
import { SystemRoute } from '../../../../../BuildingTechnology';
import {
  generateSystemFieldNames,
  generateSystemRouteFieldNames,
  generateUniqueNegativeId,
  generateYearRange,
} from '../../../../../Common/building.util';
import useBuilding from '../../../../../Context/useBuilding';
import { fieldIcon } from '../../TechnologyConsumerRoute';
import { getSelectStyles } from '../EnergySource/EnergySourceEditModal';
import { StorageFormType } from './StorageEditModal';

type Props = {
  energySystemRoute: SystemRoute;
  index: number;
  open: boolean;
  onClose: () => void;
  systemIndex: number;
};

const StorageAddModal = ({ energySystemRoute, index, onClose, open, systemIndex }: Props) => {
  const { t } = useTranslation();
  const { building } = useBuilding();
  const endYear = new Date().getFullYear();
  const buildingConstructionYear = building.year_constructed;

  const REQUIRED_MESSAGE = t('General_Required');
  const CONSTRUCTION_YEAR_MESSAGE = t('DataCollection_TechnologyEditForm_ConstructionYearMessage', {
    start: buildingConstructionYear,
    end: endYear,
  });
  //reusing the final energy range message . will be updated with validation ticket
  const MIN_RANGE_MESSAGE = t('DataCollection_TechnologyEditForm_FinalEnergyMinMessage');
  const MAX_RANGE_MESSAGE = t('DataCollection_TechnologyEditForm_FinalEnergyMaxMessage');

  //Main system form context
  const { setValue, getValues } = useFormContext();

  const { energyStorageHeatLoss, energyStorageConstructionYear, energySystemType } = energySystemRoute;

  //@ts-ignore
  const storageTypes = getSupportedStorageTypesForSystemType(energySystemType);

  const defaultValues: StorageFormType = {
    storageType: '',

    //@ts-ignore
    storageHeatLoss: undefined,
    constructionYear: null,
  };

  const StorageAddSchema = object().shape({
    storageType: string().required(REQUIRED_MESSAGE).oneOf(storageTypes, REQUIRED_MESSAGE),
    storageHeatLoss: number().required(REQUIRED_MESSAGE).moreThan(0, MIN_RANGE_MESSAGE).max(99999, MAX_RANGE_MESSAGE),
    constructionYear: number()
      .nullable()

      .min(buildingConstructionYear, CONSTRUCTION_YEAR_MESSAGE)
      .max(endYear, CONSTRUCTION_YEAR_MESSAGE),
  });

  const methods = useForm<StorageFormType>({
    resolver: yupResolver(StorageAddSchema),
    defaultValues: defaultValues,
    mode: 'onChange',
  });

  const { handleSubmit, reset, getFieldState, control, watch } = methods;

  const { isDirty } = useFormState({
    control,
  });

  const isStorageTypeDirty = getFieldState('storageType').isDirty;
  const isConstructionYearDirty = getFieldState('constructionYear').isDirty;
  const isHeatLossDirty = getFieldState('storageHeatLoss').isDirty;

  const newStorageType = watch('storageType');
  const newConstructionYear = watch('constructionYear');
  const newHeatLoss = watch('storageHeatLoss');

  const onSubmit = (data: StorageFormType) => {
    handleClose();

    const {
      energyStorageTypeFieldname,
      energyStorageHeatLossFieldname,
      energyStorageConstructionYearFieldname,
      energyStorageIdFieldname,

      //@ts-ignore
    } = generateSystemRouteFieldNames(energySystemRoute.energySystemType, systemIndex, index);

    setValue(energyStorageIdFieldname, generateUniqueNegativeId(getValues()), { shouldDirty: true });

    if (isStorageTypeDirty) {
      setValue(energyStorageTypeFieldname, data.storageType, {
        shouldDirty: true,
      });
    }
    if (isConstructionYearDirty) {
      setValue(energyStorageConstructionYearFieldname, data.constructionYear ?? null, {
        shouldDirty: true,
      });
    }
    if (isHeatLossDirty) {
      setValue(energyStorageHeatLossFieldname, data.storageHeatLoss, {
        shouldDirty: true,
      });
    }
    if (isDirty) {
      //@ts-ignore
      const { dataSourceTypeFieldname } = generateSystemFieldNames(energySystemType, systemIndex);

      setValue(dataSourceTypeFieldname, data_source_type_enum.MANUAL, {
        shouldDirty: true,
      });
    }
  };

  const autocompleteOptions = generateYearRange(buildingConstructionYear);

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <PreDialog open={open} onClose={onClose} fullWidth type="definedByChildren">
      <FormProvider
        methods={methods}
        onSubmit={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <PreDialogBody
          dialogtitle={
            <PreDialogTitle
              icon={<Box component="img" src={TechnologyIcons.storage} mt={0.25} mr={1} width={24} height={24} />}
              title={t('DataCollection_Technology_AddStorageTitle')}
            />
          }
          content={
            <Box my={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} mb={2}>
                  <RHFSelect
                    select
                    label={t('General_Storage')}
                    name="storageType"
                    sx={getSelectStyles(isStorageTypeDirty)}
                  >
                    {storageTypes.map((type) => (
                      <MenuItem sx={{ height: 40 }} key={type} value={type}>
                        {isStorageTypeDirty && newStorageType === type && fieldIcon}
                        <Box>{translateEnergyStorageTypeEnum(type)}</Box>
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Grid>

                <Grid item xs={12} mb={2}>
                  <RHFAutocomplete
                    name="constructionYear"
                    label={t('General_YearOfInstallation')}
                    options={autocompleteOptions}
                    type="number"
                    InputProps={{
                      startAdornment:
                        isConstructionYearDirty && newConstructionYear !== energyStorageConstructionYear?.toString()
                          ? fieldIcon
                          : null,
                    }}
                  />
                </Grid>

                <Grid item xs={12} mb={2}>
                  <RHFNumberField
                    name={'storageHeatLoss'}
                    label={t('General_StorageLoss')}
                    variant="outlined"
                    InputProps={{
                      startAdornment: isHeatLossDirty && newHeatLoss !== energyStorageHeatLoss ? fieldIcon : null,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography>kWh/m²a</Typography>
                            <DetailedTooltip
                              labels={{
                                title: t('General_StorageLoss'),
                                body: t('Education_StorageLossBody'),
                              }}
                              size={20}
                            />
                          </Stack>
                        </InputAdornment>
                      ),
                      autoComplete: 'off',
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          }
          actions={
            <>
              <Button variant="outlined" onClick={handleClose}>
                {t('General_Cancel')}
              </Button>
              <Button type="submit" variant="contained">
                {t('DataCollection_Technology_EnergySourceEditDone')}
              </Button>
            </>
          }
        ></PreDialogBody>
      </FormProvider>
    </PreDialog>
  );
};

export default StorageAddModal;
