import { Box, CircularProgress, ClickAwayListener, Stack, StackProps, Tooltip, Typography } from '@mui/material';
import { data_source_type_enum } from '@predium/client-graphql';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../Iconify';

type Props = {
  value: number | string;
  isEdited?: boolean;
  source?: data_source_type_enum;
  suffix?: string;
  showTooltip?: boolean;
  isLoading?: boolean;
  onSourceClick?: MouseEventHandler<HTMLDivElement>;
  onSourceClickAway?: VoidFunction;
} & StackProps;

const Value = ({
  value,
  source,
  isEdited,
  suffix,
  showTooltip,
  isLoading = false,
  onSourceClick,
  onSourceClickAway = () => {},
  ...props
}: Props) => {
  const { t } = useTranslation();
  const getPrintedValue = () => {
    if (value === undefined) {
      return '–';
    }
    return `${value} ${suffix}`;
  };

  const tooltipTitle =
    source === data_source_type_enum.APPROXIMATED ? t('General_ApproximatedValue') : t('General_CustomValue');

  return (
    <Tooltip title={showTooltip ? tooltipTitle : undefined} arrow placement="top">
      <Stack direction="row" px={0.25} py={0.25} alignItems="center" className="input-value-root" {...props}>
        {isLoading ? (
          <CircularProgress color="info" size={16} sx={{ mr: 1 }} />
        ) : (
          <>
            {!isEdited && source === data_source_type_enum.APPROXIMATED && (
              <ClickAwayListener onClickAway={onSourceClickAway}>
                <Box display="contents" onClick={onSourceClick}>
                  <Iconify width={20} height={20} color="info.main" icon="mdi:chart-timeline-variant-shimmer" mr={1} />
                </Box>
              </ClickAwayListener>
            )}
            {(isEdited || source === data_source_type_enum.MANUAL) && (
              <Iconify color="success.main" icon="mdi:pencil-box" mr={1} />
            )}
          </>
        )}

        <Typography
          variant="body2"
          sx={{
            whiteSpace: 'nowrap',
            ...(isLoading && {
              color: 'info.main',
            }),
          }}
        >
          {getPrintedValue()}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export default Value;
