import { MenuItem, Stack, TextField } from '@mui/material';
import { SUPPORTED_YEARS } from '@predium/client-lookup';
import { useTranslation } from 'react-i18next';

type Props = {
  selectedReportingYear: number;
  setSelectedReportingYear: (year: number) => void;
  constructionYear: number;
};

export default function ReportingYearSelector({
  selectedReportingYear,
  setSelectedReportingYear,
  constructionYear,
}: Props) {
  const { t } = useTranslation();
  return (
    <Stack direction="row" alignItems="center" spacing={1.5}>
      <TextField
        select
        label={t('General_ReportingYear')}
        onChange={(e) => setSelectedReportingYear(Number(e.target.value))}
        value={selectedReportingYear}
        size="small"
        sx={{
          minWidth: 120,
        }}
      >
        {SUPPORTED_YEARS.map((year) => (
          <MenuItem key={year} value={year} disabled={year < constructionYear}>
            {year}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );
}
