import { PropsWithChildren, useEffect } from 'react';
import DetailedTooltip, { Variant } from '../../../../../components/DetailedTooltip/DetailedTooltip';
import { Validation } from '../Validation/validations';

type Props = PropsWithChildren<{
  labels: Validation['errorLabels'][];
  variant?: Variant;
  open: boolean;
  onClose: VoidFunction;
}>;

const CalculationTooltip = ({ labels, variant, open, children, onClose }: Props) => {
  // Close the tooltip when the component is unmounted
  // Why? when you change tab, click away's onclick doesn't get triggered
  // the component stays open, with this effect we close it when the component is unmounted
  useEffect(
    () => () => {
      open && onClose();
    },
    [onClose, open],
  );

  const label = labels[0];
  if (!variant || !label) {
    return <>{children}</>;
  }

  const handleOnClickAway = () => {
    if (open) {
      onClose();
    }
  };

  return (
    <DetailedTooltip
      labels={{
        title: label.title,
        preBody: label.preBody,
        body: label.postBody,
        equations: labels.map(({ body }) => body),
      }}
      variant={variant}
      slotsProps={{
        tooltip: {
          placement: 'left',
          open: open ?? false,
        },
        onClickAway: {
          mouseEvent: 'onClick',
        },
      }}
      onClose={handleOnClickAway}
    >
      <span>{children}</span>
    </DetailedTooltip>
  );
};

export default CalculationTooltip;
