import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = {
  sx?: SxProps<Theme>;
};

const StickyBox = ({ children, sx }: PropsWithChildren<Props>) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        transition: 'transform 0.3s ease',
        position: 'sticky',
        top: 0,
        zIndex: 2,
        backgroundColor: theme.palette.common.white,
        width: '100%',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default StickyBox;
