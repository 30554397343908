import { country_enum } from '@predium/enums';

export const CRREMGreenElectricity: Record<country_enum, number> = {
  [country_enum.DE]: 0.064,
  [country_enum.AT]: 0.032,
  [country_enum.PL]: 0.087,
};

export type Year =
  | '2020'
  | '2021'
  | '2022'
  | '2023'
  | '2024'
  | '2025'
  | '2026'
  | '2027'
  | '2028'
  | '2029'
  | '2030'
  | '2031'
  | '2032'
  | '2033'
  | '2034'
  | '2035'
  | '2036'
  | '2037'
  | '2038'
  | '2039'
  | '2040'
  | '2041'
  | '2042'
  | '2043'
  | '2044'
  | '2045'
  | '2046'
  | '2047'
  | '2048'
  | '2049'
  | '2050';

export const electricityEF: Record<country_enum | 'UK', Record<Year, number>> = {
  AT: {
    '2020': 0.11118,
    '2021': 0.11846999999999999,
    '2022': 0.12707,
    '2023': 0.11899988545340334,
    '2024': 0.11092977090680668,
    '2025': 0.10285965636021004,
    '2026': 0.09398371976147865,
    '2027': 0.08510778316274727,
    '2028': 0.0762318465640159,
    '2029': 0.06735590996528452,
    '2030': 0.05847997336655313,
    '2031': 0.057982884013040296,
    '2032': 0.05748579465952747,
    '2033': 0.05698870530601463,
    '2034': 0.056491615952501795,
    '2035': 0.05599452659898896,
    '2036': 0.05232893767581438,
    '2037': 0.04866334875263982,
    '2038': 0.04499775982946525,
    '2039': 0.04133217090629068,
    '2040': 0.037666581983116114,
    '2041': 0.037266046658192345,
    '2042': 0.03686551133326857,
    '2043': 0.0364649760083448,
    '2044': 0.03606444068342103,
    '2045': 0.03566390535849726,
    '2046': 0.03500324217244381,
    '2047': 0.034342578986390364,
    '2048': 0.03368191580033691,
    '2049': 0.03302125261428345,
    '2050': 0.03236058942823,
  },
  // BE: {
  //   '2020': 0.16188999999999998,
  //   '2021': 0.12264,
  //   '2022': 0.12176999999999999,
  //   '2023': 0.12176999999999999,
  //   '2024': 0.12176999999999999,
  //   '2025': 0.12176999999999999,
  //   '2026': 0.12007703207326231,
  //   '2027': 0.11838406414652464,
  //   '2028': 0.11669109621978696,
  //   '2029': 0.11499812829304928,
  //   '2030': 0.11330516036631161,
  //   '2031': 0.11236351397005329,
  //   '2032': 0.11142186757379498,
  //   '2033': 0.11048022117753664,
  //   '2034': 0.10953857478127833,
  //   '2035': 0.10859692838502003,
  //   '2036': 0.1067782202968165,
  //   '2037': 0.10495951220861295,
  //   '2038': 0.10314080412040942,
  //   '2039': 0.10132209603220588,
  //   '2040': 0.09950338794400233,
  //   '2041': 0.09913530599764385,
  //   '2042': 0.09876722405128535,
  //   '2043': 0.09839914210492687,
  //   '2044': 0.09803106015856837,
  //   '2045': 0.09766297821220987,
  //   '2046': 0.09714819484748957,
  //   '2047': 0.09663341148276929,
  //   '2048': 0.096118628118049,
  //   '2049': 0.0956038447533287,
  //   '2050': 0.0950890613886084,
  // },
  // BG: {
  //   '2020': 0.37212,
  //   '2021': 0.40412,
  //   '2022': 0.48135,
  //   '2023': 0.42767274418563017,
  //   '2024': 0.3799812529744834,
  //   '2025': 0.3376080299131432,
  //   '2026': 0.32358643691863687,
  //   '2027': 0.3095648439241305,
  //   '2028': 0.2955432509296241,
  //   '2029': 0.2815216579351177,
  //   '2030': 0.2675000649406113,
  //   '2031': 0.25149061242100496,
  //   '2032': 0.2354811599013986,
  //   '2033': 0.21947170738179228,
  //   '2034': 0.20346225486218592,
  //   '2035': 0.18745280234257955,
  //   '2036': 0.16584957530738595,
  //   '2037': 0.14424634827219235,
  //   '2038': 0.12264312123699873,
  //   '2039': 0.10103989420180512,
  //   '2040': 0.07943666716661149,
  //   '2041': 0.07598898233588883,
  //   '2042': 0.07254129750516616,
  //   '2043': 0.0690936126744435,
  //   '2044': 0.06564592784372084,
  //   '2045': 0.06219824301299817,
  //   '2046': 0.059984933697458755,
  //   '2047': 0.05777162438191934,
  //   '2048': 0.055558315066379906,
  //   '2049': 0.053345005750840474,
  //   '2050': 0.05113169643530105,
  // },
  // HR: {
  //   '2020': 0.22696,
  //   '2021': 0.20052,
  //   '2022': 0.22141,
  //   '2023': 0.17438155318718732,
  //   '2024': 0.12735310637437466,
  //   '2025': 0.08032465956156201,
  //   '2026': 0.07211466326353444,
  //   '2027': 0.06390466696550685,
  //   '2028': 0.05569467066747929,
  //   '2029': 0.0474846743694517,
  //   '2030': 0.039274678071424124,
  //   '2031': 0.03827818616030314,
  //   '2032': 0.03728169424918214,
  //   '2033': 0.03628520233806115,
  //   '2034': 0.035288710426940165,
  //   '2035': 0.03429221851581918,
  //   '2036': 0.033731539372145555,
  //   '2037': 0.033170860228471925,
  //   '2038': 0.0326101810847983,
  //   '2039': 0.03204950194112467,
  //   '2040': 0.03148882279745105,
  //   '2041': 0.030973238001251673,
  //   '2042': 0.030457653205052296,
  //   '2043': 0.02994206840885292,
  //   '2044': 0.02942648361265354,
  //   '2045': 0.028910898816454166,
  //   '2046': 0.027996051736039074,
  //   '2047': 0.027081204655623987,
  //   '2048': 0.0261663575752089,
  //   '2049': 0.02525151049479381,
  //   '2050': 0.024336663414378727,
  // },
  // CY: {
  //   '2020': 0.6429,
  //   '2021': 0.6229600000000001,
  //   '2022': 0.60894,
  //   '2023': 0.5446306706687417,
  //   '2024': 0.4871129625793729,
  //   '2025': 0.43566962180352997,
  //   '2026': 0.4111161047862157,
  //   '2027': 0.3865625877689015,
  //   '2028': 0.36200907075158734,
  //   '2029': 0.3374555537342731,
  //   '2030': 0.31290203671695893,
  //   '2031': 0.30879297930403027,
  //   '2032': 0.3046839218911016,
  //   '2033': 0.30057486447817294,
  //   '2034': 0.2964658070652443,
  //   '2035': 0.29235674965231556,
  //   '2036': 0.2794138273180873,
  //   '2037': 0.266470904983859,
  //   '2038': 0.25352798264963067,
  //   '2039': 0.2405850603154024,
  //   '2040': 0.22764213798117408,
  //   '2041': 0.2210674804375958,
  //   '2042': 0.21449282289401747,
  //   '2043': 0.20791816535043917,
  //   '2044': 0.20134350780686083,
  //   '2045': 0.1947688502632825,
  //   '2046': 0.18953211141959692,
  //   '2047': 0.1842953725759114,
  //   '2048': 0.17905863373222583,
  //   '2049': 0.17382189488854027,
  //   '2050': 0.16858515604485474,
  // },
  // CZ: {
  //   '2020': 0.49549,
  //   '2021': 0.50671,
  //   '2022': 0.64225,
  //   '2023': 0.5762906889031608,
  //   '2024': 0.5171054233031994,
  //   '2025': 0.46399850623738637,
  //   '2026': 0.4370928201671941,
  //   '2027': 0.4101871340970018,
  //   '2028': 0.3832814480268096,
  //   '2029': 0.3563757619566173,
  //   '2030': 0.329470075886425,
  //   '2031': 0.32296358188183044,
  //   '2032': 0.316457087877236,
  //   '2033': 0.3099505938726414,
  //   '2034': 0.3034440998680469,
  //   '2035': 0.2969376058634524,
  //   '2036': 0.27155160843467985,
  //   '2037': 0.2461656110059072,
  //   '2038': 0.2207796135771346,
  //   '2039': 0.19539361614836195,
  //   '2040': 0.17000761871958936,
  //   '2041': 0.16273769302736926,
  //   '2042': 0.1554677673351492,
  //   '2043': 0.14819784164292912,
  //   '2044': 0.14092791595070903,
  //   '2045': 0.13365799025848896,
  //   '2046': 0.13365799025848896,
  //   '2047': 0.13365799025848896,
  //   '2048': 0.13365799025848896,
  //   '2049': 0.13365799025848896,
  //   '2050': 0.13365799025848896,
  // },
  // DK: {
  //   '2020': 0.14252,
  //   '2021': 0.18134,
  //   '2022': 0.16283,
  //   '2023': 0.14845011738742883,
  //   '2024': 0.13534015446994657,
  //   '2025': 0.12338796179018804,
  //   '2026': 0.1071931510681925,
  //   '2027': 0.09099834034619697,
  //   '2028': 0.07480352962420142,
  //   '2029': 0.05860871890220587,
  //   '2030': 0.04241390818021033,
  //   '2031': 0.04241390818021033,
  //   '2032': 0.04241390818021033,
  //   '2033': 0.04241390818021033,
  //   '2034': 0.04241390818021033,
  //   '2035': 0.04241390818021033,
  //   '2036': 0.04086007112751364,
  //   '2037': 0.03930623407481695,
  //   '2038': 0.03775239702212025,
  //   '2039': 0.03619855996942355,
  //   '2040': 0.03464472291672685,
  //   '2041': 0.033825232933185276,
  //   '2042': 0.0330057429496437,
  //   '2043': 0.03218625296610213,
  //   '2044': 0.03136676298256055,
  //   '2045': 0.03054727299901898,
  //   '2046': 0.029082570157166737,
  //   '2047': 0.027617867315314494,
  //   '2048': 0.02615316447346225,
  //   '2049': 0.02468846163161001,
  //   '2050': 0.023223758789757763,
  // },
  // EE: {
  //   '2020': 0.59869,
  //   '2021': 0.61613,
  //   '2022': 0.66099,
  //   '2023': 0.6003497999051381,
  //   '2024': 0.545272821443803,
  //   '2025': 0.49524868643625053,
  //   '2026': 0.4425739969213602,
  //   '2027': 0.3898993074064699,
  //   '2028': 0.33722461789157954,
  //   '2029': 0.28454992837668924,
  //   '2030': 0.23187523886179895,
  //   '2031': 0.2277593763374679,
  //   '2032': 0.2236435138131369,
  //   '2033': 0.21952765128880586,
  //   '2034': 0.2154117887644748,
  //   '2035': 0.21129592624014373,
  //   '2036': 0.18745256115037356,
  //   '2037': 0.16360919606060337,
  //   '2038': 0.13976583097083314,
  //   '2039': 0.11592246588106297,
  //   '2040': 0.09207910079129279,
  //   '2041': 0.0792930885086134,
  //   '2042': 0.066507076225934,
  //   '2043': 0.05372106394325461,
  //   '2044': 0.04093505166057522,
  //   '2045': 0.028149039377895833,
  //   '2046': 0.02742868748726159,
  //   '2047': 0.026708335596627348,
  //   '2048': 0.025987983705993103,
  //   '2049': 0.02526763181535886,
  //   '2050': 0.024547279924724615,
  // },
  // FI: {
  //   '2020': 0.09531999999999999,
  //   '2021': 0.09584000000000001,
  //   '2022': 0.08285,
  //   '2023': 0.0771203820890507,
  //   '2024': 0.07139076417810143,
  //   '2025': 0.06566114626715215,
  //   '2026': 0.05739805106235592,
  //   '2027': 0.04913495585755969,
  //   '2028': 0.040871860652763464,
  //   '2029': 0.03260876544796723,
  //   '2030': 0.024345670243171004,
  //   '2031': 0.024087239485145774,
  //   '2032': 0.02382880872712055,
  //   '2033': 0.023570377969095325,
  //   '2034': 0.023311947211070095,
  //   '2035': 0.02305351645304487,
  //   '2036': 0.022295843553346094,
  //   '2037': 0.02153817065364732,
  //   '2038': 0.02078049775394854,
  //   '2039': 0.020022824854249766,
  //   '2040': 0.01926515195455099,
  //   '2041': 0.018811317082455186,
  //   '2042': 0.018357482210359388,
  //   '2043': 0.017903647338263586,
  //   '2044': 0.017449812466167784,
  //   '2045': 0.016995977594071978,
  //   '2046': 0.01695776947238007,
  //   '2047': 0.016919561350688166,
  //   '2048': 0.016881353228996258,
  //   '2049': 0.01684314510730435,
  //   '2050': 0.016804936985612442,
  // },
  // FR: {
  //   '2020': 0.05128,
  //   '2021': 0.040979999999999996,
  //   '2022': 0.05357,
  //   '2023': 0.04875071507328764,
  //   '2024': 0.04393143014657528,
  //   '2025': 0.03911214521986293,
  //   '2026': 0.03702149670858642,
  //   '2027': 0.03493084819730992,
  //   '2028': 0.03284019968603342,
  //   '2029': 0.030749551174756917,
  //   '2030': 0.028658902663480416,
  //   '2031': 0.028195958233099165,
  //   '2032': 0.02773301380271792,
  //   '2033': 0.02727006937233667,
  //   '2034': 0.026807124941955418,
  //   '2035': 0.026344180511574174,
  //   '2036': 0.026344180511574174,
  //   '2037': 0.026344180511574174,
  //   '2038': 0.026344180511574174,
  //   '2039': 0.026344180511574174,
  //   '2040': 0.026344180511574174,
  //   '2041': 0.025618295665474983,
  //   '2042': 0.024892410819375793,
  //   '2043': 0.024166525973276603,
  //   '2044': 0.023440641127177413,
  //   '2045': 0.02271475628107822,
  //   '2046': 0.02271475628107822,
  //   '2047': 0.02271475628107822,
  //   '2048': 0.02271475628107822,
  //   '2049': 0.02271475628107822,
  //   '2050': 0.02271475628107822,
  // },
  DE: {
    '2020': 0.33866,
    '2021': 0.37764,
    '2022': 0.38763,
    '2023': 0.37090435692423646,
    '2024': 0.3549003998281386,
    '2025': 0.3395869890627921,
    '2026': 0.31681654600431164,
    '2027': 0.2940461029458311,
    '2028': 0.2712756598873506,
    '2029': 0.24850521682887006,
    '2030': 0.22573477377038956,
    '2031': 0.20688532896892886,
    '2032': 0.18803588416746822,
    '2033': 0.16918643936600758,
    '2034': 0.15033699456454694,
    '2035': 0.13148754976308627,
    '2036': 0.12156805673999113,
    '2037': 0.11164856371689598,
    '2038': 0.10172907069380084,
    '2039': 0.0918095776707057,
    '2040': 0.08189008464761055,
    '2041': 0.07848704414073349,
    '2042': 0.07508400363385642,
    '2043': 0.07168096312697936,
    '2044': 0.0682779226201023,
    '2045': 0.06487488211322523,
    '2046': 0.0647109718943454,
    '2047': 0.06454706167546556,
    '2048': 0.06438315145658574,
    '2049': 0.0642192412377059,
    '2050': 0.06405533101882607,
  },
  // EL: {
  //   '2020': 0.41001,
  //   '2021': 0.33664,
  //   '2022': 0.35183,
  //   '2023': 0.3041479554067975,
  //   '2024': 0.2629280583751678,
  //   '2025': 0.22729452114341128,
  //   '2026': 0.21054679938552592,
  //   '2027': 0.19379907762764056,
  //   '2028': 0.1770513558697552,
  //   '2029': 0.16030363411186985,
  //   '2030': 0.14355591235398452,
  //   '2031': 0.14355591235398452,
  //   '2032': 0.14355591235398452,
  //   '2033': 0.14355591235398452,
  //   '2034': 0.14355591235398452,
  //   '2035': 0.14355591235398452,
  //   '2036': 0.13885751364967622,
  //   '2037': 0.13415911494536795,
  //   '2038': 0.12946071624105965,
  //   '2039': 0.12476231753675136,
  //   '2040': 0.12006391883244309,
  //   '2041': 0.11172052996032648,
  //   '2042': 0.1033771410882099,
  //   '2043': 0.09503375221609331,
  //   '2044': 0.08669036334397673,
  //   '2045': 0.07834697447186015,
  //   '2046': 0.07834697447186015,
  //   '2047': 0.07834697447186015,
  //   '2048': 0.07834697447186015,
  //   '2049': 0.07834697447186015,
  //   '2050': 0.07834697447186015,
  // },
  // HU: {
  //   '2020': 0.24375,
  //   '2021': 0.22397,
  //   '2022': 0.23504,
  //   '2023': 0.22289062512200103,
  //   '2024': 0.210741250244002,
  //   '2025': 0.19859187536600295,
  //   '2026': 0.17200992339157428,
  //   '2027': 0.1454279714171456,
  //   '2028': 0.11884601944271696,
  //   '2029': 0.0922640674682883,
  //   '2030': 0.06568211549385963,
  //   '2031': 0.06568211549385963,
  //   '2032': 0.06568211549385963,
  //   '2033': 0.06568211549385963,
  //   '2034': 0.06568211549385963,
  //   '2035': 0.06568211549385963,
  //   '2036': 0.06355002455102954,
  //   '2037': 0.06141793360819944,
  //   '2038': 0.05928584266536935,
  //   '2039': 0.05715375172253925,
  //   '2040': 0.05502166077970916,
  //   '2041': 0.05315463564792381,
  //   '2042': 0.05128761051613847,
  //   '2043': 0.04942058538435312,
  //   '2044': 0.04755356025256778,
  //   '2045': 0.04568653512078244,
  //   '2046': 0.04568653512078244,
  //   '2047': 0.04568653512078244,
  //   '2048': 0.04568653512078244,
  //   '2049': 0.04568653512078244,
  //   '2050': 0.04568653512078244,
  // },
  // IE: {
  //   '2020': 0.33599,
  //   '2021': 0.37710000000000005,
  //   '2022': 0.30891,
  //   '2023': 0.2901626975138737,
  //   '2024': 0.27141539502774736,
  //   '2025': 0.25266809254162104,
  //   '2026': 0.22442384694653458,
  //   '2027': 0.19617960135144816,
  //   '2028': 0.16793535575636168,
  //   '2029': 0.13969111016127522,
  //   '2030': 0.11144686456618878,
  //   '2031': 0.10949249155535133,
  //   '2032': 0.1075381185445139,
  //   '2033': 0.10558374553367644,
  //   '2034': 0.103629372522839,
  //   '2035': 0.10167499951200157,
  //   '2036': 0.10127069309018888,
  //   '2037': 0.1008663866683762,
  //   '2038': 0.10046208024656351,
  //   '2039': 0.10005777382475083,
  //   '2040': 0.09965346740293814,
  //   '2041': 0.09934724012947609,
  //   '2042': 0.09904101285601402,
  //   '2043': 0.09873478558255197,
  //   '2044': 0.09842855830908992,
  //   '2045': 0.09812233103562787,
  //   '2046': 0.09624392496916667,
  //   '2047': 0.09436551890270549,
  //   '2048': 0.0924871128362443,
  //   '2049': 0.09060870676978311,
  //   '2050': 0.08873030070332193,
  // },
  // IT: {
  //   '2020': 0.32383999999999996,
  //   '2021': 0.30685,
  //   '2022': 0.31438,
  //   '2023': 0.29357117628890533,
  //   '2024': 0.27276235257781073,
  //   '2025': 0.2519535288667161,
  //   '2026': 0.2271857071541024,
  //   '2027': 0.20241788544148864,
  //   '2028': 0.17765006372887493,
  //   '2029': 0.15288224201626116,
  //   '2030': 0.12811442030364742,
  //   '2031': 0.12013859812340838,
  //   '2032': 0.11216277594316937,
  //   '2033': 0.10418695376293033,
  //   '2034': 0.09621113158269129,
  //   '2035': 0.08823530940245226,
  //   '2036': 0.08626279780779508,
  //   '2037': 0.0842902862131379,
  //   '2038': 0.08231777461848071,
  //   '2039': 0.08034526302382353,
  //   '2040': 0.07837275142916635,
  //   '2041': 0.07501521561479353,
  //   '2042': 0.07165767980042073,
  //   '2043': 0.06830014398604792,
  //   '2044': 0.06494260817167512,
  //   '2045': 0.06158507235730232,
  //   '2046': 0.06158507235730232,
  //   '2047': 0.06158507235730232,
  //   '2048': 0.06158507235730232,
  //   '2049': 0.06158507235730232,
  //   '2050': 0.06158507235730232,
  // },
  // LV: {
  //   '2020': 0.21567,
  //   '2021': 0.21965,
  //   '2022': 0.12988999999999998,
  //   '2023': 0.12305407071212117,
  //   '2024': 0.11621814142424236,
  //   '2025': 0.10938221213636355,
  //   '2026': 0.10520143549305316,
  //   '2027': 0.10102065884974276,
  //   '2028': 0.09683988220643237,
  //   '2029': 0.09265910556312197,
  //   '2030': 0.08847832891981158,
  //   '2031': 0.08414341540502274,
  //   '2032': 0.07980850189023389,
  //   '2033': 0.07547358837544506,
  //   '2034': 0.07113867486065621,
  //   '2035': 0.06680376134586738,
  //   '2036': 0.06419571462886178,
  //   '2037': 0.061587667911856186,
  //   '2038': 0.0589796211948506,
  //   '2039': 0.056371574477845,
  //   '2040': 0.0537635277608394,
  //   '2041': 0.04843703705300958,
  //   '2042': 0.04311054634517976,
  //   '2043': 0.03778405563734993,
  //   '2044': 0.0324575649295201,
  //   '2045': 0.027131074221690272,
  //   '2046': 0.026456732535277843,
  //   '2047': 0.02578239084886541,
  //   '2048': 0.02510804916245298,
  //   '2049': 0.024433707476040546,
  //   '2050': 0.023759365789628114,
  // },
  // LT: {
  //   '2020': 0.25356,
  //   '2021': 0.21852000000000002,
  //   '2022': 0.23781,
  //   '2023': 0.20949865200814777,
  //   '2024': 0.18118730401629554,
  //   '2025': 0.15287595602444334,
  //   '2026': 0.1429250271036137,
  //   '2027': 0.13297409818278405,
  //   '2028': 0.1230231692619544,
  //   '2029': 0.11307224034112476,
  //   '2030': 0.10312131142029513,
  //   '2031': 0.09611502874594288,
  //   '2032': 0.08910874607159062,
  //   '2033': 0.08210246339723838,
  //   '2034': 0.07509618072288611,
  //   '2035': 0.06808989804853385,
  //   '2036': 0.06808989804853385,
  //   '2037': 0.06808989804853385,
  //   '2038': 0.06808989804853385,
  //   '2039': 0.06808989804853385,
  //   '2040': 0.06808989804853385,
  //   '2041': 0.06430749246302243,
  //   '2042': 0.06052508687751103,
  //   '2043': 0.05674268129199961,
  //   '2044': 0.052960275706488195,
  //   '2045': 0.04917787012097678,
  //   '2046': 0.04758441439106066,
  //   '2047': 0.045990958661144526,
  //   '2048': 0.04439750293122839,
  //   '2049': 0.04280404720131226,
  //   '2050': 0.041210591471396135,
  // },
  // LU: {
  //   '2020': 0.10136,
  //   '2021': 0.09883,
  //   '2022': 0.07442,
  //   '2023': 0.0633532634291715,
  //   '2024': 0.05228652685834299,
  //   '2025': 0.04121979028751448,
  //   '2026': 0.035066157914879895,
  //   '2027': 0.028912525542245316,
  //   '2028': 0.02275889316961073,
  //   '2029': 0.01660526079697615,
  //   '2030': 0.01045162842434157,
  //   '2031': 0.00960768366868876,
  //   '2032': 0.008763738913035946,
  //   '2033': 0.007919794157383133,
  //   '2034': 0.007075849401730322,
  //   '2035': 0.00623190464607751,
  //   '2036': 0.00623190464607751,
  //   '2037': 0.00623190464607751,
  //   '2038': 0.00623190464607751,
  //   '2039': 0.00623190464607751,
  //   '2040': 0.00623190464607751,
  //   '2041': 0.005254314549388216,
  //   '2042': 0.004276724452698923,
  //   '2043': 0.0032991343560096293,
  //   '2044': 0.0023215442593203365,
  //   '2045': 0.001343954162631043,
  //   '2046': 0.001343954162631043,
  //   '2047': 0.001343954162631043,
  //   '2048': 0.001343954162631043,
  //   '2049': 0.001343954162631043,
  //   '2050': 0.001343954162631043,
  // },
  // MT: {
  //   '2020': 0.39062,
  //   '2021': 0.66069,
  //   '2022': 0.37110000000000004,
  //   '2023': 0.36602356727617474,
  //   '2024': 0.3609471345523494,
  //   '2025': 0.3558707018285241,
  //   '2026': 0.3485196543687383,
  //   '2027': 0.3411686069089524,
  //   '2028': 0.3338175594491666,
  //   '2029': 0.3264665119893807,
  //   '2030': 0.3191154645295949,
  //   '2031': 0.3191154645295949,
  //   '2032': 0.3191154645295949,
  //   '2033': 0.3191154645295949,
  //   '2034': 0.3191154645295949,
  //   '2035': 0.3191154645295949,
  //   '2036': 0.3191154645295949,
  //   '2037': 0.3191154645295949,
  //   '2038': 0.3191154645295949,
  //   '2039': 0.3191154645295949,
  //   '2040': 0.3191154645295949,
  //   '2041': 0.31570849752845576,
  //   '2042': 0.3123015305273166,
  //   '2043': 0.30889456352617745,
  //   '2044': 0.30548759652503826,
  //   '2045': 0.3020806295238991,
  //   '2046': 0.29976368721371166,
  //   '2047': 0.2974467449035242,
  //   '2048': 0.29512980259333677,
  //   '2049': 0.2928128602831493,
  //   '2050': 0.2904959179729618,
  // },
  // NL: {
  //   '2020': 0.37433999999999995,
  //   '2021': 0.37039999999999995,
  //   '2022': 0.29634,
  //   '2023': 0.26773409432796264,
  //   '2024': 0.23912818865592525,
  //   '2025': 0.2105222829838879,
  //   '2026': 0.19133653423402783,
  //   '2027': 0.17215078548416773,
  //   '2028': 0.15296503673430767,
  //   '2029': 0.13377928798444755,
  //   '2030': 0.11459353923458747,
  //   '2031': 0.1133171585491375,
  //   '2032': 0.11204077786368752,
  //   '2033': 0.11076439717823755,
  //   '2034': 0.10948801649278758,
  //   '2035': 0.10821163580733761,
  //   '2036': 0.10488423516050581,
  //   '2037': 0.10155683451367403,
  //   '2038': 0.09822943386684223,
  //   '2039': 0.09490203322001042,
  //   '2040': 0.09157463257317862,
  //   '2041': 0.08793749344137507,
  //   '2042': 0.0843003543095715,
  //   '2043': 0.08066321517776795,
  //   '2044': 0.0770260760459644,
  //   '2045': 0.07338893691416085,
  //   '2046': 0.07276298678131378,
  //   '2047': 0.07213703664846671,
  //   '2048': 0.07151108651561965,
  //   '2049': 0.07088513638277258,
  //   '2050': 0.07025918624992551,
  // },
  PL: {
    '2020': 0.75962,
    '2021': 0.77659,
    '2022': 0.75746,
    '2023': 0.7072437100366877,
    '2024': 0.6570274200733754,
    '2025': 0.6068111301100632,
    '2026': 0.5601948028253176,
    '2027': 0.5135784755405721,
    '2028': 0.4669621482558265,
    '2029': 0.420345820971081,
    '2030': 0.3737294936863355,
    '2031': 0.3558928139845749,
    '2032': 0.33805613428281417,
    '2033': 0.3202194545810535,
    '2034': 0.3023827748792929,
    '2035': 0.28454609517753215,
    '2036': 0.256693126992879,
    '2037': 0.22884015880822586,
    '2038': 0.2009871906235727,
    '2039': 0.17313422243891954,
    '2040': 0.14528125425426638,
    '2041': 0.13404820947436888,
    '2042': 0.12281516469447133,
    '2043': 0.1115821199145738,
    '2044': 0.10034907513467627,
    '2045': 0.08911603035477872,
    '2046': 0.0887102361064963,
    '2047': 0.08830444185821386,
    '2048': 0.08789864760993144,
    '2049': 0.087492853361649,
    '2050': 0.08708705911336656,
  },
  // PT: {
  //   '2020': 0.20155,
  //   '2021': 0.16418000000000002,
  //   '2022': 0.1372,
  //   '2023': 0.10486150742654358,
  //   '2024': 0.07252301485308718,
  //   '2025': 0.040184522279630784,
  //   '2026': 0.03633662518143392,
  //   '2027': 0.032488728083237065,
  //   '2028': 0.0286408309850402,
  //   '2029': 0.024792933886843335,
  //   '2030': 0.02094503678864647,
  //   '2031': 0.019944338416170434,
  //   '2032': 0.0189436400436944,
  //   '2033': 0.017942941671218366,
  //   '2034': 0.016942243298742336,
  //   '2035': 0.0159415449262663,
  //   '2036': 0.014661925765633127,
  //   '2037': 0.013382306604999953,
  //   '2038': 0.012102687444366779,
  //   '2039': 0.010823068283733604,
  //   '2040': 0.00954344912310043,
  //   '2041': 0.008861390692889172,
  //   '2042': 0.008179332262677913,
  //   '2043': 0.007497273832466655,
  //   '2044': 0.006815215402255394,
  //   '2045': 0.006133156972044136,
  //   '2046': 0.0058502508891753145,
  //   '2047': 0.005567344806306493,
  //   '2048': 0.0052844387234376716,
  //   '2049': 0.00500153264056885,
  //   '2050': 0.004718626557700029,
  // },
  // RO: {
  //   '2020': 0.26183999999999996,
  //   '2021': 0.27746,
  //   '2022': 0.27076999999999996,
  //   '2023': 0.22874000705460978,
  //   '2024': 0.18671001410921964,
  //   '2025': 0.14468002116382944,
  //   '2026': 0.1339841403142504,
  //   '2027': 0.12328825946467134,
  //   '2028': 0.11259237861509229,
  //   '2029': 0.10189649776551324,
  //   '2030': 0.09120061691593417,
  //   '2031': 0.087844193121546,
  //   '2032': 0.08448776932715783,
  //   '2033': 0.08113134553276966,
  //   '2034': 0.07777492173838148,
  //   '2035': 0.0744184979439933,
  //   '2036': 0.06994676640320799,
  //   '2037': 0.06547503486242268,
  //   '2038': 0.06100330332163735,
  //   '2039': 0.05653157178085204,
  //   '2040': 0.05205984024006673,
  //   '2041': 0.051019888710868364,
  //   '2042': 0.049979937181669994,
  //   '2043': 0.048939985652471624,
  //   '2044': 0.04790003412327325,
  //   '2045': 0.046860082594074884,
  //   '2046': 0.04457059393872781,
  //   '2047': 0.04228110528338074,
  //   '2048': 0.03999161662803367,
  //   '2049': 0.03770212797268661,
  //   '2050': 0.03541263931733954,
  // },
  // SK: {
  //   '2020': 0.15547999999999998,
  //   '2021': 0.16549,
  //   '2022': 0.15693000000000001,
  //   '2023': 0.1385038691299529,
  //   '2024': 0.1200777382599058,
  //   '2025': 0.10165160738985868,
  //   '2026': 0.0927905821885166,
  //   '2027': 0.08392955698717453,
  //   '2028': 0.07506853178583245,
  //   '2029': 0.06620750658449037,
  //   '2030': 0.05734648138314828,
  //   '2031': 0.05734648138314828,
  //   '2032': 0.05734648138314828,
  //   '2033': 0.05734648138314828,
  //   '2034': 0.05734648138314828,
  //   '2035': 0.05734648138314828,
  //   '2036': 0.05570658001242078,
  //   '2037': 0.054066678641693275,
  //   '2038': 0.05242677727096577,
  //   '2039': 0.05078687590023827,
  //   '2040': 0.04914697452951075,
  //   '2041': 0.04515365409452453,
  //   '2042': 0.0411603336595383,
  //   '2043': 0.03716701322455206,
  //   '2044': 0.033173692789565834,
  //   '2045': 0.02918037235457961,
  //   '2046': 0.02916032990707981,
  //   '2047': 0.02914028745958001,
  //   '2048': 0.02912024501208021,
  //   '2049': 0.029100202564580412,
  //   '2050': 0.029080160117080613,
  // },
  // SI: {
  //   '2020': 0.22405,
  //   '2021': 0.21685,
  //   '2022': 0.24571,
  //   '2023': 0.23051917194025257,
  //   '2024': 0.21532834388050515,
  //   '2025': 0.20013751582075773,
  //   '2026': 0.19835633334226596,
  //   '2027': 0.1965751508637742,
  //   '2028': 0.19479396838528243,
  //   '2029': 0.19301278590679063,
  //   '2030': 0.19123160342829884,
  //   '2031': 0.18387108927218082,
  //   '2032': 0.1765105751160628,
  //   '2033': 0.16915006095994478,
  //   '2034': 0.1617895468038268,
  //   '2035': 0.15442903264770877,
  //   '2036': 0.15075842137901194,
  //   '2037': 0.14708781011031513,
  //   '2038': 0.1434171988416183,
  //   '2039': 0.13974658757292147,
  //   '2040': 0.13607597630422463,
  //   '2041': 0.13607597630422463,
  //   '2042': 0.13607597630422463,
  //   '2043': 0.13607597630422463,
  //   '2044': 0.13607597630422463,
  //   '2045': 0.13607597630422463,
  //   '2046': 0.1359104907541063,
  //   '2047': 0.13574500520398797,
  //   '2048': 0.13557951965386963,
  //   '2049': 0.1354140341037513,
  //   '2050': 0.13524854855363297,
  // },
  // ES: {
  //   '2020': 0.17103,
  //   '2021': 0.15330000000000002,
  //   '2022': 0.16372,
  //   '2023': 0.12413633732239973,
  //   '2024': 0.08455267464479949,
  //   '2025': 0.04496901196719925,
  //   '2026': 0.04496901196719925,
  //   '2027': 0.04496901196719925,
  //   '2028': 0.04496901196719925,
  //   '2029': 0.04496901196719925,
  //   '2030': 0.04496901196719925,
  //   '2031': 0.04496901196719925,
  //   '2032': 0.04496901196719925,
  //   '2033': 0.04496901196719925,
  //   '2034': 0.04496901196719925,
  //   '2035': 0.04496901196719925,
  //   '2036': 0.04109382217912513,
  //   '2037': 0.03721863239105101,
  //   '2038': 0.033343442602976905,
  //   '2039': 0.029468252814902784,
  //   '2040': 0.025593063026828674,
  //   '2041': 0.02486277293358723,
  //   '2042': 0.02413248284034579,
  //   '2043': 0.023402192747104346,
  //   '2044': 0.022671902653862906,
  //   '2045': 0.021941612560621465,
  //   '2046': 0.021941612560621465,
  //   '2047': 0.021941612560621465,
  //   '2048': 0.021941612560621465,
  //   '2049': 0.021941612560621465,
  //   '2050': 0.021941612560621465,
  // },
  // SE: {
  //   '2020': 0.00567,
  //   '2021': 0.00767,
  //   '2022': 0.00662,
  //   '2023': 0.0063679323686781,
  //   '2024': 0.0061158647373562,
  //   '2025': 0.005863797106034299,
  //   '2026': 0.005475814470743495,
  //   '2027': 0.005087831835452692,
  //   '2028': 0.0046998492001618895,
  //   '2029': 0.004311866564871086,
  //   '2030': 0.003923883929580283,
  //   '2031': 0.003812964086408846,
  //   '2032': 0.0037020442432374088,
  //   '2033': 0.003591124400065972,
  //   '2034': 0.0034802045568945346,
  //   '2035': 0.0033692847137230977,
  //   '2036': 0.003302109910256849,
  //   '2037': 0.003234935106790601,
  //   '2038': 0.0031677603033243525,
  //   '2039': 0.003100585499858104,
  //   '2040': 0.003033410696391856,
  //   '2041': 0.003019150655423182,
  //   '2042': 0.003004890614454508,
  //   '2043': 0.002990630573485834,
  //   '2044': 0.0029763705325171605,
  //   '2045': 0.0029621104915484864,
  //   '2046': 0.002940907201059199,
  //   '2047': 0.002919703910569911,
  //   '2048': 0.0028985006200806236,
  //   '2049': 0.002877297329591336,
  //   '2050': 0.002856094039102048,
  // },
  // CH: {
  //   '2020': 0.01415,
  //   '2021': 0.014057282848375598,
  //   '2022': 0.013965173221147334,
  //   '2023': 0.013873667137542659,
  //   '2024': 0.013782760642872833,
  //   '2025': 0.013692449808362015,
  //   '2026': 0.013602730730977463,
  //   '2027': 0.013513599533260858,
  //   '2028': 0.013425052363160732,
  //   '2029': 0.013305420919641278,
  //   '2030': 0.013154705202678295,
  //   '2031': 0.01297290521231212,
  //   '2032': 0.01276002094852662,
  //   '2033': 0.01251605241130566,
  //   '2034': 0.01224099960067344,
  //   '2035': 0.011934862516613826,
  //   '2036': 0.011597641159126818,
  //   '2037': 0.011229335528228553,
  //   '2038': 0.010829945623910962,
  //   '2039': 0.01039947144614984,
  //   '2040': 0.009937912994985528,
  //   '2041': 0.009445270270377686,
  //   '2042': 0.008921543272374722,
  //   '2043': 0.008366732000944364,
  //   '2044': 0.007780836456070475,
  //   '2045': 0.007163856637793397,
  //   '2046': 0.006515792546096992,
  //   '2047': 0.005836644180965125,
  //   '2048': 0.0051264115424220005,
  //   '2049': 0.00438509463045955,
  //   '2050': 0.0036126934450535684,
  // },
  // NO: {
  //   '2020': 0.01,
  //   '2021': 0.009666666666666667,
  //   '2022': 0.009333333333333334,
  //   '2023': 0.009000000000000001,
  //   '2024': 0.008666666666666668,
  //   '2025': 0.008333333333333335,
  //   '2026': 0.008000000000000002,
  //   '2027': 0.007666666666666669,
  //   '2028': 0.007333333333333336,
  //   '2029': 0.007000000000000003,
  //   '2030': 0.00666666666666667,
  //   '2031': 0.006333333333333337,
  //   '2032': 0.0060000000000000045,
  //   '2033': 0.005666666666666671,
  //   '2034': 0.005333333333333338,
  //   '2035': 0.005000000000000005,
  //   '2036': 0.004666666666666672,
  //   '2037': 0.004333333333333339,
  //   '2038': 0.004000000000000006,
  //   '2039': 0.003666666666666673,
  //   '2040': 0.00333333333333334,
  //   '2041': 0.003000000000000007,
  //   '2042': 0.002666666666666674,
  //   '2043': 0.002333333333333341,
  //   '2044': 0.0020000000000000087,
  //   '2045': 0.0016666666666666757,
  //   '2046': 0.0013333333333333426,
  //   '2047': 0.0010000000000000096,
  //   '2048': 0.0006666666666666765,
  //   '2049': 0.00033333333333334346,
  //   '2050': 1.0408340855860843e-17,
  // },
  UK: {
    '2020': 0.23314,
    '2021': 0.21233000000000002,
    '2022': 0.19338,
    '2023': 0.17565269602305575,
    '2024': 0.1579253920461115,
    '2025': 0.14019808806916728,
    '2026': 0.12247078409222305,
    '2027': 0.10474348011527881,
    '2028': 0.08701617613833458,
    '2029': 0.06928887216139035,
    '2030': 0.05156156818444614,
    '2031': 0.04083319198068887,
    '2032': 0.03529295676709409,
    '2033': 0.03064946091946434,
    '2034': 0.027824029572906826,
    '2035': 0.024821674377225565,
    '2036': 0.020533091375209596,
    '2037': 0.01826341332417677,
    '2038': 0.017839912243257382,
    '2039': 0.01689147452962542,
    '2040': 0.015306374560403188,
    '2041': 0.0127064093188859,
    '2042': 0.012058575421494175,
    '2043': 0.011812380257711756,
    '2044': 0.011102442959280711,
    '2045': 0.009428549055544768,
    '2046': 0.008560330717581409,
    '2047': 0.00789298455175684,
    '2048': 0.007491262528418002,
    '2049': 0.006970926731126456,
    '2050': 0.006851248623714,
  },
};
