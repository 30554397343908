import { renovation_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateRenovationTypeEnum = (enumValue: renovation_type_enum) => {
  switch (enumValue) {
    case renovation_type_enum.ENERGY_CONSUMER:
      return <Trans i18nKey="Enum_RenovationTypeEnum-ENERGY_CONSUMER" />;
    case renovation_type_enum.ENERGY_SYSTEM_ROUTE:
      return <Trans i18nKey="Enum_RenovationTypeEnum-ENERGY_SYSTEM_ROUTE" />;
    case renovation_type_enum.ENVELOPE:
      return <Trans i18nKey="Enum_RenovationTypeEnum-ENVELOPE" />;
    case renovation_type_enum.HYDRAULIC_BALANCING:
      return <Trans i18nKey="Enum_RenovationTypeEnum-HYDRAULIC_BALANCING" />;
    case renovation_type_enum.SOLAR_PLANT:
      return <Trans i18nKey="Enum_RenovationTypeEnum-SOLAR_PLANT" />;
  }
};

export const translateRenovationTypeEnum_dynamic = (
  enumValue: renovation_type_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case renovation_type_enum.ENERGY_CONSUMER:
      return t('Enum_RenovationTypeEnum-ENERGY_CONSUMER');
    case renovation_type_enum.ENERGY_SYSTEM_ROUTE:
      return t('Enum_RenovationTypeEnum-ENERGY_SYSTEM_ROUTE');
    case renovation_type_enum.ENVELOPE:
      return t('Enum_RenovationTypeEnum-ENVELOPE');
    case renovation_type_enum.HYDRAULIC_BALANCING:
      return t('Enum_RenovationTypeEnum-HYDRAULIC_BALANCING');
    case renovation_type_enum.SOLAR_PLANT:
      return t('Enum_RenovationTypeEnum-SOLAR_PLANT');
  }
};
