import { List, ListItem, ListItemIcon, ListItemText, Stack, Tooltip } from '@mui/material';
import { ReactNode } from 'react';
import Iconify from '../../../components/Iconify';

type MetaInformationItem = {
  icon: string;
  label: string;
  value?: ReactNode;
};

type Props = {
  items: MetaInformationItem[];
  userInfo?: ReactNode;
};

export default function MetaInformation({ items, userInfo }: Props) {
  return (
    <Stack direction={'row'} sx={{ color: 'text.secondary', typography: 'caption' }}>
      <List
        dense={true}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: 0,
        }}
      >
        {items.map((item: MetaInformationItem, index: number) => (
          <Tooltip key={index} title={item.label} arrow>
            <ListItem sx={{ pr: 2, pl: 0, width: 'unset' }}>
              <ListItemIcon sx={{ mr: 0.5 }}>{<Iconify icon={item.icon} width={16} height={16} />}</ListItemIcon>

              <ListItemText
                primary={item.value}
                primaryTypographyProps={{
                  variant: 'caption',
                }}
              />
            </ListItem>
          </Tooltip>
        ))}
      </List>
      {userInfo}
    </Stack>
  );
}
