import { CardProps, Stack, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { fShortenNumber } from '@predium/utils';
import isNil from 'lodash/isNil';
import { ReactNode } from 'react';
import BenchmarkLabel from '../sections/EsgAnalysis/Components/BenchmarkLabel';

interface Props extends CardProps {
  title: string;
  value?: number | null;
  change?: number | null;
  /**
   * Hide the change label if the change is 0
   */
  hideNoChange?: boolean;
  unit: string;
  showTotal: boolean;
  titleEndAdornment?: ReactNode;
  selectedBenchmarkName?: string;
  isCardLayout?: boolean;
  isConsumption?: boolean;
}

export default function SimpleComparisonMetric({
  title,
  value,
  change,
  hideNoChange,
  unit,
  showTotal,
  titleEndAdornment,
  selectedBenchmarkName = '',
  isCardLayout = false,
  isConsumption = false,
  sx,
}: Props) {
  const theme = useTheme();

  const metric = (
    <Stack sx={{ height: '100%' }}>
      <Stack direction="row" alignItems="center" flexWrap="wrap">
        <Typography variant="subtitle2" sx={{ padding: '2px 0', marginRight: 'auto' }}>
          {title}
        </Typography>
      </Stack>
      <Stack direction="row" whiteSpace="nowrap">
        <Stack alignItems={'baseline'} direction={'row'}>
          <Typography variant="h3">{!isNil(value) ? fShortenNumber(value) : '-'}</Typography>
          <Typography
            variant="subtitle2"
            color={theme.palette.grey[500]}
            sx={{ display: 'flex', alignItems: 'baseline', ml: 0.5 }}
          >
            {isConsumption ? `${unit}${showTotal ? '' : '/m²'}` : `${unit}${showTotal ? '/a' : '/m²a'}`}{' '}
          </Typography>
        </Stack>

        {titleEndAdornment}
      </Stack>
      {selectedBenchmarkName && selectedBenchmarkName.length > 0 && (
        <BenchmarkLabel change={change} hideNoChange={hideNoChange} selectedBenchmarkName={selectedBenchmarkName} />
      )}
    </Stack>
  );

  return isCardLayout ? <Card sx={{ p: 2, height: '100%', ...sx }}>{metric}</Card> : <>{metric}</>;
}
