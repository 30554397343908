import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  onCancel: () => void;
  errorCountText?: ReactNode;
  primaryButton: ReactNode;
};

export default function FormEditBar({ title, onCancel, errorCountText = '', primaryButton }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        backgroundColor: theme.palette.grey[200],
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        px: 3,
        py: 2,
        borderRadius: 1,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1}>
        <Typography variant="h5">{title}</Typography>
        {errorCountText}
      </Stack>
      <Box>
        <Button variant="outlined" sx={{ mr: 2 }} onClick={onCancel}>
          {t('General_Cancel')}
        </Button>
        {primaryButton}
      </Box>
    </Stack>
  );
}
