import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LINKS } from '../../../../assets/links';
import Iconify from '../../../../components/Iconify';
import { PreLink } from '../../../../components/presentations/PreLink';

export const NoCostAvailableWithLink = () => {
  const { t } = useTranslation();

  return (
    <Stack direction={{ xs: 'column', lg: 'row' }} color="text.secondary">
      <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
        {t('General_NoCostAvailableForAction')}
      </Typography>
      <PreLink
        link={{
          href: LINKS.NO_COST_AVAILABLE_FOR_ACTION_WITH_EXPLANATION,
          name: t('General_LearnMore'),
          external: true,
          icon: <Iconify icon={'mdi:open-in-new'} width={12} ml={0.5} />,
          sx: { ml: { xs: 0, lg: 0.5 } },
        }}
        TypographyProps={{ variant: 'caption', sx: { whiteSpace: 'nowrap' } }}
      />
    </Stack>
  );
};
