import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IMAGES } from '../../../../../assets';
import { ICONS } from '../../../../../assets/icons';
import Iconify from '../../../../../components/Iconify';
import AccessRightsWrapper from '../../../../../components/permission-tooltips/AccessRightsWrapper';
import useOrgPreferences from '../../../../../hooks/useOrgPreferences';

type Props = {
  hasEditAccess: boolean;
  onMoveTo: VoidFunction;
  onExportData: VoidFunction;
  onDelete: VoidFunction;
};

const ActionButtons = ({ hasEditAccess, onDelete, onExportData, onMoveTo }: Props) => {
  const { t } = useTranslation();
  const { economicUnitsToggled } = useOrgPreferences();

  return (
    <>
      {economicUnitsToggled && (
        <AccessRightsWrapper hasAccess={hasEditAccess}>
          <Button variant="text" sx={{ mr: 2 }} onClick={onMoveTo}>
            {t('General_MoveTo')}
            <Box component="img" ml={1} src={IMAGES.MoveToIcon} />
          </Button>
        </AccessRightsWrapper>
      )}
      <Button
        variant="text"
        sx={{ mr: 2 }}
        onClick={onExportData}
        endIcon={<Iconify icon={'eva:cloud-download-outline'} width={20} height={20} />}
      >
        {t('General_ExportData')}
      </Button>
      <AccessRightsWrapper hasAccess={hasEditAccess}>
        <Button variant="text" color="error" onClick={onDelete} endIcon={<Iconify icon={ICONS.TRASH} />}>
          {t('General_Delete')}
        </Button>
      </AccessRightsWrapper>
    </>
  );
};

export default ActionButtons;
