/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import { DataCollectionGetEconomicUnitWithBuildingsFragment } from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ICONS } from '../../../assets/icons';
import DeleteConfirmationModal, { AssociatedDataType } from '../../../components/DeleteConfirmationModal';
import Iconify from '../../../components/Iconify';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import AccessRightsWrapper from '../../../components/permission-tooltips/AccessRightsWrapper';
import PreDialog from '../../../components/presentations/PreDialog/PreDialog';
import { PermissionType } from '../../../contexts/PermissionContext';
import { DELETE_ECONOMIC_UNITS } from '../../../graphql/DataCollection.mutations';
import useGetAssociatedDataForBuilding from '../../../hooks/useGetAssociatedDataForBuilding';
import usePermissions from '../../../hooks/usePermissions';
import useTooltipConstants from '../../../hooks/useTooltipConstants';
import LimitedBulkExport from '../Buildings/BulkActions/LimitedBulkExport';

type Props = {
  selectedEconomicUnits: number[];
  economicUnitsData: DataCollectionGetEconomicUnitWithBuildingsFragment[];
  onActionCompleted: () => void;
};

export default function EconomicUnitsActionMenu({
  selectedEconomicUnits,
  economicUnitsData,
  onActionCompleted,
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { tooltips: deleteTooltip } = useTooltipConstants();
  const { checkPortfolioPermission } = usePermissions();

  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const buildingIds = useMemo(() => {
    const getBuildingsToExport = (
      economicUnitsData: DataCollectionGetEconomicUnitWithBuildingsFragment[],
      selectedEconomicUnits: number[],
    ): number[] | [] => {
      return economicUnitsData
        .filter((unit) => selectedEconomicUnits.includes(unit.id))
        .map((unit) => unit.buildings.map((building) => building.id))
        .flat()
        .filter(Boolean);
    };

    return getBuildingsToExport(economicUnitsData, selectedEconomicUnits);
  }, [economicUnitsData, selectedEconomicUnits]);

  const hasDeleteAccess = selectedEconomicUnits.every((id) => checkPortfolioPermission(id, PermissionType.WRITE));

  const { associatedDataForDeletion } = useGetAssociatedDataForBuilding({
    buildingIds,
    modalOpen: deleteModalOpen,
  });

  const associatedDataForDeletionWithBuildings = useMemo(() => {
    if (associatedDataForDeletion && buildingIds.length) {
      return new Map(associatedDataForDeletion).set(AssociatedDataType.BUILDING, buildingIds.length);
    }
    return new Map();
  }, [associatedDataForDeletion, buildingIds]);

  const hasBuildingInEconomicUnit = (): boolean => {
    return economicUnitsData
      .filter((unit) => selectedEconomicUnits.includes(unit.id))
      .some((unit) => unit.buildings.length > 0);
  };

  const showErrorSnackbar = () => {
    enqueueSnackbar(t('DataCollection_DeleteEconomicUnit-error', { count: selectedEconomicUnits.length }), {
      variant: 'error',
      autoHideDuration: SnackbarTimeouts.Error,
    });
  };

  const [deleteEconomicUnits] = useMutation(DELETE_ECONOMIC_UNITS, {
    variables: {
      economicUnitIds: selectedEconomicUnits,
    },
    update: (cache, { data }) => {
      //@ts-ignore

      //@ts-ignore
      if (data.delete_economic_unit.affected_rows > 0) {
        //@ts-ignore

        //@ts-ignore
        data.delete_economic_unit.returning.forEach(({ id, __typename }) => {
          const normalizedId = cache.identify({
            id,
            __typename,
          });
          cache.evict({ id: normalizedId });
        });

        cache.gc();
      }
    },
    onError: () => {
      showErrorSnackbar();
    },
    onCompleted: (data) => {
      if (!data.delete_economic_unit?.affected_rows) {
        showErrorSnackbar();
        return;
      }
      enqueueSnackbar(t('DataCollection_DeleteEconomicUnit-Success', { count: selectedEconomicUnits.length }), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      setDeleteModalOpen(false);
      onActionCompleted();
    },
  });

  return (
    <>
      {hasBuildingInEconomicUnit() && (
        <Button
          variant="text"
          sx={{ mr: 2 }}
          onClick={() => setExportModalOpen(true)}
          endIcon={<Iconify icon={'eva:cloud-download-outline'} />}
        >
          {t('General_ExportData')}
        </Button>
      )}
      <AccessRightsWrapper hasAccess={hasDeleteAccess}>
        <Button
          color="error"
          variant="text"
          onClick={() => setDeleteModalOpen(true)}
          endIcon={<Iconify icon={ICONS.TRASH} />}
        >
          {t('General_Delete')}
        </Button>
      </AccessRightsWrapper>

      <PreDialog type="definedByChildren" open={exportModalOpen} onClose={() => setExportModalOpen(false)}>
        <LimitedBulkExport buildingIds={buildingIds} onActionCompleted={onActionCompleted} />
      </PreDialog>

      <DeleteConfirmationModal
        open={deleteModalOpen}
        title={t('General_DeleteModalTitle-EconomicUnit', { count: selectedEconomicUnits?.length })}
        description={
          // t('General_DeleteModalDescription-EconomicUnit', { count })
          <Trans
            i18nKey={'General_DeleteModalDescription-EconomicUnit'}
            values={{
              name:
                selectedEconomicUnits?.length === 1
                  ? economicUnitsData.find((eu) => eu.id === selectedEconomicUnits[0])?.name ?? ''
                  : '',
              count: selectedEconomicUnits?.length,
              alongWithAssociatedData:
                associatedDataForDeletionWithBuildings.size > 0
                  ? t('General_DeleteWarningAssociatedDataText', { count: selectedEconomicUnits?.length })
                  : '',
            }}
            components={{ bold: <strong /> }}
          />
        }
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        onDelete={() => {
          deleteEconomicUnits();
        }}
        deleteConfirmationText={t('General_DeleteModalConfirmation-EconomicUnit', {
          count: selectedEconomicUnits?.length,
        })}
        dataLossWarningText={
          associatedDataForDeletionWithBuildings.size > 0 ? t('General_DeleteModalWarning-EconomicUnit') : undefined
        }
        associatedData={associatedDataForDeletionWithBuildings}
        tooltips={deleteTooltip}
      />
    </>
  );
}
