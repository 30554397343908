import type { useSubscription } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import { Container, Stack } from '@mui/material';
import {
  DataCollectionConsumptionDraftStatusSubscriptionSubscription,
  draft_status_enum,
} from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import FocusViewPage, { Props as FocusPageProps } from '../../components/FocusViewPage';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { DelayedLoading } from '../../components/Loading';
import { SnackbarTimeouts } from '../../components/NotistackProvider';
import Page from '../../components/Page';
import PdfViewer from '../../components/PdfViewer';
import Unavailable from '../../components/Unavailable';
import {
  CONSUMPTION_DRAFT_STATUS_SUBSCRIPTION,
  CREATE_CONSUMPTION_INVOICE_FROM_DRAFT,
  DELETE_CONSUMPTION_INVOICE_DRAFT,
  SET_CONSUMPTION_INVOICE_DRAFT_OCR_FAILED,
} from '../../graphql/DataCollection.mutations';
import {
  GET_BUILDING,
  GET_CONSUMPTIONS_INVOICES,
  GET_CONSUMPTION_INVOICE_DRAFT,
  GET_CONSUMPTION_INVOICE_DRAFTS,
  GET_CONSUMPTION_SUMMARIES,
} from '../../graphql/DataCollection.queries';
import usePosthogTracking from '../../hooks/usePosthogTracking';
import useSessionData from '../../hooks/useSessionData';
import { PATHS } from '../../routes/paths';
import SubBuildingConsumptionInvoiceDraft from '../../sections/DataCollection/Building/Consumption/SubBuildingConsumptionInvoiceDraft';
import { ConsumptionTabNamesEnum } from '../../sections/DataCollection/Building/Consumption/SubBuildingConsumptionListTabs';
import DataCollectionDraft from '../../sections/DataCollection/Building/Drafts/DataCollectionDraft';
import { DraftStatusLoading } from '../../sections/DataCollection/Building/Drafts/DraftStatusLoading';
import SubBuildingConsumptionDraftForm from '../../sections/DataCollection/Building/Drafts/SubBuildingConsumptionDraftForm';
import { BuildingTabEnum } from './DataCollectionBuilding';

type Props = {
  useSubscription: typeof useSubscription;
};

export default function DataCollectionSubBuildingConsumptionDraft({ useSubscription }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { id: buildingIdString, draftId: consumptionDraftIdString } = useParams();

  const { user, org } = useSessionData();
  const { trackEvent } = usePosthogTracking();

  const buildingId = parseInt(buildingIdString!, 10);
  const consumptionInvoiceDraftId = parseInt(consumptionDraftIdString!);
  const [isDirty, setIsDirty] = useState(false);
  const [subscriptionStatusData, setSubscriptionStatusData] =
    useState<DataCollectionConsumptionDraftStatusSubscriptionSubscription | null>(null);
  const [acceptOcrResultModalOpen, setAcceptOcrResultModalOpen] = useState<boolean>(false);
  const [steps, setSteps] = useState<number[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [deleteConsumptionInvoiceDraftMutation] = useMutation(DELETE_CONSUMPTION_INVOICE_DRAFT, {
    variables: {
      invoiceDraftId: consumptionInvoiceDraftId,
    },
    update: (cache, { data }) => {
      if (data?.delete_consumption_invoice_draft_by_pk) {
        const normalizedId = cache.identify({
          id: data.delete_consumption_invoice_draft_by_pk.id,
          __typename: data.delete_consumption_invoice_draft_by_pk.__typename,
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
    onCompleted: (data) => {
      if (data.delete_consumption_invoice_draft_by_pk) {
        enqueueSnackbar(t('DataCollectionSubBuildingConsumptionDraft_DeleteConsumptionDraft-success'), {
          variant: 'success',
          autoHideDuration: SnackbarTimeouts.Success,
        });
        navigate(
          PATHS.dataCollection.building(
            { id: buildingId },
            { tab: BuildingTabEnum.consumption, consumptionsTab: ConsumptionTabNamesEnum.consumption_drafts },
          ),
        );
      }
    },
    onError: () =>
      enqueueSnackbar(t('DataCollectionSubBuildingConsumptionDraft_DeleteConsumptionDraft-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
  });

  const [setConsumptionDraftOCRMutation] = useMutation(SET_CONSUMPTION_INVOICE_DRAFT_OCR_FAILED, {
    variables: {
      consumptionInvoiceDraftId,
    },
    onCompleted: () =>
      enqueueSnackbar(t('DataCollectionSubBuildingConsumptionDraft_SaveChanges-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
  });

  useQuery(GET_CONSUMPTION_INVOICE_DRAFTS, {
    fetchPolicy: 'network-only',
    variables: { buildingId },
    onCompleted: (data) => {
      if (data.consumption_invoice_draft) {
        const ids = data.consumption_invoice_draft.map((item) => item.id);
        setSteps(ids);
      }
    },
    onError: () => {
      navigate(PATHS.notFound());
    },
  });

  const { data: consumptionInvoiceDraftData, refetch } = useQuery(GET_CONSUMPTION_INVOICE_DRAFT, {
    variables: { consumptionInvoiceDraftId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data.consumption_invoice_draft_by_pk) {
        return navigate(PATHS.notFound());
      }
    },
    onError: () => {
      enqueueSnackbar(t('DataCollectionSubBuildingConsumptionDraft_FetchDraft-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
      navigate(PATHS.notFound());
    },
  });

  const { error: subscriptionError, loading } = useSubscription(CONSUMPTION_DRAFT_STATUS_SUBSCRIPTION, {
    variables: {
      consumptionInvoiceDraftId: consumptionInvoiceDraftId,
    },
    onData: async ({ data }) => {
      const { data: subscriptionData } = data;
      if (subscriptionData && subscriptionData.consumption_invoice_draft_by_pk) {
        const status = subscriptionData.consumption_invoice_draft_by_pk.draft_status;

        // trigger GET_ENERGY_CERTIFICATE_DRAFT query when subscription status changes but when its not uploading and OCR is not in progress
        if (status !== draft_status_enum.UPLOADING && status !== draft_status_enum.OCR_IN_PROGRESS) {
          await refetch();
        }
        // Update the draft status only after new form data has been fetched.
        setSubscriptionStatusData(subscriptionData ?? null);
      }
    },
  });

  const [createConsumptionInvoiceFromDraftMutation] = useMutation(CREATE_CONSUMPTION_INVOICE_FROM_DRAFT, {
    variables: {
      consumptionInvoiceDraftId,
    },
    refetchQueries: [
      { query: GET_BUILDING, variables: { buildingId, year: new Date().getFullYear() } },
      { query: GET_CONSUMPTION_INVOICE_DRAFTS, variables: { buildingId } },
      { query: GET_CONSUMPTION_SUMMARIES, variables: { buildingId, year: new Date().getFullYear() } },
      { query: GET_CONSUMPTIONS_INVOICES, variables: { buildingId } },
    ],
    onError: () =>
      enqueueSnackbar(t('DataCollectionSubBuildingConsumptionDraft_CreateConsumptionInvoice-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
    onCompleted: () => {
      // need to get updated steps inline to avoid race condition
      const stepsAfterUpdate = steps.filter((id) => id !== consumptionInvoiceDraftId);
      if (stepsAfterUpdate.length === 0) {
        navigate(PATHS.dataCollection.building({ id: buildingId }, { tab: BuildingTabEnum.consumption }));
      } else {
        if (activeStep === stepsAfterUpdate.length) {
          navigate(
            PATHS.dataCollection.buildingConsumptionInvoiceDraft({ id: buildingId, draftId: stepsAfterUpdate[0] }),
          );
        } else {
          navigate(
            PATHS.dataCollection.buildingConsumptionInvoiceDraft({
              id: buildingId,
              // Go to the next draft in line.
              draftId: stepsAfterUpdate[Math.min(activeStep + 1, stepsAfterUpdate.length - 1)],
            }),
          );
        }
      }
    },
  });

  const maxSteps = steps.length;
  const activeStep = steps.indexOf(consumptionInvoiceDraftId) ?? 0;
  const handleStep = (direction: 'increase' | 'decrease') => () => {
    const prevStep = activeStep + (direction === 'increase' ? 1 : -1);
    navigate(PATHS.dataCollection.buildingConsumptionInvoiceDraft({ id: buildingId, draftId: steps[prevStep] }));
  };
  const stepperProps: FocusPageProps['navigationStepperProps'] = useMemo(
    () => ({
      backStepTitle: t('DataCollection_PreviousDraft'),
      nextStepTitle: t('DataCollection_NextDraft'),
      maxSteps: maxSteps,
      handleNext: handleStep('increase'),
      handleBack: handleStep('decrease'),
      activeStep,
      getDisplayText: ({ currentStep, maxSteps }) =>
        t('General_DraftNumberOfTotal', {
          current: currentStep,
          total: maxSteps,
        }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeStep, maxSteps],
  );
  if (
    loading ||
    !consumptionInvoiceDraftData ||
    !consumptionInvoiceDraftData.consumption_invoice_draft_by_pk ||
    !subscriptionStatusData ||
    !subscriptionStatusData.consumption_invoice_draft_by_pk ||
    steps.length === 0
  ) {
    return <DelayedLoading />;
  }
  if (subscriptionError) {
    return <Unavailable title={t('DataCollection_ConsumptionDraft_DraftDoesNotExist')} />;
  }

  const createConsumptionInvoiceFromDraft = async () => {
    const { data } = await createConsumptionInvoiceFromDraftMutation();

    if (data) {
      trackEvent('BUILDING_INVOICE_CONSUMPTION_CREATED', {
        orgId: org!.id,
        userId: user!.id,
        buildingId: buildingId,
        consumptionInvoiceId: data.createConsumptionInvoiceFromDraft.consumption_invoice_id,
      });
    }
  };

  const navigateToNextStep = () => {
    if (activeStep === maxSteps - 1) {
      navigate(PATHS.dataCollection.buildingConsumptionInvoiceDraft({ id: buildingId, draftId: steps[0] }));
    } else {
      navigate(
        PATHS.dataCollection.buildingConsumptionInvoiceDraft({ id: buildingId, draftId: steps[activeStep + 1] }),
      );
    }
  };

  const goToNextAfterDelete = () => {
    if (maxSteps === 1) {
      navigate(PATHS.dataCollection.building({ id: buildingId }, { tab: BuildingTabEnum.consumption }));
    } else {
      navigateToNextStep();
    }
  };

  const draftStatus = subscriptionStatusData.consumption_invoice_draft_by_pk.draft_status;
  const isEditable = !(
    draftStatus === draft_status_enum.UPLOADING || draftStatus === draft_status_enum.OCR_IN_PROGRESS
  );

  const deleteInvoiceDraft = async () => {
    await deleteConsumptionInvoiceDraftMutation();
    goToNextAfterDelete();
  };

  const setOcrFailed = async () => {
    await setConsumptionDraftOCRMutation();
  };

  const building = consumptionInvoiceDraftData.consumption_invoice_draft_by_pk!.building;

  if (!isEditable) {
    return (
      <Page title={t('PageName_DataCollection_ConsumptionDraft')}>
        <Container maxWidth="lg" sx={{ mb: 5 }}>
          <Stack sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <HeaderBreadcrumbs
              heading={building.address?.street ?? ''}
              links={[
                { name: t('General_DataCollection'), href: PATHS.dataCollection.buildings() },
                {
                  name: t('General_Consumption'),
                  href: PATHS.dataCollection.building(
                    { id: buildingId },
                    { tab: BuildingTabEnum.consumption, consumptionsTab: ConsumptionTabNamesEnum.consumption_drafts },
                  ),
                },
                { name: t('PageName_DataVerification') },
              ]}
              infoText={t('DataCollection_Drafts_InfoText', { draftType: t('General_Invoice', { count: 1 }) })}
              sx={{ mb: 0 }}
            ></HeaderBreadcrumbs>
          </Stack>
          <DraftStatusLoading
            deleteInvoiceDraft={deleteInvoiceDraft}
            stepperProps={stepperProps}
            draftSubscription={{
              draftStatus,
              updatedAt: new Date(subscriptionStatusData!.consumption_invoice_draft_by_pk!.updated_at!),
            }}
            onDeleteAndRetry={async () => {
              await deleteInvoiceDraft();
            }}
            onManualEntry={async () => {
              await setOcrFailed();
            }}
          />
        </Container>
      </Page>
    );
  }

  return (
    <>
      <SubBuildingConsumptionDraftForm
        setIsDirty={setIsDirty}
        consumptionInvoiceDraftData={consumptionInvoiceDraftData.consumption_invoice_draft_by_pk}
        onCreateInvoice={createConsumptionInvoiceFromDraft}
        renderChildren={(submitForm, errors) => (
          <FocusViewPage
            primaryAction={{
              label: t('General_Create'),
              isDisabled: Object.keys(errors).length > 0,
            }}
            secondaryAction={{
              isDisabled: !isDirty,
              onClick: async () => {
                await submitForm();
                setTimeout(() => {
                  navigate(
                    PATHS.dataCollection.building(
                      { id: buildingId },
                      { tab: BuildingTabEnum.consumption, consumptionsTab: ConsumptionTabNamesEnum.consumption_drafts },
                    ),
                  );
                }, 1000);
              },
              label: t('General_ContinueLater'),
            }}
            onDeleteCallback={() => setDeleteModalOpen(true)}
            navigationStepperProps={stepperProps}
            minorContent={
              <DataCollectionDraft
                deleteModalOpen={deleteModalOpen}
                submitForm={submitForm}
                acceptOcrResultModalOpen={acceptOcrResultModalOpen}
                setAcceptOcrResultModalOpen={setAcceptOcrResultModalOpen}
              >
                <SubBuildingConsumptionInvoiceDraft />
              </DataCollectionDraft>
            }
            majorContent={
              consumptionInvoiceDraftData.consumption_invoice_draft_by_pk!.file ? (
                <PdfViewer
                  fileURL={consumptionInvoiceDraftData.consumption_invoice_draft_by_pk!.file.downloadUrl}
                  compact
                />
              ) : null
            }
            onCloseCallback={() => {
              navigate(
                PATHS.dataCollection.building(
                  { id: buildingId },
                  { tab: BuildingTabEnum.consumption, consumptionsTab: ConsumptionTabNamesEnum.consumption_drafts },
                ),
              );
            }}
            title={t('PageName_DataCollection_ConsumptionInvoiceDraft')}
            subTitle={consumptionInvoiceDraftData.consumption_invoice_draft_by_pk!.file!.name!}
          />
        )}
      />

      <DeleteConfirmationModal
        open={deleteModalOpen}
        title={t('DataCollection_DeleteConsumptionDraftInvoiceModal-title')}
        description={t('DataCollection_DeleteConsumptionDraftInvoiceModal-description')}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        onDelete={async () => {
          await deleteInvoiceDraft();
          setDeleteModalOpen(false);
        }}
      />
    </>
  );
}
