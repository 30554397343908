import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import isString from 'lodash/isString';
import { ReactNode } from 'react';
import Breadcrumbs, { Props as BreadcrumbsProps } from './Breadcrumbs';
import InfoTooltip from './InfoTooltip';

interface Props extends BreadcrumbsProps {
  action?: ReactNode;
  heading: string | ReactNode;
  moreLink?: string[];
  infoText?: string;
  navigationSelector?: ReactNode;
}

export default function HeaderBreadcrumbs({
  links,
  action,
  heading,
  infoText,
  moreLink = '' || [],
  sx,
  navigationSelector,
  ...other
}: Props) {
  return (
    <Box sx={{ mb: 5, ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          {links.length > 0 && <Breadcrumbs links={links} {...other} />}

          <Stack direction="row" alignItems="center" sx={{ pb: 0.5 }}>
            {typeof heading === 'string' ? <Typography variant="h4">{heading}</Typography> : heading}
            {infoText && <InfoTooltip text={infoText} maxwidth={800} />}
            {navigationSelector}
          </Stack>
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>

      <Box sx={{ mt: 2 }}>
        {isString(moreLink) ? (
          <div />
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}
