import { renovation_type_enum, subsidy_category_type_enum, tax_bracket_enum } from '@predium/enums';

/**
 * TODO: tax_bracket_enum._0_100 is missing. We can translate it in this state.
 */
export function getTaxBracketLandlordEnumTitle(taxBracket: tax_bracket_enum): string {
  let exhaustiveCheck: never;

  switch (taxBracket) {
    case tax_bracket_enum._10_90:
      return '90%';
    case tax_bracket_enum._20_80:
      return '80%';
    case tax_bracket_enum._30_70:
      return '70%';
    case tax_bracket_enum._40_60:
      return '60%';
    case tax_bracket_enum._50_50:
      return '50%';
    case tax_bracket_enum._60_40:
      return '40%';
    case tax_bracket_enum._70_30:
      return '30%';
    case tax_bracket_enum._80_20:
      return '20%';
    case tax_bracket_enum._90_10:
      return '10%';
    case tax_bracket_enum._100_0:
      return '0%';
    default:
      exhaustiveCheck = taxBracket;
      throw new Error(`Unhandled tax_bracket_enum ${taxBracket}: ${exhaustiveCheck}`);
  }
}

/**
 * A helper function to map the renovation type of a simulated action to the applicable subsidy categories and check which are applicable to the given action
 * @param renovationType The renovation type of the simulated action
 * @returns The subsidy categories that are applicable to the given renovation type
 */
export function mapActionCategoryToSubsidyCategories(
  renovationType: renovation_type_enum,
): subsidy_category_type_enum[] {
  let applicableSubsidies: subsidy_category_type_enum[] = [];

  switch (renovationType) {
    case renovation_type_enum.ENERGY_CONSUMER:
      applicableSubsidies = [
        subsidy_category_type_enum.SYSTEMS_HEATING,
        subsidy_category_type_enum.SYSTEMS_VENTILATION,
      ];
      break;
    case renovation_type_enum.ENVELOPE:
      applicableSubsidies = [
        subsidy_category_type_enum.ENVELOPE_CEILING,
        subsidy_category_type_enum.ENVELOPE_WALL,
        subsidy_category_type_enum.ENVELOPE_FLOOR,
        subsidy_category_type_enum.ENVELOPE_WINDOW,
        subsidy_category_type_enum.ENVELOPE_ROOF,
      ];
      break;
    case renovation_type_enum.SOLAR_PLANT:
      applicableSubsidies = [subsidy_category_type_enum.SYSTEMS_PV];
      break;
    case renovation_type_enum.ENERGY_SYSTEM_ROUTE:
      break;
    default:
      break;
  }

  return applicableSubsidies;
}
