import { Tooltip } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../Iconify';
import { IconButtonAnimate } from '../animate';
import HelpCenterModal from './HelpCenterModal';

export default function HelpCenter() {
  const { t } = useTranslation();
  const [helpCenterModalOpen, setHelpCenterModalOpen] = useState(false);

  return (
    <>
      <Tooltip title={t('Settings_Support')} placement="left" arrow>
        <IconButtonAnimate
          data-cy="cy-help-center-menu"
          onClick={() => setHelpCenterModalOpen(true)}
          sx={{ width: 40, height: 40, mr: 1 }}
        >
          <Iconify icon="mdi:help-circle-outline" />
        </IconButtonAnimate>
      </Tooltip>
      <HelpCenterModal open={helpCenterModalOpen} onClose={() => setHelpCenterModalOpen(false)} />
    </>
  );
}
