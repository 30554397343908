import { Box, Grid, Stack, Typography } from '@mui/material';
import { TARGET_PATH_LAST_YEAR } from '@predium/client-lookup';
import { fRoundedNumber } from '@predium/utils';
import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import InfoTooltip from '../../../../components/InfoTooltip';
import BenchmarkLabel from '../../Components/BenchmarkLabel';
import { Co2PathGraph } from '../../Visualization/EsgPathGraphs/Co2PathGraph';

type Props = {
  co2Path: number[];
  co2TargetPath: number[];
  buildingLegendName: string;
  strandingYear?: number;
  cumulativeExcessEmissions: number | undefined;
  graphStartYear: number;
  benchmark: {
    selectedBenchmarkName: string;
    strandingYearChange: number | null;
  };
};

export function Co2CrremAnalysis({
  co2Path,
  co2TargetPath,
  buildingLegendName,
  strandingYear,
  cumulativeExcessEmissions,
  graphStartYear,
  benchmark: { selectedBenchmarkName, strandingYearChange },
}: Props) {
  const { t } = useTranslation();

  const isDataAvailable = co2Path && co2Path.length > 0;

  const excessiveEmissions =
    isDataAvailable && !isNil(cumulativeExcessEmissions) ? fRoundedNumber(cumulativeExcessEmissions / 1000, 1) : '-';

  return (
    <Grid container>
      <Grid container>
        <Grid item xs={12} md={2} lg={2}>
          <Box>
            <Box mb={4}>
              <Typography variant="subtitle1" sx={{ mr: 1 }}>
                {t('General_StrandingDate')}
              </Typography>
              <Typography variant="h3" sx={{ mr: 1 }}>
                {strandingYear && isDataAvailable ? strandingYear : '-'}
              </Typography>
              {isDataAvailable && selectedBenchmarkName && (
                <BenchmarkLabel
                  change={strandingYearChange}
                  selectedBenchmarkName={selectedBenchmarkName}
                  unit="a"
                  inverse
                />
              )}
            </Box>
            <Stack>
              <Stack direction={'row'} alignItems={'center'}>
                <Typography variant="subtitle1" sx={{ mr: 1 }}>
                  {t('EsgAnalysis_CrremAnalysis-CumulativeExcessEmissions')}
                  <InfoTooltip
                    text={t('EsgAnalysis_Co2CrremAnalysis_ExcessEmissionsTooltip')}
                    sx={{ ml: 1, p: 0, svg: { height: 20, width: 20 } }}
                  ></InfoTooltip>
                </Typography>
              </Stack>
              <Stack direction={'row'} alignItems={'baseline'}>
                <Typography variant="h3" sx={{ mr: 1 }}>
                  {excessiveEmissions}
                </Typography>
                <Typography variant="subtitle2" color={'text.secondary'}>
                  t CO₂e
                </Typography>
              </Stack>
              <Typography variant="caption" color={'text.secondary'} sx={{ mt: -1 }}>
                {t('EsgAnalysis_Co2CrremAnalysisUntil2050')}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={10} lg={10}>
          <Box pl={3}>
            <Stack pl={2}>
              <Stack direction={'row'} alignItems={'center'}>
                <Typography variant="body1" sx={{ fontWeight: 700, mr: 1 }}>
                  {t('General_CO2Path')}
                </Typography>
                <Typography variant="caption">{t('EsgAnalysis_CrremAnalysis-Co2GraphCaption')}</Typography>
              </Stack>
              <Typography variant="caption">kg CO₂e/m²a</Typography>
            </Stack>

            <Co2PathGraph
              co2Path={co2Path}
              co2TargetPath={co2TargetPath}
              buildingLegendName={buildingLegendName}
              graphStartYear={graphStartYear}
              strandingYears={strandingYear ? [{ startYear: strandingYear, endYear: TARGET_PATH_LAST_YEAR }] : []}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
