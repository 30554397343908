import { Button, DialogActions, DialogContent, DialogTitle, Stack, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../../components/Iconify';
import { DelayedLoading } from '../../../../../components/Loading';
import useSessionData from '../../../../../hooks/useSessionData';
import { useLanguage } from '../../../../../provider/LanguageProvider';
import { downloadFile } from '../../../../../utils/createDownloadLink';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '../../../../../utils/formatTime';

type Props = {
  count: number;
  triggerExport: VoidFunction;
  exportBody?: string;
  onActionCompleted?: VoidFunction;
};

export default function BulkExport({ count, triggerExport, exportBody, onActionCompleted }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { language } = useLanguage();

  const [isDelayed, setIsDelayed] = useState(true);
  const { org } = useSessionData();

  //TODO refactor this and get rid of useEffect.
  useEffect(() => {
    triggerExport();
    setIsDelayed(true);

    // This is used to make sure the loading screen is shown at least 2 seconds
    setTimeout(() => {
      setIsDelayed(false);
    }, 2000);
  }, [triggerExport]);

  if (exportBody && !isDelayed) {
    const currentDate = formatDateToLocale(new Date(), COMMON_DATE_FORMATS.DAY_MONTH_YEAR_TIME, language);

    return (
      <>
        <DialogTitle>{t('General_DataExportCompleted')}</DialogTitle>
        <Stack direction="column" alignItems="center">
          <Iconify icon="bi:check" width={90} height={90} sx={{ mt: 3, mb: 3 }} color={theme.typography.h4.color} />
        </Stack>

        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            startIcon={<Iconify icon={'eva:cloud-download-outline'} />}
            onClick={() => {
              //@ts-ignore
              const fileName = `${currentDate}-Predium-Datenexport-${org.name.replace(' ', '-')}.xlsx`;
              downloadFile(exportBody, 'xlsx', fileName);
              if (onActionCompleted) {
                onActionCompleted();
              }
            }}
          >
            {t('General_DownloadDataExport')}
          </Button>
        </DialogActions>
      </>
    );
  }

  return (
    <>
      <DialogTitle>{t('DataCollection_BuildingDataExportIsCreated', { count })}</DialogTitle>
      <DialogContent sx={{ height: 250 }}>
        <DelayedLoading delay={0} />
      </DialogContent>
    </>
  );
}
