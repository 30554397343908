/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Container } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { BenchmarkType, ENTIRE_COMPANY } from '../../sections/EsgAnalysis/Components/EsgAnalysisBenchmark';
import { FloorAreaBySubBuildingClass } from '../../sections/EsgAnalysis/EsgAnalysisUtil';
import PortfolioAnalysisBody from '../../sections/EsgAnalysis/Portfolio/PortfolioAnalysisBody';
import PortfolioAnalysisHeader, { EsgAnalysisTab } from '../../sections/EsgAnalysis/Portfolio/PortfolioAnalysisHeader';

export default function EsgAnalysisPortfolios() {
  const [floorAreaByBuildingClass, setFloorAreaByBuildingClass] = useState<FloorAreaBySubBuildingClass>([]);
  const [currentTab, setCurrentTab] = useState<EsgAnalysisTab>('demand');
  const [showTotal, setShowTotal] = useState<boolean>(false);
  const [currentView, setCurrentView] = useState<'dashboard' | 'list'>('dashboard');
  const [totalBuildings, setTotalBuildings] = useState(0);
  const [selectedBenchmark, setSelectedBenchmark] = useState<BenchmarkType>({
    id: ENTIRE_COMPANY,
    name: t('EsgAnalysisBenchmark_EntireCompany'),
  });
  return (
    <Container sx={{ mb: 1, pb: 30, minHeight: '100vh' }} maxWidth="lg">
      <PortfolioAnalysisHeader
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        setShowTotal={setShowTotal}
        setCurrentView={setCurrentView}
        currentView={currentView}
        setSelectedBenchmark={setSelectedBenchmark}
        floorAreaByBuildingClass={floorAreaByBuildingClass}
        totalBuildings={totalBuildings}
      />

      <PortfolioAnalysisBody
        currentPageView={'esg-portfolio'}
        setFloorAreaByBuildingClass={setFloorAreaByBuildingClass}
        currentTab={currentTab}
        showTotal={showTotal}
        selectedBenchmark={selectedBenchmark}
        currentView={currentView}
        setTotalBuildings={setTotalBuildings}
      />
    </Container>
  );
}
