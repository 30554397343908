/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery, type useSubscription } from '@apollo/client';
import { Alert, AlertTitle, Button, Typography } from '@mui/material';
import { async_job_status_type_enum, emission_factor_type_enum } from '@predium/enums';
import { translateEmissionFactorTypeEnum } from '@predium/i18n/client';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FullScreenLoader from '../../../components/FullScreenLoader';
import Iconify from '../../../components/Iconify';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import PreDialog from '../../../components/presentations/PreDialog/PreDialog';
import { PreLink } from '../../../components/presentations/PreLink';
import { UPDATE_ORG_PREFERENCE_EMISSION_FACTOR_TYPE } from '../../../graphql/common.mutations';
import { ASYNC_JOB_SUBSCRIPTION_ORG, GET_ORG_PREFERENCES } from '../../../graphql/common.queries';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import useSessionData from '../../../hooks/useSessionData';
import { PATHS } from '../../../routes';

type Props = {
  open: boolean;
  onClose: () => void;
  currentEmissionFactor?: emission_factor_type_enum;
  newEmissionFactor: emission_factor_type_enum;
  portfoliosCount: number | null;
  useSubscription: typeof useSubscription;
};

export const EmissionFactorChangeDialog = ({
  open,
  onClose,
  currentEmissionFactor,
  newEmissionFactor,
  portfoliosCount,
  useSubscription,
}: Props) => {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();
  const { enqueueSnackbar } = useSnackbar();
  const [jobStatus, setJobStatus] = useState<async_job_status_type_enum | null>(null);
  const initialPageLoad = useRef<boolean>(true);
  const { org } = useSessionData();
  const [updatePreferenceMutation, { loading }] = useMutation(UPDATE_ORG_PREFERENCE_EMISSION_FACTOR_TYPE, {
    refetchQueries: [GET_ORG_PREFERENCES],
  });

  const { data } = useQuery(GET_ORG_PREFERENCES, {
    skip: !org?.id,
  });
  useSubscription(ASYNC_JOB_SUBSCRIPTION_ORG, {
    variables: {
      title: 'RECALCULATE_ORG',
      orgId: org?.id,
    },
    onData: ({ data: subscriptionData }) => {
      //@ts-ignore
      if (!subscriptionData.data.async_job.length) {
        return;
      }

      //@ts-ignore
      const latestJob = subscriptionData.data.async_job[0];
      const status: async_job_status_type_enum = latestJob.status_id;

      setJobStatus(status);
      if (initialPageLoad.current && status !== async_job_status_type_enum.PENDING) {
        // Make sure we don't show the snackbar on initial page load if the job is already in complete / error state
        initialPageLoad.current = false;
        return;
      }
      initialPageLoad.current = false;

      if (status === async_job_status_type_enum.COMPLETED) {
        enqueueSnackbar(t('General_ChangesSavedSuccessfully'), {
          variant: 'success',
          autoHideDuration: SnackbarTimeouts.Success,
        });
      } else if (status === async_job_status_type_enum.ERROR) {
        enqueueSnackbar(t('General_FailedToSaveChanges'), {
          variant: 'error',
          autoHideDuration: SnackbarTimeouts.Error,
        });
      }
    },
  });

  const updatePreferenceEmissionFactorType = async (emissionFactorType: keyof typeof emission_factor_type_enum) => {
    // Manually set to pending to show the loading screen without latency
    setJobStatus(async_job_status_type_enum.PENDING);
    onClose();
    const result = await updatePreferenceMutation({
      variables: {
        emissionFactorTypeId: emission_factor_type_enum[emissionFactorType],
        orgId: org?.id,
      },
    });
    if (result.errors?.length !== 0 || !result.errors) {
      trackEvent('EMISSION_FACTOR_TYPE_UPDATED', {
        oldEmissionFactorType: data?.org[0].org_preferences?.emission_factor_type_id ?? null,
        newEmissionFactorType: emissionFactorType,
      });
    }
  };

  return (
    <>
      {!loading && (
        <PreDialog
          open={open}
          onClose={onClose}
          type="definedByProperty"
          maxWidth="md"
          dialogtitle={
            <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
              {currentEmissionFactor
                ? translateEmissionFactorTypeEnum(currentEmissionFactor)
                : t('EmissionFactorChangeDialog_NoOrgEmissionFactorAvailable')}
              &nbsp;
              <Iconify icon={'mdi:arrow-right-thick'} />
              &nbsp;{translateEmissionFactorTypeEnum(newEmissionFactor)}
            </Typography>
          }
          content={
            <>
              {/*@ts-ignore */}
              {portfoliosCount > 0 ? (
                <>
                  <Typography gutterBottom>{t('EmissionFactorChangeDialog_ConfirmationText')}</Typography>
                  <Alert severity="warning" sx={{ mb: 2, mt: 3 }}>
                    <AlertTitle>{t('General_CO2KeyFiguresAreBeingRecalculated')}</AlertTitle>
                    {t('UpdateOrganizationEmissionFactorDialog_Description')}
                    <PreLink
                      link={{
                        href: PATHS.org.portfolios(),
                        name: t('General_PortfoliosAffected'),
                        external: true,
                      }}
                    />
                  </Alert>
                </>
              ) : (
                <Typography>{t('EmissionFactorChangeDialog_ConfirmationText')}</Typography>
              )}
            </>
          }
          actions={
            <>
              <Button variant="outlined" onClick={onClose}>
                {t('General_Cancel')}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  updatePreferenceEmissionFactorType(newEmissionFactor);
                }}
                autoFocus
              >
                {t('General_Save')}
              </Button>
            </>
          }
        />
      )}
      <FullScreenLoader
        // TODO: Currently all navigation and interaction with our software is blocked if a job is pending.
        // Either we show the loading only if the user tries to change the emission factor type again (see commented out code below or PortfolioEditDialog.tsx)
        // Or we implement a new FullScreenLoader which does not block the navigation menu.
        open={jobStatus === async_job_status_type_enum.PENDING}
        text={t('General_CO2KeyFiguresAreBeingRecalculated')}
        disableCloseAction={true}
        // As mentioned above - this shows the loading screen only when the EmissionFactorChangeDialog is actually opened (but then hidden again from the FullScreenLoader)
        // open={open && jobStatus === async_job_status_type_enum.PENDING}
        // disableCloseAction={false}
        // onClose={() => onClose()}
      />
    </>
  );
};
