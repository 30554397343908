import { Stack, SxProps, Theme, ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps, Tooltip } from '@mui/material';
import { ReactNode, useState } from 'react';

interface Props<LeftValueType, RightValueType> extends ToggleButtonGroupProps {
  leftValue: { key: LeftValueType; value: ReactNode; tooltip?: string };
  rightValue: { key: RightValueType; value: ReactNode; tooltip?: string };
  initialValueType: LeftValueType | RightValueType | null;
  onToggle: (newValue: LeftValueType | RightValueType) => void;
  sx?: SxProps<Theme>;
}

export default function PreToggleButton<LeftValueType extends string, RightValueType extends string>({
  leftValue,
  rightValue,
  initialValueType,
  onToggle,
  sx,
  ...other
}: Props<LeftValueType, RightValueType>) {
  const [valueType, setValueType] = useState<LeftValueType | RightValueType | null>(initialValueType);
  const onChange = (_: React.MouseEvent<HTMLElement>, newValueType: LeftValueType | RightValueType | undefined) => {
    if (newValueType) {
      setValueType(newValueType);
      onToggle(newValueType);
    }
  };

  return (
    <ToggleButtonGroup {...other} exclusive value={valueType} onChange={onChange} sx={{ width: '100%', ...sx }}>
      <ToggleButton
        value={leftValue.key}
        sx={{
          width: '100%',
          textTransform: 'none',
        }}
      >
        {leftValue.tooltip ? (
          <Tooltip title={leftValue.tooltip} arrow>
            <Stack alignItems={'center'}>{leftValue.value}</Stack>
          </Tooltip>
        ) : (
          leftValue.value
        )}
      </ToggleButton>

      <ToggleButton
        value={rightValue.key}
        sx={{
          width: '100%',
          textTransform: 'none',
        }}
      >
        {rightValue.tooltip ? (
          <Tooltip title={rightValue.tooltip} arrow>
            <Stack alignItems={'center'}>{rightValue.value}</Stack>
          </Tooltip>
        ) : (
          rightValue.value
        )}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
