import { Alert, AlertTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function ActionPlanDeleteAlert({ actionPlanCount }: { actionPlanCount: number }) {
  const { t } = useTranslation();
  return (
    <Alert severity="error">
      <AlertTitle>{t('DataCollection_DeleteActionPlanAlert-title', { count: actionPlanCount })}</AlertTitle>
      {t('DataCollection_DeleteActionPlanAlert-subtitle')}
    </Alert>
  );
}

export function InconsistentDataAlert() {
  const { t } = useTranslation();
  return <Alert severity="info">{t('DataCollection_InconsistentDataAlert-subtitle')}</Alert>;
}

export function InconsistentHeatingDemandDataAlert() {
  const { t } = useTranslation();
  return <Alert severity="info">{t('DataCollection_InconsistentHeatingDemandAlert-subtitle')}</Alert>;
}

export function AccessRightsRequiredAlert() {
  const { t } = useTranslation();
  return <Alert severity="info">{t('DataCollection_BuildingEditAccessRequired-subtitle')}</Alert>;
}
