import { country_enum } from '@predium/enums';
import { TFunction } from 'i18next';

export const getExpenditureFactorTooltip = (t: TFunction, country: country_enum) => {
  if (country === country_enum.DE) {
    return t('Education_ExpenditureFactorBody-DE');
  }

  if (country === country_enum.AT) {
    return t('Education_ExpenditureFactorBody-AT');
  }

  return null;
};
