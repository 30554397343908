/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Card, CardProps, useTheme } from '@mui/material';
import { energy_source_type_enum } from '@predium/enums';
import { translateEnergySourceTypeEnum_dynamic } from '@predium/i18n/client';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import BaseOptionChart from '../../../theme/apexcharts';

export const updateStylingForLegend = (id: string) => {
  const legend = document.getElementById(id)?.querySelector<HTMLElement>('.apexcharts-legend');
  if (legend) {
    legend.style.justifyContent = 'center';
  }
};

type DataItem = {
  count: number;
  energySourceType: energy_source_type_enum;
};

interface Props extends CardProps {
  data: DataItem[];
  animated?: boolean;
}

function calculateColumnWidth(seriesLength: number) {
  return 5 + (seriesLength - 1) * 5;
}

export default function BuildingsByEnergySourceType({ data, animated }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const sortedData = [...data].sort((a, b) => (a.energySourceType < b.energySourceType ? -1 : 1));

  const categories = sortedData.map((item: DataItem) => {
    const value = translateEnergySourceTypeEnum_dynamic(item.energySourceType, t);
    return value.length < 25
      ? value
      : value.length > 30
      ? [
          value.substring(0, value.lastIndexOf(' ', 25)),
          value.substring(value.lastIndexOf(' ', Math.floor(value.length / 2)) + 1),
        ]
      : [
          value
            .split(' ')
            .slice(0, Math.floor(value.split(' ').length / 2))
            .join(' '),
          value
            .split(' ')
            .slice(Math.floor(value.split(' ').length / 2))
            .join(' '),
        ];
  });

  const series = sortedData.map((item: DataItem) => {
    return item.count;
  });

  const colors = sortedData.map((item: DataItem) => {
    return theme.palette.energySourceTypeColors[item.energySourceType as energy_source_type_enum].light;
  });

  const baseRotate = -45;
  const baseMinHeight = 140;
  const increment = 5;

  const rotate = categories.length >= 5 && baseRotate - Math.floor(categories.length / increment) * 5;
  const minHeight = categories.length >= 5 && baseMinHeight + Math.floor(categories.length / increment) * 5;

  const chartState: ApexCharts.ApexOptions = {
    colors,
    chart: {
      stacked: true,
      animations: {
        enabled: !!animated,
      },
      type: 'bar',
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val > 0 ? val : '';
      },
      style: {
        fontSize: '12px',
        colors: [theme.palette.text.primary],
      },
      offsetY: -18,
    },
    tooltip: {
      enabled: false,
    },
    title: {
      text: t('General_DistributionByEnergySource'),
    },
    xaxis: {
      categories,
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 500,
        },
        hideOverlappingLabels: false,

        //@ts-ignore
        rotate: rotate,

        //@ts-ignore
        minHeight: minHeight,
      },
    },
    yaxis: {
      title: { text: t('General_NumberOfBuildings'), style: { fontSize: '13px', fontWeight: 600 } },
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
        columnWidth: `${calculateColumnWidth(series.length)}%`,
        borderRadius: 8,
        borderRadiusApplication: 'end',
        dataLabels: {
          position: 'top',
          orientation: 'horizontal',
          hideOverflowingLabels: false,
        },
      },
    },
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'darken',
          value: 0.5,
        },
      },
    },
  };

  return (
    <Card
      sx={{
        p: 3,
      }}
    >
      <ReactApexChart
        //@ts-ignore
        type={chartState.chart.type}
        options={merge(BaseOptionChart(), chartState)}
        series={[{ data: series }]}
        height="350px"
      />
    </Card>
  );
}
