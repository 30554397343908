import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { area_type_enum } from '@predium/enums';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../../components/Iconify';
import useBuilding from '../../Context/useBuilding';
import SquareMeterRow from './SquareMeterRow';

type Props = {
  title: string;
  subtitle: string;

  onAddAreaClick?: VoidFunction;

  areas: ReactNode;
  areaSum: number;
  showAreaSum: boolean;
  listAreaType: area_type_enum;
};

// This is a constant that is used to calculate the maxHeight of the Box component
// the value is the sum of the height of the header and the footer of the page
const HEADER_FOOTER_HEIGHT = 650;

const System = ({ areas, subtitle, title, onAddAreaClick, areaSum, showAreaSum, listAreaType }: Props) => {
  const { t } = useTranslation();
  const { hasEditAccess } = useBuilding();
  const [maxAvailableHeight, setMaxAvailableHeight] = useState(() => window.innerHeight - HEADER_FOOTER_HEIGHT);

  useEffect(() => {
    const handleResize = () => {
      setMaxAvailableHeight(window.innerHeight - HEADER_FOOTER_HEIGHT);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Stack border="1px solid" borderColor="divider" borderRadius={2}>
      <Stack flex={1} pt={2} pb={1}>
        <Stack px={2} direction="row" justifyContent="space-between" mb={2} spacing={2}>
          <Stack spacing={0.75} flex={4}>
            <Typography variant="subtitle2" fontWeight={700}>
              {title}
            </Typography>
            <Typography variant="body2" color="grey.600">
              {subtitle}
            </Typography>
          </Stack>
          {hasEditAccess && onAddAreaClick && (
            <Box>
              <Button
                onClick={onAddAreaClick}
                variant="outlined"
                startIcon={<Iconify icon="mdi:plus" />}
                sx={{
                  '&.MuiButton-outlined': {
                    borderColor: 'divider',
                  },
                }}
              >
                {t('DataCollectionAreas_AddArea')}
              </Button>
            </Box>
          )}
        </Stack>
        <Stack flex={1}>
          <Box mb={1} maxHeight={maxAvailableHeight} overflow="auto">
            {areas}
          </Box>
          {showAreaSum && (
            <Box px={1}>
              <Divider sx={{ mt: 'auto', mb: 1 }} />
              <SquareMeterRow
                labels={{
                  title: t('DataCollectionAreas_Sum'),
                }}
                options={{
                  displayErrorInline: false,
                }}
                value={areaSum}
                titleProps={{ variant: 'body2', color: 'grey.600' }}
                areaType={listAreaType}
              />
            </Box>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default System;
