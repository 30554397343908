/* eslint-disable @typescript-eslint/ban-ts-comment */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import {
  energy_consumer_technology_type_enum,
  energy_source_type_enum,
  energy_system_type_enum,
  SystemRecommendationActionFragment,
} from '@predium/client-graphql';
import { forwardRef, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProvider } from '../../../../../../../components/hook-form';
import InfoTable from '../../../../../../../components/presentations/InfoTable';
import { BuildingTabEnum } from '../../../../../../../pages/DataCollection/DataCollectionBuilding';
import { useLanguage } from '../../../../../../../provider/LanguageProvider';
import { createSystemInfoTable } from '../../../../../Scenario/scenarios.util';
import { NoCostAvailableWithLink } from '../../../../Components/NoCostAvailableWithLink';
import { HeatingHotWaterActionFormSchema } from '../../../CreateActions/validation-schema';
import { TechnicalActionEditProps } from '../../TechnicalAction';
import { HeatingHotWaterDefaultType } from './HeatingAction';
import TechnicalCommonFields from './TechnicalCommonFields';

const HotWaterAction = forwardRef(
  (
    {
      previousActionBuildingModel,
      resetSimulatedData,
      minimumDate,
      loading,
      action,
    }: TechnicalActionEditProps & {
      action: SystemRecommendationActionFragment;
    },
    ref,
  ) => {
    const { language } = useLanguage();

    const { t } = useTranslation();
    const currentActionType = energy_system_type_enum.HOT_WATER;
    const parameters = action.parameters;

    const defaultValues: HeatingHotWaterDefaultType = {
      system_renovation_parameter: {
        consumer_technology_type: parameters.consumer_technology_type ?? ('' as energy_consumer_technology_type_enum),
        efficiency: parameters.efficiency ?? undefined,
        energy_source_type: parameters.energy_source_type ?? ('' as energy_source_type_enum),
        cost_per_m2: parameters.cost_per_m2 ? parseFloat(parameters.cost_per_m2.toFixed(2)) : undefined,
        technology_name: parameters.technology_name ?? '',
        system_type: currentActionType,
      },
      implementation_to: minimumDate,
      //TODO: shouldn't we check here type of selected system? no check in the original code
      renovation_ids: previousActionBuildingModel.energy_systems.map((system) => system.id),
    };

    const buildingId = previousActionBuildingModel.building!.id;

    const formMethods = useForm<HeatingHotWaterDefaultType>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: defaultValues,
      resolver: yupResolver(HeatingHotWaterActionFormSchema(minimumDate, language)),
    });

    useImperativeHandle(ref, () => ({
      methods: formMethods,
    }));

    const infoTableData = createSystemInfoTable(currentActionType, previousActionBuildingModel, t);

    return (
      <FormProvider methods={formMethods}>
        <Box my={3}>
          {infoTableData && (
            <Box mb={3}>
              <InfoTable
                buildingId={buildingId}
                showTab={BuildingTabEnum.technology}
                data={infoTableData}
                title={t('General_StateBeforeRenovation')}
              />
            </Box>
          )}

          <TechnicalCommonFields
            selectedActionType={currentActionType}
            minimumDate={minimumDate}
            loading={loading}
            resetSimulatedData={resetSimulatedData}
            noCostAvailableMessage={<NoCostAvailableWithLink />}
          />
        </Box>
      </FormProvider>
    );
  },
);

export default HotWaterAction;
