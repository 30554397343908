import { Stack, Typography } from '@mui/material';
import { fPercent, fShortenNumber } from '@predium/utils';
import isNil from 'lodash/isNil';

type Props = {
  title: string;
  value?: number;
  unit: string;
  percentage?: number;
  hidePercentage?: boolean;
};

const Meter = ({ title, value, percentage, unit, hidePercentage }: Props) => {
  const percentageLabel = !isNil(percentage) ? `${fPercent(percentage, 0)}` : '— %';

  return (
    <Stack direction="row">
      <Typography variant="body2" color="text.secondary" mr={2}>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary" mr={1} ml="auto">
        {!hidePercentage && percentageLabel}
      </Typography>
      <Typography variant="body2" fontWeight={700} minWidth={110} textAlign="right">
        {!isNil(value) ? fShortenNumber(value) : '—'} {unit}
      </Typography>
    </Stack>
  );
};

export default Meter;
