import { useMutation } from '@apollo/client';
import { Box, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataCollectionSubBuildingConsumptionFragment,
  consumption_source_type_enum,
} from '../../../../../libs/generated/client-graphql/src';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import { SnackbarTimeouts } from '../../components/NotistackProvider';
import Scrollbar from '../../components/Scrollbar';
import { WarningModal } from '../../components/WarningModal';
import AccessRightsWrapper from '../../components/permission-tooltips/AccessRightsWrapper';
import PreDialog, { PreDialogBody } from '../../components/presentations/PreDialog/PreDialog';
import { PermissionType } from '../../contexts/PermissionContext';
import { DELETE_CONSUMPTION } from '../../graphql/DataCollection.mutations';
import { GET_CONSUMPTION_SUMMARIES } from '../../graphql/DataCollection.queries';
import usePermissions from '../../hooks/usePermissions';
import SubBuildingConsumptionInvoiceDraft from '../../sections/DataCollection/Building/Consumption/SubBuildingConsumptionInvoiceDraft';
import SubBuildingSingleConsumptionForm from '../../sections/DataCollection/Building/Drafts/SubBuildingSingleConsumptionForm';

type Props = {
  buildingId: number;
  subBuildingId: number;
  isOpen: boolean;
  onClose: () => void;
  consumptionData: DataCollectionSubBuildingConsumptionFragment | null;
};

export default function DataCollectionSingleConsumptionModal({
  buildingId,
  subBuildingId,
  isOpen,
  onClose,
  consumptionData,
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [childFormIsDirty, setChildFormIsDirty] = useState(false);
  const [isOpenWarningDialog, setIsOpenWarningDialog] = useState(false);

  const { checkBuildingPermission } = usePermissions();

  const [deleteConsumptionMutation] = useMutation(DELETE_CONSUMPTION, {
    variables: {
      id: consumptionData?.id!,
    },
    onCompleted: () => {
      enqueueSnackbar(t('DataCollection_ConsumptionDelete-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar(t('DataCollection_ConsumptionDelete-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    update: (cache, { data }) => {
      if (data?.delete_consumption_by_pk) {
        const normalizedId = cache.identify({
          id: data.delete_consumption_by_pk.id,
          __typename: data.delete_consumption_by_pk.__typename,
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
    refetchQueries: [GET_CONSUMPTION_SUMMARIES],
  });

  const hasEditAccess = checkBuildingPermission(buildingId, PermissionType.WRITE);

  return (
    <>
      <PreDialog
        open={isOpen}
        onClose={() => {
          if (childFormIsDirty) {
            setIsOpenWarningDialog(true);
          } else {
            onClose();
          }
        }}
        type="definedByChildren"
        maxWidth="sm"
        fullWidth
      >
        <SubBuildingSingleConsumptionForm
          subBuildingId={subBuildingId}
          buildingId={buildingId}
          consumptionData={consumptionData!}
          handleClose={onClose}
          setChildFormIsDirty={setChildFormIsDirty}
          renderChildren={(submitForm) => (
            <>
              <PreDialogBody
                dialogtitle={
                  consumptionData?.source_type_id === consumption_source_type_enum.ESTIMATION
                    ? t('DataCollection_ConsumptionModalTitle-estimation')
                    : t('PageName_DataCollection_Consumption')
                }
                content={
                  <Scrollbar
                    sx={{
                      // make actions sticky and content scrollable by fixing maxHeight
                      // full height - dialog top margin - dialog bottom margin - header - actions
                      maxHeight: 'calc(100vh - 32px - 32px - 76px - 84px)',
                    }}
                  >
                    <SubBuildingConsumptionInvoiceDraft isSingleConsumption disabled={!hasEditAccess} />
                  </Scrollbar>
                }
                actions={
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <AccessRightsWrapper hasAccess={hasEditAccess}>
                      <Button
                        variant="text"
                        color="error"
                        disabled={!consumptionData}
                        onClick={() => setDeleteModalOpen(true)}
                      >
                        {t('General_Delete')}
                      </Button>
                    </AccessRightsWrapper>

                    <AccessRightsWrapper hasAccess={hasEditAccess}>
                      <Button variant="contained" type="submit">
                        {t('General_Save')}
                      </Button>
                    </AccessRightsWrapper>
                  </Box>
                }
              />

              {isOpenWarningDialog && (
                <WarningModal
                  title={t('General_Note')}
                  open={isOpenWarningDialog}
                  description={t('DataCollection_BuildingDocuments_UnsavedChangesInDraft-Message')}
                  onAcknowledge={() => {
                    submitForm().then(() => {
                      setIsOpenWarningDialog(false);
                      onClose();
                    });
                  }}
                  onClose={() => {
                    setIsOpenWarningDialog(false);
                    onClose();
                  }}
                  buttonText={t('General_Save')}
                  cancelText={t('General_Leave')}
                />
              )}
            </>
          )}
        />
      </PreDialog>

      {/* Dont render all dialogs in a list but dont cut animation short. */}
      {isOpen && (
        <DeleteConfirmationModal
          open={deleteModalOpen}
          title={t('DataCollection_DeleteModalTitle-Consumption')}
          description={t('DataCollection_DeleteModalDescription-Consumption')}
          onClose={() => {
            setDeleteModalOpen(false);
          }}
          onDelete={async () => {
            deleteConsumptionMutation();
          }}
        />
      )}
    </>
  );
}
