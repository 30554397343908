import { Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material/styles';
import { ensureDefined } from '@predium/utils';
import createAvatar from '../utils/createAvatar';

interface InlineUserProps extends React.ComponentProps<typeof Avatar> {
  firstName?: string | null;
  lastName?: string | null;
  onClick?: VoidFunction;
  size?: number;
  showNameAsTooltip?: boolean;
}

export default function InlineUser({
  firstName,
  lastName,
  onClick,
  size = 40,
  showNameAsTooltip = false,
  sx,
}: InlineUserProps) {
  const theme: Theme = useTheme();

  const fontSize = size > 30 ? theme.typography.body1.fontSize : theme.typography.body2.fontSize;

  const fullName = [firstName, lastName].filter(Boolean).join(' ');

  const { name, color } = createAvatar(ensureDefined(firstName), ensureDefined(lastName));

  const avatar = (
    <Avatar sx={{ width: size, height: size, fontSize }} color={color}>
      {name}
    </Avatar>
  );

  return (
    <Stack direction="row" alignItems="center" onClick={onClick} sx={{ ...sx }}>
      {showNameAsTooltip ? (
        <Tooltip title={fullName} arrow>
          {avatar}
        </Tooltip>
      ) : (
        <>
          {avatar}
          <Typography sx={{ pl: 2, fontSize }} variant="subtitle1">
            {fullName}
          </Typography>
        </>
      )}
    </Stack>
  );
}
