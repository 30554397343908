import { Button, Typography } from '@mui/material';
import * as sentry from '@sentry/react';
import { PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { IMAGES } from '../../assets/images';

type Props = {
  pageName: string;
};

/**
 * A generic boundary for a particular page.
 * The user can still use other parts of the application.
 */
function PageErrorBoundary({ children, pageName }: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ErrorBoundary
      onError={(error, info) => {
        sentry.captureException(error, {
          extra: { info, location: location.pathname, pageName },
        });
      }}
      key={pageName}
      fallbackRender={({ resetErrorBoundary }) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '2rem',
            // respecting the padding of the main component
            height: 'calc(100vh - 84px)',
          }}
        >
          <img src={IMAGES.ErrorFallbackPage} alt="A file icon with a smiley looking in a unsatisfied way" />
          <Typography variant="h3">{t('General_AnErrorHasOccurred')}</Typography>
          <Typography textAlign="center" maxWidth={600}>
            <Trans i18nKey="PageErrorBoundary_Text" values={{ pageName: pageName }} components={{ bold: <strong /> }} />
          </Typography>

          <Button onClick={resetErrorBoundary}>{t('General_TryAgain')}</Button>
          <Button
            variant="contained"
            onClick={() => {
              navigate(-1);
              resetErrorBoundary();
            }}
          >
            {t('General_ReturnToPreviousPage')}
          </Button>
        </div>
      )}
    >
      {children}
    </ErrorBoundary>
  );
}

export default PageErrorBoundary;
