import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  consumption_frequency_type_enum,
  DataCollectionGetConsumptionInvoiceQuery,
  DataCollectionSaveConsumptionInvoiceMutation,
} from '@predium/client-graphql';
import { convertStandardUnitsToUserInput } from '@predium/client-lookup';
import { consumption_unit_enum, invoice_payer_type_enum } from '@predium/enums';
import { useSnackbar } from 'notistack';
import { flushSync } from 'react-dom';
import { FieldErrorsImpl, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import { FormProvider } from '../../../../components/hook-form';
import { UPDATE_CONSUMPTION_INVOICE } from '../../../../graphql/DataCollection.mutations';
import { GET_BUILDING, GET_CONSUMPTION_SUMMARIES } from '../../../../graphql/DataCollection.queries';
import { BuildingTabEnum } from '../../../../pages/DataCollection/DataCollectionBuilding';
import { PATHS } from '../../../../routes/paths';
import { constructConsumptionValidationSchema, SingleConsumptionFormData } from './SubBuildingSingleConsumptionForm';

/**
 * Extends the SingleConsumptionFormData with the invoice data.
 */
type ConsumptionInvoiceFormData = SingleConsumptionFormData & {
  invoiceDate: string;
  invoicePayer: string;
};

type Props = {
  consumptionInvoiceData: NonNullable<DataCollectionGetConsumptionInvoiceQuery['consumption_invoice_by_pk']>;
  goToNextInvoice: () => void;
  renderChildren: (
    submitForm: () => Promise<DataCollectionSaveConsumptionInvoiceMutation>,
    errors: Partial<FieldErrorsImpl<ConsumptionInvoiceFormData>>,
  ) => JSX.Element;
};

function SubBuildingConsumptionForm({ consumptionInvoiceData, goToNextInvoice, renderChildren }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [saveConsumptionInvoice] = useMutation(UPDATE_CONSUMPTION_INVOICE, {
    onCompleted: () => {
      enqueueSnackbar(t('DataCollectionSubBuildingConsumption_DataUpdated-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
    onError: () => {
      enqueueSnackbar(t('DataCollectionSubBuildingConsumption_DataUpdated-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    refetchQueries: [
      {
        query: GET_BUILDING,
        variables: {
          buildingId: consumptionInvoiceData.building.id,
          year: new Date().getFullYear(),
        },
      },
      {
        query: GET_CONSUMPTION_SUMMARIES,
        variables: { buildingId: consumptionInvoiceData.building.id, year: new Date().getFullYear() },
      },
    ],
  });

  const DataSchema = constructConsumptionValidationSchema({
    t,
  });
  // For the invoice itself
  const INVOICE_DATE_MESSAGE = t('DataCollection_ValidationInvoiceDateRequired');
  const INVOICE_PAYER_MESSAGE = t('DataCollection_ValidationInvoicePayerRequired');
  const InvoiceSchema = Yup.object().shape({
    invoiceDate: Yup.date().typeError(INVOICE_DATE_MESSAGE).required(INVOICE_DATE_MESSAGE),
    invoicePayer: Yup.string().typeError(INVOICE_PAYER_MESSAGE).required(INVOICE_PAYER_MESSAGE),
  });

  const { date, invoice_payer, consumptions, building } = consumptionInvoiceData;

  const sharedConsumptionData = consumptions[0]!;
  const { area_id, from, to, provider } = sharedConsumptionData;

  const methods = useForm<ConsumptionInvoiceFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    resolver: yupResolver(DataSchema.concat(InvoiceSchema)),
    shouldFocusError: false,
    defaultValues: {
      buildingId: building?.id,

      // For the invoice itself
      invoiceDate: date ?? '',
      invoicePayer: invoice_payer ?? '',

      provider: provider ?? '',
      from: from,
      to: to,
      areaId: area_id,

      consumptionDrafts: consumptions.map(
        ({
          id,
          value,
          display_unit_value,
          cost,
          consumption_type_id,
          energy_source_type_id,
          sub_type_id,
          consumption_waste_detail,
        }) => ({
          id,
          consumptionTypeId: consumption_type_id,
          energySourceTypeId: energy_source_type_id ?? undefined,
          subTypeId: sub_type_id ?? undefined,
          value:
            value && display_unit_value
              ? parseFloat(
                  convertStandardUnitsToUserInput(
                    consumption_type_id,
                    display_unit_value,
                    value,
                    (energy_source_type_id ?? sub_type_id)!,
                  ).toFixed(4),
                )
              : value ?? undefined,
          displayUnitValue: display_unit_value ?? ('' as consumption_unit_enum),
          cost: cost ?? undefined,

          // TRASH CONSUMPTION ONLY
          waste: consumption_waste_detail
            ? {
                id: consumption_waste_detail?.id ?? undefined,
                volume:
                  consumption_waste_detail?.volume && consumption_waste_detail.display_unit_volume
                    ? parseFloat(
                        convertStandardUnitsToUserInput(
                          consumption_type_id,
                          consumption_waste_detail.display_unit_volume,
                          consumption_waste_detail.volume,
                          (energy_source_type_id ?? sub_type_id)!,
                        ).toFixed(4),
                      )
                    : consumption_waste_detail?.volume ?? undefined,
                displayUnitVolume: consumption_waste_detail?.display_unit_volume ?? ('' as consumption_unit_enum),
                frequency:
                  consumption_waste_detail?.frequency && consumption_waste_detail.display_unit_frequency
                    ? parseFloat(
                        convertStandardUnitsToUserInput(
                          consumption_type_id,
                          consumption_waste_detail.display_unit_frequency,
                          consumption_waste_detail.frequency,
                          (energy_source_type_id ?? sub_type_id)!,
                        ).toFixed(4),
                      )
                    : consumption_waste_detail?.frequency ?? undefined,
                displayUnitFrequency:
                  consumption_waste_detail?.display_unit_frequency ?? ('' as consumption_frequency_type_enum),
                amount: consumption_waste_detail?.amount ?? undefined,
                price: consumption_waste_detail?.price ?? undefined,
              }
            : undefined,
        }),
      ),
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = methods;

  const saveForm = async (data: ConsumptionInvoiceFormData) => {
    const {
      buildingId,
      invoiceDate,
      invoicePayer,
      consumptionDrafts,
      from: sharedFrom,
      to: sharedTo,
      areaId: sharedAreaId,
      provider: sharedProvider,
    } = data;

    const { data: mutationResult } = await saveConsumptionInvoice({
      variables: {
        buildingId,
        consumptionInvoiceId: consumptionInvoiceData.id,
        invoiceDate: invoiceDate as DateTime,
        invoicePayer: invoicePayer as invoice_payer_type_enum,

        consumptions: consumptionDrafts.map(({ waste, ...consumption }) => ({
          ...consumption,
          subTypeId: consumption.subTypeId ?? null,
          consumptionTypeId: consumption.consumptionTypeId ?? null,
          displayUnitValue: consumption.displayUnitValue || undefined,
          from: sharedFrom,
          to: sharedTo,
          areaId: sharedAreaId,
          provider: sharedProvider,

          ...(waste && Object.values(waste).some((value) => value !== undefined)
            ? {
                wasteDetails: {
                  id: waste.id,
                  volume: waste.volume,
                  displayUnitVolume: waste.displayUnitVolume || undefined,
                  frequency: waste.frequency,
                  displayUnitFrequency: waste.displayUnitFrequency || undefined,
                  amount: waste.amount,
                  price: waste.price,
                },
              }
            : {}),
        })),
      },
      onCompleted: ({ updateConsumptionInvoice }) => {
        // Reset dirty fields for NavigationModal
        flushSync(() => {
          reset(undefined, { keepValues: true, keepErrors: true, keepIsSubmitted: true });
        });

        const newBuildingId = updateConsumptionInvoice.UpdateConsumptionInvoiceOutput?.building.id!;

        const hasBuildingChanged = newBuildingId !== building.id;

        if (hasBuildingChanged) {
          // Go to same consumption on new building
          navigate(
            PATHS.dataCollection.building(
              {
                id: newBuildingId,
              },
              { tab: BuildingTabEnum.consumption },
            ),
          );
        } else {
          goToNextInvoice();
        }
      },
    });

    return mutationResult;
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(saveForm)}>
      {/* @ts-ignore */}
      {renderChildren(handleSubmit(saveForm), errors)}
    </FormProvider>
  );
}

export default SubBuildingConsumptionForm;
