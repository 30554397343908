/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useLazyQuery } from '@apollo/client';
import {
  GetSessionDataOrgFragment,
  GetSessionDataUserFragment,
  ServerSideFeatureFlagsFragment,
  language_enum,
  role_enum,
} from '@predium/client-graphql';
import * as Sentry from '@sentry/react';
import { usePostHog } from 'posthog-js/react';
import { ReactNode, createContext, useEffect, useState } from 'react';
import { GET_SESSION_DATA } from '../graphql/Users.queries';
import useAccessToken from '../hooks/useAccessToken';
import { usePlanhat } from '../hooks/usePlanhat';
import { useLanguage } from '../provider/LanguageProvider';

interface SessionData {
  user: GetSessionDataUserFragment | undefined;
  org: GetSessionDataOrgFragment | undefined;
  serverSideFeatureFlags: ServerSideFeatureFlagsFragment;
  isAdmin: boolean;
}

const initialState: SessionData = {
  user: undefined,
  org: undefined,
  serverSideFeatureFlags: {
    AREA_MANAGEMENT: false,
    BUILDING_CREATION_CONSUMER_EXTENSION: false,
    CONFIGMANAGER_EMISSIONFACTORTYPE_DISABLED: false,
    CONSUMPTION_ANALYSIS: false,
    HEATING_DEMAND_EDITABLE: false,
    RECOMMENDED_ACTIONS: false,
  },
  isAdmin: false,
};

const SessionDataContext = createContext(initialState);

type SessionDataProviderProps = {
  children: ReactNode;
};

function SessionDataProvider({ children }: SessionDataProviderProps) {
  const [sessionData, setSessionData] = useState<SessionData>({
    user: undefined,
    org: undefined,
    serverSideFeatureFlags: {
      AREA_MANAGEMENT: false,
      BUILDING_CREATION_CONSUMER_EXTENSION: false,
      CONFIGMANAGER_EMISSIONFACTORTYPE_DISABLED: false,
      CONSUMPTION_ANALYSIS: false,
      HEATING_DEMAND_EDITABLE: false,
      RECOMMENDED_ACTIONS: false,
    },
    isAdmin: false,
  });
  const { idTokenClaims } = useAccessToken();
  const [loadSessionData, { data }] = useLazyQuery(GET_SESSION_DATA);
  const posthog = usePostHog();
  const { setLanguage, language } = useLanguage();
  const { identify } = usePlanhat();

  useEffect(() => {
    const ac = new AbortController();

    if (!idTokenClaims) {
      return;
    }
    const userId = idTokenClaims?.['x-hasura-user-id'];

    if (userId) {
      loadSessionData({ variables: { userId } });
    }

    return () => ac.abort();
  }, [idTokenClaims, loadSessionData]);

  useEffect(() => {
    if (data?.user_by_pk) {
      // all the information we want to track about the user
      const userData = {
        email: data.user_by_pk.email,
        first_name: data.user_by_pk.first_name,
        last_name: data.user_by_pk.last_name,
        orgId: data.user_by_pk.org.id,
        orgName: data.user_by_pk.org.name,
      };

      setSessionData({
        user: data.user_by_pk,
        org: data.user_by_pk.org,
        serverSideFeatureFlags: data.user_by_pk.featureFlags,
        isAdmin: data.user_by_pk.user_role.some((role) => role.role === role_enum.ORG_ADMIN),
      });

      // set language state which adapts the client translations and sets the language header for the backend
      // if the language is DEBUG, we don't want to set the language state from server
      if (language !== ('DEBUG' as language_enum)) {
        setLanguage(data.user_by_pk.language);
      }

      if (posthog) {
        posthog.identify(data.user_by_pk.id.toString(), userData);
        posthog.people.set({
          orgId: data.user_by_pk.org.id,
          orgName: data.user_by_pk.org.name,
        });

        posthog.group('orgId', data.user_by_pk.org.id.toString());
      }

      Sentry.setUser({
        id: data.user_by_pk.id,
        orgId: data.user_by_pk.org.id,
        role: data.user_by_pk.user_role[0].role,
      });

      Sentry.setTags({
        language: data.user_by_pk.language,
        ...data.user_by_pk.featureFlags,
      });

      identify(data.user_by_pk.id, userData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, posthog, setLanguage]);

  return (
    <SessionDataContext.Provider
      value={{
        user: sessionData.user,
        org: sessionData.org,
        serverSideFeatureFlags: sessionData.serverSideFeatureFlags,
        isAdmin: sessionData.isAdmin,
      }}
    >
      {children}
    </SessionDataContext.Provider>
  );
}

export { SessionDataContext, SessionDataProvider };
